import React, { useEffect, useState } from "react";
import "./TradingCompetition.css";
import { TradingCompetitorUserStatic } from "components/TradingCompetition/TradingCompetitorUserStatic";

export const Round3 = () => {
  const dataLeaderboard = [
    { position: "🏆", address: "0x070E....f616d6", rank: 16, percentage: "75.0%", balance: "50704.08$" },
    { position: "🥈", address: "0x9978....630428", rank: 5, percentage: "80.0%", balance: "36277.72$" },
    { position: "🥉", address: "0x720F....81A348", rank: 1, percentage: "100.0%", balance: "30198.52$" },
    { position: 4, address: "0x2898....7dC7f6", rank: 27, percentage: "85.2%", balance: "28394.69$" },
    { position: 5, address: "0x25CC....F47B9f", rank: 9, percentage: "77.8%", balance: "17424.53$" },
    { position: 6, address: "0x50C7....d72E51", rank: 10, percentage: "70.0%", balance: "10210.01$" },
    { position: 7, address: "0xb901....844EEe", rank: 4, percentage: "100.0%", balance: "1195.24$" },
    { position: 8, address: "0x9B56....D4B107", rank: 3, percentage: "100.0%", balance: "818.48$" },
    { position: 9, address: "0x40E1....E960E3", rank: 3, percentage: "100.0%", balance: "68.33$" },
    { position: 10, address: "0x173a....20dF08", rank: 4, percentage: "0.0%", balance: "-118.14$" },
    { position: 11, address: "0xc76E....d80399", rank: 15, percentage: "13.3%", balance: "-326.07$" },
    { position: 12, address: "0x3270....0cE960", rank: 7, percentage: "0.0%", balance: "-5845.64$" },
    { position: 13, address: "0xF043....05dF2e", rank: 5, percentage: "20.0%", balance: "-13932.30$" },
    { position: 14, address: "0x2B78....6A27e1", rank: 6, percentage: "16.7%", balance: "-14935.57$" },
    { position: 15, address: "0x26DB....f010c1", rank: 4, percentage: "0.0%", balance: "-18158.92$" },
    { position: 16, address: "0x5710....b51400", rank: 2, percentage: "0.0%", balance: "-28558.03$" },
    { position: 17, address: "0x5fDc....8a0AAD", rank: 1, percentage: "0.0%", balance: "-36009.53$" },
    { position: 18, address: "0x5477....9D195a", rank: 1, percentage: "0.0%", balance: "-47400.00$" },
    { position: 19, address: "0x5A55....0E0814", rank: 12, percentage: "0.0%", balance: "-98898.56$" },
    { position: 20, address: "0x346f....A137F3", rank: 5, percentage: "0.0%", balance: "-134300.00$" },
    { position: 21, address: "0xd257....CCC7c0", rank: 17, percentage: "47.1%", balance: "-217216.83$" },
  ];

  const updatedDataLeaderboard = dataLeaderboard.map((item) => ({
    ...item,
    balance: item.balance.replace("$", ""),
  }));

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
      <div className="TradingTitle">
        <h1 className="orange-title">🏆 TRADING CONTEST LEADERBOARD - ROUND 3 🏆</h1>
      </div>

      <div className="TradingButtons">
        <p style={{ fontSize: "18px" }}>From November 19, 2023, at 10:00 UTC to December 3, 2023, at 10:00 UTC</p>
      </div>

      <div className="TradingCompetition">
        <div className="TradingCompetitionHeader">
          <div className="competition-info">Rank</div>
          <div className="competition-info">Address</div>
          <div className="competition-info">Trades</div>
          <div className="competition-info">Winrate</div>
          <div className="competition-info">PnL</div>
        </div>
      </div>

      {Array.isArray(updatedDataLeaderboard)
        ? updatedDataLeaderboard.map((tradingCompetitor, index) => {
            return <TradingCompetitorUserStatic index={index + 1} tradingCompetitor={tradingCompetitor} key={index} />;
          })
        : ""}
    </div>
  );
};

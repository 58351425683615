import cx from "classnames";

import "./Tab.css";
import SpinningLoader from "components/Common/SpinningLoader";

export default function Tab(props) {
  const { options, option, optionStatus, setOption, onChange, type = "block", className, optionLabels, icons } = props;
  const onClick = (opt) => {
    if (setOption) {
      setOption(opt);
    }
    if (onChange) {
      onChange(opt);
    }
  };

  return (
    <div className={cx("Tab", type, className)}>
      {options.map((opt) => {
        const label = optionLabels && optionLabels[opt] ? optionLabels[opt] : opt;
        const status = optionStatus && optionStatus[opt];
        return (
          <div
            disabled={true}
            className={cx(
              "Tab-option",
              "muted",
              { green: "Long" === opt },
              { reed: "Short" === opt },
              { blue: "Swap" === opt },
              { active: opt === option }
            )}
            onClick={() => onClick(opt)}
            key={opt}
            style={{ fontFamily: "Lato", fontSize: "14px" }}
          >
            {status == 1 && (
              <span>
                {" "}
                <SpinningLoader />{" "}
              </span>
            )}

            <span className="white">{label}</span>
          </div>
        );
      })}
    </div>
  );
}

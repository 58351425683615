import cx from "classnames";

import { Trans, t, select } from "@lingui/macro";
import { isPositionStillInProcessingTimeframe } from "./PositionsLogic";
import GifLoad from "../../img/gif-unscreen.gif";

export default function ProcessingPosition(props) {
  const { chainId, position, batchData, cleanPairs, setOurSelectedToken, onSelectToken } = props;
  if (!isPositionStillInProcessingTimeframe(position, chainId, batchData)) return null;

  let parts = position.pairName.split("/");
  const image = require(`../../img/tokenlogo/${parts[0]}${parts[1]}.svg`).default;
  return (
    <div key={position.orderId}>
      <div
        id="PositionsList"
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "12px",
          height: "40px",
          minHeight: "40px",
          maxHeight: "40px",
          padding: "5px 0px",
        }}
      >
        <div style={{ textAlign: "center", width: "8%" }}>
          <span style={{ textTransform: "uppercase", color: "#DEDEDE" }}>
            {position.open ? t`Opening` : t`Closing`}
          </span>
        </div>

        <div style={{ width: "1.7%", textAlign: "center" }}>
          <span>&nbsp;</span>
        </div>

        <div
          style={{
            textAlign: "center",
            width: "8%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-around",
          }}
          className="element-par-img"
          onClick={() => {
            let pairName = position.pairName;
            let par = cleanPairs.find((p) => p[0] == pairName);
            let pairSymbols = par[0];

            const pairIndex = cleanPairs.find((i) => {
              return i[0] === pairName;
            });

            const precioPair = cleanPairs.find((i) => {
              return i[0] === pairName;
            });

            const symbol = pairSymbols.split("/")[0];

            const token = {
              pairSymbols: pairSymbols,
              symbol: symbol,
              pairName: par[7],
              pairIndex: pairIndex[1],
              price: precioPair[4],
              marketStatus: precioPair[5],
              pairFullName: precioPair[0],
            };

            setOurSelectedToken(token);
            onSelectToken(onSelectToken);
          }}
        >
          <div style={{ width: "15%" }}>
            <img src={image} width="100%" />
          </div>
          <div style={{ width: "85%" }}>
            <span>{position.pairName}</span>
          </div>
        </div>

        <div
          className="text-stuck-regular"
          style={{
            width: "60%",
            textTransform: "uppercase",
            textAlign: "center",
            fontStyle: "italic",
            marginTop: "2px",
          }}
        >
          <span>
            <span className="normal-text">⏳</span> REQUESTING REAL TIME PRICE FROM ORACLES...{" "}
            <span className="normal-text">⏳</span>
          </span>
        </div>

        <div style={{ textAlign: "center", width: "8%" }}>
          <img src={GifLoad} width="60%" />
        </div>
      </div>
    </div>
  );
}

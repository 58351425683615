import "./Fuckies.css";
import FuckiesTitle from "./img/fuckies-exchange.png";
import Escala from "./img/escala.png";
import priceBack from "../../img/fuckies/price-back.png";
import botonImage from "../../img/fuckies/BOTON-1.png";
import fuckiesButton from "../../img/fuckies/testFuckies.png";
import boxElement from "../../img/fuckies/box-fondo.png";

import FUCKIESTESTNETABI from "../../abis/DexynthFuckies_Testnet.json";
import FUCKIESTRADEABI from "../../abis/DexynthFuckiesTrade.json";

// Biblioteca para trabajar con eth y smart contracts
import { ethers } from "ethers";
// Esto es un hook que nos proporciona el identificador de cadena actual que indica en que red de eth estamos trabajando
import { useChainId } from "lib/chains";
// Función que nos ayuda a obtener la dirección del contrato inteligente
import { getContract } from "config/contracts";
import { useContext, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import FuckiesButtons from "./FuckiesButtons";
import AppContext from "AppContext";
import { USDC, dUSDC, DEXY, Fuckies } from "lib/contracts/dexynthContracts";
import BUSD_ABI from "abis/USDT.json";
import DEXY_ABI from "abis/DexynthToken_Testnet.json";
import DBUSD_ABI from "abis/DexynthTokenVault.json";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import { useLocalStorageByChainId } from "../../lib/localStorage";
import { usePairPrices } from "hooks/usePairsPrices";

const BUY = "BUY";
const SELL = "SELL";

export const FuckiesComponent = () => {
  // Estados de la aplicación
  const [busdToClaim, setBusdToClaim] = useState(0);
  const [fuckiesBalance, setFuckiesBalance] = useState(0);
  const { pares, pairs, setPairs, cleanPairs, setCleanPairs, setupPricesWebsocket, latestPrices, market24 } =
    useContext(AppContext);

  usePairPrices(pairs, cleanPairs, latestPrices, market24);

  // Obtenemos el chainId de la blockchain del hook useChainId
  const { chainId } = useChainId();

  const LIST_SECTIONS = [BUY, SELL].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-section-OTC", LIST_SECTIONS[0]);

  // Creamos un proveedor que se conecta a la red eth seleccionada en nuestro wallet
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  const FUCKIES_ADDRESS = getContract(chainId, "Fuckies");
  const FUCKIES_ADDRESS_testnet = getContract(chainId, "FuckiesTrade");

  const BUSD = USDC;
  const dBUSD = dUSDC;

  // Obtenemos la abi
  const fuckiesTestnetAbi = FUCKIESTESTNETABI.abi;
  const fuckiesTradeAbi = FUCKIESTRADEABI.abi;

  // Obtención de allowance
  const fromToken = listSection === "BUY" ? BUSD : Fuckies;
  const toToken = listSection === "SELL" ? BUSD : Fuckies;

  const tokenInfo = {
    [BUSD]: {
      address: getContract(chainId, BUSD),
      abi: BUSD_ABI,
      label: "USDT",
    },
    [dBUSD]: {
      address: getContract(chainId, dBUSD),
      abi: DBUSD_ABI,
      label: "dUSDT",
    },
    [Fuckies]: {
      address: getContract(chainId, Fuckies),
    },
  };

  // Obtenemos el signer que es el encargado de firmar transacciones y mensajes
  const signer = provider.getSigner();

  const fuckiesContract = new ethers.Contract(FUCKIES_ADDRESS, fuckiesTestnetAbi, signer);
  const fuckiesTradeContract = new ethers.Contract(FUCKIES_ADDRESS_testnet, fuckiesTradeAbi, signer);

  const { active, account, library } = useWeb3React();

  // Allowance
  const { data: tokenAllowance } = useSWR(
    active && [
      `OtcSwap:tokenAllowance:${active}`,
      chainId,
      tokenInfo[fromToken].address,
      "allowance",
      account,
      tokenInfo[dBUSD].address,
    ],
    {
      fetcher: contractFetcher(library, tokenInfo[fromToken].abi),
    }
  );

  async function getBusdToClaim(account) {
    const busdClaim = await fuckiesTradeContract.getBUSDToClaim(account);
    const fuckiesBal = await fuckiesTradeContract.getFUCKIESBalance(account);
    const fuckiesParse = ethers.BigNumber.from(fuckiesBal);
    const busdToClaimTest = parseInt(busdClaim, 16);

    // setear valores
    setBusdToClaim(busdToClaimTest);
    setFuckiesBalance(Math.ceil(fuckiesParse.toString() / 1e18));
  }

  const getTestFuckies = async () => {
    await fuckiesContract.getFreeTokens();
  };

  useEffect(() => {
    setupPricesWebsocket();
    (async () => {
      await getBusdToClaim(account);
    })();
  }, []);

  return (
    <div className="fuckies">
      <div className="fuckies-container">
        <div className="fuckies-title" style={{ marginTop: "20px", width: "100%" }}>
          <img style={{ width: "100%" }} src={FuckiesTitle} />
        </div>

        <div className="fuckies-elements">
          <div
            className="fuckies-element"
            style={{
              width: "33%",
              backgroundImage: `url(${boxElement})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          >
            <div className="element-1">
              <div>
                <p>BTC/USD</p>
              </div>
              <div>
                <p>LONG</p>
              </div>
              <div className="w-100">
                <p>$23189.12</p>
              </div>
            </div>

            <div class="element-2">
              <img src={Escala} />
            </div>

            <div className="element-3">
              <div>
                <p>S/L</p>
                <p>$23123.4</p>
              </div>
              <div>
                <p>PRICE</p>
                <p>$23123.4</p>
              </div>
              <div>
                <p>T/P</p>
                <p>$23123.4</p>
              </div>
            </div>
          </div>

          <div
            className="fuckies-element"
            style={{
              width: "33%",
              backgroundImage: `url(${boxElement})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          >
            <div className="element-1">
              <div>
                <p>BTC/USD</p>
              </div>
              <div>
                <p>LONG</p>
              </div>
              <div className="w-100">
                <p>$23189.12</p>
              </div>
            </div>

            <div class="element-2">
              <img src={Escala} />
            </div>

            <div className="element-3">
              <div>
                <p>S/L</p>
                <p>$23123.4</p>
              </div>
              <div>
                <p>PRICE</p>
                <p>$23123.4</p>
              </div>
              <div>
                <p>T/P</p>
                <p>$23123.4</p>
              </div>
            </div>
          </div>

          <div
            className="fuckies-element"
            style={{
              width: "33%",
              backgroundImage: `url(${boxElement})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          >
            <div className="element-1">
              <div>
                <p>BTC/USD</p>
              </div>
              <div>
                <p>LONG</p>
              </div>
              <div className="w-100">
                <p>$23189.12</p>
              </div>
            </div>

            <div class="element-2">
              <img src={Escala} />
            </div>

            <div className="element-3">
              <div>
                <p>S/L</p>
                <p>$23123.4</p>
              </div>
              <div>
                <p>PRICE</p>
                <p>$23123.4</p>
              </div>
              <div>
                <p>T/P</p>
                <p>$23123.4</p>
              </div>
            </div>
          </div>
        </div>

        <div className="fuckies-info">
          <div className="info-number-fuckies" style={{ backgroundImage: `${priceBack}`, backgroundSize: "cover" }}>
            <p>{fuckiesBalance || 0}</p>
          </div>

          <div className="info-number-usdt" style={{ backgroundImage: `${priceBack}`, backgroundSize: "cover" }}>
            <p>{busdToClaim || 0}</p>
          </div>
        </div>

        <div style={{ width: "10%", position: "absolute", top: "73%", left: "8%" }}>
          <button
            style={{ background: "none", border: "none", width: "180px" }}
            onClick={() => {
              getTestFuckies();
            }}
          >
            <img style={{ width: "100%" }} src={fuckiesButton} />
          </button>
        </div>

        <div className="fuckies-button">
          <div>
            <FuckiesButtons fuckiesTradeContract={fuckiesTradeContract} pairs={pairs} latestPrices={latestPrices} />
          </div>
          <button className="last-button"></button>
        </div>
      </div>
    </div>
  );
};

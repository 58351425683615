import { GLOBAL_CONFIG_HOSTING } from "config/context";

export const DEXYNTH = "dexynth";
export const LOCAL = "local";
export const HEROKU = "heroku";

export const PROD = DEXYNTH;

const messagingUrl = GLOBAL_CONFIG_HOSTING === "aws" ? "wss://gc.dexynth.com" : "wss://c.gc.dexynth.com";

const URLS = {
  [HEROKU]: {
    PRICES: "wss://dexynth-pricing-front.herokuapp.com",
    EVENTS_RELAYER: "wss://dexynth-events-relayer-front.herokuapp.com",
    HISTORIC: "https://dexynth-historical-api.herokuapp.com",
    STATS: "https://dexynth-stats-api.herokuapp.com",
  },
  [DEXYNTH]: {
    PRICES: "wss://pff.dexynth.com",
    EVENTS_RELAYER: "wss://er.dexynth.com",
    HISTORIC: "https://h.dexynth.com",
    MESSAGING: messagingUrl,
    STATS: "https://d.dexynth.com/stats",
  },

  [LOCAL]: {
    PRICES: "wss://dexynth-pricing-front.herokuapp.com",
    EVENTS_RELAYER: "ws://localhost:5001",
    HISTORIC: "http://localhost:5002",
  },
};

export function getUrl(env: string, service: string) {
  return URLS[env][service];
}

import useSWR from "swr";

import { useWeb3React } from "@web3-react/core";

import { contractFetcher } from "lib/contracts";
import { dUSDC } from "config/contracts";
import { useContracts } from "hooks/useContracts";

import { formatAmount } from "lib/numbers";
import { USDG_DECIMALS } from "lib/legacy";

export function useOtcInfo({ chainId }) {
  const { contractInfo } = useContracts({ chainId });
  const { active, library, account } = useWeb3React();

  // uint public assetsToDeplete;         // 1e18
  const { data: assetsToDeplete } = useSWR(
    active && [`OtcHeader:assetsToDeplete:${active}`, chainId, contractInfo[dUSDC].address, "assetsToDeplete"],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abi),
    }
  );
  //console.log("assetsToDeplete", formatAmount(assetsToDeplete, USDG_DECIMALS, 2, true));

  //  uint public assetsToDevFees;         // 1e18
  const { data: assetsToDevFees } = useSWR(
    active && [`OtcHeader:assetsToDevFees:${active}`, chainId, contractInfo[dUSDC].address, "assetsToDevFees"],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  //console.log("assetsToDevFees", formatAmount(assetsToDevFees, USDG_DECIMALS, 2, true));

  // 1e18
  const { data: availableAssets } = useSWR(
    active && [`OtcHeader:availableAssets:${active}`, chainId, contractInfo[dUSDC].address, "availableAssets"],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  //console.log("availableAssets", formatAmount(availableAssets, USDG_DECIMALS, 2, true));

  const { data: totalAssets } = useSWR(
    active && [`OtcHeader:totalAssets:${active}`, chainId, contractInfo[dUSDC].address, "totalAssets"],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  //console.log("totalAssets", formatAmount(totalAssets, USDG_DECIMALS, 2, true));

  const { data: totalDepleted } = useSWR(
    active && [`OtcHeader:totalDepleted:${active}`, chainId, contractInfo[dUSDC].address, "totalDepleted"],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  //console.log("totalDepleted", formatAmount(totalDepleted, USDG_DECIMALS, 2, true));

  const { data: totalDepletedGns } = useSWR(
    active && [`OtcHeader:totalDepletedGns:${active}`, chainId, contractInfo[dUSDC].address, "totalDepletedGns"],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  //console.log("totalDepletedGns", formatAmount(totalDepletedGns, USDG_DECIMALS, 2, true));

  const { data: totalRefilled } = useSWR(
    active && [`OtcHeader:totalRefilled:${active}`, chainId, contractInfo[dUSDC].address, "totalRefilled"],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  //console.log("totalRefilled", formatAmount(totalRefilled, USDG_DECIMALS, 2, true));

  const { data: totalRefilledGns } = useSWR(
    active && [`OtcHeader:totalRefilledGns:${active}`, chainId, contractInfo[dUSDC].address, "totalRefilledGns"],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  //console.log("totalRefilledGns", formatAmount(totalRefilledGns, USDG_DECIMALS, 2, true));

  const { data: totalSupply } = useSWR(
    active && [`OtcHeader:totalSupply:${active}`, chainId, contractInfo[dUSDC].address, "totalSupply"],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  //console.log("totalSupply", formatAmount(totalSupply, USDG_DECIMALS, 2, true));

  const { data: maxGnsSupplyMintDailyP } = useSWR(
    active && [
      `OtcHeader:maxGnsSupplyMintDailyP:${active}`,
      chainId,
      contractInfo[dUSDC].address,
      "maxGnsSupplyMintDailyP",
    ],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  //console.log("maxGnsSupplyMintDailyP", formatAmount(maxGnsSupplyMintDailyP, USDG_DECIMALS, 2, true));
  //accPnlPerTokenUsed
  const { data: accPnlPerTokenUsed } = useSWR(
    active && [`OtcHeader:accPnlPerTokenUsed:${active}`, chainId, contractInfo[dUSDC].address, "accPnlPerTokenUsed"],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  //console.log("accPnlPerTokenUsed", formatAmount(accPnlPerTokenUsed, USDG_DECIMALS, 2, true));
  /*
  const { data: gnsTokenToAssetsPrice } = useSWR(
    active && [`OtcHeader:gnsTokenToAssetsPrice:${active}`, chainId, contractInfo[dUSDC].address, "gnsTokenToAssetsPrice"],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  //console.log("gnsTokenToAssetsPrice", formatAmount(gnsTokenToAssetsPrice, USDG_DECIMALS, 2, true));*/

  const getAvailableToRefill = () => {
    if (accPnlPerTokenUsed <= 0) return "0.00";
    return (accPnlPerTokenUsed * totalSupply) / 10 ** (2 * USDG_DECIMALS);
  };

  const availableToRefillFmt = getAvailableToRefill();
  const totalRefilledFmt = formatAmount(totalRefilled, USDG_DECIMALS, 2, true);
  const assetsToDepleteFmt = formatAmount(assetsToDeplete, USDG_DECIMALS, 2, true);
  const totalDepletedFmt = formatAmount(totalDepleted, USDG_DECIMALS, 2, true);

  const otcData = {
    assetsToDeplete: assetsToDeplete,
    assetsToDepleteFmt: assetsToDepleteFmt,
    assetsToDevFees: assetsToDevFees,
    availableAssets: availableAssets,
    totalAssets: totalAssets,
    totalDepleted: totalDepleted,
    totalDepletedFmt: totalDepletedFmt,
    totalDepletedGns: totalDepletedGns,
    totalRefilled: totalRefilled,
    totalRefilledFmt: totalRefilledFmt,
    totalDepletedFmt: totalDepletedFmt,
    totalRefilledGns: totalRefilledGns,
    totalSupply: totalSupply,
    maxGnsSupplyMintDailyP: maxGnsSupplyMintDailyP,
    accPnlPerTokenUsed: accPnlPerTokenUsed,
    availableToRefillFmt: availableToRefillFmt,
  };
  return { otcData };
}

import { getContract, WS_TO_FRONT_NAME_MAP, ABI_INFO } from "config/contracts";
import { ethers } from "ethers";

export function useContracts({ chainId }) {
  const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum, "any"): null;
  const signer = provider ? provider.getSigner(): null;

  const getContractInfo = () => {
    const contractInfo = {};
    //if (!provider || !signer) return contractInfo;

    for (const [key, value] of Object.entries(WS_TO_FRONT_NAME_MAP)) {
      const contractAddress = getContract(chainId, value);
      const abiJson = ABI_INFO[value];
      const contract = abiJson && signer? new ethers.Contract(contractAddress, abiJson.abi, signer) : null;
      contractInfo[value] = {
        address: getContract(chainId, value),
        abiJson: abiJson,
        contract: contract,
      };
    }
    return contractInfo;
  };

  const getAllContractNames = () => {
    return Object.values(WS_TO_FRONT_NAME_MAP);
  };

  return {
    contractInfo: getContractInfo(),
    contractNames: getAllContractNames(),
  };
}

import "./Vault.css";
import { t, Trans } from "@lingui/macro";
import { useEffect, useState } from "react";

import { useWeb3React } from "@web3-react/core";

import { ethers } from "ethers";

import { isSupportedChain } from "config/chains";
import { callContract } from "lib/contracts";

import { useTokenInfo } from "hooks/useTokenInfo";
import { useContracts } from "hooks/useContracts";

import { USDG_DECIMALS } from "lib/legacy";

import { formatAmount, parseValue, limitDecimals } from "lib/numbers";

import { SECONDS_PER_DAY } from "lib/dates";

import { dUSDC, USDC, OpenPnlFeed } from "config/contracts";
import "./Vault.css";
const U_DEPOSIT = "U_DEPOSIT";

const BUSD = USDC;
const dBUSD = dUSDC;

export default function VaultSwapBox(props) {
  const { chainId, depositKind, pendingTxns, setPendingTxns, vaultData } = props;
  const { collatRatio, dBUSDPrice } = vaultData;

  const [toValue, setToValue] = useState("");
  const [fromValue, setFromValue] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  //const [needsApproval, setNeedsApproval] = useState(true);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [lockDays, setLockDays] = useState(0);
  const [lockDaysText, setLockDaysText] = useState("None");
  const [activeClass30, setactiveClass30] = useState("active");
  const [activeClass180, setactiveClass180] = useState("");
  const [activeClass365, setactiveClass365] = useState("");

  const [activeDiscount, setActiveDiscount] = useState(30 * SECONDS_PER_DAY);

  const { active, account, library } = useWeb3React();

  const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum, "any") : null;
  const signer = provider ? provider.getSigner() : null;

  const fromTokenName = BUSD;
  const toTokenName = dBUSD;

  let amount = parseValue(fromValue, USDG_DECIMALS);

  const { contractInfo } = useContracts({ chainId });

  const { tokenAllowance, needsApproval, tokenBalance, fmtTokenBalance } = useTokenInfo({
    tokenName: fromTokenName,
    spenderName: dBUSD,
    chainId,
    idString: "VaultSwapBox",
    amountToSpend: amount,
  });
  //console.log("VaultSwapBox.useTokenInfo", tokenAllowance, needsApproval, tokenBalance, fmtTokenBalance);

  useEffect(() => {
    if (!needsApproval && isWaitingForApproval) {
      setIsWaitingForApproval(false);
    }
  }, [needsApproval, isWaitingForApproval]);

  const onFromValueChange = (e) => {
    if (!e.target.value) {
      setFromValue(e.target.value);
      setToValue(e.target.value);
      return;
    }

    const _fromValue = e.target.value ? e.target.value : 0.0;
    setFromValue(_fromValue);
    setToValue((_fromValue * 10 ** USDG_DECIMALS) / dBUSDPrice);
  };

  const isPrimaryEnabled = () => {
    if (depositKind == U_DEPOSIT) return true;

    if (fromValue <= 0.0) {
      return false;
    }
    if (!active) {
      return false;
    }
    if (!isSupportedChain(chainId)) {
      return false;
    }
    if (needsApproval && isWaitingForApproval) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (needsApproval) {
      return true;
    }
    return true;
  };

  const approve = () => {
    setIsApproving(true);

    const contract = contractInfo[fromTokenName].contract;
    callContract(chainId, contract, "approve", [contractInfo[dBUSD].address, ethers.constants.MaxUint256], {
      setPendingTxns: setPendingTxns,
      hideSuccessMsg: false,
    })
      .then(async (res) => {
        setIsWaitingForApproval(true);
        await res.wait(1);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsApproving(false);
        setIsWaitingForApproval(false);
      });
  };

  const depositWithLock = (contract) => {
    const lockDuration = lockDays * SECONDS_PER_DAY;
    const _fromValue = ethers.utils.parseUnits(fromValue.toString(), USDG_DECIMALS);
    setIsSubmitting(true);
    callContract(chainId, contract, "depositWithDiscountAndLock", [_fromValue, lockDuration, account], {
      setPendingTxns: setPendingTxns,
      hideSuccessMsg: false,
    })
      .then(async (res) => {
        setIsConfirming(true);
        await res.wait(1);
        setIsConfirming(false);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const deposit = (contract) => {
    const _fromValue = ethers.utils.parseUnits(fromValue.toString(), USDG_DECIMALS);
    setIsSubmitting(true);
    callContract(chainId, contract, "deposit", [_fromValue, account], {
      setPendingTxns: setPendingTxns,
      hideSuccessMsg: false,
    })
      .then(async (res) => {
        setIsConfirming(true);
        await res.wait(1);
        setIsConfirming(false);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const performAction = () => {
    const dBUSD_contract = contractInfo[dBUSD].contract;

    if (depositKind == U_DEPOSIT) {
      depositWithLock(dBUSD_contract);
    } else {
      deposit(dBUSD_contract);
    }
  };

  const onClickPrimary = () => {
    if (needsApproval) {
      approve();
    } else {
      performAction();
    }
  };
  const onClickMax = () => {
    setFromValue(fmtTokenBalance);
    setToValue((fmtTokenBalance * 10 ** USDG_DECIMALS) / dBUSDPrice);
  };

  const setDiscount = (days) => {
    const dBUSD_contract = contractInfo[dBUSD].contract;

    const discount = dBUSD_contract.lockDiscountP(collatRatio, days * SECONDS_PER_DAY).then((discount) => {
      setActiveDiscount(discount);
    });
  };

  const getPrimaryText = () => {
    //console.log("needsApproval " + needsApproval);
    //console.log("SWRneedsApproval " + SWRneedsApproval);
    if (!active) {
      return t`Connect Wallet`;
    }
    if (!isSupportedChain(chainId)) {
      return t`Incorrect Network`;
    }

    if (needsApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }
    if (isApproving) {
      return t`Approving USDT`;
    }
    if (needsApproval) {
      return t`Approve USDT`;
    }
    if (isConfirming) {
      return t`Confiming Tx`;
    }
    if (isSubmitting) {
      return t`Submitting Tx`;
    }
    if (depositKind == U_DEPOSIT) return t`DEPOSIT AND LOCK`;
    return `${depositKind}`;
  };

  return (
    <div className="vault-swapbox">
      <div>
        <div className="">
          <div className="">
            <div className="" style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: "12px", color: "#808080", marginBottom: "-10px" }}>LOCKED DEPOSIT AMOUNT</p>
              <button style={{ color: "#808080" }} className="max-button" onClick={onClickMax}>
                Max
              </button>
            </div>
          </div>
          <div className="" style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <input
                type="number"
                min="10"
                max="10000000"
                placeholder="0.0 USDT"
                className=""
                style={{ width: "100%" }}
                value={fromValue}
                onChange={onFromValueChange}
              />
            </div>
            {/* <div>{tokenInfo[fromTokenName].label}</div> */}
          </div>
        </div>

        {depositKind == U_DEPOSIT && (
          <div>
            <div className="">
              <div className="">
                <p style={{ fontSize: "12px", color: "#808080", marginBottom: "-15px", marginTop: "10px", textTransform: "uppercase" }}>Lock Days</p>

              </div>
            </div>
            <div id="stopLossCounter" style={{ marginTop: "20px", display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  backgroundColor: "#121117",
                  height: "50px",
                  padding: "10px",
                }}
              >
                <div
                  onClick={() => {
                    setactiveClass30("active");
                    setactiveClass180("");
                    setactiveClass365("");
                    setLockDays(30);
                    setLockDaysText(30);
                    setDiscount(30);
                  }}
                  className={activeClass30}
                  style={{ cursor: "pointer" }}
                >
                  30d
                </div>

                <div
                  onClick={() => {
                    setactiveClass30("");
                    setactiveClass180("active");
                    setactiveClass365("");
                    setLockDays(180);
                    setLockDaysText(180);
                    setDiscount(180);
                  }}
                  className={activeClass180}
                  style={{ cursor: "pointer" }}
                >
                  180d
                </div>

                <div
                  onClick={() => {
                    setactiveClass30("");
                    setactiveClass180("");
                    setactiveClass365("active");
                    setLockDays(365);
                    setLockDaysText(365);
                    setDiscount(365);
                  }}
                  className={activeClass365}
                  style={{ cursor: "pointer" }}
                >
                  365d
                </div>

                <input
                  id="lockDaysBox"
                  type="number"
                  className=""
                  placeholder="Enter Period"
                  autoComplete="off"
                  onFocus={() => {
                    setactiveClass30("");
                    setactiveClass180("");
                    setactiveClass365("");
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setLockDays(newValue);
                    setDiscount(newValue);
                    setLockDaysText(newValue);
                  }}
                  style={{ border: "1px solid black", width: "30%", height: "0px", padding: "15px 0px", backgroundColor: "#19181D", textAlign: "center" }}
                />
              </div>
              <div style={{ width: "100%" }}>

              </div>
            </div>

            <div className=""></div>
          </div>
        )}

        <div className="" style={{ marginTop: "20px" }}>
          <div className="" style={{ display: "flex", justifyContent: "space-between"}}>
            <div style={{ fontSize: "12px", color: "#808080", textTransform: "uppercase", marginBottom: "5px" }}>Receive {depositKind == U_DEPOSIT && <strong>1 gNFT-{"USDT"}</strong>}</div>

            <p style={{ color:"#808080"}}>
              Disc {" "}
              <span style={{ color: "#cafc4f", paddingLeft: "2px" }}>
                {formatAmount(activeDiscount.toString(), USDG_DECIMALS, 2, true)}%
              </span>
            </p>
          </div>
          <div className="" style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <input
                type="number"
                min="10"
                max="10000000"
                placeholder="0.0 USDT"
                className=""
                value={limitDecimals(toValue, 2)}
                readOnly="readonly"
              />
            </div>
            {/* <div>{tokenInfo[toTokenName].label}</div> */}
          </div>
        </div>
      </div>
      <div className="vault-end-info" style={{ display: "flex", justifyContent: "space-between" }}>
        <div class="end-info-text" style={{ color: "#808080" }}>
          1 {"USDT"} = {(1 / formatAmount(dBUSDPrice, USDG_DECIMALS, 10, false)).toFixed(4)} {"dUSDT"}
        </div>

        <div className="end-info-button" style={{ fontSize: "14px" }}>
          <button className="button-end-vault" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </div>
    </div>
  );
}

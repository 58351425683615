import React, { useState, useContext } from "react";
import "./Banners.css";
import AppContext from "AppContext";

import useMessaging from "hooks/useMessaging";

/* @FrankyPrograma: You need to use the styling properties from bannerInfo Object 
                    for the MaintenanceBanner component.

bannerInfo Object. Example:
{
  "icon": "⚠️",
  "bgColor": "#555555",
  "textColor": "#FFFFFF",
  "fontSize": "14px",
  "fontStyle": "italic",
  "message": "This is the first message."
}
*/
export const Banner = ({ bannerInfo }) => {
  const [hideBanner, setHideBanner] = useState(false);
  let marginTop = "4px";

  return hideBanner == false ? (
    <div className="container-banner">
      <div
        className="banner-maintenance"
        style={{
          marginTop: marginTop,
          backgroundColor: bannerInfo?.bgColor,
          fontSize: bannerInfo?.fontSize,
          color: bannerInfo?.textColor,
        }}
      >
        <span style={{ fontStyle: bannerInfo?.fontStyle }}>
          <span style={{ fontStyle: "normal" }}>{bannerInfo?.icon} </span>
          {bannerInfo?.message}
          <span style={{ fontStyle: "normal" }}> {bannerInfo?.icon}</span>
        </span>

        <i
          onClick={() => {
            setHideBanner(!hideBanner);
          }}
          class="fa-solid fa-xmark"
          style={{ position: "absolute", top: "50%", right: "15px", transform: "translateY(-50%)" }}
        ></i>
      </div>
    </div>
  ) : (
    ""
  );
};

export const Banners = ({ chainId, path }) => {
  
  const { filteredBannersInfo } = useMessaging({ path, chainId });

  if (!filteredBannersInfo) return null;

  return filteredBannersInfo.map((banner) => {
    return <Banner bannerInfo={banner} />;
  });
};

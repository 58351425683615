import { ethers } from "ethers";
import { isDevelopment } from "lib/legacy";

import bnbIcon from "img/ic_bsc.svg";
import shmIcon from "img/shardeum.webp";
import zkSyncIcon from "img/zksync.svg";
import opBnbIcon from "img/ic_bsc.svg";
import dexynthIcon from "img/DEXYNTHIcon.svg";
import zetachainIcon from "img/zetachain.png";
import tenIcon from "img/ten.svg";
import shimmerIcon from "img/shimmer.svg";
import berachainIcon from "img/berachain.png";
import iotaIcon from "img/iota.svg";
import fireIcon from "img/5ire.svg";

import { IS_PROD } from "config/context";

const { parseEther } = ethers.utils;

export const MAINNET = 56;
export const AVALANCHE = 43114;
export const TESTNET = 97;
export const ARBITRUM_TESTNET = 421611;
export const ARBITRUM = 42161;
export const SHARDEUM_TESTNET = 8082;
export const ZKSYNC_TESTNET = 300;
export const OPBNB_TESTNET = 5611;
export const ZETACHAIN_TESTNET = 7001;
export const TEN_TESTNET = 443;
export const IOTA_TESTNET = 1075;
export const BERACHAIN_TESTNET = 80084;
export const SHIMMER_MAINNET = 148;
export const FIRE_CHAIN_TESTNET = 997;
export const FIRE_CHAIN_MAINNET = 995;

// TODO take it from web3
export const DEFAULT_CHAIN_ID = BERACHAIN_TESTNET;
export const ALT_CHAIN_ID = IOTA_TESTNET;
export const CHAIN_ID = DEFAULT_CHAIN_ID;

const CHAIN_PROPERTIES = {
  [IOTA_TESTNET]: {
    name: "IOTA EVM Testnet",
    mainnet: false,
    enabled: true,
  },
  [FIRE_CHAIN_TESTNET]: {
    name: "5IRE Chain Testnet",
    mainnet: false,
    enabled: true,
  },
  [FIRE_CHAIN_MAINNET]: {
    name: "5IRE Chain Mainnet",
    mainnet: true,
    enabled: !IS_PROD,
  },
  [BERACHAIN_TESTNET]: {
    name: "Berachain bArtio",
    mainnet: false,
    enabled: true,
  },
  [MAINNET]: {
    name: "Binance Smart Chain",
    mainnet: true,
    enabled: false,
  },
  [AVALANCHE]: {
    name: "Avalanche",
    mainnet: true,
    enabled: false,
  },
  [TESTNET]: {
    name: "Binance Smart Chain Testnet",
    mainnet: false,
    enabled: false,
  },
  [ARBITRUM_TESTNET]: {
    name: "Arbitrum Testnet",
    mainnet: false,
    enabled: false,
  },
  [ARBITRUM]: {
    name: "Arbitrum",
    mainnet: true,
    enabled: false,
  },
  [SHARDEUM_TESTNET]: {
    name: "Shardeum SphinX",
    mainnet: false,
    enabled: false,
  },
  [ZKSYNC_TESTNET]: {
    name: "ZKSync Sepolia Testnet",
    mainnet: false,
    enabled: false,
  },
  [OPBNB_TESTNET]: {
    name: "opBNB Testnet",
    mainnet: false,
    enabled: false,
  },
  [ZETACHAIN_TESTNET]: {
    name: "ZetaChain Athens 3 Testnet",
    mainnet: false,
    enabled: false,
  },
  [TEN_TESTNET]: {
    name: "Ten Testnet",
    mainnet: false,
    enabled: true,
  },

  [SHIMMER_MAINNET]: {
    name: "Shimmer EVM Mainnet",
    mainnet: true,
    enabled: !IS_PROD,
  },
};

export const TESTNET_CHAIN_IDS = Object.keys(CHAIN_PROPERTIES)
  .filter((key) => CHAIN_PROPERTIES[key].enabled && !CHAIN_PROPERTIES[key].mainnet)
  .map(Number); // Convert keys from string to number

export const MAINNET_CHAIN_IDS = Object.keys(CHAIN_PROPERTIES)
  .filter((key) => CHAIN_PROPERTIES[key].enabled && CHAIN_PROPERTIES[key].mainnet)
  .map(Number); // Convert keys from string to number

export const SUPPORTED_CHAIN_IDS = Object.keys(CHAIN_PROPERTIES)
  .filter((key) => CHAIN_PROPERTIES[key].enabled)
  .map(Number); // Convert keys from string to number

{
  !IS_PROD && console.log("SUPPORTED_CHAIN_IDS", SUPPORTED_CHAIN_IDS);
}

export const IS_NETWORK_DISABLED = {
  [ARBITRUM]: !CHAIN_PROPERTIES[ARBITRUM].enabled,
  [AVALANCHE]: !CHAIN_PROPERTIES[AVALANCHE].enabled,
  [ARBITRUM_TESTNET]: !CHAIN_PROPERTIES[ARBITRUM_TESTNET].enabled,
  [MAINNET]: !CHAIN_PROPERTIES[MAINNET].enabled,
  [TESTNET]: !CHAIN_PROPERTIES[TESTNET].enabled,
  [SHARDEUM_TESTNET]: !CHAIN_PROPERTIES[SHARDEUM_TESTNET].enabled,
  [ZKSYNC_TESTNET]: !CHAIN_PROPERTIES[ZKSYNC_TESTNET].enabled,
  [OPBNB_TESTNET]: !CHAIN_PROPERTIES[OPBNB_TESTNET].enabled,
  [IOTA_TESTNET]: !CHAIN_PROPERTIES[IOTA_TESTNET].enabled,
  [FIRE_CHAIN_TESTNET]: !CHAIN_PROPERTIES[FIRE_CHAIN_TESTNET].enabled,
  [FIRE_CHAIN_MAINNET]: !CHAIN_PROPERTIES[FIRE_CHAIN_MAINNET].enabled,
  [BERACHAIN_TESTNET]: !CHAIN_PROPERTIES[BERACHAIN_TESTNET].enabled,
  [SHIMMER_MAINNET]: !CHAIN_PROPERTIES[SHIMMER_MAINNET].enabled,
};

export const CHAIN_NAMES_MAP = {
  [MAINNET]: CHAIN_PROPERTIES[MAINNET].name,
  [TESTNET]: CHAIN_PROPERTIES[TESTNET].name,
  [SHARDEUM_TESTNET]: CHAIN_PROPERTIES[SHARDEUM_TESTNET].name,
  [ARBITRUM_TESTNET]: CHAIN_PROPERTIES[ARBITRUM_TESTNET].name,
  [ARBITRUM]: CHAIN_PROPERTIES[ARBITRUM].name,
  [AVALANCHE]: CHAIN_PROPERTIES[AVALANCHE].name,
  [ZKSYNC_TESTNET]: CHAIN_PROPERTIES[ZKSYNC_TESTNET].name,
  [OPBNB_TESTNET]: CHAIN_PROPERTIES[OPBNB_TESTNET].name,
  [ZETACHAIN_TESTNET]: CHAIN_PROPERTIES[ZETACHAIN_TESTNET].name,
  [TEN_TESTNET]: CHAIN_PROPERTIES[TEN_TESTNET].name,
  [IOTA_TESTNET]: CHAIN_PROPERTIES[IOTA_TESTNET].name,
  [FIRE_CHAIN_TESTNET]: CHAIN_PROPERTIES[FIRE_CHAIN_TESTNET].name,
  [FIRE_CHAIN_MAINNET]: CHAIN_PROPERTIES[FIRE_CHAIN_MAINNET].name,
  [SHIMMER_MAINNET]: CHAIN_PROPERTIES[SHIMMER_MAINNET].name,
  [BERACHAIN_TESTNET]: CHAIN_PROPERTIES[BERACHAIN_TESTNET].name,
};

export const CHAIN_ICONS_MAP = {
  [MAINNET]: [bnbIcon, "ic_bsc.svg"],
  [TESTNET]: [bnbIcon, "ic_bsc.svg"],
  [SHARDEUM_TESTNET]: [shmIcon, "shardeum.webp"],
  [ZKSYNC_TESTNET]: [zkSyncIcon, "zksync.svg"],
  [OPBNB_TESTNET]: [opBnbIcon, "ic_bsc.svg"],
  [ZETACHAIN_TESTNET]: [zetachainIcon, "zetachain.png"],
  [TEN_TESTNET]: [tenIcon, "ten.svg"],
  [IOTA_TESTNET]: [iotaIcon, "iota.svg"],
  [FIRE_CHAIN_TESTNET]: [fireIcon, "5ire.svg"],
  [FIRE_CHAIN_MAINNET]: [fireIcon, "5ire.svg"],
  [SHIMMER_MAINNET]: [shimmerIcon, "shimmer.svg"],
  [BERACHAIN_TESTNET]: [berachainIcon, "berachain.png"],
};
export function getIconSrc(chainId: number) {
  return CHAIN_ICONS_MAP[chainId] ? CHAIN_ICONS_MAP[chainId][0] : dexynthIcon;
}
export function getIconFile(chainId: number) {
  return CHAIN_ICONS_MAP[chainId] ? CHAIN_ICONS_MAP[chainId][1] : undefined;
}
export const GAS_PRICE_ADJUSTMENT_MAP = {
  [ARBITRUM]: "0",
  [AVALANCHE]: "3000000000", // 3 gwei
};

export const MAX_GAS_PRICE_MAP = {
  [AVALANCHE]: "200000000000", // 200 gwei
};

export const HIGH_EXECUTION_FEES_MAP = {
  [ARBITRUM]: 3, // 3 USD
  [AVALANCHE]: 3, // 3 USD
};

const constants = {
  [MAINNET]: {
    nativeTokenSymbol: "BNB",
    defaultCollateralSymbol: "BUSD",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 8,
    v2: false,
  },

  [TESTNET]: {
    nativeTokenSymbol: "BNB",
    wrappedTokenSymbol: "WBNB",
    defaultCollateralSymbol: "BUSD",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00300001"),
  },
  [SHARDEUM_TESTNET]: {
    nativeTokenSymbol: "SHM",
    wrappedTokenSymbol: "WSHM",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00300001"),
  },
  [ZKSYNC_TESTNET]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00300001"),
  },
  [OPBNB_TESTNET]: {
    nativeTokenSymbol: "BNB",
    wrappedTokenSymbol: "WBNB",
    defaultCollateralSymbol: "BUSD",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00300001"),
  },
  [ZETACHAIN_TESTNET]: {
    nativeTokenSymbol: "ZETA",
    wrappedTokenSymbol: "ZETA",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00300001"),
  },
  [TEN_TESTNET]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "ETH",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00300001"),
  },
  [IOTA_TESTNET]: {
    nativeTokenSymbol: "SMR",
    wrappedTokenSymbol: "SMR",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00300001"),
  },
  [FIRE_CHAIN_TESTNET]: {
    nativeTokenSymbol: "5IRE",
    wrappedTokenSymbol: "5IRE",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00300001"),
  },
  [FIRE_CHAIN_MAINNET]: {
    nativeTokenSymbol: "5IRE",
    wrappedTokenSymbol: "5IRE",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00300001"),
  },
  [SHIMMER_MAINNET]: {
    nativeTokenSymbol: "SMR",
    wrappedTokenSymbol: "SMR",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00300001"),
  },
  [BERACHAIN_TESTNET]: {
    nativeTokenSymbol: "BERA",
    wrappedTokenSymbol: "BERA",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00300001"),
  },
  [ARBITRUM_TESTNET]: {
    nativeTokenSymbol: "ETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },

  [ARBITRUM]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },

  [AVALANCHE]: {
    nativeTokenSymbol: "AVAX",
    wrappedTokenSymbol: "WAVAX",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0100001"),
  },
};
export const NETWORK_OPTIONS = [
  {
    color: "#264f79",
    icon: CHAIN_ICONS_MAP[IOTA_TESTNET][1],
    label: CHAIN_NAMES_MAP[IOTA_TESTNET],
    value: IOTA_TESTNET,
    enabled: CHAIN_PROPERTIES[IOTA_TESTNET].enabled,
    mainnet: CHAIN_PROPERTIES[IOTA_TESTNET].mainnet,
  },
  {
    color: "#264f79",
    icon: CHAIN_ICONS_MAP[FIRE_CHAIN_TESTNET][1],
    label: CHAIN_NAMES_MAP[FIRE_CHAIN_TESTNET],
    value: FIRE_CHAIN_TESTNET,
    enabled: CHAIN_PROPERTIES[FIRE_CHAIN_TESTNET].enabled,
    mainnet: CHAIN_PROPERTIES[FIRE_CHAIN_TESTNET].mainnet,
  },
  {
    color: "#264f79",
    icon: CHAIN_ICONS_MAP[FIRE_CHAIN_MAINNET][1],
    label: CHAIN_NAMES_MAP[FIRE_CHAIN_MAINNET],
    value: FIRE_CHAIN_MAINNET,
    enabled: CHAIN_PROPERTIES[FIRE_CHAIN_MAINNET].enabled,
    mainnet: CHAIN_PROPERTIES[FIRE_CHAIN_MAINNET].mainnet,
  },
  {
    color: "#264f79",
    icon: CHAIN_ICONS_MAP[TEN_TESTNET][1],
    label: CHAIN_NAMES_MAP[TEN_TESTNET],
    value: TEN_TESTNET,
    enabled: CHAIN_PROPERTIES[TEN_TESTNET].enabled,
    mainnet: CHAIN_PROPERTIES[TEN_TESTNET].mainnet,
  },
  {
    color: "#264f79",
    icon: CHAIN_ICONS_MAP[BERACHAIN_TESTNET][1],
    label: CHAIN_NAMES_MAP[BERACHAIN_TESTNET],
    value: BERACHAIN_TESTNET,
    enabled: CHAIN_PROPERTIES[BERACHAIN_TESTNET].enabled,
    mainnet: CHAIN_PROPERTIES[BERACHAIN_TESTNET].mainnet,
  },
  {
    color: "#264f79",
    icon: CHAIN_ICONS_MAP[TESTNET][1],
    label: CHAIN_NAMES_MAP[TESTNET],
    value: TESTNET,
    enabled: CHAIN_PROPERTIES[TESTNET].enabled,
    mainnet: CHAIN_PROPERTIES[TESTNET].mainnet,
  },
  {
    color: "#264f79",
    icon: CHAIN_ICONS_MAP[SHIMMER_MAINNET][1],
    label: CHAIN_NAMES_MAP[SHIMMER_MAINNET],
    value: SHIMMER_MAINNET,
    enabled: CHAIN_PROPERTIES[SHIMMER_MAINNET].enabled,
    mainnet: CHAIN_PROPERTIES[SHIMMER_MAINNET].mainnet,
  },
  {
    color: "#264f79",
    icon: CHAIN_ICONS_MAP[SHARDEUM_TESTNET][1],
    label: CHAIN_NAMES_MAP[SHARDEUM_TESTNET],
    value: SHARDEUM_TESTNET,
    enabled: CHAIN_PROPERTIES[SHARDEUM_TESTNET].enabled,
    mainnet: CHAIN_PROPERTIES[SHARDEUM_TESTNET].mainnet,
  },
  {
    color: "#264f79",
    icon: CHAIN_ICONS_MAP[ZETACHAIN_TESTNET][1],
    label: CHAIN_NAMES_MAP[ZETACHAIN_TESTNET],
    value: ZETACHAIN_TESTNET,
    enabled: CHAIN_PROPERTIES[ZETACHAIN_TESTNET].enabled,
    mainnet: CHAIN_PROPERTIES[ZETACHAIN_TESTNET].mainnet,
  },
  {
    color: "#264f79",
    icon: CHAIN_ICONS_MAP[ZKSYNC_TESTNET][1],
    label: CHAIN_NAMES_MAP[ZKSYNC_TESTNET],
    value: ZKSYNC_TESTNET,
    enabled: CHAIN_PROPERTIES[ZKSYNC_TESTNET].enabled,
    mainnet: CHAIN_PROPERTIES[ZKSYNC_TESTNET].mainnet,
  },
];

export const STUCK_TIMEOUTS = {
  [OPBNB_TESTNET]: 70,
  [SHARDEUM_TESTNET]: 120,
  [ZKSYNC_TESTNET]: 70,
  [ZETACHAIN_TESTNET]: 70,
  [TEN_TESTNET]: 90,
  [IOTA_TESTNET]: 70,
  [FIRE_CHAIN_TESTNET]: 70,
  [FIRE_CHAIN_MAINNET]: 70,
  [SHIMMER_MAINNET]: 70,
  [BERACHAIN_TESTNET]: 70,
};

export const BOT_TIMEOUTS = {
  [OPBNB_TESTNET]: 15,
  [SHARDEUM_TESTNET]: 30,
  [ZKSYNC_TESTNET]: 30,
  [ZETACHAIN_TESTNET]: 30,
  [TEN_TESTNET]: 30,
  [IOTA_TESTNET]: 30,
  [FIRE_CHAIN_TESTNET]: 30,
  [FIRE_CHAIN_MAINNET]: 30,
  [SHIMMER_MAINNET]: 30,
  [BERACHAIN_TESTNET]: 30,
};

const ALCHEMY_WHITELISTED_DOMAINS = ["gmx.io", "app.gmx.io"];

export const ARBITRUM_RPC_PROVIDERS = [getDefaultArbitrumRpcUrl()];
export const AVALANCHE_RPC_PROVIDERS = ["https://api.avax.network/ext/bc/C/rpc"]; // BSC MAINNET

// BSC TESTNET
// const RPC_PROVIDERS = [
//   "https://data-seed-prebsc-1-s1.binance.org:8545",
//   "https://data-seed-prebsc-2-s1.binance.org:8545",
//   "https://data-seed-prebsc-1-s2.binance.org:8545",
//   "https://data-seed-prebsc-2-s2.binance.org:8545",
//   "https://data-seed-prebsc-1-s3.binance.org:8545",
//   "https://data-seed-prebsc-2-s3.binance.org:8545"
// ]

export const BSC_RPC_PROVIDERS = [
  "https://bsc-dataseed.binance.org",
  "https://bsc-dataseed1.defibit.io",
  "https://bsc-dataseed1.ninicoin.io",
  "https://bsc-dataseed2.defibit.io",
  "https://bsc-dataseed3.defibit.io",
  "https://bsc-dataseed4.defibit.io",
  "https://bsc-dataseed2.ninicoin.io",
  "https://bsc-dataseed3.ninicoin.io",
  "https://bsc-dataseed4.ninicoin.io",
  "https://bsc-dataseed1.binance.org",
  "https://bsc-dataseed2.binance.org",
  "https://bsc-dataseed3.binance.org",
  "https://bsc-dataseed4.binance.org",
];

export const RPC_PROVIDERS = {
  [MAINNET]: BSC_RPC_PROVIDERS,
  [ARBITRUM]: ARBITRUM_RPC_PROVIDERS,
  [AVALANCHE]: AVALANCHE_RPC_PROVIDERS,
};

export const FALLBACK_PROVIDERS = {
  [ARBITRUM]: [getAlchemyHttpUrl()],
  [AVALANCHE]: ["https://avax-mainnet.gateway.pokt.network/v1/lb/626f37766c499d003aada23b"],
};

export const NETWORK_METADATA = {
  [MAINNET]: {
    chainId: "0x" + MAINNET.toString(16),
    chainName: "BSC",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: BSC_RPC_PROVIDERS,
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [TESTNET]: {
    chainId: "0x" + TESTNET.toString(16),
    chainName: "BSC Testnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
  [SHARDEUM_TESTNET]: {
    chainId: "0x" + SHARDEUM_TESTNET.toString(16),
    chainName: "Shardeum SphinX",
    nativeCurrency: {
      name: "SHM",
      symbol: "SHM",
      decimals: 18,
    },
    rpcUrls: ["https://sphinx.shardeum.org"],
    blockExplorerUrls: ["https://explorer-sphinx.shardeum.org/"],
  },
  /*
  [SHARDEUM_TESTNET]: {
    chainId: "0x" + SHARDEUM_TESTNET.toString(16),
    chainName: "Shardeum Testnet",
    nativeCurrency: {
      name: "SHM",
      symbol: "SHM",
      decimals: 18,
    },
    rpcUrls: ["http://35.87.101.223:8080"],
    blockExplorerUrls: ["http://35.91.64.8:6001/"],
  },*/
  [ZKSYNC_TESTNET]: {
    chainId: "0x" + ZKSYNC_TESTNET.toString(16),
    chainName: "ZKSync Sepolia Testnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://sepolia.era.zksync.dev/"],
    blockExplorerUrls: ["https://sepolia.explorer.zksync.io/"],
  },
  [OPBNB_TESTNET]: {
    chainId: "0x" + OPBNB_TESTNET.toString(16),
    chainName: "opBNB Testnet",
    nativeCurrency: {
      name: "tBNB",
      symbol: "tBNB",
      decimals: 18,
    },
    rpcUrls: ["https://opbnb-testnet-rpc.bnbchain.org"],
    // TO_REVIEW: This is correct according to the Doc but its failing....
    //blockExplorerUrls: ["https://opbnb-testnet.bscscan.com/"],
  },
  [ZETACHAIN_TESTNET]: {
    chainId: "0x" + ZETACHAIN_TESTNET.toString(16),
    chainName: "ZetaChain Athens 3 Testnet",
    nativeCurrency: {
      name: "ZETA",
      symbol: "ZETA",
      decimals: 18,
    },
    rpcUrls: ["https://zetachain-athens-evm.blockpi.network/v1/rpc/public"],
    // TO_REVIEW: This is correct according to the Doc but its failing....
    //blockExplorerUrls: ["https://athens3.explorer.zetachain.com"],
  },
  [TEN_TESTNET]: {
    chainId: "0x" + TEN_TESTNET.toString(16),
    chainName: "Ten Testnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [],
    // TO_REVIEW: This is correct according to the Doc but its failing....
    //blockExplorerUrls: ["https://testnet.tenscan.com"],
  },
  [IOTA_TESTNET]: {
    chainId: "0x" + IOTA_TESTNET.toString(16),
    chainName: "IOTA EVM Testnet",
    nativeCurrency: {
      name: "IOTA",
      symbol: "IOTA",
      decimals: 18,
    },
    rpcUrls: ["https://json-rpc.evm.testnet.iotaledger.net"],
  },
  [FIRE_CHAIN_TESTNET]: {
    chainId: "0x" + FIRE_CHAIN_TESTNET.toString(16),
    chainName: "5IRE Chain Testnet",
    nativeCurrency: {
      name: "5IRE",
      symbol: "5IRE",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.ga.5ire.network"],
    blockExplorerUrls: ["https://explorer.ga.5ire.network/"],
  },
  [FIRE_CHAIN_MAINNET]: {
    chainId: "0x" + FIRE_CHAIN_MAINNET.toString(16),
    chainName: "5IRE Chain Mainnet",
    nativeCurrency: {
      name: "5IRE",
      symbol: "5IRE",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.5ire.network"],
    blockExplorerUrls: [" https://preview.5ire.network/"],
  },
  [SHIMMER_MAINNET]: {
    chainId: "0x" + SHIMMER_MAINNET.toString(16),
    chainName: "ShimmerEVM Mainnet",
    nativeCurrency: {
      name: "SMR",
      symbol: "SMR",
      decimals: 18,
    },
    rpcUrls: ["https://explorer.evm.shimmer.network"],
  },
  [BERACHAIN_TESTNET]: {
    chainId: "0x" + BERACHAIN_TESTNET.toString(16),
    chainName: "BeraChain Testnet",
    nativeCurrency: {
      name: "BERA",
      symbol: "BERA",
      decimals: 18,
    },
    rpcUrls: ["https://bartio.rpc.berachain.com/"],
    // TO_REVIEW: This is correct according to the Doc but its failing....
    blockExplorerUrls: ["https://bartio.beratrail.io/"],
  },
  [ARBITRUM_TESTNET]: {
    chainId: "0x" + ARBITRUM_TESTNET.toString(16),
    chainName: "Arbitrum Testnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://rinkeby.arbitrum.io/rpc"],
    blockExplorerUrls: ["https://rinkeby-explorer.arbitrum.io/"],
  },
  [ARBITRUM]: {
    chainId: "0x" + ARBITRUM.toString(16),
    chainName: "Arbitrum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ARBITRUM_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(ARBITRUM)],
  },
  [AVALANCHE]: {
    chainId: "0x" + AVALANCHE.toString(16),
    chainName: "Avalanche",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: AVALANCHE_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(AVALANCHE)],
  },
};

export const getConstant = (chainId: number, key: string) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }

  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }

  return constants[chainId][key];
};

export function getChainName(chainId: number) {
  return CHAIN_NAMES_MAP[chainId];
}

export function isTestnetChainId(chainId: number) {
  return TESTNET_CHAIN_IDS.includes(chainId);
}

export function getChainKind(chainId: number) {
  return TESTNET_CHAIN_IDS.includes(chainId) ? "testnet" : "mainnet";
}

export function getStuckTimeout(chainId: number) {
  return STUCK_TIMEOUTS[chainId];
}

export function getBotTimeout(chainId: number) {
  return BOT_TIMEOUTS[chainId];
}

export function getDefaultArbitrumRpcUrl() {
  return "https://arb1.arbitrum.io/rpc";
}

export function getAlchemyHttpUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "https://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "https://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

export function getAlchemyWsUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "wss://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "wss://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

export function getExplorerUrl(chainId) {
  if (chainId === 3) {
    return "https://ropsten.etherscan.io/";
  } else if (chainId === 42) {
    return "https://kovan.etherscan.io/";
  } else if (chainId === MAINNET) {
    return "https://bscscan.com/";
  } else if (chainId === TESTNET) {
    return "https://testnet.bscscan.com/";
  } else if (chainId === SHARDEUM_TESTNET) {
    return "https://explorer-sphinx.shardeum.org/";
  } else if (chainId === ARBITRUM_TESTNET) {
    return "https://testnet.arbiscan.io/";
  } else if (chainId === ARBITRUM) {
    return "https://arbiscan.io/";
  } else if (chainId === AVALANCHE) {
    return "https://snowtrace.io/";
  } else if (chainId === TEN_TESTNET) {
    return "https://tenscan.io/personal/";
  } else if (chainId === ZETACHAIN_TESTNET) {
    return "https://athens3.explorer.zetachain.com/";
  } else if (chainId === ZKSYNC_TESTNET) {
    return "https://goerli.explorer.zksync.io/";
  } else if (chainId === OPBNB_TESTNET) {
    return "https://opbnb-testnet.bscscan.com/";
  } else if (chainId === IOTA_TESTNET) {
    return "https://explorer.evm.testnet.iotaledger.net/";
  } else if (chainId === FIRE_CHAIN_TESTNET) {
    return "https://explorer.ga.5ire.network/";
  } else if (chainId === FIRE_CHAIN_MAINNET) {
    return "https://preview.5ire.network/";
  } else if (chainId === SHIMMER_MAINNET) {
    return "https://explorer.evm.shimmer.network/";
  } else if (chainId === BERACHAIN_TESTNET) {
    return "https://bartio.beratrail.io/";
  }

  return "https://etherscan.io/";
}

export function getHighExecutionFee(chainId) {
  return HIGH_EXECUTION_FEES_MAP[chainId] || 3;
}

export function isSupportedChain(chainId) {
  return SUPPORTED_CHAIN_IDS.includes(chainId);
}

import React, { useEffect, useState } from "react";

export const TradingCompetitorUser = ({ index, tradingCompetitor }) => {
  const [address, setAddress] = useState("");
  const [indexText, setIndexText] = useState("");

  useEffect(() => {
    const primerosCuatro = tradingCompetitor[0].substring(0, 6);
    const ultimosCuatro = tradingCompetitor[0].substring(tradingCompetitor[0].length - 6);

    setAddress(primerosCuatro + "...." + ultimosCuatro);
  }, [tradingCompetitor]);

  useEffect(() => {
    if (index === 1) {
      setIndexText("🏆");
    } else if (index === 2) {
      setIndexText("🥈");
    } else if (index == 3) {
      setIndexText("🥉");
    } else {
      setIndexText(index);
    }

    console.log(indexText);
  }, [index]);

  return (
    <div className="TradingCompetition">
      <div className="TradingCompetitionHeader">
        <div className="competition-info">{indexText}</div>
        <div className="competition-info">{address}</div>
        <div className="competition-info">{tradingCompetitor[1]}</div>
        <div className="competition-info">{((tradingCompetitor[2] / tradingCompetitor[1]) * 100).toFixed(1)}%</div>
        <div className="competition-info">{tradingCompetitor[3].toFixed(2)}$</div>
      </div>
    </div>
  );
};

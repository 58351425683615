import React, { useContext, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Trans, t, select } from "@lingui/macro";

import { ethers } from "ethers";
import { getContract } from "config/contracts";
import GNSPAIRINFOV6ABI from "abis/DexynthPairInfosV6_1.json";

import { InfoTrade } from "./InfoTrade";
import ProcessingPosition from "./ProcessingPosition";
import {
  POS_STATUS_STUCK,
  POS_STATUS_ORPHAN_CLOSING_STUCK,
  POS_STATUS_NORMAL,
  POS_STATUS_CLOSING,
  POS_STATUS_TRYING_TO_CLOSE,
  POS_STATUS_TRYING_TO_CLOSE_BY_BOT,
  getStatusText,
  getCorrectedRealStatus,
  getExtendedStatusText,
} from "./PositionsLogic";

import GifLoad from "../../img/gif-unscreen.gif";

import AppContext from "AppContext";
import { formatDecimals } from "lib/numbers";

import { isPositionStillInProcessingTimeframe } from "./PositionsLogic";
import { stat } from "fs";
import { is } from "date-fns/locale";

function RegularPosition(props) {
  const {
    onSelectToken,
    pairIndex,
    index,
    account,
    chainId,
    batchData,
    ourPositions,
    setOverFlow,
    closePosition,
    closeStuckPosition,
    setOurSelectedToken,
    set_TakeProfit,
    set_StopLoss,
    set_LiquidationPrice,
    setIsEditTakeProfitStopLossModalOpen,
    setSelectedPosition,
  } = props;

  const [openInfo, setOpenInfo] = useState(false);
  const [selectedPositionInfo, setSelectedPositionInfo] = useState(null);
  const [rolloverFee, setRolloverFee] = useState();
  const [fundingFee, setFundingFee] = useState();
  const [color, setColor] = useState();
  const [liquidationPrice, setLiquidationPrice] = useState();
  const { pares, pairs, setPairs, cleanPairs, setCleanPairs, setupPricesWebsocket, latestPrices, market24 } =
    useContext(AppContext);

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const pairInfoAddress = getContract(chainId, "PairInfos");
  const pairInfoAbi = GNSPAIRINFOV6ABI.abi;
  const signer = provider.getSigner();
  const pairInfoContract = new ethers.Contract(pairInfoAddress, pairInfoAbi, signer);
  const [changed, setChanged] = useState();

  const getTradeRollover = (position) => {
    const collateral = parseFloat(position.positionSizeDai).toFixed(2) * 1e10;
    const rolloverFeeHex = pairInfoContract.getTradeRolloverFee(
      account,
      position.pairIndex,
      position.index,
      collateral
    );

    rolloverFeeHex
      .then((result) => {
        setRolloverFee(result._hex);
      })
      .catch((error) => {
        console.error("Error al resolver la promesa:", error);
      });
  };

  const getTradeFunding = (position) => {
    const collateral = parseFloat(position.positionSizeDai).toFixed(2) * 1e10;
    const fundingFeeHex = pairInfoContract.getTradeFundingFee(
      account,
      position.pairIndex,
      position.index,
      position.buy,
      collateral,
      position.leverage
    );

    fundingFeeHex
      .then((result) => {
        setFundingFee(result._hex);
      })
      .catch((error) => {
        console.error("Error al resolver la promesa:", error);
      });
  };

  const getLiquidationPrice = (position) => {
    const collateral = parseFloat(position.positionSizeDai).toFixed(2) * 1e10;
    const LiquidationPrice = pairInfoContract.getTradeLiquidationPrice(
      account,
      position.pairIndex,
      position.index,
      position.openPrice,
      position.buy,
      collateral,
      position.leverage
    );

    LiquidationPrice.then((result) => {
      setLiquidationPrice(result._hex);
      console.log("Liq price", result._hex);
    }).catch((error) => {
      console.error("Error al resolver la promesa:", error);
    });
  };
  let position = ourPositions[pairIndex][index];
  const pairPrice = position?.pairIndex !== undefined ? latestPrices[position.pairIndex] : 0;

  useEffect(() => {
    setChanged(true);

    let timer1 = setTimeout(() => {
      setChanged(false);
    }, 500);

    return () => {
      clearTimeout(timer1);
    };
  }, [pairPrice]);

  if (position === undefined) {
    return <></>;
  }

  let status = getCorrectedRealStatus(position, chainId, batchData);
  let statusText = getStatusText(position, chainId, batchData, status);

  let tp = position?.tp ? position.tp : 0;
  let sl = position?.sl ? position.sl : 0;
  let _liquidationPrice = 1;
  let openPrice = position?.openPrice ? position.openPrice : 0;

  let pnl = 1;
  let pnlOpenInfo = 1;
  let leverage = position?.leverage ? position.leverage : 1;
  if (position.buy) {
    _liquidationPrice = Math.round(openPrice - openPrice / leverage);
  } else if (!position.buy) {
    _liquidationPrice = Math.round(openPrice + openPrice / leverage);
  }

  statusText = getExtendedStatusText(position, _liquidationPrice, pairPrice, status, statusText);

  tp = formatDecimals(tp.toString());
  sl = formatDecimals(sl.toString());
  openPrice = formatDecimals(openPrice.toString());
  _liquidationPrice = formatDecimals(_liquidationPrice.toString());

  if (position !== undefined) {
    if (position.buy) {
      pnl = (
        ((pairPrice * position.positionSizeDai) / (position.openPrice / 1e10) - position.positionSizeDai) *
        position.leverage
      ).toFixed(2);
    } else if (!position.buy) {
      pnl = (
        (position.positionSizeDai - (pairPrice * position.positionSizeDai) / (position.openPrice / 1e10)) *
        position.leverage
      ).toFixed(2);
    }
  }

  let priceColor = "";
  let slColor = "";
  let tpColor = "";
  let statusColor = "";

  let market24Status = position === undefined ? "CLOSED" : market24[position.pairIndex];
  if (statusText === "STUCK" || market24Status == "CLOSED") {
    statusColor = "negative";
  } else if (statusText === "CLOSING") {
    statusColor = "gris";
  } else {
    statusColor = "positive";
  }

  // Color change for TP/SL/Liq Thresholds

  const slLiq = sl != 0 ? sl : _liquidationPrice;

  if (position.buy && pairPrice >= tp) {
    priceColor = "positive";
    tpColor = "positive";
  } else if (position.buy && pairPrice <= slLiq) {
    priceColor = "negative";
    slColor = "negative";
  } else if (!position.buy && pairPrice <= tp) {
    priceColor = "positive";
    tpColor = "positive";
  } else if (!position.buy && pairPrice >= slLiq) {
    priceColor = "negative";
    slColor = "negative";
  } else {
    priceColor = "grisPriceColor";
    tpColor = "grisPriceColor";
    slColor = "grisPriceColor";
  }

  let parts;

  try {
    parts = position.pairName.split("/");
  } catch (error) {
    console.log(error);
  }

  const image = require(`../../img/tokenlogo/${parts[0]}${parts[1]}.svg`).default;
  // The values that are NaN or Undefined are not being rendered, this is a temporary solution as when that happens, they should be considered as Orphan.
  if (position.openPrice > 0 || (!position.open && position.status === POS_STATUS_STUCK))
    return (
      <div key={position.index * pnl * tp * position.positionSizeDai}>
        {openInfo && (
          <InfoTrade
            pairName={selectedPositionInfo.pairName}
            type={selectedPositionInfo.buy}
            collateral={selectedPositionInfo.positionSizeDai}
            leverage={selectedPositionInfo.leverage}
            openPrice={selectedPositionInfo.openPrice}
            pairPrice={latestPrices[selectedPositionInfo.pairIndex]}
            pnl={
              selectedPositionInfo.buy
                ? (
                    ((latestPrices[selectedPositionInfo.pairIndex] * selectedPositionInfo.positionSizeDai) /
                      (selectedPositionInfo.openPrice / 1e10) -
                      selectedPositionInfo.positionSizeDai) *
                    selectedPositionInfo.leverage
                  ).toFixed(2)
                : (
                    (selectedPositionInfo.positionSizeDai -
                      (latestPrices[selectedPositionInfo.pairIndex] * selectedPositionInfo.positionSizeDai) /
                        (selectedPositionInfo.openPrice / 1e10)) *
                    position.leverage
                  ).toFixed(2)
            }
            setIsVisible={setOpenInfo}
            isVisible={openInfo}
            rolloverFee={rolloverFee}
            fundingFee={fundingFee}
            tp={tp}
            sl={sl}
            _liquidationPrice={_liquidationPrice}
            priceColor={priceColor}
            tpColor={tpColor}
            slColor={slColor}
            position={position}
          />
        )}

        <div
          id="PositionsList"
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "12px",
            height: "40px",
            minHeight: "40px",
            maxHeight: "40px",
            padding: "5px 0px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              width: "8%",
              fontStyle: "light !important",
            }}
            className={`${statusColor}`}
            onClick={() => {
              setSelectedPositionInfo(position);
              getTradeRollover(position);
              getTradeFunding(position);
              getLiquidationPrice(position);
              setOpenInfo(true);
              setOverFlow("hidden");
            }}
          >
            {market24[position.pairIndex] == "CLOSED" ? "MARKET CLOSE" : statusText}
          </div>
          <div
            style={{ textAlign: "center", width: "8%" }}
            onClick={() => {
              setSelectedPositionInfo(position);
              getTradeRollover(position);
              getTradeFunding(position);
              getLiquidationPrice(position);
              setOpenInfo(true);
              setOverFlow("hidden");
            }}
          >
            <span
              style={{ textTransform: "uppercase" }}
              className={cx("Exchange-list-side", {
                positive: position.buy,
                negative: !position.buy,
              })}
            >
              {position.buy ? t`Long` : t`Short`}
            </span>
          </div>
          <div
            style={{
              textAlign: "center",
              width: "8%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "15%" }}>
              <img src={image} width="100%" />
            </div>
            <div style={{ width: "85%" }} className="hide">
              <span
                onClick={() => {
                  let pairName = position.pairName;
                  let par = cleanPairs.find((p) => p[0] == pairName);
                  let pairSymbols = par[0];

                  const pairIndex = cleanPairs.find((i) => {
                    return i[0] === pairName;
                  });

                  const precioPair = cleanPairs.find((i) => {
                    return i[0] === pairName;
                  });

                  const symbol = pairSymbols.split("/")[0];

                  const token = {
                    pairSymbols: pairSymbols,
                    symbol: symbol,
                    pairName: par[7],
                    pairIndex: pairIndex[1],
                    price: precioPair[4],
                    marketStatus: precioPair[5],
                    pairFullName: precioPair[0],
                  };

                  setOurSelectedToken(token);
                  onSelectToken(token);
                }}
              >
                {position.pairName}
              </span>
            </div>
          </div>
          <div
            onClick={() => {
              setSelectedPositionInfo(position);
              getTradeRollover(position);
              getTradeFunding(position);
              getLiquidationPrice(position);
              setOpenInfo(true);
              setOverFlow("hidden");
            }}
            className="hide"
            style={{ marginBottom: status == POS_STATUS_STUCK ? "0px" : "0px", textAlign: "center", width: "8%" }}
          >
            <span>{(parseFloat(position.positionSizeDai) * position.leverage).toFixed(2)}$</span>
          </div>

          <div
            onClick={() => {
              setSelectedPositionInfo(position);
              getTradeRollover(position);
              getTradeFunding(position);
              getLiquidationPrice(position);
              setOpenInfo(true);
              setOverFlow("hidden");
            }}
            className="hide"
            style={{ textAlign: "center", width: "8%" }}
          >
            <span>{!isNaN(position.positionSizeDai) ? `${parseFloat(position.positionSizeDai).toFixed(2)}$` : ""}</span>
          </div>

          <div
            onClick={() => {
              setSelectedPositionInfo(position);
              getTradeRollover(position);
              getTradeFunding(position);
              getLiquidationPrice(position);
              setOpenInfo(true);
              setOverFlow("hidden");
            }}
            className="hide"
            style={{ marginBottom: status == POS_STATUS_STUCK ? "-20px" : "0px", textAlign: "center", width: "7%" }}
          >
            <span>
              {position.leverage === undefined || isNaN(position.leverage) ? "\u200B" : `${position.leverage}x`}
            </span>
          </div>

          {status == POS_STATUS_STUCK && (
            <div
              className="text-stuck-regular"
              style={{
                width: "80vw",
                backgroundColor: "rgba(17, 17, 23, 0.8)",
                marginLeft: "0px !important",
                position: "absolute",
                left: "20%",
                marginTop: "25px",
                height: "50px",
                textAlign: "center",
              }}
            >
              <span style={{ marginLeft: "-51.8%" }}>
                <span className="normal-text">⚠️</span>{" "}
                <span style={{ fontStyle: "italic" }}>THIS CLOSING ATTEMPT IS STUCK, PLEASE CANCEL IT MANUALLY...</span>
              </span>
            </div>
          )}

          <div
            onClick={() => {
              setSelectedPositionInfo(position);
              getTradeRollover(position);
              getTradeFunding(position);
              getLiquidationPrice(position);
              setOpenInfo(true);
              setOverFlow("hidden");
            }}
            className="hide"
            style={{ textAlign: "center", width: "8%" }}
          >
            <span style={{ transition: "0.5s" }} className={`${changed && "priceChange"} ${priceColor}`}>
              {pairPrice}
            </span>
          </div>

          <div
            onClick={() => {
              setSelectedPositionInfo(position);
              getTradeRollover(position);
              getTradeFunding(position);
              getLiquidationPrice(position);
              setOpenInfo(true);
              setOverFlow("hidden");
            }}
            className="hide"
            style={{ textAlign: "center", width: "8%" }}
          >
            {openPrice !== "0.0000000000" && <span>{openPrice}</span>}
          </div>

          <div
            onClick={() => {
              setSelectedPositionInfo(position);
              getTradeRollover(position);
              getTradeFunding(position);
              getLiquidationPrice(position);
              setOpenInfo(true);
              setOverFlow("hidden");
            }}
            style={{ color: `${slColor}`, textAlign: "center", width: "8%" }}
            className="hide"
          >
            {position.sl !== undefined && (
              <>
                {position.sl !== 0 && <span className={`${slColor} liqPrice`}>{sl} (SL)</span>}
                {position.sl === 0 && <span className={`${slColor} liqPrice`}>{_liquidationPrice} (Liq)</span>}
              </>
            )}
          </div>

          <div
            onClick={() => {
              setSelectedPositionInfo(position);
              getTradeRollover(position);
              getTradeFunding(position);
              getLiquidationPrice(position);
              setOpenInfo(true);
              setOverFlow("hidden");
            }}
            className="hide"
            style={{ color: `${tpColor}`, textAlign: "center", width: "8%" }}
          >
            {tp !== "0.0000000000" && <span className={`${tpColor}`}>{tp}</span>}
          </div>

          <div
            onClick={() => {
              setSelectedPositionInfo(position);
              getTradeRollover(position);
              getTradeFunding(position);
              getLiquidationPrice(position);
              setOpenInfo(true);
              setOverFlow("hidden");
            }}
            style={{ textAlign: "center", width: "8%" }}
          >
            <span
              className={cx({
                positive: pnl > 0,
                negative: pnl < 0,
              })}
            >
              {position.leverage === undefined || isNaN(position.leverage)
                ? ""
                : `${Math.max(pnl, -position.positionSizeDai).toFixed(2)}$ (${Math.max(
                    ((pnl / position.positionSizeDai) * 100).toFixed(2),
                    -100
                  )}%)`}
            </span>
          </div>
          {status !== POS_STATUS_TRYING_TO_CLOSE &&
          status !== POS_STATUS_TRYING_TO_CLOSE_BY_BOT &&
          status !== POS_STATUS_CLOSING ? (
            <div style={{ textAlign: "center", width: "8%", zIndex: "900" }} id="actions">
              {!position.isClosed && (
                <div className="App-card-buttons" id="actions-buttons">
                  {statusText !== "STUCK" && (
                    <button
                      onClick={() => {
                        setOpenInfo(false);
                        set_TakeProfit(tp);
                        set_StopLoss(sl != 0 ? sl : 0);
                        set_LiquidationPrice(_liquidationPrice);
                        setIsEditTakeProfitStopLossModalOpen(true);
                        setSelectedPosition(position);
                        setOverFlow("hidden");
                      }}
                      style={{
                        border: "none",
                        background: "none",
                        color: "white",
                        fontSize: "18px",
                        marginRight: `${market24[position.pairIndex] == "CLOSED" ? "25px" : "0"}`,
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square editTradeButton"></i>
                    </button>
                  )}
                  {market24[position.pairIndex] != "CLOSED" && (
                    <button
                      onClick={() => {
                        setOpenInfo(false);
                        console.log("OPENINFO", openInfo);
                        if (market24[position.pairIndex] == "CLOSED") {
                          console.log("You cant close position");
                        } else if (status === POS_STATUS_STUCK) {
                          closeStuckPosition(position);
                        } else {
                          closePosition(position);
                        }
                      }}
                      className={`${market24[position.pairIndex] == "CLOSED" && "gray"} closeTradeButton`}
                      style={{
                        border: "none",
                        background: "none",
                        fontSize: "18px",
                        zIndex: "900",
                        marginLeft: `${position.status === POS_STATUS_STUCK ? "30px" : "0"}`,
                      }}
                    >
                      <i
                        style={{ cursor: "pointer" }}
                        className={`${
                          market24[position.pairIndex] == "CLOSED" ? "#333" : "#808080"
                        } fa-solid fa-xmark closeTradeButton`}
                      ></i>
                    </button>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div style={{ textAlign: "center", width: "8%" }}>
              <img src={GifLoad} width="60%" />
            </div>
          )}
        </div>
      </div>
    );
  else
    return (
      <ProcessingPosition
        onSelectToken={onSelectToken}
        cleanPairs={cleanPairs}
        setOurSelectedToken={setOurSelectedToken}
        chainId={chainId}
        position={position}
        batchData={batchData}
      />
    );
}

export default function RegularPositions(props) {
  const {
    account,
    chainId,
    batchData,
    ourPositions,
    setOverFlow,
    closePosition,
    closeStuckPosition,
    ourSelectedToken,
    setOurSelectedToken,
    set_TakeProfit,
    set_StopLoss,
    set_LiquidationPrice,
    setIsEditTakeProfitStopLossModalOpen,
    setSelectedPosition,
    filterBySelectedPair,
    onSelectToken,
  } = props;

  return (
    Object.keys(ourPositions).length > 0 &&
    Object.keys(ourPositions).map((i) => {
      if (!filterBySelectedPair || (filterBySelectedPair && ourSelectedToken.pairIndex == i))
        return Object.keys(ourPositions[i]).map((j) => {
          let position = ourPositions[i][j];

          if (position === undefined) return;
          return (
            <RegularPosition
              onSelectToken={onSelectToken}
              pairIndex={i}
              index={j}
              account={account}
              chainId={chainId}
              batchData={batchData}
              ourPositions={ourPositions}
              setOverFlow={setOverFlow}
              closePosition={closePosition}
              closeStuckPosition={closeStuckPosition}
              setOurSelectedToken={setOurSelectedToken}
              set_TakeProfit={set_TakeProfit}
              set_StopLoss={set_StopLoss}
              set_LiquidationPrice={set_LiquidationPrice}
              setIsEditTakeProfitStopLossModalOpen={setIsEditTakeProfitStopLossModalOpen}
              setSelectedPosition={setSelectedPosition}
            />
          );
        });
    })
  );
}

import useSWR from "swr";
import { useContext } from "react";
import AppContext from "AppContext";

export function useStats({ periodic = false, refreshInterval = 10000 }) {
  const { MODE, filteredEndpointsInfo } = useContext(AppContext);

  //const STATS_URL = getUrl(PROD, "STATS");
  const STATS_URL = filteredEndpointsInfo.stats;

  const fetchStatsData = async () => {
    if (!STATS_URL) return;
    return fetch(STATS_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((resp) => resp.json());
  };

  const { data, error } = useSWR(periodic && ["useStats"], fetchStatsData, {
    refreshInterval: refreshInterval,
    revalidateOnFocus: true,
  });

  return { stats: data };
}

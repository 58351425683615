import React, { useEffect, useState, useRef, useMemo } from "react";

import { getUrl, PROD } from "config/urls";
import { waitForOpenConnection } from "lib/sockets";

import { IS_PROD, MODE } from "config/context";

import { useWeb3React } from "@web3-react/core";
import { fil } from "date-fns/locale";

export default function useMessaging({ path, chainId }) {
  const messagingSocket = useRef(null);
  const { connector, library, deactivate, activate, active, account } = useWeb3React();

  const PATH_TO_POSITION_MAP = {
    "/": 0,
    "/trading": 1,
    "/staking": 2,
    "/otc": 3,
    "/vault": 4,
    "/stats": 5,
    "/dashboard": 6,
  };

  const mockChainsInfo = [
    {
      id: 1075,
      name: "IOTA EVM Testnet",
      short_name: "iota_evm_testnet",
      mainnet: false,
      enabled: {
        prod: true,
        stg: true,
        dev: true,
      },
      explorerUrl: "https://explorer.evm.testnet.iotaledger.net/",
      nativeCurrency: {
        name: "IOTA",
        symbol: "IOTA",
        decimals: 18,
      },
      rpcUrls: ["https://json-rpc.evm.testnet.iotaledger.net"],
      svg: "iota",
    },
    {
      id: 80084,
      name: "Berachain bArtio",
      short_name: "berachain_testnet",
      mainnet: false,
      enabled: {
        prod: true,
        stg: true,
        dev: true,
      },
      explorerUrl: "https://bartio.beratrail.io/",
      nativeCurrency: {
        name: "BERA",
        symbol: "BERA",
        decimals: 18,
      },
      rpcUrls: ["https://bartio.rpc.berachain.com/"],
      svg: "berachain",
    },
    {
      id: 443,
      name: "Ten Testnet",
      short_name: "ten_testnet",
      mainnet: false,
      enabled: {
        prod: true,
        stg: true,
        dev: true,
      },
      explorerUrl: "https://testnet.tenscan.io",
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["https://testnet.ten.xyz/v1/?token=5f5bcac1422e054d7625e7503db8ac39465fdc2a"],
      svg: "ten",
    },
    {
      id: 7001,
      name: "ZetaChain Testnet",
      short_name: "zetachain_testnet",
      mainnet: false,
      enabled: {
        prod: true,
        stg: true,
        dev: true,
      },
      explorerUrl: "https://athens3.explorer.zetachain.com",
      nativeCurrency: {
        name: "ZETA",
        symbol: "ZETA",
        decimals: 18,
      },
      rpcUrls: ["https://zetachain-athens-evm.blockpi.network/v1/rpc/public"],
      svg: "zetachain",
    },
    {
      id: 300,
      name: "ZKSync Testnet",
      short_name: "zksync_testnet",
      mainnet: false,
      enabled: {
        prod: true,
        stg: true,
        dev: true,
      },
      explorerUrl: "https://sepolia.explorer.zksync.io/",
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["https://sepolia.era.zksync.dev/"],
      svg: "zksync",
    },
    {
      id: 8082,
      name: "Shardeum SphinX",
      short_name: "shardeum_sphinx",
      mainnet: false,
      enabled: {
        prod: false,
        stg: false,
        dev: false,
      },
      explorerUrl: "",
      nativeCurrency: {
        name: "",
        symbol: "",
        decimals: 18,
      },
      rpcUrls: ["https://explorer-sphinx.shardeum.org/"],
      svg: "shardeum",
    },
    {
      id: 148,
      name: "Shimmer EVM Mainnet",
      short_name: "shimmer_evm_testnet",
      mainnet: true,
      enabled: {
        prod: false,
        stg: false,
        dev: false,
      },
      explorerUrl: "https://explorer.evm.shimmer.network",
      nativeCurrency: {
        name: "SMR",
        symbol: "SMR",
        decimals: 18,
      },
      rpcUrls: ["https://json-rpc.evm.shimmer.network"],
      svg: "shimmer",
    },
  ];
  const mockBannersInfo = [
    {
      positions: [1, 3],
      networks: [0],
      icon: "⚠️",
      bgColor: "#555555",
      textColor: "#FFFFFF",
      fontSize: "14px",
      fontStyle: "italic",
      message: "For Trading and Oct. All networks",
    },
    {
      positions: [1],
      networks: [280],
      icon: "⚠️",
      bgColor: "#00FF57",
      textColor: "#FFFFFF",
      fontsize: "14px",
      fontStyle: "italic",
      message: "Only for Trading at Shardeum",
    },
    {
      positions: [6],
      networks: [0],
      icon: "⚠️",
      bgColor: "#00FF57",
      textColor: "#FFFFFF",
      fontsize: "14px",
      fontStyle: "italic",
      message: "Only for Dashboard. All networks",
    },
    {
      positions: [0],
      networks: [8081],
      icon: "⚠️",
      bgColor: "#D1A35A",
      textColor: "#000000",
      fontsize: "14px",
      fontStyle: "italic",
      message:
        "Be aware that ONLY MARKET Orders are available in Shardeum testnet. Limit Orders, Take Profit, Stop Loss, and Liquidations will be added soon.",
    },
  ];

  const [bannersInfo, setBannersInfo] = useState([]);
  const [chainsInfo, setChainsInfo] = useState([]);
  const [endpointsInfo, setEndpointsInfo] = useState([]);
  const [filteredEndpointsInfo, setFilteredEndpointsInfo] = useState([]);

  const setupMessagingWebsocket = async () => {
    const messagingUrl = getUrl(PROD, "MESSAGING");
    if (messagingSocket.current) {
      await waitForOpenConnection(messagingSocket.current);
    } else {
      IS_PROD || console.log(`Connecting to  MESSAGING ${messagingUrl}`);
      messagingSocket.current = new WebSocket(messagingUrl);
    }

    messagingSocket.current.onopen = () => {
      IS_PROD || console.log(`WebSocket connection to ${messagingUrl} opened!`);
    };

    messagingSocket.current.onmessage = (event) => {
      const object = JSON.parse(event.data);
      if (object.banners) {
        setBannersInfo(object.banners);
        IS_PROD || console.log(`>>> MESSAGING ${messagingUrl} message:`);
        IS_PROD || console.log(">>> BANNERS", object.banners);
      } else if (object.chains) {
        setChainsInfo(object.chains);
        IS_PROD || console.log(`>>> MESSAGING ${messagingUrl} message:`);
        IS_PROD || console.log(">>> CHAINS", object.chains);
      } else if (object.frontend_endpoints || object.endpoints) {
        setEndpointsInfo(object.endpoints || object.frontend_endpoints);
        IS_PROD || console.log(`>>> MESSAGING ${messagingUrl} message:`);
        IS_PROD || console.log(">>> ENDPOINTS", object.frontend_endpoints);
      }
    };

    messagingSocket.current.onclose = () => {
      IS_PROD || console.log(`Messaging WebSocket connection to ${messagingUrl} closed. Retrying in 3 seconds...`);
      messagingSocket.current = null;
      // Reconnect the WebSocket after a delay
      setTimeout(setupMessagingWebsocket, 3000);
    };
  };

  useEffect(() => {
    setupMessagingWebsocket();
  }, []);

  const filteredBannersInfo = useMemo(() => {
    //IS_PROD || console.log("@@MESS: path", path, "chainId", chainId, "active", active, "account", account);
    if (!active) return;
    const position = PATH_TO_POSITION_MAP?.[path] || 666;
    return bannersInfo.filter((banner) => {
      return (
        (banner.positions.includes(position) || banner.positions.includes(0)) &&
        (banner.networks.includes(chainId) || banner.networks.includes(0))
      );
    });
  }, [bannersInfo, chainId, path, active]);

  const filteredChainsInfo = useMemo(() => {
    return chainsInfo.filter((chain) => chain.id === chainId);
  }, [chainsInfo, chainId, active]);

  return { bannersInfo, filteredBannersInfo, chainsInfo, filteredChainsInfo, endpointsInfo };
}

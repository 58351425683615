import useSWR from "swr";

import { useWeb3React } from "@web3-react/core";

import { contractFetcher } from "lib/contracts";
import { dUSDC } from "config/contracts";
import { useContracts } from "hooks/useContracts";

import { formatAmount } from "lib/numbers";
import { USDG_DECIMALS } from "lib/legacy";

export function useTokenInfo({ tokenName, spenderName, chainId, idString, amountToSpend = undefined }) {
  const { contractInfo } = useContracts({ chainId });
  const { active, library, account } = useWeb3React();

  const { data: tokenAllowance } = useSWR(
    active && [
      `${idString}:tokenAllowance:${active}`,
      chainId,
      contractInfo[tokenName].address,
      "allowance",
      account,
      contractInfo[spenderName].address,
    ],
    {
      fetcher: contractFetcher(library, contractInfo[tokenName].abiJson),
    }
  );

  const needsApproval =
    amountToSpend && tokenAllowance && amountToSpend.gt(0) ? amountToSpend.gt(tokenAllowance) : undefined;

  const { data: tokenBalance } = useSWR(
    active && [`${idString}tokenBalance:${active}`, chainId, contractInfo[tokenName].address, "balanceOf", account],
    {
      fetcher: contractFetcher(library, contractInfo[tokenName].abiJson),
    }
  );

  console.log("amountToSpend", amountToSpend, needsApproval);

  const fmtTokenBalance = formatAmount(tokenBalance, USDG_DECIMALS, 2, false);

  return { tokenAllowance, needsApproval, tokenBalance, fmtTokenBalance };
}

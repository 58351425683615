import "./FavoritesHeader.css";
import Star from "../../img/Star1.svg";
import { useState, useEffect } from "react";
import useSWR from "swr";

export const FavoritesHeader = ({ onSelect, latestPrices, cleanPairs, setOurSelectedToken }) => {
  const filteredPairs = cleanPairs.filter((pair) => pair[8]);

  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const container = document.querySelector(".favs-header");
    setIsOverflowing(container.scrollWidth > container.clientWidth);
  }, [filteredPairs]);

  const scrollLeft = () => {
    const container = document.querySelector(".favs-header");
    container.scrollTo({
      left: container.scrollLeft - 250,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    const container = document.querySelector(".favs-header");
    container.scrollTo({
      left: container.scrollLeft + 250,
      behavior: "smooth",
    });
  };

  return (
    <div className="favs-header">
      {isOverflowing && (
        <div className="scroll-buttons">
          <button
            className="scroll-button scroll-left-button"
            onClick={() => {
              scrollLeft();
            }}
          >
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </button>

          <button
            className="scroll-button scroll-right-button"
            onClick={() => {
              scrollRight();
            }}
          >
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </div>
      )}

      <div className="favs-star">
        <img src={Star} />
      </div>

      {filteredPairs.map((pair) => {
        let color = "#fff";

        if (pair[4] > pair[6]) {
          color = "#148A63";
        } else {
          color = "#E23E3E";
        }

        let parts = pair[0].split("/");
        const image = require(`../../img/tokenlogo/${parts[0]}${parts[1]}.svg`).default;

        return (
          <div
            className="favs-par"
            key={pair[0]}
            onClick={() => {
              onSelect(pair[0], pair[7]);
            }}
          >
            <img src={image} width="18px" style={{ marginRight: "12px" }} />
            <p style={{ color: "#999999", marginTop: "10px", width: "30%", marginRight: "16px" }}>
              <span style={{ color: "white" }}>{parts[0]}</span>
              <span>/{parts[1]}</span>
            </p>
            <p style={{ color: color, marginTop: "10px", width: "30%" }}> {pair[4] == "-1" ? "--" : pair[4]}</p>
          </div>
        );
      })}
    </div>
  );
};

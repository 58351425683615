import React, { useEffect, useState } from "react";

export const TradingCompetitorUserStatic = ({ index, tradingCompetitor }) => {
  return (
    <div className="TradingCompetition">
      <div className="TradingCompetitionHeader">
        <div className="competition-info">{tradingCompetitor.position}</div>
        <div className="competition-info">{tradingCompetitor.address}</div>
        <div className="competition-info">{tradingCompetitor.rank}</div>
        <div className="competition-info">{tradingCompetitor.percentage}</div>
        <div className="competition-info">{tradingCompetitor.balance}$</div>
      </div>
    </div>
  );
};

import useSWR from "swr";

import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";

import { contractFetcher } from "lib/contracts";
import { dUSDC, OpenPnlFeed, LockedDepositNFT } from "config/contracts";
import { useContracts } from "hooks/useContracts";

import { formatAmount } from "lib/numbers";
import { USDG_DECIMALS } from "lib/legacy";

import { formatDateTime, SECONDS_PER_HOUR, formatSecondsToDaysString } from "lib/dates";

import { IS_PROD } from "config/context";
import { set } from "date-fns";

export function useVaultInfo({ chainId, vaultTokenName = dUSDC }) {
  const { contractInfo } = useContracts({ chainId });
  const { active, library, account } = useWeb3React();

  const [lockedNfts, setLockedNfts] = useState([]);
  const [lockedAmount, setLockedAmount] = useState(0);
  const [currentEpochWithdrawRequestWindow, setCurrentEpochWithdrawRequestWindow] = useState(undefined);
  const [remainingEpochTime, setRemainingEpochTime] = useState(0);
  const [now, setNow] = useState(0);

  const SECONDS_24H = 24 * SECONDS_PER_HOUR;

  const { data: requestsStart } = useSWR(
    active &&
      contractInfo[OpenPnlFeed].address && [
        `VaultInfo:requestsStart`,
        chainId,
        contractInfo[OpenPnlFeed].address,
        "requestsStart",
      ],
    {
      fetcher: contractFetcher(library, contractInfo[OpenPnlFeed].abiJson),
    }
  );
  //IS_PROD || console.log("VaultInfo.requestsStart  " + requestsStart + "  " + active);

  const { data: requestsEvery } = useSWR(
    active && [`VaultInfo:requestsEvery`, chainId, contractInfo[OpenPnlFeed].address, "requestsEvery"],
    {
      fetcher: contractFetcher(library, contractInfo[OpenPnlFeed].abiJson),
    }
  );
  //IS_PROD || console.log("VaultInfo.requestsEvery   " + requestsEvery + "  " + active);

  const { data: requestsCount } = useSWR(
    active && [`VaultInfo:requestsCount`, chainId, contractInfo[OpenPnlFeed].address, "requestsCount"],
    {
      fetcher: contractFetcher(library, contractInfo[OpenPnlFeed].abiJson),
    }
  );

  const { data: currentEpoch } = useSWR(
    active && [`VaultInfo:currentEpoch:`, chainId, contractInfo[vaultTokenName].address, "currentEpoch"],
    {
      fetcher: contractFetcher(library, contractInfo[vaultTokenName].abiJson),
    }
  );
  //IS_PROD || console.log("VaultInfo.currentEpoch  " + currentEpoch + "  " + active);

  const { data: currentEpochStart } = useSWR(
    active && [`VaultInfo:currentEpochStart`, chainId, contractInfo[vaultTokenName].address, "currentEpochStart"],
    {
      fetcher: contractFetcher(library, contractInfo[vaultTokenName].abiJson),
    }
  );
  //IS_PROD || console.log("contractFetcher VaultInfo.currentEpochStart " + currentEpochStart + "  " + active);

  const currentEpochEnd =
    parseInt(currentEpochStart) + (parseInt(requestsStart) + parseInt(requestsEvery) * parseInt(requestsCount));

  /*
  useEffect(() => {
    const currentEpochEndNew =
      parseInt(currentEpochStart) + (parseInt(requestsStart) + parseInt(requestsEvery) * parseInt(requestsCount));
    IS_PROD || console.log("VaultInfo.useEffect.currentEpochEndNew: " + currentEpochEndNew);
    setcurrentEpochEnd(currentEpochEndNew);
  }, [currentEpochStart, requestsStart, requestsEvery, requestsCount]);*/

  /* dBUSDTotalSupply */
  const { data: dBUSDTotalSupply } = useSWR(
    active && [`VaultInfo:dBUSDTotalSupply:${active}`, chainId, contractInfo[vaultTokenName].address, "totalSupply"],
    {
      fetcher: contractFetcher(library, contractInfo[vaultTokenName].abiJson),
    }
  );

  /* dBUSDPrice */
  const { data: dBUSDPrice } = useSWR(
    active && [`VaultInfo:dBUSDPrice:${active}`, chainId, contractInfo[vaultTokenName].address, "shareToAssetsPrice"],
    {
      fetcher: contractFetcher(library, contractInfo[vaultTokenName].abiJson),
    }
  );

  /* tvl */
  const { data: tvl } = useSWR(
    active && [`VaultInfo:tvl:${active}`, chainId, contractInfo[vaultTokenName].address, "tvl"],
    {
      fetcher: contractFetcher(library, contractInfo[vaultTokenName].abiJson),
    }
  );
  //console.log("tvl " + tvl + "  " + active + " " + contractInfo[vaultTokenName].address + " " + chainId);

  /* collatRatio */
  const { data: collatRatio } = useSWR(
    active && [`VaultInfo:collatRatio:${active}`, chainId, contractInfo[vaultTokenName].address, "collateralizationP"],
    {
      fetcher: contractFetcher(library, contractInfo[vaultTokenName].abiJson),
    }
  );

  /* dBUSDUserBalance */
  const { data: dBUSDUserBalance } = useSWR(
    active && [
      `VaultInfo:dBUSDUserBalance:${active}`,
      chainId,
      contractInfo[vaultTokenName].address,
      "balanceOf",
      account,
    ],
    {
      fetcher: contractFetcher(library, contractInfo[vaultTokenName].abiJson),
    }
  );
  //console.log("dBUSDUserBalance " + dBUSDUserBalance + "  " + active + " " + tokenInfo[dBUSD].address + " " + chainId);
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now() / 1000.0;
      setNow(now);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentEpochStart) {
      const remainingEpochTimeAux = parseInt(getCurrentEpochEnd()) - now;
      const diffTime = currentEpochEnd - Date.now() / 1000.0;
      setCurrentEpochWithdrawRequestWindow(remainingEpochTimeAux - SECONDS_24H);
      setRemainingEpochTime(remainingEpochTimeAux);
    }
  }, [currentEpochStart, now]);

  const getLockedTokens = async () => {
    if (!contractInfo[LockedDepositNFT].contract) return;
    const lockedNftsAux = [];
    const lckBalance = parseInt(lockedNFTBalance);
    const LCKDEPNFT_contract = contractInfo[LockedDepositNFT].contract;
    const dBUSD_contract = contractInfo[vaultTokenName].contract;

    let lockedAmountAux = 0;
    for (let index = 0; index < lckBalance; index++) {
      await LCKDEPNFT_contract.tokenOfOwnerByIndex(account, index)
        .then(async (nftId) => {
          await dBUSD_contract.lockedDeposits(nftId).then((info) => {
            let [, shares, assetsDeposited, assetsDiscount, atTimestampe, lockDuration] = info;
            lockedAmountAux += parseInt(shares);
            lockedNftsAux.push(info);
          });
        })
        .catch((response) => {
          console.log("ERROR useVaultInfo.getLockedTokens: " + response);
        });
    }
    lockedNftsAux.sort((a, b) => {
      return parseInt(a[4]) + parseInt(a[5]) - (parseInt(b[4]) + parseInt(b[5]));
    });
    setLockedNfts(lockedNftsAux);
    setLockedAmount(lockedAmountAux);
  };

  const { data: lockedNFTBalance } = useSWR(
    active && [
      `VaultInfo:lockedNFTBalance:${active}`,
      chainId,
      contractInfo[LockedDepositNFT].address,
      "balanceOf",
      account,
    ],
    {
      fetcher: contractFetcher(library, contractInfo[LockedDepositNFT].abiJson),
    }
  );

  useEffect(() => {
    getLockedTokens();
  }, [lockedNFTBalance]);

  const lockedAmountPrec = lockedAmount
    ? formatAmount(lockedAmount.toLocaleString("fullwide", { useGrouping: false }), USDG_DECIMALS, USDG_DECIMALS, false)
    : 0;
  const dBUSDUserBalancPrec = dBUSDUserBalance
    ? formatAmount(
        dBUSDUserBalance.toLocaleString("fullwide", { useGrouping: false }),
        USDG_DECIMALS,
        USDG_DECIMALS,
        false
      )
    : 0;

  const lockedAmountFmt = lockedAmount
    ? formatAmount(lockedAmount.toLocaleString("fullwide", { useGrouping: false }), USDG_DECIMALS, 2, false)
    : 0;

  const userTotalValuePrec =
    dBUSDPrice && dBUSDUserBalance
      ? (
          (parseFloat(dBUSDUserBalancPrec) + parseFloat(lockedAmountPrec)) *
          formatAmount(dBUSDPrice, USDG_DECIMALS, 2, false)
        ).toString()
      : "...";

  const userTotalValueFmt = (Math.round(userTotalValuePrec * 100) / 100).toFixed(2);

  const getCurrentEpochEnd = () => {
    return parseInt(currentEpochStart) + (parseInt(requestsStart) + parseInt(requestsEvery) * parseInt(requestsCount));
  };

  const getCurrentEpochWithdrawRequestWindow = () => {
    const diffTime = getCurrentEpochEnd() - Date.now() / 1000.0;
    return diffTime - SECONDS_24H;
  };

  const getRemainingEpochTime = () => {
    return getCurrentEpochEnd() - Date.now() / 1000.0;
  };

  const vaultData = {
    currentEpoch: parseInt(currentEpoch),
    currentEpochFmt: currentEpoch ? currentEpoch.toString() : "...",
    currentEpochStart: currentEpochStart,
    currentEpochStartFmt: currentEpochStart ? formatDateTime(currentEpochStart) : "...",
    currentEpochEnd: getCurrentEpochEnd(),
    currentEpochEndFmt: currentEpochEnd ? formatDateTime(getCurrentEpochEnd()) : "...",
    elapsedEpochTime: Date.now() / 1000.0 - currentEpochStart,
    remainingEpochTime: remainingEpochTime,
    remainingEpochTimeFmt: remainingEpochTime
      ? formatSecondsToDaysString(currentEpochEnd - Date.now() / 1000.0)
      : "...",
    currentEpochWithdrawRequestWindow: currentEpochWithdrawRequestWindow,
    tvl: tvl,
    tvlFmt: tvl ? formatAmount(tvl, USDG_DECIMALS, 2, true) : "...",
    collatRatio: collatRatio,
    collatRatioFmt: collatRatio ? formatAmount(collatRatio, USDG_DECIMALS, 2, true) : "...",
    dBUSDTotalSupply: dBUSDTotalSupply,
    dBUSDTotalSupplyFmt: formatAmount(dBUSDTotalSupply, USDG_DECIMALS, 2, true),
    dBUSDPrice: dBUSDPrice,
    dBUSDPriceFmt: formatAmount(dBUSDPrice, USDG_DECIMALS, 10, true),
    dBUSDUserBalance: dBUSDUserBalance,
    dBUSDUserBalanceFmt: formatAmount(dBUSDUserBalance, USDG_DECIMALS, 2, true),
    lockedNFTBalance: lockedNFTBalance,
    lockedNfts: lockedNfts,
    lockedAmount: lockedAmount,
    lockedAmountFmt: lockedAmountFmt,
    userTotalValueFmt: userTotalValueFmt,
  };
  //console.log("###- vaultData ", vaultData);
  return { vaultData };
}

import "./ReferralsV2.css";
import { ethers } from "ethers";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import { useWeb3React } from "@web3-react/core";
import usersLogo from "img/users-logo.png";
import "react-toastify/dist/ReactToastify.css";
import { helperToast } from "lib/helperToast";

import { useLoyaltyReferrals } from "hooks/useLoyaltyReferrals.js";
import { REFERRAL_CODE_KEY } from "config/localStorage";

import { getAppBaseUrlGen } from "lib/legacy";
import { useEffect, useState } from "react";
import { add } from "date-fns";

export function ReferredTable({ referred_addresses }) {
  const { AddressZero } = ethers.constants;

  const getEllipseAddress = (address) => {
    if (!address) return "";
    if (address === AddressZero) return "NONE";

    return address.slice(0, 6) + "..." + address.slice(-6);
  };

  return (
      <table className="referred-table">
        <thead>
          <tr>
            <th style={{ padding: "3px", width: "33%" }}>Address</th>
            <th style={{ padding: "3px", width: "33%" }}>Referral Level</th>
            <th style={{ padding: "3px", width: "33%" }}>Points</th>
          </tr>
        </thead>
        <tbody>
          {referred_addresses?.map((address, index) => {
            let betterIndex = address.join("-");
            console.log("betterIndex", betterIndex);
            return (
              <tr key={betterIndex}>
                <td style={{ padding: "3px", width: "33%" }}>{getEllipseAddress(address[0])}</td>
                <td style={{ padding: "3px", width: "33%" }}>
                  {Intl.NumberFormat("es-CL").format(parseFloat(address[1]))}
                </td>
                <td style={{ padding: "3px", width: "33%" }}>
                  {Intl.NumberFormat("es-CL").format(parseFloat(address[2]))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
  );
}
const getReferrer = (account) => {
  const referrers = JSON.parse(localStorage.getItem(REFERRAL_CODE_KEY)) || {};
  const currentReferrer = referrers[account] || null;
  return currentReferrer;
};

function Referrals({ connectWallet, setWalletModalVisible }) {
  const { active, account: walletAccount, library } = useWeb3React();
  const currentReferrer = getReferrer(walletAccount);
  const { AddressZero } = ethers.constants;
  const [dexTempCode, setDexTempCode] = useState("");
  const [refCode, setRefCode] = useState("");
  const [display, setDisplay] = useState("");

  console.log("cambio de prueba");

  const { referralData, fetching, generateReferralCode, fetchReferralData } = useLoyaltyReferrals({
    account: walletAccount,
    periodic: true,
    refreshInterval: 8000,
  });

  useEffect(() => {
    const dexTemp = localStorage.getItem("DEXYNTH-TMP-referralCode");
    const dexRef = JSON.parse(localStorage.getItem("DEXYNTH-referralCode"));

    setDexTempCode(dexTemp);
    setRefCode(dexRef);
    fetchReferralData();
  }, []);

  const getEllipseAddress = (address) => {
    if (!address) return "";
    if (address === AddressZero) return "NONE";

    return address.slice(0, 6) + "..." + address.slice(-6);
  };

  const [message, setMessage] = useState();

  useEffect(() => {
    console.log("@@@@ walletAccount", walletAccount);
    console.log("@@@@ referralData.referrer", referralData.referrer);
    console.log("@@@@ refCode", refCode);
    console.log("@@@@ dexTempCode", dexTempCode);

    const zeroAddress = "0x0000000000000000000000000000000000000000";

    if (walletAccount) {
      if (referralData.referrer != zeroAddress && referralData.referrer != "" && referralData.referrer != undefined) {
        setMessage(`Referred by ${getEllipseAddress(referralData.referrer)}`);
      } else if (referralData.referrer === zeroAddress) {
        setMessage("");
      } else if (referralData.referrer === "") {
        if (refCode !== zeroAddress && refCode !== "") {
          setMessage(`Referred by ${refCode} f`);
        } else if (refCode === zeroAddress) {
          setMessage("");
        } else {
          setMessage("");
        }
      }
    }

    if (!walletAccount && dexTempCode != "" && dexTempCode != null && dexTempCode != undefined) {
      setMessage(`Referred by ${dexTempCode}`);
    } else {
      setMessage("");
    }
  }, [refCode, dexTempCode, walletAccount, referralData]);

  const requestReferralCode = async () => {
    const code = await generateReferralCode();
  };

  const copyToClipboard = () => {
    // URL you want to copy
    const referralCode = referralData?.referral_code;
    const baseUrl = getAppBaseUrlGen();
    const url = `https://www.dexynth.com/?invite=${referralCode}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        // Optional: Display a message or do something after copying
        console.log("URL copied to clipboard!");
        helperToast.info(<div>Copied: {url}</div>, { autoClose: 1500 });
      })
      .catch((err) => {
        // Error handling
        console.error("Failed to copy URL: ", err);
      });
  };

  function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  const referralCode = referralData?.referral_code;
  const baseUrl = getAppBaseUrlGen();

  return (
    <SEO title={getPageTitle("Referrals")}>
      <div className="Referrals">
        <h2 className="referrals-title">🔥 LOYALTY PROGRAM 🔥</h2>
        <p style={{ textAlign: "center" }}>
          Get Loyalty Points through Dexynth´s referral program and trading. <br /> 💰💰💰 Loyalty points will become
          $DEXY airdrop 💰💰💰
        </p>
        <div className="referrals-table-content" style={{ marginBottom: "80px", marginTop: "80px" }}>
          {/* Estruc */}
          <div className="referrals-table-content-short-box">
            <h3 style={{ display: walletAccount ? "block" : "none" }}>
              {referralData
                ? isNumeric(referralData?.points?.trading_points)
                  ? Intl.NumberFormat("es-CL").format(parseFloat(referralData?.points?.trading_points))
                  : "Loading..."
                : "Loading..."}
            </h3>
            <img className="highlighted-value-img" src={usersLogo} alt="users-logo" />
            <p>TRADING POINTS</p>
          </div>

          <div className="referrals-table-content-short-box">
            <h3 style={{ display: walletAccount ? "block" : "none" }}>
              {referralData
                ? isNumeric(referralData?.points?.referral_points)
                  ? Intl.NumberFormat("es-CL").format(parseFloat(referralData?.points?.referral_points))
                  : "Loading..."
                : "Loading..."}
            </h3>
            <img className="highlighted-value-img" src={usersLogo} alt="users-logo" />
            <p>REFERRAL POINTS</p>
          </div>

          <div className="referrals-table-content-short-box">
            <h3 style={{ display: walletAccount ? "block" : "none" }}>
              {referralData
                ? isNumeric(referralData?.points?.total_loyalty_points)
                  ? Intl.NumberFormat("es-CL").format(parseFloat(referralData?.points?.total_loyalty_points))
                  : "Loading..."
                : "Loading..."}
            </h3>
            <img className="highlighted-value-img" src={usersLogo} alt="users-logo" />
            <p>TOTAL LOYALTY POINTS</p>
          </div>
        </div>
        <div className="referrals-table-content-v2">
          <p>{message}</p>

          <div className="referral-code-div">
            {!referralData?.referral_code && walletAccount && (
              <button className="highlighted-value" disabled={fetching || !active} onClick={requestReferralCode}>
                Generate referral code
              </button>
            )}
            <h1>Multilevel Referral Link</h1>
            <h2>Invite friends for more loyalty points !</h2>
            <p style={{ fontSize: "18px" }}>
              When your friend trades you earn 5% of his points ! <br /> If a second friend trades, your friend earns 5%
              and you and extra 1% !{" "}
            </p>
            <img className="highlighted-value-img" src={usersLogo} alt="users-logo" />
            <span className="highlighted-sub-value">Invite friends for more loyalty points!</span>

            {referralData?.referral_code && (
              <p
                style={{ cursor: "pointer", display: walletAccount ? "block" : "none" }}
                onClick={copyToClipboard}
                className="buttonConnect"
              >
                {" "}
                {`www.dexynth.com?invite=${referralCode}`}
                <i class="fa-regular fa-copy" style={{ marginLeft: "10px" }}></i>
              </p>
            )}
            {!walletAccount && (
              <p className="buttonConnect" style={{ marginBottom: "0px" }} onClick={() => setWalletModalVisible(true)}>
                Connect Wallet
              </p>
            )}
          </div>
        </div>
         {walletAccount && referralData?.referred_addresses?.length > 0 && ( 
          <div className="page-title" style={{ marginTop: "220px" }}>
            <ReferredTable referred_addresses={referralData?.referred_addresses} />
          </div>
         )}
      </div>
    </SEO>
  );
}

export default Referrals;

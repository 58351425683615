import { useState } from "react";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";

import Tooltip from "components/Tooltip/Tooltip";
import Footer from "components/Footer/Footer";
import { StakeModal, UnstakeModal, ClaimModal } from "components/Stake/StakeModals";

import { DEXY, Staking } from "config/contracts";

import "./Stake.css";
import { formatKeyAmount } from "lib/numbers";
import { useChainId } from "lib/chains";
import { useContracts } from "hooks/useContracts";
import { useStakingInfo } from "hooks/useStakingInfo";

export default function Stake({ setPendingTxns, connectWallet }) {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [rewardRouterAddress, setRewardRouterAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");

  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);

  const { contractInfo } = useContracts({ chainId });
  //console.log("contractInfo", contractInfo);
  const { stakingData: rawStakingData } = useStakingInfo({ contractInfo, chainId });
  const {
    dexyPrice,
    dexyPriceFmt,
    dexyBalance,
    dexyBalanceFmt,
    dexyBalanceUsdFmt,
    dexyTotalSupply,
    dexyTotalSupplyFmt,
    dexyTotalSupplyUsdFmt,
    userStakedTokens,
    userStakedTokensFmt,
    userStakedTokensUsdFmt,
    stakedDexySupply,
    stakedDexySupplyFmt,
    stakedDexySupplyUsdFmt,
    nativeTokenSymbol,
    wrappedTokenSymbol,
    userPendingRewards,
    userPendingRewardsFmt,
  } = rawStakingData;

  const showStakeGmxModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle(t`Stake DEXY`);
    setStakeModalMaxAmount(dexyBalance);
    setStakeValue("");
    setStakingTokenSymbol("DEXY");
    setStakingTokenAddress(contractInfo[DEXY].address);
    setStakingFarmAddress(contractInfo[Staking].address);
    setRewardRouterAddress(contractInfo[Staking].address);
    setStakeMethodName("stakeTokens");
  };

  const showUnstakeGmxModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(t`Unstake DEXY`);
    let maxAmount = userStakedTokens;
    setUnstakeModalMaxAmount(userStakedTokens);
    setStakingFarmAddress(contractInfo[Staking].address);
    setUnstakeValue("");
    setUnstakingTokenSymbol("DEXY");
    setUnstakeMethodName("unstakeTokens");
  };

  return (
    <div className="default-container page-layout">
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        setPendingTxns={setPendingTxns}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        farmAddress={stakingFarmAddress}
        unstakeMethodName={unstakeMethodName}
      />

      <ClaimModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        farmAddress={stakingFarmAddress}
        totalVesterRewards={userPendingRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
      <div className="section-title-block">
        <div className="section-title-icon"></div>
        <div className="section-title-content">
          <div className="Page-title">
            <Trans>Earn</Trans>
          </div>
          <div className="Page-description">
            <Trans>
              Stake{" "}
              <a href="https://" target="_blank" rel="noopener noreferrer">
                DEXY
              </a>{" "}
              to earn rewards.
            </Trans>
          </div>
        </div>
      </div>
      <div className="StakeV2-content">
        <div className="StakeV2-cards">
          <div className="App-card StakeV2-gmx-card">
            <div className="App-card-title">DEXY STAKING</div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  <Trans>Price</Trans>
                </div>
                <div>
                  {!dexyPrice && "..."}
                  {dexyPrice && <div>{"$" + dexyPriceFmt}</div>}
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Wallet</Trans>
                </div>
                <div>
                  {dexyBalanceFmt} DEXY ($
                  {dexyBalanceUsdFmt})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Staked</Trans>
                </div>

                <div>
                  {userStakedTokensFmt} DEXY ($
                  {userStakedTokensUsdFmt})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>APR</Trans>
                </div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(undefined, "gmxAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <div>
                            <br />
                            <Trans>
                              APRs are updated weekly on Wednesday and will depend on the fees collected for the week.
                            </Trans>
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Rewards</Trans>
                </div>
                <div>${userPendingRewardsFmt}</div>
              </div>

              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Total Staked</Trans>
                </div>
                {!stakedDexySupply && "..."}
                {stakedDexySupply && (
                  <div>
                    {stakedDexySupplyFmt} DEXY ($
                    {stakedDexySupplyUsdFmt})
                  </div>
                )}
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Total Supply</Trans>
                </div>
                {!dexyTotalSupply && "..."}
                {dexyTotalSupply && (
                  <div>
                    {dexyTotalSupplyFmt} DEXY ($
                    {dexyTotalSupplyUsdFmt})
                  </div>
                )}
              </div>
              <div className="App-card-divider" />
              <div className="App-card-options">
                {active && (
                  <button className="App-button-option App-card-option" onClick={() => showStakeGmxModal()}>
                    <Trans>STAKE</Trans>
                  </button>
                )}
                {active && (
                  <button className="App-button-option App-card-option" onClick={() => showUnstakeGmxModal()}>
                    <Trans>UNSTAKE</Trans>
                  </button>
                )}

                {active && (
                  <button className="App-button-option App-card-option" onClick={() => setIsClaimModalVisible(true)}>
                    <Trans>CLAIM</Trans>
                  </button>
                )}
                {!active && (
                  <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                    <Trans>CONNECT WALLET</Trans>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

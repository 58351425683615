import { t, Trans } from "@lingui/macro";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";

import { isSupportedChain } from "config/chains";

import { USDG_DECIMALS } from "lib/legacy";
import { parseValue } from "lib/numbers";

import { approveTokens } from "domain/tokens";
import { helperToast } from "lib/helperToast";

import { useTokenInfo } from "hooks/useTokenInfo";
import { useContracts } from "hooks/useContracts";

import { dUSDC, USDC, DEXY } from "config/contracts";

export default function OtcSwap(props) {
  const { chainId, direction, setPendingTxns } = props;

  const [toValue, setToValue] = useState("");
  const [fromValue, setFromValue] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { active, account, library } = useWeb3React();
  //const provider = new ethers.providers.Web3Provider(window.ethereum);
  //const signer = provider.getSigner();
  const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum, "any") : null;
  const signer = provider ? provider.getSigner() : null;

  const { contractInfo } = useContracts({ chainId });

  const fromToken = direction === "BUY" ? USDC : DEXY;
  const toToken = direction === "SELL" ? USDC : DEXY;

  let amount = parseValue(fromValue, USDG_DECIMALS);

  const { tokenAllowance, needsApproval, tokenBalance, fmtTokenBalance } = useTokenInfo({
    tokenName: fromToken,
    spenderName: dUSDC,
    chainId,
    idString: "OtcSwap",
    amountToSpend: amount,
  });

  useEffect(() => {
    if (!needsApproval && isWaitingForApproval) {
      setIsWaitingForApproval(false);
    }
  }, [needsApproval, isWaitingForApproval]);

  const onFromValueChange = (e) => {
    if (!e.target.value) {
      setFromValue(e.target.value);
      setToValue(e.target.value);
      return;
    }
    const _fromValue = e.target.value ? e.target.value : 0.0;
    setFromValue(_fromValue);
    setToValue(_fromValue);
  };

  const isPrimaryEnabled = () => {
    if (fromValue <= 0.0) {
      return false;
    }
    if (!active) {
      return false;
    }
    if (!isSupportedChain(chainId)) {
      return false;
    }
    if (needsApproval && isWaitingForApproval) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (needsApproval) {
      return true;
    }
    return true;
  };

  const performAction = () => {
    const _fromValue =
      fromToken === DEXY
        ? ethers.utils.parseUnits(fromValue, USDG_DECIMALS)
        : ethers.utils.parseUnits(fromValue, USDG_DECIMALS);

    setIsSubmitting(true);
    //const contract = new ethers.Contract(contractInfo[dUSDC].address, contractInfo[dUSDC].abi.abi, signer);
    const action = fromToken === DEXY ? contractInfo[dUSDC].contract.deplete : contractInfo[dUSDC].contract.refill;

    action(_fromValue)
      .then(async (res) => {
        setIsConfirming(true);
        await res.wait(1);
        setIsConfirming(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.reason.includes("NOT_UNDER_COLLATERALIZED")) {
          helperToast.error(t`ERROR in Tx: Not Undercollateralized`);
        } else if (err.reason.includes("AMOUNT_TOO_BIG")) {
          helperToast.error(t`ERROR in Tx: Amount too big`);
        } else if (err.reason.includes("ABOVE_INFLATION_LIMIT")) {
          helperToast.error(t`ERROR in Tx: Above Inflation Limit`);
        } else {
          helperToast.error(t`ERROR in Tx: ${err.reason}`);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onClickPrimary = () => {
    console.log("Click");
    if (needsApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: contractInfo[fromToken].address,
        spender: contractInfo[dUSDC].address,
        chainId,
      });
      return;
    }
    performAction();
  };

  const getPrimaryText = () => {
    if (!active) {
      return t`Connect Wallet`;
    }
    if (!isSupportedChain(chainId)) {
      return t`Incorrect Network`;
    }

    if (needsApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }
    if (isApproving) {
      return t`Approving ${contractInfo[fromToken].label}...`;
    }
    if (needsApproval) {
      return t`Approve ${contractInfo[fromToken].label}`;
    }
    if (isConfirming) {
      return t`Confiming Tx`;
    }
    if (isSubmitting) {
      return t`Submitting Tx`;
    }
    return t`${direction}`;
  };

  return (
    <div className="otc-swap">
      <div className="otc-content">
        <div className="otc-label">
          <div className="otc-text">Send</div>

          <div className="">
            <Trans>Balance</Trans>: {fmtTokenBalance}
            <button
              className="max-button"
              onClick={() => {
                setFromValue(fmtTokenBalance);
              }}
            >
              Max
            </button>
          </div>
        </div>
        <div className="otc-input-container">
          <input
            type="number"
            min="10"
            max="10000000"
            placeholder="0.0"
            className="otc-input"
            value={fromValue}
            onChange={onFromValueChange}
          />
        </div>
      </div>
      <br />
      <div className="otc-content">
        <div className="otc-label">
          <div className="otc-text">Receive</div>
        </div>
        <div className="otc-input-container">
          <input
            type="number"
            min="10"
            max="10000000"
            placeholder="0.0"
            className="otc-input"
            value={toValue}
            readOnly="readonly"
          />
        </div>
      </div>
      <div className="button-container">
        <button className="button-end" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
          {getPrimaryText()}
        </button>
      </div>
    </div>
  );
}

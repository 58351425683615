import React, { useEffect, useRef } from "react";
import { widget } from "../../charting_library";
import "./TradingView.css";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const TVChartContainer = ({ ourSelectedToken }) => {
  let token = ourSelectedToken.pairName;
  const chartContainerRef = useRef();

  const defaultProps = {
    symbol: token,
    interval: "D",
    timezone: "Etc/UTC",
    locale: "en",
    datafeedUrl: "https://demo_feed.tradingview.com",
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    popup_width: "",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    custom_css_url: "../themed.css",
  };

  useEffect(() => {
    const widgetOptions = {
      symbol: token,
      theme: "dark",
      overrides: {
        "paneProperties.background": "#101010",
        "paneProperties.backgroundType": "solid",
        "linetoolabcd.color": "#101010",
        "linetoolheadandshoulders.backgroundColor": "#101010",

        // "paneProperties.horzGridProperties.color": "#121212",
        // "paneProperties.vertGridProperties.color": "#121212",
        // "paneProperties.separatorColor": "#121212"
      },
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
      interval: defaultProps.interval,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath,
      toolbar_bg: "#101010",
      timezone: "Etc/UTC",
      locale: "en",
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      custom_css_url: "../themed.css",
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => {
              console.log("Noticed!");
            },
          })
        );

        button.innerHTML = "Check API";
      });
      // tvWidget.setSymbol(token);

      // tvWidget.setSymbol(token, '1D');
    });

    console.log(ourSelectedToken);

    return () => {
      tvWidget.remove();
    };
  }, [token]);

  return <div style={{ height: "100%" }} ref={chartContainerRef} className={"TVChartContainer"} />;
};

import "./AddressDropdown.css";
import { Menu } from "@headlessui/react";
import { Trans } from "@lingui/macro";
import { shortenAddress, useENS } from "lib/legacy";
import { useCopyToClipboard, createBreakpoint } from "react-use";
import externalLink from "img/ic_new_link_16.svg";
import copy from "img/ic_copy_16.svg";
import disconnect from "img/ic_sign_out_16.svg";
import { FaChevronDown } from "react-icons/fa";
import Davatar from "@davatar/react";
import { helperToast } from "lib/helperToast";
import { Link } from "react-router-dom";

function AddressDropdown({ account, accountUrl, disconnectAccountAndCloseSettings }) {
  const useBreakpoint = createBreakpoint({ L: 600, M: 550, S: 400 });
  const breakpoint = useBreakpoint();
  const [, copyToClipboard] = useCopyToClipboard();
  const { ensName } = useENS(account);
  return (
    <Menu>
      <Menu.Button as="div">
        <button className="App-cta small transparent address-btn">
          <span className="user-address" style={{ color: "#D1A35A" }}>
            {ensName || shortenAddress(account, breakpoint === "S" ? 9 : 13)}
          </span>
          <FaChevronDown />
        </button>
      </Menu.Button>
      <div>
        <Menu.Items
          as="div"
          className="menu-items"
          style={{ fontFamily: "Lato !important", color: "#1D1D1D !important", right: "110px", width: "220px" }}
        >
          <Menu.Item>
            <div
              className="menu-item"
              style={{}}
              onClick={() => {
                copyToClipboard(account);
                helperToast.success("Address copied to your clipboard");
              }}
            >
              <img src={copy} alt="Copy user address" />
              <p>
                <Trans>Copy Address</Trans>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <a href={accountUrl} target="_blank" rel="noopener noreferrer" className="menu-item ">
              <img src={externalLink} alt="Open address in explorer" />
              <p>
                <Trans>View in Explorer</Trans>
              </p>
            </a>
          </Menu.Item>
          <Menu.Item>
            <div className="menu-item" onClick={disconnectAccountAndCloseSettings}>
              <img src={disconnect} alt="Disconnect the wallet" />
              <p>
                <Trans>Disconnect</Trans>
              </p>
            </div>
          </Menu.Item>
        </Menu.Items>
      </div>
    </Menu>
  );
}

export default AddressDropdown;

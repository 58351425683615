import { Trans, t, select } from "@lingui/macro";

const TradeHistoryHeader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        fontSize: "14px",
        backgroundColor: "#303030",
        padding: "5px 0px",
      }}
    >
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>Date</Trans>
        {/* ok */}
      </div>
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>Type</Trans>
        {/* ok */}
      </div>
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>L/S</Trans>
        {/* ok */}
      </div>
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>Pair</Trans>
        {/* ok */}
      </div>
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>Position</Trans>
        {/* ok */}
      </div>
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>Collateral</Trans>
        {/* ok */}
      </div>
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>Leverage</Trans>
        {/* ok */}
      </div>
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>Execution Price</Trans>
        {/* ok */}
      </div>
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>Open Price</Trans>
        {/* ok */}
      </div>
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>Take Profit</Trans>
        {/* ok */}
      </div>
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>Stop Loss</Trans>
        {/* ok */}
      </div>
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>Profit</Trans>
        {/* ok */}
      </div>
      <div style={{ textAlign: "center", width: "7.6%", color: "#828785" }}>
        <Trans>PnL</Trans>
        {/* ok */}
      </div>
    </div>
  );
};

export default TradeHistoryHeader;

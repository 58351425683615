import DexynthContracts from "abis/DexynthContracts.json";
import { ethers } from "ethers";

import { setContract, getContract } from "config/contracts";
import { TESTNET } from "config/chains";

export const USDC = "USDC";
export const Factory = "Factory";
export const Router = "Router";
export const DEXY = "DEXY";
export const LPToken = "LPToken";
export const TradingStorage = "TradingStorage";
export const PairsStorage = "PairsStorage";
export const NftRewards = "NftRewards";
export const PairInfos = "PairInfos";
export const Referrals = "Referrals";
export const Trading = "Trading";
export const Staking = "Staking";
export const PriceAggregator = "PriceAggregator";
export const TradingCallbacks = "TradingCallbacks";
export const OpenPnlFeed = "OpenPnlFeed";
export const dUSDC = "dUSDC";
export const LockedDepositNFT = "LockedDepositNFT";
export const LINK = "LINK";
export const Fuckies = "Fuckies";
export const FuckiesTrade = "FuckiesTrade";
export const Pool = "Pool";

export const allContracts = [
  USDC,
  Factory,
  Router,
  DEXY,
  LPToken,
  TradingStorage,
  PairsStorage,
  NftRewards,
  PairInfos,
  Referrals,
  Trading,
  Staking,
  PriceAggregator,
  TradingCallbacks,
  OpenPnlFeed,
  dUSDC,
  LockedDepositNFT,
  LINK,
  Fuckies,
  FuckiesTrade,
  Pool,
];

export const getDexynthContractAddresses = async (
  provider,
  chainId,
  contractList = allContracts,
  setCurrentContract
) => {
  console.log([provider, chainId]);

  if (!provider) {
    console.log("getDexynthContractAddresses.NO_PROVIDER_ERROR");
    setCurrentContract("");
    return false;
  }
  const signer = provider.getSigner();
  const dexynthContracts = new ethers.Contract(getContract(chainId, "DexynthContracts"), DexynthContracts.abi, signer);
  const addresses = {};
  try {
    for (let i = 0; i < contractList.length; i++) {
      const contract = contractList[i];
      const res = await dexynthContracts[contract]();
      addresses[contract] = res;
      setContract(TESTNET, contract, res);
      setCurrentContract("Contract:" + contract + " Address:" + res);
      console.log(`"${contract}" :  ${getContract(TESTNET, contract)},`);
    }
    //if (setLoaded) setLoaded(true);
  } catch {
    console.log("getDexynthContractAddresses.CONTRACT_LOADING_ERROR");
    setCurrentContract("");
    return false;
  }
  return true;
};

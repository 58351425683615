import "./Vault.css";
import { forwardRef } from "react";
import { t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";

import VaultBox from "components/Vault/Vault";
import VaultGeneralInfo from "components/Vault/VaultGeneralInfo";
import VaultAccountInfo from "components/Vault/VaultAccountInfo";
import Tab from "components/Tab/Tab";
import Footer from "components/Footer/Footer";

import { useLocalStorageByChainId } from "lib/localStorage";

import { useChainId } from "lib/chains";
import { useVaultInfo } from "hooks/useVaultInfo";

export const Vault = forwardRef((props, ref) => {
  const { pendingTxns, setPendingTxns } = props;

  // web3
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const { vaultData } = useVaultInfo({ chainId });

  const DEPOSIT = "DEPOSIT";
  const WITHDRAW = "WITHDRAW";
  const UNLOCK = "UNLOCK";
  const U_DEPOSIT = "U_DEPOSIT";

  const LIST_SECTIONS = [DEPOSIT, U_DEPOSIT, WITHDRAW, UNLOCK].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-section-VAULT", LIST_SECTIONS[0]);

  const LIST_SECTIONS_LABELS = {
    [DEPOSIT]: t`DEPOSIT`,
    [WITHDRAW]: t`WITHDRAW`,
    [UNLOCK]: t`UNLOCK`,
    [U_DEPOSIT]: t`DEPOSIT WITH DISCOUNT & LOCK`,
  };

  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }

  const getListSection = () => {
    return (
      <div>
        <div className="Vault-list-tab-container">
          <Tab
            options={LIST_SECTIONS}
            optionLabels={LIST_SECTIONS_LABELS}
            option={listSection}
            onChange={(section) => setListSection(section)}
            type="inline"
            className="Vault-list-tabs"
          />
        </div>
        <VaultBox
          direction={listSection}
          chainId={chainId}
          setPendingTxns={setPendingTxns}
          vaultData={vaultData}
          account={account}
        />
      </div>
    );
  };

  return (
    <div className="contenedor-vault">
      <div className="">
        <div className="">
          <div className="general-vault">
            <VaultGeneralInfo vaultData={vaultData} />
            <VaultAccountInfo vaultData={vaultData} />
          </div>

          <div className="">
            <div className=""></div>
            <div className="">
              <div className="">
                <div className="">
                  <div className="">{getListSection()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

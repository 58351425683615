import { useState, useEffect, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";

import { useContext } from "react";
import AppContext from "AppContext";
import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import { useChainId } from "lib/chains";

import { BigNumber, ethers } from "ethers";

export function useHistoric({ filterBySelectedPair, ourSelectedToken, days, historicEvent }) {
  const { chainId } = useChainId();
  const { active, account, library } = useWeb3React();
  const { filteredEndpointsInfo } = useContext(AppContext);

  const [trades, setTrades] = useState([]);
  const [isFetchingTrades, setIsFetchingTrades] = useState(0);
  const [lastDays, setLastDays] = useState(1);

  useEffect(() => {
    if (days) {
      setLastDays(days);
      fetchTradesData({ days: days });
    }
  }, [days]);

  useEffect(() => {
    fetchTradesData({ days: lastDays });
  }, [account, chainId]);

  useEffect(() => {
    addTrade(historicEvent);
  }, [historicEvent]);

  const addTrade = (trade) => {
    if (!trade) return;
    if (!trade.event) return;
    trade.event = trade.event == "LimitExecutedEvent" || trade.event == "LimitExecuted" ? "LimitExecuted" : trade.event;
    trade.timestamp = parseInt(Date.now() / 1000);
    setTrades((trades) => [trade, ...trades]);
  };

  const fetchTradesData = ({ days }) => {
    if (!account) return;

    //const baseUrl = getUrl(PROD, "HISTORIC");
    const baseUrl = filteredEndpointsInfo.historical_api;
    const url = `${baseUrl}/data/${days}/${account}/${chainId}`;
    setTrades([]);
    setIsFetchingTrades(1);
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTrades(data.trades);
        setIsFetchingTrades(0);
      })
      .catch((error) => {
        console.log(">>>>> error", error);
        setIsFetchingTrades(0);
      });
  };
  const tradesWithMessages = useMemo(() => {
    if (!trades) {
      return [];
    }
    return trades
      .filter((trade, index) => {
        return !filterBySelectedPair || (filterBySelectedPair && trade.pairIndex == ourSelectedToken.pairIndex);
      })
      .map((trade, index) => ({
        idx: index,
        ...trade,
      }));
    //.filter((trade) => trade.msg);
  }, [trades, filterBySelectedPair, ourSelectedToken]);

  return { trades: tradesWithMessages, isFetchingTrades };
}

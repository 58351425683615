import React, { useState, useRef, useEffect } from "react";
import AppContext from "./AppContext";
import { getUrl, PROD, LOCAL } from "config/urls";
import { waitForOpenConnection } from "lib/sockets";
import useSWR from "swr";

import { useLocation } from "react-router-dom";
import { useChainId } from "lib/chains";
import useMessaging from "hooks/useMessaging";

import { IS_PROD, MODE } from "config/context";

const AppProvider = ({ children }) => {
  //const location = useLocation();
  const { chainId } = useChainId();

  const { endpointsInfo } = useMessaging({ path: undefined, chainId });
  const filteredEndpointsInfo = endpointsInfo ? endpointsInfo[MODE] : [];

  const pares = [
    ["BTC/USD", 0, 0, true, 0, 0, 0, "BTCUSDT", true],
    ["ETH/USD", 1, 0, true, 0, 0, 0, "ETHUSDT", true],
    ["LINK/USD", 2, 0, true, 0, 0, 0, "LINKUSDT", false],
    ["DOGE/USD", 3, 0, true, 0, 0, 0, "DOGEUSDT", false],
    ["MATIC/USD", 4, 0, true, 0, 0, 0, "MATICUSDT", false],
    ["ADA/USD", 5, 0, true, 0, 0, 0, "ADAUSDT", false],
    ["SUSHI/USD", 6, 0, false, 0, 0, 0, "SUSHIUSDT", false],
    ["AAVE/USD", 7, 0, true, 0, 0, 0, "AAVEUSDT", false],
    ["ALGO/USD", 8, 0, true, 0, 0, 0, "ALGOUSDT", false],
    ["BAT/USD", 9, 0, true, 0, 0, 0, "BATUSDT", false],
    ["COMP/USD", 10, 0, true, 0, 0, 0, "COMPUSDT", false],
    ["DOT/USD", 11, 0, true, 0, 0, 0, "DOTUSDT", false],
    ["EOS/USD", 12, 0, true, 0, 0, 0, "EOSUSDT", false],
    ["LTC/USD", 13, 0, true, 0, 0, 0, "LTCUSDT", false],
    ["MANA/USD", 14, 0, true, 0, 0, 0, "MANAUSDT", false],
    ["OMG/USD", 15, 0, true, 0, 0, 0, "OMGUSDT", false],
    ["SNX/USD", 16, 0, true, 0, 0, 0, "SNXUSDT", false],
    ["UNI/USD", 17, 0, true, 0, 0, 0, "UNIUSDT", false],
    ["XLM/USD", 18, 0, true, 0, 0, 0, "XLMUSDT", false],
    ["XRP/USD", 19, 0, true, 0, 0, 0, "XRPUSDT", false],
    ["ZEC/USD", 20, 0, true, 0, 0, 0, "ZECUSDT", false],
    ["EUR/USD", 21, 1, true, 0, 0, 0, "EURUSDT", true],
    ["USD/JPY", 22, 1, true, 0, 0, 0, "USDJPY", false],
    ["GBP/USD", 23, 1, true, 0, 0, 0, "GBPUSDT", false],
    ["USD/CHF", 24, 1, true, 0, 0, 0, "USD/CHF", false],
    ["AUD/USD", 25, 1, true, 0, 0, 0, "AUDUSD", false],
    ["USD/CAD", 26, 1, true, 0, 0, 0, "USD/CAD", false],
    ["NZD/USD", 27, 1, true, 0, 0, 0, "NZDUSD", false],
    ["EUR/CHF", 28, 1, true, 0, 0, 0, "EUR/CHF", false],
    ["EUR/JPY", 29, 1, true, 0, 0, 0, "EURJPY", false],
    ["EUR/GBP", 30, 1, true, 0, 0, 0, "EUR/GBP", false],
    ["LUNA/USD", 31, 0, false, 0, 0, 0, "LUNAUSDT", false],
    ["YFI/USD", 32, 0, true, 0, 0, 0, "YFIUSDT", false],
    ["SOL/USD", 33, 0, true, 0, 0, 0, "SOLUSDT", false],
    ["XTZ/USD", 34, 0, true, 0, 0, 0, "XTZUSDT", false],
    ["BCH/USD", 35, 0, true, 0, 0, 0, "BCHUSDT", false],
    ["BNT/USD", 36, 0, false, 0, 0, 0, "BNTUSDT", false],
    ["CRV/USD", 37, 0, true, 0, 0, 0, "CRVUSDT", false],
    ["DASH/USD", 38, 0, true, 0, 0, 0, "DASHUSDT", false],
    ["ETC/USD", 39, 0, true, 0, 0, 0, "ETCUSDT", false],
    ["ICP/USD", 40, 0, true, 0, 0, 0, "ICPUSDT", false],
    ["MKR/USD", 41, 0, true, 0, 0, 0, "MKRUSDT", false],
    ["NEO/USD", 42, 0, false, 0, 0, 0, "NEOUSDT", false],
    ["THETA/USD", 43, 0, true, 0, 0, 0, "THETAUSDT", false],
    ["TRX/USD", 44, 0, true, 0, 0, 0, "TRXUSDT", false],
    ["ZRX/USD", 45, 0, false, 0, 0, 0, "ZRXUSDT", false],
    ["SAND/USD", 46, 0, true, 0, 0, 0, "SANDUSDT", false],
    ["BNB/USD", 47, 0, true, 0, 0, 0, "BNBUSDT", false],
    ["AXS/USD", 48, 0, false, 0, 0, 0, "AXSUSDT", false],
    ["GRT/USD", 49, 0, true, 0, 0, 0, "GRTUSDT", false],
    ["HBAR/USD", 50, 0, false, 0, 0, 0, "HBARUSDT", false],
    ["XMR/USD", 51, 0, true, 0, 0, 0, "XMRUSDT", false],
    ["ENJ/USD", 52, 0, true, 0, 0, 0, "ENJUSDT", false],
    ["FTM/USD", 53, 0, true, 0, 0, 0, "FTMUSDT", false],
    ["FTT/USD", 54, 0, false, 0, 0, 0, "FTTUSDT", false],
    ["APE/USD", 55, 0, true, 0, 0, 0, "APEUSDT", false],
    ["CHZ/USD", 56, 0, true, 0, 0, 0, "CHZUSDT", false],
    ["SHIB/USD", 57, 0, true, 0, 0, 0, "SHIBUSDT", false],
    ["AAPL/USD", 58, 3, true, 0, 0, 0, "AAPL", true],
    ["FB/USD", 59, 3, false, 0, 0, 0, "FBUSDT", false],
    ["GOOGL/USD", 60, 2, true, 0, 0, 0, "GOOGL", false],
    ["AMZN/USD", 61, 3, true, 0, 0, 0, "AMZN", false],
    ["MSFT/USD", 62, 2, true, 0, 0, 0, "MSFT", false],
    ["TSLA/USD", 63, 4, true, 0, 0, 0, "TSLA", false],
    ["SNAP/USD", 64, 4, true, 0, 0, 0, "SNAP", false],
    ["NVDA/USD", 65, 3, true, 0, 0, 0, "NVDA", false],
    ["V/USD", 66, 2, true, 0, 0, 0, "V", false],
    ["MA/USD", 67, 3, true, 0, 0, 0, "MA", false],
    ["PFE/USD", 68, 2, true, 0, 0, 0, "PFE", false],
    ["KO/USD", 69, 2, true, 0, 0, 0, "KO", false],
    ["DIS/USD", 70, 3, true, 0, 0, 0, "DISUSDT", false],
    ["GME/USD", 71, 4, true, 0, 0, 0, "GME", false],
    ["NKE/USD", 72, 3, true, 0, 0, 0, "NKE", false],
    ["AMD/USD", 73, 4, true, 0, 0, 0, "AMDUSDT", false],
    ["PYPL/USD", 74, 3, true, 0, 0, 0, "PYPL", false],
    ["ABNB/USD", 75, 4, true, 0, 0, 0, "ABNB", false],
    ["BA/USD", 76, 3, true, 0, 0, 0, "BA/USD", false],
    ["SBUX/USD", 77, 2, true, 0, 0, 0, "SBUX", false],
    ["WMT/USD", 78, 2, true, 0, 0, 0, "WMTUSDT", false],
    ["INTC/USD", 79, 3, true, 0, 0, 0, "INTC", false],
    ["MCD/USD", 80, 2, true, 0, 0, 0, "MCD", false],
    ["META/USD", 81, 3, true, 0, 0, 0, "METAUSDT", false],
    ["GOOGL_1/USD", 82, 2, false, 0, 0, 0, "GOOGL_1USDT", false],
    ["GME_1/USD", 83, 4, false, 0, 0, 0, "GME_1USDT", false],
    ["AMZN_1/USD", 84, 3, false, 0, 0, 0, "AMZN_1USDT", false],
    ["TSLA_1/USD", 85, 4, false, 0, 0, 0, "TSLA_1USDT", false],
    ["SPY/USD", 86, 5, true, 0, 0, 0, "SPY", false],
    ["QQQ/USD", 87, 5, true, 0, 0, 0, "QQQ", false],
    ["IWM/USD", 88, 5, true, 0, 0, 0, "IWM", false],
    ["DIA/USD", 89, 5, true, 0, 0, 0, "DIAUSDT", false],
    ["XAU/USD", 90, 6, true, 0, 0, 0, "XAUUSD", false],
    ["XAG/USD", 91, 7, true, 0, 0, 0, "XAGUSD", false],
    ["USD/CNH", 92, 9, true, 0, 0, 0, "USD/CNH", false],
    ["USD/SGD", 93, 9, true, 0, 0, 0, "USD/SGD", false],
    ["EUR/SEK", 94, 9, true, 0, 0, 0, "EUR/SEK", false],
    ["USD/KRW", 95, 9, true, 0, 0, 0, "USD/KRW", false],
    ["EUR/NOK", 96, 9, true, 0, 0, 0, "EUR/NOK", false],
    ["USD/INR", 97, 9, false, 0, 0, 0, "USD/INR", false],
    ["USD/MXN", 98, 9, true, 0, 0, 0, "USD/MXN", false],
    ["USD/TWD", 99, 9, false, 0, 0, 0, "USD/TWD", false],
    ["USD/ZAR", 100, 9, true, 0, 0, 0, "USDZAR", false],
    ["USD/BRL", 101, 9, false, 0, 0, 0, "USD/BRL", false],
    ["AVAX/USD", 102, 0, true, 0, 0, 0, "AVAXUSDT", false],
    ["ATOM/USD", 103, 0, true, 0, 0, 0, "ATOMUSDT", false],
    ["NEAR/USD", 104, 0, true, 0, 0, 0, "NEARUSDT", false],
    ["QNT/USD", 105, 0, true, 0, 0, 0, "QNTUSDT", false],
    ["IOTA/USD", 106, 0, false, 0, 0, 0, "IOTAUSDT", false],
    ["TON/USD", 107, 0, false, 0, 0, 0, "TONUSDT", false],
    ["RPL/USD", 108, 0, false, 0, 0, 0, "RPLUSDT", false],
    ["BAYC/ETH", 109, 10, false, 0, 0, 0, "BAYC/ETH", false],
    ["MAYC/ETH", 110, 10, false, 0, 0, 0, "MAYC/ETH", false],
    ["CPUNKS/ETH", 111, 10, false, 0, 0, 0, "CPUNKS/ETH", false],
  ].sort((a, b) => {
    if (a[8] === true && b[8] === false) {
      return -1;
    } else if (a[8] === false && b[8] === true) {
      return 1;
    } else {
      return a[0].localeCompare(b[0]);
    }
  });

  const [cleanPairs, setCleanPairs] = useState(pares.filter((pair) => pair[3]));
  const [pairs, setPairs] = useState(cleanPairs);

  // Gestión de los precios
  const pricesSocket = useRef(null);
  const [latestPrices, setLatestPrices] = useState([]);
  const [market24, setMarket24] = useState([]);

  const setupPricesWebsocket = async () => {
    //const pricesUrl = getUrl(PROD, "PRICES");
    const pricesUrl = filteredEndpointsInfo?.price_feeder_frontend;
    if (pricesSocket.current) {
      await waitForOpenConnection(pricesSocket.current);
    } else {
      pricesSocket.current = new WebSocket(pricesUrl);
    }

    pricesSocket.current.onopen = () => {
      IS_PROD || console.log(`WebSocket connection to ${pricesUrl} opened!`);
    };

    pricesSocket.current.onmessage = (event) => {
      const object = JSON.parse(event.data);
      setLatestPrices(object.prices);
      setMarket24(object.change24h);
    };

    pricesSocket.current.onclose = () => {
      IS_PROD || console.log(`Prices WebSocket connection to ${pricesUrl} closed. Retrying in 3 seconds...`);
      pricesSocket.current = null;
      // Reconnect the WebSocket after a delay
      setTimeout(setupPricesWebsocket, 3000);
    };
  };

  /* @alfaori: Replaced by useStats hooks at Dashboard and Stats pages
  const fetcher = () => {
    return fetch("https://d.dexynth.com/stats/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((resp) => resp.json());
  };

  const { data, error } = useSWR("https://d.dexynth.com/stats/", fetcher);
  */

  return (
    <AppContext.Provider
      value={{
        pares,
        pairs,
        setPairs,
        cleanPairs,
        setCleanPairs,
        setupPricesWebsocket,
        setLatestPrices,
        latestPrices,
        setMarket24,
        market24,
        //data,
        MODE,
        endpointsInfo,
        filteredEndpointsInfo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

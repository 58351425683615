import "./Vault.css";
import { t } from "@lingui/macro";
import React from "react";

import { USDG_DECIMALS } from "lib/legacy";

import { formatAmount } from "lib/numbers";
import { formatSecondsToDaysString } from "lib/dates";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";

export default function VaultUnlock(props) {
  const { vaultData } = props;
  const { lockedNfts } = vaultData;

  const getRemainingToUnlock = (atTimestampe, lockDuration) => {
    const now = Date.now() / 1000.0;
    const lockFinishTime = parseInt(atTimestampe) + parseInt(lockDuration);
    return lockFinishTime - now;
  };

  const canBeUnlocked = (atTimestampe, lockDuration) => {
    const now = Date.now() / 1000.0;
    const lockFinishTime = parseInt(atTimestampe) + parseInt(lockDuration);
    const enabled = getRemainingToUnlock(atTimestampe, lockDuration) < 0;
    return enabled;
  };
  const getUnlockButtonText = (atTimestampe, lockDuration) => {
    const rem = getRemainingToUnlock(atTimestampe, lockDuration);
    if (rem > 0) return formatSecondsToDaysString(rem);
    return t`UNLOCK`;
  };

  let i = 0;
  return (
    <React.Fragment>
      <div>
        <div className="App-card-title" style={{ marginTop: "20px" }}>
          Existing Locks ({lockedNfts.length})
        </div>

        <div className="App-card-divider"></div>
        {lockedNfts.map((info) => {
          i++;
          let [, shares, assetsDeposited, assetsDiscount, atTimestampe, lockDuration] = info;
          return (
            <div key={`NFT-${i}`} className="App-card-row">
              <div className="">
                <Tooltip
                  handle={`Shares: ${formatAmount(shares, USDG_DECIMALS, 2, true)}`}
                  className="nowrap"
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <>
                        <StatsTooltipRow
                          label="Assets Deposited"
                          showDollar={false}
                          value={`${formatAmount(assetsDeposited, USDG_DECIMALS, 2, true)}`}
                        />
                        <StatsTooltipRow
                          label="Assets Discount"
                          showDollar={false}
                          value={`${formatAmount(assetsDiscount, USDG_DECIMALS, 2, true)}`}
                        />
                        <div className="Tooltip-divider" />
                        <StatsTooltipRow
                          label="Total Shares"
                          showDollar={false}
                          value={`${formatAmount(shares, USDG_DECIMALS, 2, true)}`}
                        />
                        <div className="Tooltip-divider" />
                        <StatsTooltipRow
                          label="Time to Unlock"
                          showDollar={false}
                          value={`${getUnlockButtonText(atTimestampe, lockDuration)}`}
                        />
                      </>
                    );
                    return <div>{getUnlockButtonText(atTimestampe, lockDuration)}</div>;
                  }}
                />
              </div>
              <div className="muted align-right">
                <button onClick={() => {}} disabled={!canBeUnlocked(atTimestampe, lockDuration)}>
                  UNLOCK
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

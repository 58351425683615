import React, { useEffect, useMemo, useState } from "react";

import VaultDeposit from "components/Vault/VaultDeposit";
import VaultWithdraw from "components/Vault/VaultWithdraw";
import VaultUnlock from "components/Vault/VaultUnlock";

export default function VaultBox(props) {
  
  const { chainId, direction, setPendingTxns, vaultData, account } = props;
  const U_DEPOSIT = "U_DEPOSIT";
  return (
    <React.Fragment>
      {direction == "DEPOSIT" && <VaultDeposit chainId={chainId} vaultData={vaultData} ubication="DEPOSIT" />}

      {direction == "WITHDRAW" && (
        <VaultWithdraw chainId={chainId} setPendingTxns={setPendingTxns} vaultData={vaultData} account={account} />
      )}
      {direction == "UNLOCK" && <VaultUnlock vaultData={vaultData} />}
      {direction == U_DEPOSIT && <VaultDeposit chainId={chainId} vaultData={vaultData} ubication="U_DEPOSIT" />}
    </React.Fragment>
  );
}

import useSWR from "swr";

import { useWeb3React } from "@web3-react/core";

import { contractFetcher } from "lib/contracts";
import { getConstant } from "config/chains";
import { DEXY, Staking, PriceAggregator } from "config/contracts";

import { expandDecimals, formatAmount, formatKeyAmount } from "lib/numbers";

import { USDG_DECIMALS, DEXY_PRICE_DECIMALS } from "lib/legacy";

export function useStakingInfo({ contractInfo, chainId }) {
  const { active, library, account } = useWeb3React();

  const { data: dexyPrice } = useSWR(
    [`StakeV2:tokenPriceTWAP:${active}`, chainId, contractInfo[PriceAggregator].address, "tokenPriceTWAP"],
    {
      fetcher: contractFetcher(library, contractInfo[PriceAggregator].abiJson),
    }
  );

  //setStakingFarmAddress(GNSStakingV6_2Address);
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const { data: dexyBalance } = useSWR(
    [`StakeV2:dexyBalance:${active}`, chainId, contractInfo[DEXY].address, "balanceOf", account],
    {
      fetcher: contractFetcher(library, contractInfo[DEXY].abiJson),
    }
  );

  const { data: stakedDexySupply } = useSWR(
    [
      `StakeV2:stakedDexySupply:${active}`,
      chainId,
      contractInfo[DEXY].address,
      "balanceOf",
      contractInfo[Staking].address,
    ],
    {
      fetcher: contractFetcher(library, contractInfo[DEXY].abiJson),
    }
  );

  const { data: dexyTotalSupply } = useSWR(
    [`StakeV2:dexyTotalSupply:${active}`, chainId, contractInfo[DEXY].address, "totalSupply"],
    {
      fetcher: contractFetcher(library, contractInfo[DEXY].abiJson),
    }
  );

  const { data: userStakingInfo } = useSWR(
    [`StakeV2:userStakingInfo:${active}`, chainId, contractInfo[Staking].address, "users", account],
    {
      fetcher: contractFetcher(library, contractInfo[Staking].abiJson),
    }
  );
  /*
  const [userStakedTokens, userDebtDai, userStakedNftsCount, userTotalBoostTokens, userHarvestedRewardsDai] =
    getUserStakingData(userStakingInfo);*/

  const { data: userPendingRewards } = useSWR(
    [`StakeV2:userPendingRewards:${active}`, chainId, contractInfo[Staking].address, "pendingRewardDai"],
    {
      fetcher: contractFetcher(library, contractInfo[Staking].abiJson),
    }
  );

  function getUsdPrice(dexyAmmount) {
    if (dexyAmmount && !dexyAmmount.isZero() && dexyPrice) {
      return dexyAmmount.mul(dexyPrice).div(expandDecimals(1, USDG_DECIMALS));
    }
    return 0;
  }

  function getUserStakingData(userStakingInfo) {
    return userStakingInfo ? userStakingInfo : [undefined, undefined, undefined, undefined, undefined];
  }

  const [userStakedTokens, userDebtDai, userStakedNftsCount, userTotalBoostTokens, userHarvestedRewardsDai] =
    getUserStakingData(userStakingInfo);

  const dexyBalanceUsd = getUsdPrice(dexyBalance);
  const dexyTotalSupplyUsd = getUsdPrice(dexyTotalSupply);
  const userStakedTokensUsd = getUsdPrice(userStakedTokens);
  const stakedDexySupplyUsd = getUsdPrice(stakedDexySupply);

  const stakingData = {
    dexyPrice: dexyPrice,
    dexyPriceFmt: formatAmount(dexyPrice, DEXY_PRICE_DECIMALS, 2, true),
    dexyBalance: dexyBalance,
    dexyBalanceFmt: formatAmount(dexyBalance, USDG_DECIMALS, 2, true),
    dexyBalanceUsd: dexyBalanceUsd,
    dexyBalanceUsdFmt: formatAmount(dexyBalanceUsd, DEXY_PRICE_DECIMALS, 2, true),
    stakedDexySupply: stakedDexySupply,
    stakedDexySupplyFmt: formatAmount(stakedDexySupply, USDG_DECIMALS, 2, true),
    stakedDexySupplyUsd: stakedDexySupplyUsd,
    stakedDexySupplyUsdFmt: formatAmount(stakedDexySupplyUsd, DEXY_PRICE_DECIMALS, 2, true),
    dexyTotalSupply: dexyTotalSupply,
    dexyTotalSupplyFmt: formatAmount(dexyTotalSupply, USDG_DECIMALS, 2, true),
    dexyTotalSupplyUsd: dexyTotalSupplyUsd,
    dexyTotalSupplyUsdFmt: formatAmount(dexyTotalSupplyUsd, DEXY_PRICE_DECIMALS, 2, true),
    userStakingInfo: userStakingInfo,
    userStakedTokens: userStakedTokens,
    userStakedTokensFmt: formatAmount(userStakedTokens, USDG_DECIMALS, 2, true),
    userStakedTokensUsd: userStakedTokensUsd,
    userStakedTokensUsdFmt: formatAmount(userStakedTokensUsd, DEXY_PRICE_DECIMALS, 2, true),
    userPendingRewards: userPendingRewards,
    userPendingRewardsFmt: formatAmount(userPendingRewards, USDG_DECIMALS, 2, true),
    nativeTokenSymbol: nativeTokenSymbol,
    wrappedTokenSymbol: wrappedTokenSymbol,
  };
  return { stakingData };
}

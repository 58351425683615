import { format as formatDateFn } from "date-fns";

export const SECONDS_PER_HOUR = 60 * 60;
export const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;

export function formatDateTime(time: number) {
  try {
     return formatDateFn(time * 1000, "dd MMM yyyy, h:mm a");
  } catch (error) {
    return "..."
  }
}

export function epochSecondsToDateString(time: number) {
  const myDate = new Date(0);
  myDate.setUTCSeconds(time);
  return myDate.toLocaleString();
}

export function formatDate(time: number) {
  return formatDateFn(time * 1000, "dd MMM yyyy");
}

export function getTimeRemaining(time: number) {
  const now = parseInt(String(Date.now() / 1000));
  if (time < now) {
    return "0h 0m";
  }
  const diff = time - now;
  const hours = parseInt(String(diff / (60 * 60)));
  const minutes = parseInt(String((diff - hours * 60 * 60) / 60));
  return `${hours}h ${minutes}m`;
}

export function formatSecondsToDaysString(seconds: number) {
  let days = seconds / SECONDS_PER_DAY;
  let hours = (days % 1) * 24;
  let minutes = (hours % 1) * 60;
  let secs = (minutes % 1) * 60;
  [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)];

 //console.log(days + "d", hours + "h", minutes + "m", secs + "s");
  return `${days}d ${hours}h ${minutes}m ${secs}s`;
}

export function isValidTimestamp(timestamp: any) {
  return new Date(timestamp).getTime() > 0;
}

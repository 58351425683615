import { forwardRef } from "react";
import { t, Trans } from "@lingui/macro";

import OtcSwap from "components/Otc/OtcSwap";
import OtcHeader from "components/Otc/OtcHeader";

import Tab from "components/Tab/Tab";
import Footer from "components/Footer/Footer";

import "./Otc.css";

import { useLocalStorageByChainId } from "lib/localStorage";

import { useChainId } from "lib/chains";

export const Otc = forwardRef((props, ref) => {
  const { pendingTxns, setPendingTxns } = props;

  const { chainId } = useChainId();

  const BUY = "BUY";
  const SELL = "SELL";

  const LIST_SECTIONS = [BUY, SELL].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-section-OTC", LIST_SECTIONS[0]);
  const LIST_SECTIONS_LABELS = {
    [BUY]: t`BUY`,
    [SELL]: t`SELL`,
  };
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }

  const getListSection = () => {
    return (
      <div className="otc-contenedor">
        <Tab
          options={LIST_SECTIONS}
          optionLabels={LIST_SECTIONS_LABELS}
          option={listSection}
          onChange={(section) => setListSection(section)}
          type="inline"
          className="otc-tab"
        />
        <OtcSwap direction={listSection} chainId={chainId} pendingTxns={pendingTxns} setPendingTxns={setPendingTxns} />
      </div>
    );
  };

  return (
    <div className="otc-layout">
      <h2 className="otc-title">OTC BUY SELL</h2>
      <OtcHeader chainId={chainId} />
      <div className="">
        <div className="Otc-lists large">{getListSection()}</div>
      </div>
      <div className="Otc-lists small">{getListSection()}</div>
    </div>
  );
});

import React, { useEffect, useState } from "react";
import "./InfoTrade.css";
import cx from "classnames";

export const InfoTrade = ({
  pairName,
  type,
  collateral,
  leverage,
  openPrice,
  pairPrice,
  pnl,
  setIsVisible,
  isVisible,
  tp,
  sl,
  _liquidationPrice,
  slColor,
  position,
  tpColor,
  priceColor,
}) => {
  const [showMessage, setShowMessage] = useState(false);

  let color;

  if (type) {
    color = "#148A63";
  } else {
    color = "#E23E3E";
  }

  let liqSlText = "";

  if (position.sl !== 0) {
    liqSlText = `${sl} (SL)`;
  } else {
    liqSlText = `${_liquidationPrice} (Liq)`;
  }

  const copyTradeData = () => {
    const formattedData = `
    Pair Name: ${pairName}
    Operation Type: ${type ? "LONG" : "SHORT"}
    Collateral: ${parseFloat(collateral).toFixed(2)} $
    Leverage: ${leverage}
    Open Price: ${openPrice / 1e10}
    Pair Price: ${pairPrice}
    Take profit: ${tp}
    PNL: ${Math.max(pnl, -leverage).toFixed(2)}$ (${Math.max(((pnl / collateral) * 100).toFixed(2), -100)}%)
    Liq/Sl: ${liqSlText}
    `;

    navigator.clipboard
      .writeText(formattedData)
      .then(() => {
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      })
      .catch((error) => {
        console.error("Failed to copy trade data to clipboard:", error);
      });
  };

  const parts = pairName.split("/");
  const image = require(`../../img/tokenlogo/${parts[0] + parts[1]}.svg`).default;

  return (
    <div
      className="modal-overlay"
      onClick={() => {
        setIsVisible(false);
      }}
    >
      <div
        className="infoTrade"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          className="closeButton"
          onClick={() => {
            const openInfo = !isVisible;
            setIsVisible(openInfo);
          }}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="infoTitle">
          <div className="tradeTitle">
            <img width="30px" height="30px" src={image} />
            <p>
              {parts[0]}
              <span>/{parts[1]}</span>
            </p>
          </div>

          <div>
            <p style={{ color: color }}>{type ? "LONG" : "SHORT"}</p>
          </div>
        </div>

        <div className="infoText">
          <div className="infoItem">
            <p>Collateral</p>
            <p>{parseFloat(collateral).toFixed(2)} $</p>
          </div>
          <div className="infoItem">
            <p>Leverage</p>
            <p>{leverage} X</p>
          </div>
          <div className="infoItem">
            <p>Open Price</p>
            <p>{openPrice / 1e10} $</p>
          </div>
          <div className={`infoItem`}>
            <p>Pair Price</p>
            <p className={`${priceColor}`}>{pairPrice} $</p>
          </div>
          <div className="infoItem">
            <p>PNL</p>
            <p
              className={cx({
                positive: pnl > 0,
                negative: pnl < 0,
              })}
            >
              {Math.max(pnl, -collateral).toFixed(2)}$ ({Math.max(((pnl / collateral) * 100).toFixed(2), -100)}%)
            </p>
          </div>
          <div className="infoItem">
            <p>Liq/SL</p>
            <>{liqSlText}</>
          </div>
          <div className="infoItem">
            <p>Take Profit</p>
            <p className={`${tpColor}`}>{tp}</p>
          </div>
        </div>
        <button
          onClick={() => {
            copyTradeData();
          }}
        >
          Share Trade
        </button>
        {showMessage && (
          <div className="message">
            <p>Data copied to clipboard</p>
          </div>
        )}
      </div>
    </div>
  );
};

const mode = process.env.REACT_APP_MODE;
export const GLOBAL_CONFIG_HOSTING = process.env.REACT_APP_GLOBAL_CONFIG_HOSTING || "contabo";

export const DEV = "dev";
export const PROD = "prod";
export const STG = "stg";

export const MODE = mode?.toLowerCase() || DEV;
export const IS_DEV = MODE === DEV;
export const IS_PROD = MODE === PROD;
export const IS_STG = MODE === STG;

export const GA_MEASURE_ID = "G-VJHE170EH5";

import React, { useState, useCallback, useContext, useEffect, useRef } from "react";
import { t, Trans } from "@lingui/macro";
import cx from "classnames";
import {
  SWAP,
  INCREASE,
  DECREASE,
  USD_DECIMALS,
  getOrderError,
  getExchangeRateDisplay,
  getExchangeRate,
} from "lib/legacy.js";
// getPositionForOrder,
import { handleCancelOrder } from "domain/legacy";
import { getContract } from "config/contracts";

import Tooltip from "../Tooltip/Tooltip";
// import OrderEditor from "./OrderEditor";

import "./OrdersList.css";
import Checkbox from "../Checkbox/Checkbox";
// import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { formatAmount } from "lib/numbers";
import { helperToast } from "lib/helperToast";

import OrdersListHeader from "./OrdersListHeader";
import AppContext from "AppContext";

import { ethers } from "ethers";
import TRADINGSTORAGEABI from "../../abis/DexynthTradingStorageV5.json"; // AbiStorage
import GNSTRADINGV6 from "../../abis/DexynthTradingV6_4_1.json";
import { useWeb3React } from "@web3-react/core";

export default function OrdersList(props) {
  const {
    blocks,
    account,
    library,
    setPendingTxns,
    pendingTxns,
    infoTokens,
    positionsMap,
    orders,
    hideActions,
    chainId,
    cancelOrderIdList,
    setCancelOrderIdList,
    ourOrders,
    ordersLoading,
    tradingV6Contract,
    ourSelectedToken,
    filterBySelectedPair,
    setOurSelectedToken,
  } = props;

  const [editingOrder, setEditingOrder] = useState(null);

  const onCancelClick = useCallback(
    (order) => {
      handleCancelOrder(chainId, library, order, { pendingTxns, setPendingTxns });
    },
    [library, pendingTxns, setPendingTxns, chainId]
  );

  const onEditClick = useCallback(
    (order) => {
      setEditingOrder(order);
    },
    [setEditingOrder]
  );

  // const renderHead = useCallback(() => {
  //   const isAllOrdersSelected = cancelOrderIdList?.length > 0 && cancelOrderIdList?.length === orders.length;
  //   return (
  //     <tr className="Exchange-list-header">
  //       {ourOrders.length > 0 && (
  //         <th>
  //           <div className="checkbox-inline ">
  //             <Checkbox
  //               isChecked={isAllOrdersSelected}
  //               setIsChecked={() => {
  //                 if (isAllOrdersSelected) {
  //                   setCancelOrderIdList([]);
  //                 } else {
  //                   const allOrderIds = ourOrders.map((o) => `${o.type}-${o.index}`);
  //                   setCancelOrderIdList(allOrderIds);
  //                 }
  //               }}
  //             />
  //           </div>
  //         </th>
  //       )}

  //       <th>
  //         <div>
  //           <Trans>Type</Trans>
  //         </div>
  //       </th>
  //       <th>
  //         <div>
  //           <Trans>Order</Trans>
  //         </div>
  //       </th>
  //       <th>
  //         <div>
  //           <Trans>Price</Trans>
  //         </div>
  //       </th>
  //       <th>
  //         <div>
  //           <Trans>Mark Price</Trans>
  //         </div>
  //       </th>
  //     </tr>
  //   );
  // }, [cancelOrderIdList, ourOrders, setCancelOrderIdList]);

  const renderEmptyRow = useCallback(() => {
    if (ourOrders && ourOrders.length > 0) {
      return null;
    }

    return (
      <tr>
        <td colSpan="5">
          <Trans>No open orders</Trans>
        </td>
      </tr>
    );
  }, [ourOrders]);

  const renderActions = useCallback(
    (order) => {
      return (
        <>
          <td>
            <button className="Exchange-list-action" onClick={() => onEditClick(order)}>
              <Trans>Edit</Trans>
            </button>
          </td>
          <td>
            <button className="Exchange-list-action" onClick={() => onCancelClick(order)}>
              <Trans>Cancel</Trans>
            </button>
          </td>
        </>
      );
    },
    [onEditClick, onCancelClick]
  );

  const renderLargeList = useCallback(() => {
    if (!ourOrders || !ourOrders.length > 0) {
      return null;
    }

    return ourOrders.map((order) => {
      if (order.type === SWAP) {
        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromTokenInfo = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress);
        const toTokenInfo = getTokenInfo(
          infoTokens,
          order.path[order.path.length - 1],
          order.shouldUnwrap,
          nativeTokenAddress
        );

        const markExchangeRate = getExchangeRate(fromTokenInfo, toTokenInfo);
        const orderId = `${order.type}-${order.index}`;

        return (
          <tr className="Exchange-list-item" key={orderId}>
            <td>
              <div className="checkbox-inline ">
                <Checkbox
                  isChecked={cancelOrderIdList?.includes(orderId)}
                  setIsChecked={() => {
                    setCancelOrderIdList((prevState) => {
                      if (prevState.includes(orderId)) {
                        return prevState.filter((i) => i !== orderId);
                      } else {
                        return prevState.concat(orderId);
                      }
                    });
                  }}
                />
              </div>
            </td>
            <td className="Exchange-list-item-type">
              <Trans>Limit</Trans>
            </td>
            <td>
              <Trans>Swap</Trans>{" "}
              {formatAmount(
                order.amountIn,
                fromTokenInfo.decimals,
                fromTokenInfo.isStable || fromTokenInfo.isUsdg ? 2 : 4,
                true
              )}{" "}
              {fromTokenInfo.symbol} for{" "}
              {formatAmount(
                order.minOut,
                toTokenInfo.decimals,
                toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                true
              )}{" "}
              {toTokenInfo.symbol}
            </td>
            <td>
              <Tooltip
                handle={getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo)}
                renderContent={() => t`
                  You will receive at least ${formatAmount(
                    order.minOut,
                    toTokenInfo.decimals,
                    toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                    true
                  )} ${
                  toTokenInfo.symbol
                } if this order is executed. The execution price may vary depending on swap fees at the time the order is executed.
                `}
              />
            </td>
            <td>{getExchangeRateDisplay(markExchangeRate, fromTokenInfo, toTokenInfo, true)}</td>
            {!hideActions && renderActions(order)}
          </tr>
        );
      }

      console.log("OrderList largelist");
      console.log(order);
      // const indexToken = getTokenInfo(infoTokens, order.indexToken);

      // // Longs Increase: max price
      // // Longs Decrease: min price
      // // Short Increase: min price
      // // Short Decrease: max price
      // const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong);

      // const markPrice = maximisePrice ? indexToken.contractMaxPrice : indexToken.contractMinPrice;
      // const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
      // const indexTokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;

      // const error = getOrderError(account, order, positionsMap);
      // const orderId = `${order.type}-${order.index}`;
      // const orderText = (
      //   <>
      //     {order.type === INCREASE ? t`Increase` : t`Decrease`} {indexTokenSymbol} {order.isLong ? t`Long` : t`Short`}
      //     &nbsp;by ${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
      //     {error && <div className="Exchange-list-item-error">{error}</div>}
      //   </>
      // );

      return (
        <tr className="Exchange-list-item" key={`${order.isLong}-${order.type}-${order.index}`}>
          <td className="Exchange-list-item-type">
            <div>
              <Checkbox
              // isChecked={cancelOrderIdList?.includes(orderId)}
              // setIsChecked={() => {
              //   setCancelOrderIdList((prevState) => {
              //     if (prevState.includes(orderId)) {
              //       return prevState.filter((i) => i !== orderId);
              //     } else {
              //       return prevState.concat(orderId);
              //     }
              //   });
              // }}
              />
            </div>
          </td>
          <td className="Exchange-list-item-type">{order.type === INCREASE ? t`Limit` : t`Trigger`}</td>
          <td>
            {/* {order.type === DECREASE ? (
              orderText
            ) : (
              <Tooltip
                handle={orderText}
                position="right-bottom"
                renderContent={() => {
                  const collateralTokenInfo = getTokenInfo(infoTokens, order.purchaseToken);
                  const collateralUSD = getUsd(order.purchaseTokenAmount, order.purchaseToken, false, infoTokens);
                  return (
                    <StatsTooltipRow
                      label={t`Collateral`}
                      value={`${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (${formatAmount(
                        order.purchaseTokenAmount,
                        collateralTokenInfo.decimals,
                        4,
                        true
                      )}
                      ${collateralTokenInfo.baseSymbol || collateralTokenInfo.symbol})`}
                    />
                  );
                }}
              />
            )} */}
            orderText
          </td>
          <td>
            {/* {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 2, true)} */}triggerPricePrefix
          </td>
          <td>
            {/* <Tooltip
              handle={formatAmount(markPrice, USD_DECIMALS, 2, true)}
              position="right-bottom"
              renderContent={() => {
                return (
                  <Trans>
                    The price that orders can be executed at may differ slightly from the chart price, as market orders
                    update oracle prices, while limit/trigger orders do not.
                  </Trans>
                );
              }}
            /> */}
            <Trans>
              The price that orders can be executed at may differ slightly from the chart price, as market orders update
              oracle prices, while limit/trigger orders do not.
            </Trans>
          </td>
          {/* {!hideActions && renderActions(order)} */}
        </tr>
      );
    });
  }, [
    ourOrders,
    renderActions,
    infoTokens,
    positionsMap,
    hideActions,
    chainId,
    account,
    cancelOrderIdList,
    setCancelOrderIdList,
  ]);

  const renderSmallList = useCallback(() => {
    if (!ourOrders || !ourOrders.length > 0) {
      return null;
    }

    return ourOrders.map((order) => {
      const indexToken = getTokenInfo(infoTokens, order.indexToken);
      const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong);
      const markPrice = maximisePrice ? indexToken.contractMaxPrice : indexToken.contractMinPrice;
      const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
      const indexTokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;

      const collateralTokenInfo = getTokenInfo(infoTokens, order.purchaseToken);
      const collateralUSD = getUsd(order.purchaseTokenAmount, order.purchaseToken, true, infoTokens);

      const error = getOrderError(account, order, positionsMap);

      return (
        <div key={`${order.isLong}-${order.type}-${order.index}`} className="App-card">
          <div className="App-card-title-small">
            {order.type === INCREASE ? t`Increase` : t`Decrease`} {indexTokenSymbol} {order.isLong ? t`Long` : t`Short`}
            &nbsp;by ${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
            {error && <div className="Exchange-list-item-error">{error}</div>}
          </div>
          <div className="App-card-divider"></div>
          <div className="App-card-content">
            <div className="App-card-row">
              <div className="label">
                <Trans>Price</Trans>
              </div>
              <div>
                {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 2, true)}
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Mark Price</Trans>
              </div>
              <div>
                <Tooltip
                  handle={formatAmount(markPrice, USD_DECIMALS, 2, true)}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <Trans>
                        The price that the order can be executed at may differ slightly from the chart price as market
                        orders can change the price while limit / trigger orders cannot.
                      </Trans>
                    );
                  }}
                />
              </div>
            </div>
            {order.type === INCREASE && (
              <div className="App-card-row">
                <div className="label">
                  <Trans>Collateral</Trans>
                </div>
                <div>
                  ${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (
                  {formatAmount(order.purchaseTokenAmount, collateralTokenInfo.decimals, 4, true)}{" "}
                  {collateralTokenInfo.baseSymbol || collateralTokenInfo.symbol})
                </div>
              </div>
            )}
            {!hideActions && (
              <>
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  <button className="App-button-option App-card-option" onClick={() => onEditClick(order)}>
                    <Trans>Edit</Trans>
                  </button>
                  <button className="App-button-option App-card-option" onClick={() => onCancelClick(order)}>
                    <Trans>Cancel</Trans>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      );
    });
  }, [ourOrders, onEditClick, onCancelClick, infoTokens, positionsMap, hideActions, chainId, account]);

  const closePosition = (position) => {
    tradingV6Contract
      .cancelOpenLimitOrder(position.pairIndex, position.index)
      .then((v) => {
        //TODO: Usar un toast o algo para informar de que se ha ejecutado la orden correctamente
        helperToast.success("The order has been closed successfully");
      })
      .catch((response) => {
        helperToast.error("Error, search in the console for more info: errormsg cancelOpenLimitOrder:");
        console.log("errormsg cancelOpenLimitOrder: ");
        console.log(response);
      });
  };

  const checkEqualProperties = (position, objectTrade) => {
    const properties = ["tp", "pairIndex", "maxPrice", "minPrice", "sl"];

    return properties.every((property) => position[property] === objectTrade[property]);
  };

  const formatDecimals = (numberToFormat) => {
    var res = numberToFormat;
    if (numberToFormat.length >= 13) {
      //Dos decimales
      var intAux = numberToFormat.substring(0, numberToFormat.length - 10);
      var decimalsAux = numberToFormat.substring(numberToFormat.length - 10, numberToFormat.length - 8);
      res = intAux + "." + decimalsAux;
    } else if (numberToFormat.length < 13 && numberToFormat.length > 10) {
      //Tres decimales
      intAux = numberToFormat.substring(0, numberToFormat.length - 10);
      decimalsAux = numberToFormat.substring(numberToFormat.length - 10, numberToFormat.length - 7);
      res = intAux + "." + decimalsAux;
    } else if (numberToFormat.length === 10) {
      //Seis decimales
      decimalsAux = numberToFormat.substring(numberToFormat.length - 10, numberToFormat.length - 4);
      res = "0." + decimalsAux;
    } else if (numberToFormat.length === 9) {
      //Siete decimales
      decimalsAux = numberToFormat.substring(numberToFormat.length - 9, numberToFormat.length - 3);
      res = "0.0" + decimalsAux;
    } else if (numberToFormat.length === 8) {
      //Ocho decimales
      decimalsAux = numberToFormat.substring(numberToFormat.length - 8, numberToFormat.length - 2);
      res = "0.00" + decimalsAux;
    } else if (numberToFormat.length === 7) {
      //Nueve decimales
      decimalsAux = numberToFormat.substring(numberToFormat.length - 7, numberToFormat.length - 1);
      res = "0.000" + decimalsAux;
    } else if (numberToFormat.length < 7) {
      res = "0.";
      for (let i = 0; i < 10 - numberToFormat.length; i++) {
        res += "0";
      }
      res += numberToFormat;
    }
    return res;
  };

  const { pares, pairs, setPairs, cleanPairs, setCleanPairs, setupPricesWebsocket, latestPrices, market24 } =
    useContext(AppContext);

  return (
    <React.Fragment>
      <table className="Exchange-list Orders App-box large">
        <tbody>
          {/*renderHead()}
          {/*renderEmptyRow()*/}
          {/* {renderLargeList()} */}
        </tbody>
      </table>
      <div className="Exchange-list Orders">
        {ourOrders && (
          // <div className="Exchange-list small">

          <div className="Exchange-list" id="exchange-lists">
            <div>
              {Object.keys(ourOrders).length === 0 && ordersLoading === 1 && (
                <div
                  className="Exchange-empty-positions-list-note App-card"
                  style={{ background: "none", padding: "8px" }}
                >
                  <p style={{ marginTop: "30px" }}>...</p>
                </div>
              )}
              {Object.keys(ourOrders).length === 0 && ordersLoading === 0 && (
                <div
                  className="Exchange-empty-positions-list-note App-card"
                  style={{ background: "none", padding: "8px" }}
                >
                  <p>No open orders</p>
                </div>
              )}
              {Object.keys(ourOrders).length > 0 && <OrdersListHeader />}
              {Object.keys(ourOrders).length > 0 &&
                Object.keys(ourOrders).map((i) => {
                  if (!filterBySelectedPair || (filterBySelectedPair && ourSelectedToken.pairIndex == i))
                    return Object.keys(ourOrders[i]).map((j) => {
                      let position = ourOrders[i][j];

                      let tp = 1;
                      let sl = 1;
                      let openPrice = 1;
                      const pairPrice = latestPrices[position.pairIndex];

                      tp = formatDecimals(position.tp.toString());

                      sl = formatDecimals(position.sl.toString());

                      openPrice = formatDecimals(position.minPrice.toString());

                      let parts = position.pairName.split("/");
                      const image = require(`../../img/tokenlogo/${parts[0]}${parts[1]}.svg`).default;
                      const key = `${position.pairName}-${position.pairIndex}-${position.index}`;

                      return (
                        <div key={key}>
                          <div className="App-card-content">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: "13px",
                                marginTop: "10px",
                                textTransform: "uppercase",
                                fontFamily: "Lato",
                                color: "#808080"
                              }}
                              id="OrdersList"
                            >
                              <div style={{ textAlign: "center", width: "10%" }}>
                                <span
                                  style={{ textTransform: "uppercase" }}
                                  className={cx("Exchange-list-side", {
                                    positive: position.buy,
                                    negative: !position.buy,
                                  })}
                                >
                                  {position.buy ? t`Long` : t`Short`}
                                </span>
                              </div>

                              <div
                                style={{
                                  textAlign: "center",
                                  width: "10%",
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div style={{ width: "15%" }}>
                                  <img src={image} width="100%" />
                                </div>

                                <span
                                  style={{ width: "85%" }}
                                  onClick={() => {
                                    let pairName = position.pairName;
                                    let par = cleanPairs.find((p) => p[0] == pairName);
                                    let ourPar = {
                                      symbol: par[0].split("/")[0],
                                      pairName: par[7],
                                      pairIndex: par[1],
                                      pairSymbols: par[0],
                                    };
                                    setOurSelectedToken(ourPar);
                                  }}
                                >
                                  {position.pairName}
                                </span>
                              </div>

                              <div style={{ textAlign: "center", width: "10%" }} className="hide">
                                <span>{position.leverage * position.positionSize}$</span>
                              </div>

                              <div style={{ textAlign: "center", width: "10%" }} className="hide">
                                <span>{position.positionSize}$</span>
                              </div>

                              <div style={{ textAlign: "center", width: "10%" }} className="hide">
                                <span>{position.leverage}x</span>
                              </div>

                              <div style={{ textAlign: "center", width: "10%" }} className="hide">
                                <span>{pairPrice}</span>
                              </div>

                              <div style={{ textAlign: "center", width: "10%" }} className="hide">
                                <span>{openPrice}</span>
                              </div>

                              <div style={{ textAlign: "center", width: "10%" }}>
                                <span>{sl}</span>
                              </div>

                              <div style={{ textAlign: "center", width: "10%" }}>
                                <span>{tp}</span>
                              </div>
                              <div style={{ textAlign: "center", width: "10%" }}>
                                {!position.isClosed && (
                                  <div className="App-card-buttons">
                                    <button
                                      disabled={position.positionSize === 0}
                                      onClick={() => {
                                        closePosition(position);
                                      }}
                                      style={{ border: "none", background: "none", color: "#808080", fontSize: "18px" }}
                                    >
                                      <i className="fa-solid fa-xmark closeTradeButton"></i>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    });
                })}
            </div>
          </div>
        )}
      </div>
      {/* {editingOrder && (
        <OrderEditor
          account={account}
          order={editingOrder}
          setEditingOrder={setEditingOrder}
          infoTokens={infoTokens}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          getPositionForOrder={getPositionForOrder}
          positionsMap={positionsMap}
          library={library}
          totalTokenWeights={totalTokenWeights}
          usdgSupply={usdgSupply}
          savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
        />
      )} */}
    </React.Fragment>
  );
}

import "./Vault.css";

import { t } from "@lingui/macro";

import { useLocalStorageByChainId } from "lib/localStorage";

import Tab from "components/Tab/Tab";
import VaultSwapBox from "components/Vault/VaultSwapBox";

const DEPOSIT = "DEPOSIT";
const U_DEPOSIT = "U_DEPOSIT";

export default function VaultDeposit(props) {
  const { chainId, title, dBUSDPrice, tokenInfo, epochData, vaultData, ubication } = props;

  const LIST_SECTIONS = [DEPOSIT, U_DEPOSIT].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-section-VAULT-DEPOSIT", LIST_SECTIONS[0]);
  const LIST_SECTIONS_LABELS = {
    [DEPOSIT]: t`Deposit`,
    [U_DEPOSIT]: t`Deposit With Discount & Lock`,
  };

  if (ubication) {
    listSection = ubication;
  }
  const getListSection = () => {
    return (
      <div className="vault-container" style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
        <div className="lists-text" style={{textAlign: "left", padding: "45px"}}>
          Deposit USDT in exchange for dUSDT, a Bep-20 representing your ownership in the vault. <br /><br />

          Stakers receive fees from each trade placed on tha platform in exchange for serving as the counterparty to all trades. <br /><br />

          dUSDT accumulates these fees in real time. <br /><br />

          <span className="red-text-color" style={{ color: "#E23E3E !important" }}>WARNING</span> <br />
          Inmediat withraw is not allowed.<br />
          See withdraw panel
        </div>
        <VaultSwapBox depositKind={listSection} chainId={chainId} vaultData={vaultData} />
      </div>
    );
  };

  return (
    <div>
      <div className="Vault-lists">{getListSection()}</div>
    </div>
  );
}

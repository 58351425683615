import { useWeb3React } from "@web3-react/core";
import AddressDropdown from "../AddressDropdown/AddressDropdown";
import ConnectWalletButton from "../Common/ConnectWalletButton";
import React, { useCallback, useEffect, useState, useContext } from "react";
import { HeaderLink } from "./HeaderLink";
import connectWalletImg from "img/ic_wallet_24.svg";

import AppContext from "AppContext";

import "./Header.css";
import { isHomeSite, getAccountUrl } from "lib/legacy";
import { isDevelopment } from "lib/legacy";
import cx from "classnames";
import { Trans } from "@lingui/macro";
import NetworkDropdown from "../NetworkDropdown/NetworkDropdown";
import LanguagePopupHome from "../NetworkDropdown/LanguagePopupHome";
import { getAppBaseUrlGen } from "lib/legacy";
import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  TESTNET,
  SHARDEUM_TESTNET,
  getChainName,
  NETWORK_OPTIONS,
  ZKSYNC_TESTNET,
  OPBNB_TESTNET,
  ZETACHAIN_TESTNET,
  TEN_TESTNET,
  SHIMMER_MAINNET,
  IOTA_TESTNET,
  BERACHAIN_TESTNET,
} from "config/chains";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";

import ExternalLink from "components/ExternalLink/ExternalLink";
import TwitterIcon from "img/TwitterIcon.svg";
import TelegramIcon from "img/TelegramIcon.svg";
// import MediumIcon from "img/MediumIcon.svg";
import DiscordIcon from "img/DiscordIcon.svg";
import useSWR from "swr";
import { Link } from "react-router-dom";
import { IS_PROD } from "config/context";

type Props = {
  openSettings: () => void;
  small?: boolean;
  setWalletModalVisible: (visible: boolean) => void;
  disconnectAccountAndCloseSettings: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

export function AppHeaderUser({
  openSettings,
  small,
  setWalletModalVisible,
  disconnectAccountAndCloseSettings,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  /*
  const networkOptions = [
    {
      label: getChainName(ARBITRUM),
      value: ARBITRUM,
      icon: "ic_arbitrum_24.svg",
      color: "#264f79",
    },
    {
      label: getChainName(AVALANCHE),
      value: AVALANCHE,
      icon: "ic_avalanche_24.svg",
      color: "#E841424D",
    },
  ];
  if (isDevelopment()) {
    networkOptions.push({
      label: getChainName(ARBITRUM_TESTNET),
      value: ARBITRUM_TESTNET,
      icon: "ic_arbitrum_24.svg",
      color: "#264f79",
    });
  }*/

  const { MODE, filteredEndpointsInfo } = useContext(AppContext);
  const { chainId } = useChainId();
  const { active, account } = useWeb3React();
  const showConnectionOptions = !isHomeSite();

  const networkOptions = NETWORK_OPTIONS;
  const [buttonText, setButtonText] = useState("");
  const [buttonText2, setButtonText2] = useState("");
  const [loyaltyPoints, setLoyaltyPoint] = useState(0);
  const DATA_API_URL = filteredEndpointsInfo?.data_api;
  const LOYALTY_POINTS_URL = `${DATA_API_URL}/user_loyalty_points`;

  const fetcher = (account) =>
    fetch(LOYALTY_POINTS_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address: account,
      }) as BodyInit,
    })
      .then((resp) => resp.json())
      .catch((err) => {
        console.log(err);
      });

  const { data } = useSWR(account ? [LOYALTY_POINTS_URL, account] : null, {
    fetcher: () => fetcher(account),
    revalidateOnFocus: true,
    refreshInterval: 30000,
  });

  useEffect(() => {
    setLoyaltyPoint(data?.loyalty_points);
  }, [data, account]);

  useEffect(() => {
    if (active) {
      setWalletModalVisible(false);
    }
  }, [active, setWalletModalVisible]);

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId) {
        return;
      }
      //console.log("Franky", option.value, active);
      return switchNetwork(option.value, active);
    },
    [chainId, active]
  );

  const baseUrl = getAppBaseUrlGen();

  const [display, setDisplay] = useState("");
  const [display2, setDisplay2] = useState("");

  useEffect(() => {
    if (window.location.href === `${baseUrl}/`) {
      setDisplay("none");
    } else {
      setDisplay("flex");
    }

    if (window.location.href === `${baseUrl}/trading`) {
      setDisplay2("flex");
    } else {
      setDisplay2("none");
    }

    if (!IS_PROD) {
      setDisplay("flex");
      setDisplay2("flex");
    }

    //console.log(window.location.href);
  }, [window.location.href]);

  const selectorLabel = getChainName(chainId);

  if (!active) {
    return (
      <div className="App-header-user">
        {/* <div className={cx("App-header-trade-link", { "homepage-header": isHomeSite() })}>
          <HeaderLink
            className="default-btn"
            to="/trade"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Trade</Trans>
          </HeaderLink>
        </div> */}

        {showConnectionOptions ? (
          <div style={{ display: display }}>
            <ConnectWalletButton onClick={() => setWalletModalVisible(true)} imgSrc={connectWalletImg}>
              {small ? <Trans>Connect</Trans> : <Trans>Connect Wallet</Trans>}
            </ConnectWalletButton>
            <NetworkDropdown
              small={small}
              networkOptions={networkOptions}
              selectorLabel={selectorLabel}
              onNetworkSelect={onNetworkSelect}
              openSettings={openSettings}
            />
          </div>
        ) : (
          <LanguagePopupHome />
        )}
      </div>
    );
  }

  const accountUrl = getAccountUrl(chainId, account);

  return (
    <div className="App-header-user" style={{ display: display, width: "auto" }}>
      <Link
        className="btn-normal btn-onhover btn-loyalty"
        style={{ color: "white", padding: "8px", minWidth: "100% !important" }}
        to="/loyalty-program"
      >
        👑 <span className="responsive-hide">Loyalty points:</span>{" "}
        {loyaltyPoints && Intl.NumberFormat("es-CL").format(loyaltyPoints)}
      </Link>

      {/* {buttonText !== "" && buttonText !== undefined && buttonText !== null && (
        <button
          style={{ display: display2 }}
          className="header-btn"
          onClick={() => {
            if (chainId === 300) {
              window.open(" https://www.alchemy.com/faucets/ethereum-sepolia", "_blank");
            } else if (chainId === 8082) {
              window.open("https://faucet-dapps.shardeum.org", "_blank");
            } else if (chainId === 5611) {
              window.open("https://testnet.bnbchain.org/faucet-smart", "_blank");
            } else if (chainId === 7001) {
              window.open("https://labs.zetachain.com/get-zeta", "_blank");
            } else if (chainId === 443) {
              window.open("https://testnet.ten.xyz", "_blank");
            } else if (chainId === 1073) {
              window.open("https://evm-toolkit.evm.testnet.shimmer.network/", "_blank");
            } else if (chainId === 80084) {
              window.open("https://artio.faucet.berachain.com/", "_blank");
            }
          }}
        >
          {buttonText}
        </button>
      )}

      {buttonText2 !== "" &&
        buttonText2 !== undefined &&
        buttonText2 !== null &&
        !(chainId === 8082 || chainId === 7001 || chainId === 443 || chainId === 1073) && (
          <button
            style={{ display: display2 }}
            className="header-btn"
            onClick={() => {
              if (chainId === 300) {
                window.open("https://goerli.bridge.zksync.io/", "_blank");
              } else if (chainId === 5611) {
                window.open("https://opbnb-testnet-bridge.bnbchain.org/", "_blank");
              } else if (chainId === 80084) {
                window.open("https://faucet.quicknode.com/berachain/artio", "_blank");
              }
            }}
          >
            {buttonText2}
          </button>
        )} */}

      {/*
      <div className="App-header-trade-link">
        <Trans>
          <ExternalLink href="https://twitter.com/DEXYNTH">
            <img src={TwitterIcon} alt="Twitter Icon" />
          </ExternalLink>
        </Trans>
      </div>
      <div className="App-header-trade-link">
        <Trans>
          <ExternalLink href="https://t.me/Dexynth">
            <img src={TelegramIcon} alt="Telegram Icon" />
          </ExternalLink>
        </Trans>
      </div>
      {/* <div className="App-header-trade-link">
        <Trans>
          <ExternalLink href="https://metamask.io"><img src={MediumIcon} alt="Medium Icon" /></ExternalLink>
        </Trans>
      </div>
      <div className="App-header-trade-link">
        <Trans>
          <ExternalLink href="https://discord.gg/UnqsFgdk9N">
            <img src={DiscordIcon} alt="Discord Icon" />
          </ExternalLink>
        </Trans>
      </div>
      <div className="App-header-trade-link">
        <HeaderLink
          className="default-btn"
          to="/trade"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Trade</Trans>
        </HeaderLink>
      </div> */}

      {showConnectionOptions ? (
        <>
          <div className="btn-normal btn-onhover" style={{ display: display, padding: "0px" }}>
            <AddressDropdown
              account={account}
              accountUrl={accountUrl}
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
            />
          </div>
          <NetworkDropdown
            small={small}
            networkOptions={networkOptions}
            selectorLabel={selectorLabel}
            onNetworkSelect={onNetworkSelect}
            openSettings={openSettings}
            chainId={chainId}
          />
        </>
      ) : (
        <LanguagePopupHome />
      )}
    </div>
  );
}

import React, { ReactNode, useEffect, useState, useRef } from "react";
import cx from "classnames";

import { useWeb3React } from "@web3-react/core";

import { AppHeaderUser } from "./AppHeaderUser";
import { AppHeaderLinks } from "./AppHeaderLinks";

import logoImg from "img/logo_GMX.svg";
import logoSmallImg from "img/logo_GMX_small.svg";
import { RiCreativeCommonsZeroLine, RiMenuLine } from "react-icons/ri";
import { FaTimes } from "react-icons/fa";
import { AnimatePresence as FramerAnimatePresence, motion } from "framer-motion";

import DEXYNTHIcon from "img/new-dexynth-logo.png";
import DEXYNTHLogo from "img/new-dexynth-logo.png";
import DEXYNTHpng from "img/dexynth-logo-green.png";

import { useChainId } from "lib/chains";
import { TESTNET } from "config/chains";

import { ethers } from "ethers";
// import { allContracts, getDexynthContractAddresses } from "lib/contracts/dexynthContracts";

import { setContract, getContract, WS_TO_FRONT_NAME_MAP } from "config/contracts";

import "./Header.css";
import { Link } from "react-router-dom";
import useSWR from "swr";

const MIN_IN_MSECS = 60 * 1000;

// Fix framer-motion old React FC type (solved in react 18)
const AnimatePresence = (props: React.ComponentProps<typeof FramerAnimatePresence> & { children: ReactNode }) => (
  <FramerAnimatePresence {...props} />
);

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const slideVariants = {
  hidden: { x: "-100%" },
  visible: { x: 0 },
};

type Props = {
  disconnectAccountAndCloseSettings: () => void;
  openSettings: () => void;
  setWalletModalVisible: (visible: boolean) => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
  setContractsLoaded: (loaded: boolean) => void;
  setCurrentContract: (contract: string) => void;
};

export function Header({
  disconnectAccountAndCloseSettings,
  openSettings,
  setWalletModalVisible,
  redirectPopupTimestamp,
  showRedirectModal,
  setContractsLoaded,
  setCurrentContract,
}: Props) {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isNativeSelectorModalVisible, setIsNativeSelectorModalVisible] = useState(false);
  const [isContractsLoading, setIsContractsLoading] = useState(false);
  const { chainId } = useChainId();
  const { active, account, library } = useWeb3React();

  useEffect(() => {
    if (isDrawerVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isDrawerVisible]);

  return (
    <>
      {isDrawerVisible && (
        <AnimatePresence>
          {isDrawerVisible && (
            <motion.div
              className="App-header-backdrop"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={fadeVariants}
              transition={{ duration: 0.2 }}
              onClick={() => setIsDrawerVisible(!isDrawerVisible)}
            ></motion.div>
          )}
        </AnimatePresence>
      )}
      {isNativeSelectorModalVisible && (
        <AnimatePresence>
          {isNativeSelectorModalVisible && (
            <motion.div
              className="selector-backdrop"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={fadeVariants}
              transition={{ duration: 0.2 }}
              onClick={() => setIsNativeSelectorModalVisible(!isNativeSelectorModalVisible)}
            ></motion.div>
          )}
        </AnimatePresence>
      )}
      <header id="header">
        <div className="App-header large app-header-large">
          <div className="App-header-container-left" style={{ display: "flex" }}>
            <Link to="/">
              <img src={DEXYNTHLogo} alt="DEXYNTH Logo" width={150} />
            </Link>
            <AppHeaderLinks redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal} />
          </div>
          <div className="App-header-container-right">
            <AppHeaderUser
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
              openSettings={openSettings}
              setWalletModalVisible={setWalletModalVisible}
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
            />
          </div>
        </div>
        <div className={cx("App-header", "small", { active: isDrawerVisible })}>
          <div
            className={cx("App-header-link-container", "App-header-top", {
              active: isDrawerVisible,
            })}
          >
            <div className="App-header-container-left">
              <div className="App-header-menu-icon-block" onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
                {!isDrawerVisible && <RiMenuLine className="App-header-menu-icon" />}
                {isDrawerVisible && <FaTimes className="App-header-menu-icon" />}
              </div>
              <div className="App-header-link-main clickable">
                <img src={DEXYNTHpng} alt="GMX Logo" className="movil-logo" />
              </div>
            </div>
            <div className="App-header-container-right">
              <AppHeaderUser
                disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
                openSettings={openSettings}
                small
                setWalletModalVisible={setWalletModalVisible}
                redirectPopupTimestamp={redirectPopupTimestamp}
                showRedirectModal={showRedirectModal}
              />
            </div>
          </div>
        </div>
      </header>
      <AnimatePresence>
        {isDrawerVisible && (
          <motion.div
            className="App-header-links-container App-header-drawer"
            style={{ textAlign: "right", backgroundColor: "rgb(18, 18, 18, 0.8)", cursor: "pointer" }}
          >
            <p style={{ fontSize: "30px", marginRight: "10px" }} onClick={() => setIsDrawerVisible(false)}>
              &times;
            </p>
            <AppHeaderLinks
              small
              openSettings={openSettings}
              clickCloseIcon={() => setIsDrawerVisible(false)}
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

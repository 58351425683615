import useSWR from "swr";
import { useWeb3React } from "@web3-react/core";

export function useBlockBatchNumber() {
  const { library, chainId } = useWeb3React();

  const fetchBlockNumberByLib = async (...args) => {
    const [id, chainId] = args;
    if (library) {
      const blockNumber = await library.getBlockNumber();
      return blockNumber;
    }
  };

  const { data: blockBatchNumber, error } = useSWR(
    true && [`useBlockBatchNumber:${chainId}`, chainId],
    fetchBlockNumberByLib
  );

  return { blockNumber: blockBatchNumber };
  //return { fetchBlockNumber };
}

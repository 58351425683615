import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";

import "./Vault.css";

export default function VaultAccountInfo(props) {
  const { vaultData } = props;
  const { active, account } = useWeb3React();
  const { dBUSDUserBalanceFmt, userTotalValueFmt, lockedAmountFmt } = vaultData;

  return (
    <div className="account-overview">
      <div className="overview-title">
        <p>Account Overview</p>
        <div className="">
          <div className="">
            <p style={{ fontSize: "18px"}}>{`${account.substring(0, 4)}...${account.substring(account.length - 4)}`}</p>
          </div>
        </div>
      </div>

      <div className="overview-info">
        <div>
          <div>
            <p style={{ marginBottom: "0px" }}>Available dUSDT</p>
          </div>
          <div className="green">{dBUSDUserBalanceFmt}</div>
        </div>
        <div className="">
          <div className="">
            <Trans>Locked dUSDT</Trans>
          </div>
          <div className="green">{lockedAmountFmt}</div>
        </div>
        <div className="">
          <div className="">
            <Trans>Total Value USDT</Trans>
          </div>
          <div className="green">
            {userTotalValueFmt}
          </div>
        </div>
      </div>
    </div>
  );
}

import { ethers } from "ethers";
import React, { useEffect, useState, useContext } from "react";
import useSWR from "swr";

import AppContext from "AppContext";

export const LeaderboardLoyalty = () => {  
  const { MODE, filteredEndpointsInfo } = useContext(AppContext);
  const [dataPoints, setDataPoints] = useState();
  const [indexText, setIndexText] = useState();
  const { AddressZero } = ethers.constants;
  const DATA_API_URL = filteredEndpointsInfo.data_api;
  const LEADERBOARD_URL = `${DATA_API_URL}/loyalty_points/leaderboard`;

  const fetcher = () =>
    
    fetch(LEADERBOARD_URL, {
      method: "POST",
      body: {
        "Content-Type": "application/json",
      },
    }).then((resp) => resp.json());

  const { data, error } = useSWR(LEADERBOARD_URL, fetcher);

  useEffect(() => {
    setDataPoints(data?.loyalty_points_leaderboard);
    console.log(dataPoints);
  }, [data]);

  const getIndexText = (index) => {
    if (index + 1 === 1) {
      return "🏆";
    } else if (index + 1 === 2) {
      return "🥈";
    } else if (index + 1 == 3) {
      return "🥉";
    } else {
      return index + 1;
    }
  };

  const getEllipseAddress = (address) => {
    if (!address) return "";
    if (address === AddressZero) return "NONE";

    return address.slice(0, 6) + "..." + address.slice(-6);
  };

  return (
    <div>
      <div className="TradingTitle">
        <h1 className="leaderboard-title">🏆 LOYALTY POINTS LEADERBOARD 🏆</h1>
      </div>
      <div className="TradingCompetition">
        <div className="TradingCompetitionHeader">
          <div className="competition-info" style={{ width: "33.3%" }}>
            Rank
          </div>
          <div className="competition-info" style={{ width: "33.3%" }}>
            Address
          </div>
          <div className="competition-info" style={{ width: "33.3%" }}>
            Loyalty Points
          </div>
        </div>
      </div>

      {Array.isArray(dataPoints)
        ? dataPoints.map((tradingCompetitor, index) => {
            return (
              <div className="TradingCompetition">
                <div className="TradingCompetitionHeader">
                  <div className="competition-info" style={{ width: "33.3%" }}>
                    {getIndexText(index)}
                  </div>
                  <div className="competition-info" style={{ width: "33.3%" }}>
                    {getEllipseAddress(tradingCompetitor[0])}
                  </div>
                  <div className="competition-info" style={{ width: "33.3%" }}>
                    {Intl.NumberFormat("es-CL").format(parseFloat(tradingCompetitor[1]))}
                  </div>
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
};

import { constants } from "buffer";
import { getStuckTimeout, getBotTimeout } from "config/chains";

export const POS_STATUS_STUCK = "stuck";
export const POS_STATUS_ORPHAN_CLOSING_STUCK = "orphan_closing_stuck";
export const POS_STATUS_NORMAL = "normal";
export const POS_STATUS_CLOSING = "closing";
export const POS_STATUS_TRYING_TO_CLOSE = "trying_to_close";
export const POS_STATUS_TRYING_TO_CLOSE_BY_BOT = "trying_to_close_by_bot";
export const POS_STATUS_TRYING_TO_OPEN_BY_BOT = "trying_to_open_by_bot";
export const POS_STATUS_SURE_STUCK = "sure_stuck";

export function isPositionStillInProcessingTimeframe(position, chainId, batchData) {
  const batchTimeout = getStuckTimeout(chainId);
  const currentTime = Date.now();
  if (!position?.orderId) return false;
  /*
  console.log(
    "%%%",
    position.orderId,
    "positionInTimeframe",
    position.block,
    batchTimeout,
    position.block + batchTimeout,
    batchData,
    position.block + batchTimeout > batchData
  );*/
  if (position.status === POS_STATUS_ORPHAN_CLOSING_STUCK) {
    if (position.timestamp && position.timestamp + 70 * 1000 > currentTime) return true;
    return false;
  }
  if (position.block + batchTimeout > batchData) return true;
  //if (chainId === ZKSYNC_TESTNET && position.timestamp && position.timestamp + 70 * 1000 > currentTime) return true;
  return false;
}

export function shouldBeConsideredAsStuck(orderStatus) {
  if (orderStatus == "MarketExecuted" || orderStatus == "LimitExecutedEvent" || orderStatus == "LimitExecuted")
    return false;
  return true;
}

export function getCorrectedRealStatus(position, chainId, batchData) {
  const currentTime = Date.now();
  let status = position?.status;
  const botTimeoutInMillsecs = getBotTimeout(chainId) * 1000;

  if (
    status == POS_STATUS_TRYING_TO_CLOSE_BY_BOT &&
    position.timestamp &&
    position.timestamp + botTimeoutInMillsecs < currentTime
  )
    return POS_STATUS_NORMAL;
  if (
    status == POS_STATUS_TRYING_TO_OPEN_BY_BOT &&
    position.timestamp &&
    position.timestamp + botTimeoutInMillsecs < currentTime
  )
    return POS_STATUS_NORMAL;
  if (status !== POS_STATUS_STUCK) return status;
  if (isPositionStillInProcessingTimeframe(position, chainId, batchData)) return POS_STATUS_CLOSING;
  if (status === "undefined" || status === undefined) return POS_STATUS_NORMAL;
  return POS_STATUS_STUCK;
}

export function getStatusText(position, chainId, batchData, status) {
  //let status = position.status;

  if (status == POS_STATUS_NORMAL) return "OPENED";
  if (status == POS_STATUS_TRYING_TO_CLOSE_BY_BOT) return "BOT CLOSING";
  if (status == POS_STATUS_TRYING_TO_OPEN_BY_BOT) return "BOT OPENING";
  if (isPositionStillInProcessingTimeframe(position, chainId, batchData)) return "CLOSING";
  if (status == POS_STATUS_STUCK) return "STUCK";
  if (status == POS_STATUS_TRYING_TO_CLOSE) return "CLOSING";
  if (status == undefined) return "OPENED";
}

export function getExtendedStatusText(position, liqPrice, price, status, statusText) {
  //console.log("getExtendedStatusText", position, liqPrice, price, status, statusText);
  if (status !== POS_STATUS_TRYING_TO_CLOSE_BY_BOT) return statusText;

  let tp = position?.tp ? Number(position.tp) / 1e10 : 0;
  let sl = position?.sl ? Number(position.sl) / 1e10 : 0;
  const _price = Number(price);

  const slLiq = sl != 0 ? sl : liqPrice;
  const isLong = position?.buy;
  if (isLong) {
    if (_price >= tp) {
      console.log("getExtendedStatusText.CLOSING TP.LONG", price, tp);
      return "CLOSING TP";
    } else if (_price <= slLiq) {
      console.log("getExtendedStatusText.CLOSING SL.LONG", _price, slLiq);
      return sl != 0 ? "CLOSING SL" : "CLOSING LIQ";
    }
  } else {
    if (_price <= tp) {
      console.log("getExtendedStatusText.CLOSING TP.SHORT", _price, tp);
      return "CLOSING TP";
    } else if (_price >= slLiq) {
      console.log("getExtendedStatusText.CLOSING SL.SHORT", _price, slLiq);
      return sl != 0 ? "CLOSING SL" : "CLOSING LIQ";
    }
  }
  return statusText;
}

import useSWR from "swr";
import { useEffect, useState, useRef, useContext } from "react";
import { ethers } from "ethers";

import AppContext from "AppContext";

import { REFERRER_ADDRESS, REFERRAL_CODE_KEY } from "config/localStorage";

import { useContracts } from "hooks/useContracts";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";

import { IS_PROD } from "config/context";
import { Referrals } from "config/contracts";
import { set } from "date-fns";

const mockReferralData = {
  points: {
    trading_points: 88638,
    referral_points: 16813,
    total_loyalty_points: 105451,
  },
  referrer: "0x0000000000000000000000000000000000000000",
  referral_code: "",
  referred_addresses: [
    ["0xb305c4f840F1310FE5BB7F7Db28370b782e77564", 1, 21],
    ["0xb305c4f840F1310FE5BB7F7Db28370b782e77566", 2, 23],
    ["0xb305c4f840F1310FE5BB7F7Db28370b782e77567", 2, 26],
    ["0xb305c4f840F1310FE5BB7F7Db28370b782e77568", 2, 236],
    ["0xb305c4f840F1310FE5BB7F7Db28370b782e77569", 3, 267],
    ["0xb305c4f840F1310FE5BB7F7Db28370b782e77510", 3, 266],
    ["0xb305c4f840F1310FE5BB7F7Db28370b782e77511", 3, 246],
  ],
};

const mockAccount = "0xbF13755c11F362E59B3df8BCadA06CAf1C2cdB99";

const mockAddress = { address: "0x1770B4dF4E55908Db2d2325eF52f793a8d3b8cD3" };
const mockReferralCode = { code: "TOSHARE" };

export function useLoyaltyReferrals({ account, periodic = false, refreshInterval = 10000 }) {
  const { MODE, filteredEndpointsInfo } = useContext(AppContext);
  const { AddressZero } = ethers.constants;
  const [referralData, setReferralData] = useState({});
  const [fetching, setFetching] = useState(false);
  const { active } = useWeb3React();
  const { chainId } = useChainId();
  const { contractInfo } = useContracts({ chainId });
  const DATA_API_URL = filteredEndpointsInfo.data_api;

  const mountedRef = useRef(true);
  useEffect(() => {
    // When component mounts
    mountedRef.current = true;

    return () => {
      // Cleanup when component unmounts
      mountedRef.current = false;
    };
  }, []);

  const fetchReferralData = async () => {
    let headers = { "Content-Type": "application/json" };
    let body = { address: account };
    const url = `${DATA_API_URL}/user_referral_points`;
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        if (mountedRef.current) {
          !IS_PROD && console.log("data", data);
          const returnedData = data;
          setReferralData(returnedData);
          return returnedData;
        }
      });
  };

  const fetchReferralCode = async () => {
    let headers = { "Content-Type": "application/json" };
    let body = { address: account };
    const url = `${DATA_API_URL}/get_referral_code`;
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        const returnedData = data;
        return returnedData;
      });
  };

  const fetchAddressFromReferralCode = async (code) => {
    let headers = { "Content-Type": "application/json" };
    let body = { code: code };
    const url = `${DATA_API_URL}/get_address_from_referral_code`;
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        const returnedData = data;
        return returnedData;
      });
  };

  const processCreateReferralCodeEvent = async (...args) => {
    const [address, referralCode] = args;
    IS_PROD || console.log(">>>>>>>>>>>> processCreateReferralCodeEvent", address);
    if (address === account) {
      await fetchReferralData();
      setFetching(false);
    }
  };

  useEffect(() => {
    if (!active) return;
    setFetching(false);
    setReferralData({});
    if (account) {
      const contract = contractInfo[Referrals].contract;
      contract.on("CreateReferralCode", processCreateReferralCodeEvent);
    }
    return function cleanup() {
      const contract = contractInfo[Referrals].contract;
      contract.off("CreateReferralCode", processCreateReferralCodeEvent);
    };
  }, [account, chainId]);

  const getAddressFromReferralCode = async (referralCode) => {
    IS_PROD || console.log(">>> useLoyaltyReferrals.getAddressFromReferralCode", referralCode);
    if (!referralCode) {
      return AddressZero;
    }
    const { address } = await fetchAddressFromReferralCode(referralCode);
    return address;
  };

  const getReferrerAddress = async (account) => {
    const existingReferralData = referralData ? referralData : await fetchReferralData();
    IS_PROD || console.log(">>> useLoyaltyReferrals.existingReferrerData", existingReferralData);
    let referrerAddress = existingReferralData?.referrer;
    if (!referrerAddress) {
      const referrers = JSON.parse(localStorage.getItem(REFERRAL_CODE_KEY)) || {};
      const currentReferrerCode = referrers[account] || null;
      referrerAddress = await getAddressFromReferralCode(currentReferrerCode);
    }
    IS_PROD || console.log(">>> useLoyaltyReferrals.referrerAddress", referrerAddress);
    return referrerAddress || AddressZero;
  };

  const generateReferralCode = async () => {
    setFetching(true);

    //const { referralCode } = await fetchReferralCode();
    //const tx = await contractInfo[Referrals].contract.createReferralCode();
    contractInfo[Referrals].contract
      .createReferralCode()
      .then((tx) => {
        IS_PROD || console.log(">>>>>>>>>>>> tx", tx);
      })
      .catch((err) => {
        IS_PROD || console.error(">>>>>>>>>>>> err", err);
        setFetching(false);
      });
  };

  useEffect(() => {
    if (account && !periodic) {
      fetchReferralData();
    }
  }, []);

  const { error } = useSWR(active && periodic && [`useLoyaltyReferrals:${account}`, account], fetchReferralData, {
    refreshInterval: refreshInterval,
    revalidateOnFocus: true,
  });

  return {
    referralData: referralData,
    fetchReferralData,
    getAddressFromReferralCode,
    fetching,
    generateReferralCode,
    getReferrerAddress,
  };
}

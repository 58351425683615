import { useOtcInfo } from "hooks/useOtcInfo";

export default function SwapBox(props) {
  const { chainId } = props;

  const { otcData } = useOtcInfo({ chainId });
  const { availableToRefillFmt, totalRefilledFmt, assetsToDepleteFmt, totalDepletedFmt } = otcData;

  return (
    <section className="otc-info" style={{ width: "80%", margin: "0 auto", padding: "0px 10px" }}>
      <div className="info-content">
        <p className="first-element">YOU CAN BUY</p>
        <p className="second-element" style={{ color: "#cafc4f" }}>
          {availableToRefillFmt}
        </p>
        <p className="first-element">$DEXYs</p>
      </div>

      <div className="info-content">
        <p className="first-element">TOTAL MINTED</p>
        <p className="second-element">{totalRefilledFmt}</p>
        <p className="first-element">$DEXYs</p>
      </div>

      <div className="info-content">
        <p className="first-element">YOU CAN SELL</p>
        <p className="second-element">{assetsToDepleteFmt}</p>
        <p className="first-element">$DEXYs</p>
      </div>

      <div className="info-content">
        <p className="first-element">TOTAL BURNED</p>
        <p className="second-element red">{totalDepletedFmt}</p>
        <p className="first-element">$DEXYs</p>
      </div>
    </section>
  );
}

import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { Trans, t } from "@lingui/macro";
import useSWR from "swr";
// import { PieChart, Pie, Cell, Tooltip } from "recharts";
// import TooltipComponent from "components/Tooltip/Tooltip";
import PoweredBy from "../../img/powered_by.svg";

// import hexToRgba from "hex-to-rgba";
import { ethers } from "ethers";

import { GMX_DECIMALS, getPageTitle, arrayURLFetcher } from "lib/legacy";
import { useTotalGmxInLiquidity, useGmxPrice, useTotalGmxStaked, useTotalGmxSupply } from "domain/legacy";
import useFeesSummary from "domain/useFeesSummary";

import { getContract } from "config/contracts";

// import VaultV2 from "abis/VaultV2.json";
// import ReaderV2 from "abis/ReaderV2.json";
// import GlpManager from "abis/GlpManager.json";
import Footer from "components/Footer/Footer";

import "./DashboardV2.css";

// import gmx40Icon from "img/ic_gmx_40.svg";
// import glp40Icon from "img/ic_glp_40.svg";
// import avalanche16Icon from "img/ic_avalanche_16.svg";
// import arbitrum16Icon from "img/ic_arbitrum_16.svg";
// import arbitrum24Icon from "img/ic_arbitrum_24.svg";
// import avalanche24Icon from "img/ic_avalanche_24.svg";

// import TwitterIcon from "img/TwitterIcon.svg";
// import TelegramIcon from "img/TelegramIcon.svg";
// import MediumIcon from "img/MediumIcon.svg";
// import DiscordIcon from "img/DiscordBlackIcon.svg";
// import DiscordDef from "img/DiscordDefault.svg";
// import DiscordVar from "img/DiscordVariant2.svg";

import dexynthLogo from "img/DEXYNTHSTATIC.png";
import stayTuned from "img/STAY_TUNED_FOR_THE_BIG_REVEAL_.svg";
// import poweredBy1 from "img/POWERED BY.png";
// import poweredBy2 from "img/POWERED BY 1.png";
import launch from "img/LAUNCH.svg";
import t60days from "img/T-60DAYS.svg";
import subjectToChanges from "img/Subject_to_change.svg";
import smartTrading from "img/SMART TRADING.png";
import delivering from "img/Delivering a superior.png";
import ourPartners from "img/OUR PARTNERS.png";
import noKycImg from "img/NO KYC.svg";
import synTradImg from "img/SYNTHETIC TRADING.svg";
import effLiqImg from "img/EFFICIENT LIQUIDITY.svg";
import realYieldImg from "img/REAL YIELD PROTOCOL.svg";
import ownDonImg from "img/OUR OWN DON.svg";
import leverageImg from "img/1000X.svg";
import FondoSmartTrading from "img/FONDO_SMART_TRADING.svg";

import yellowPartner from "img/YellowPartner.svg";
import PrasagaPartner from "img/Prasaga 2 1.svg";
import ANALOGPartner from "img/ANALOG logo 1.svg";
import OBSCPartner from "img/OBSC.svg";

import numenPartner from "img/numen-partner.svg";
import ANMLPartner from "img/ANMLPartner.svg";
import COINZIXPartner from "img/COINZIXPartner.svg";
import synthrPartner from "img/synthrPartner.svg";
import myriaPartner from "img/myriaPartner.svg";

import ONOMYPartner from "img/ONOMYPartner.svg";
import BAGCPartner from "img/BAGCPartner.svg";
import MEEIROPartner from "img/MEEIROPartner.svg";
import FiveIREPartner from "img/FiveIREPartner.svg";

import ShardeumPartner from "img/ShardeumPartner.svg";
import ASTRAPartner from "img/ASTRAPartner.svg";
import OUTERPartner from "img/OUTERPartner.svg";
import DAMEXPartner from "img/DAMEXPartner.svg";
import TV from "img/tv.png";
import verichains from "img/VERICHAINS.svg";

// import AssetDropdown from "./AssetDropdown";
// import ExternalLink from "components/ExternalLink/ExternalLink";
import SEO from "components/Common/SEO";
import useTotalVolume from "domain/useTotalVolume";
// import StatsTooltip from "components/StatsTooltip/StatsTooltip";
// import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { ARBITRUM, AVALANCHE, getChainName, TESTNET } from "config/chains";
import { getServerUrl } from "config/backend";
// import { contractFetcher } from "lib/contracts";
// import { useInfoTokens } from "domain/tokens";
import { getWhitelistedTokens } from "config/tokens";
import { bigNumberify, expandDecimals } from "lib/numbers";
import { useChainId } from "lib/chains";
// import { formatDate } from "lib/dates";
const ACTIVE_CHAIN_IDS = [ARBITRUM, AVALANCHE];

const { AddressZero } = ethers.constants;

function getVolumeInfo(hourlyVolumes) {
  if (!hourlyVolumes || hourlyVolumes.length === 0) {
    return {};
  }
  const dailyVolumes = hourlyVolumes.map((hourlyVolume) => {
    const secondsPerHour = 60 * 60;
    const minTime = parseInt(Date.now() / 1000 / secondsPerHour) * secondsPerHour - 24 * secondsPerHour;
    const info = {};
    let totalVolume = bigNumberify(0);
    for (let i = 0; i < hourlyVolume.length; i++) {
      const item = hourlyVolume[i].data;
      if (parseInt(item.timestamp) < minTime) {
        break;
      }

      if (!info[item.token]) {
        info[item.token] = bigNumberify(0);
      }

      info[item.token] = info[item.token].add(item.volume);
      totalVolume = totalVolume.add(item.volume);
    }
    info.totalVolume = totalVolume;
    return info;
  });
  return dailyVolumes.reduce(
    (acc, cv, index) => {
      acc.totalVolume = acc.totalVolume.add(cv.totalVolume);
      acc[ACTIVE_CHAIN_IDS[index]] = cv;
      return acc;
    },
    { totalVolume: bigNumberify(0) }
  );
}

function getPositionStats(positionStats) {
  if (!positionStats || positionStats.length === 0) {
    return null;
  }
  return positionStats.reduce(
    (acc, cv, i) => {
      acc.totalLongPositionSizes = acc.totalLongPositionSizes.add(cv.totalLongPositionSizes);
      acc.totalShortPositionSizes = acc.totalShortPositionSizes.add(cv.totalShortPositionSizes);
      acc[ACTIVE_CHAIN_IDS[i]] = cv;
      return acc;
    },
    {
      totalLongPositionSizes: bigNumberify(0),
      totalShortPositionSizes: bigNumberify(0),
    }
  );
}

// function getCurrentFeesUsd(tokenAddresses, fees, infoTokens) {
//   if (!fees || !infoTokens) {
//     return bigNumberify(0);
//   }

//   let currentFeesUsd = bigNumberify(0);
//   for (let i = 0; i < tokenAddresses.length; i++) {
//     const tokenAddress = tokenAddresses[i];
//     const tokenInfo = infoTokens[tokenAddress];
//     if (!tokenInfo || !tokenInfo.contractMinPrice) {
//       continue;
//     }

//     const feeUsd = fees[i].mul(tokenInfo.contractMinPrice).div(expandDecimals(1, tokenInfo.decimals));
//     currentFeesUsd = currentFeesUsd.add(feeUsd);
//   }

//   return currentFeesUsd;
// }

export default function DashboardV2() {
  const { active, library } = useWeb3React();
  const { chainId } = useChainId();
  // const totalVolume = useTotalVolume();

  // const chainName = getChainName(chainId);

  const { data: positionStats } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/position_stats")),
    {
      fetcher: arrayURLFetcher,
    }
  );

  const { data: hourlyVolumes } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/hourly_volume")),
    {
      fetcher: arrayURLFetcher,
    }
  );

  let { total: totalGmxSupply } = useTotalGmxSupply();

  // const currentVolumeInfo = getVolumeInfo(hourlyVolumes);

  // const positionStatsInfo = getPositionStats(positionStats);

  // function getWhitelistedTokenAddresses(chainId) {
  //   const whitelistedTokens = getWhitelistedTokens(chainId);
  //   return whitelistedTokens.map((token) => token.address);
  // }

  //const whitelistedTokens = getWhitelistedTokens(chainId);
  //const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  // const visibleTokens = tokenList.filter((t) => !t.isTempHidden);

  // const readerAddress = getContract(chainId, "Reader");
  // const vaultAddress = getContract(chainId, "Vault");
  // const glpManagerAddress = getContract(chainId, "GlpManager");

  // const gmxAddress = getContract(chainId, "GMX");
  // const glpAddress = getContract(chainId, "GLP");
  // const usdgAddress = getContract(chainId, "USDG");

  // const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];

  // const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  // });

  // const { data: totalSupplies } = useSWR(
  //   [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
  //   {
  //     fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
  //   }
  // );

  // const { data: totalTokenWeights } = useSWR(
  //   [`GlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
  //   {
  //     fetcher: contractFetcher(library, VaultV2),
  //   }
  // );

  // const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
  // const { infoTokens: infoTokensArbitrum } = useInfoTokens(null, ARBITRUM, active, undefined, undefined);
  // const { infoTokens: infoTokensAvax } = useInfoTokens(null, AVALANCHE, active, undefined, undefined);

  // const { data: currentFees } = useSWR(
  //   infoTokensArbitrum[AddressZero].contractMinPrice && infoTokensAvax[AddressZero].contractMinPrice
  //     ? "Dashboard:currentFees"
  //     : null,
  //   {
  //     fetcher: () => {
  //       return Promise.all(
  //         ACTIVE_CHAIN_IDS.map((chainId) =>
  //           contractFetcher(null, ReaderV2, [getWhitelistedTokenAddresses(chainId)])(
  //             `Dashboard:fees:${chainId}`,
  //             chainId,
  //             getContract(chainId, "Reader"),
  //             "getFees",
  //             getContract(chainId, "Vault")
  //           )
  //         )
  //       ).then((fees) => {
  //         return fees.reduce(
  //           (acc, cv, i) => {
  //             const feeUSD = getCurrentFeesUsd(
  //               getWhitelistedTokenAddresses(ACTIVE_CHAIN_IDS[i]),
  //               cv,
  //               ACTIVE_CHAIN_IDS[i] === ARBITRUM ? infoTokensArbitrum : infoTokensAvax
  //             );
  //             acc[ACTIVE_CHAIN_IDS[i]] = feeUSD;
  //             acc.total = acc.total.add(feeUSD);
  //             return acc;
  //           },
  //           { total: bigNumberify(0) }
  //         );
  //       });
  //     },
  //   }
  // );

  //const { data: feesSummaryByChain } = useFeesSummary();
  //const feesSummary = feesSummaryByChain[chainId];

  // const eth = infoTokens[getTokenBySymbol(chainId, "ETH").address];
  /*const shouldIncludeCurrrentFees =
    feesSummaryByChain[chainId].lastUpdatedAt &&
    parseInt(Date.now() / 1000) - feesSummaryByChain[chainId].lastUpdatedAt > 60 * 60;
*/
  // const totalFees = ACTIVE_CHAIN_IDS.map((chainId) => {
  //   if (shouldIncludeCurrrentFees && currentFees && currentFees[chainId]) {
  //     return currentFees[chainId].div(expandDecimals(1, USD_DECIMALS)).add(feesSummaryByChain[chainId].totalFees || 0);
  //   }

  //   return feesSummaryByChain[chainId].totalFees || 0;
  // })
  //   .map((v) => Math.round(v))
  //   .reduce(
  //     (acc, cv, i) => {
  //       acc[ACTIVE_CHAIN_IDS[i]] = cv;
  //       acc.total = acc.total + cv;
  //       return acc;
  //     },
  //     { total: 0 }
  //   );

  const { gmxPrice, gmxPriceFromArbitrum, gmxPriceFromAvalanche } = useGmxPrice(
    chainId,
    { arbitrum: chainId === ARBITRUM ? library : undefined },
    active
  );

  let { total: totalGmxInLiquidity } = useTotalGmxInLiquidity(chainId, active);

  let { avax: avaxStakedGmx, arbitrum: arbitrumStakedGmx, total: totalStakedGmx } = useTotalGmxStaked();

  let gmxMarketCap;
  if (gmxPrice && totalGmxSupply) {
    gmxMarketCap = gmxPrice.mul(totalGmxSupply).div(expandDecimals(1, GMX_DECIMALS));
  }

  let stakedGmxSupplyUsd;
  if (gmxPrice && totalStakedGmx) {
    stakedGmxSupplyUsd = totalStakedGmx.mul(gmxPrice).div(expandDecimals(1, GMX_DECIMALS));
  }

  // let aum;
  // if (aums && aums.length > 0) {
  //   aum = aums[0].add(aums[1]).div(2);
  // }

  // let glpPrice;
  // let glpSupply;
  // let glpMarketCap;
  // if (aum && totalSupplies && totalSupplies[3]) {
  //   glpSupply = totalSupplies[3];
  //   glpPrice =
  //     aum && aum.gt(0) && glpSupply.gt(0)
  //       ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
  //       : expandDecimals(1, USD_DECIMALS);
  //   glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  // }

  // let tvl;
  // if (glpMarketCap && gmxPrice && totalStakedGmx) {
  //   tvl = glpMarketCap.add(gmxPrice.mul(totalStakedGmx).div(expandDecimals(1, GMX_DECIMALS)));
  // }

  // const ethFloorPriceFund = expandDecimals(350 + 148 + 384, 18);
  // const glpFloorPriceFund = expandDecimals(660001, 18);
  // const usdcFloorPriceFund = expandDecimals(784598 + 200000, 30);

  // let totalFloorPriceFundUsd;

  // if (eth && eth.contractMinPrice && glpPrice) {
  //   const ethFloorPriceFundUsd = ethFloorPriceFund.mul(eth.contractMinPrice).div(expandDecimals(1, eth.decimals));
  //   const glpFloorPriceFundUsd = glpFloorPriceFund.mul(glpPrice).div(expandDecimals(1, 18));

  //   totalFloorPriceFundUsd = ethFloorPriceFundUsd.add(glpFloorPriceFundUsd).add(usdcFloorPriceFund);
  // }

  // let adjustedUsdgSupply = bigNumberify(0);

  // for (let i = 0; i < tokenList.length; i++) {
  //   const token = tokenList[i];
  //   const tokenInfo = infoTokens[token.address];
  //   if (tokenInfo && tokenInfo.usdgAmount) {
  //     adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
  //   }
  // }

  // const getWeightText = (tokenInfo) => {
  //   if (
  //     !tokenInfo.weight ||
  //     !tokenInfo.usdgAmount ||
  //     !adjustedUsdgSupply ||
  //     adjustedUsdgSupply.eq(0) ||
  //     !totalTokenWeights
  //   ) {
  //     return "...";
  //   }

  //   const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
  //   // use add(1).div(10).mul(10) to round numbers up
  //   const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights).add(1).div(10).mul(10);

  //   const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(
  //     targetWeightBps,
  //     2,
  //     2,
  //     false
  //   )}%`;

  //   return (
  //     <TooltipComponent
  //       handle={weightText}
  //       position="right-bottom"
  //       renderContent={() => {
  //         return (
  //           <>
  //             <StatsTooltipRow
  //               label={t`Current Weight`}
  //               value={`${formatAmount(currentWeightBps, 2, 2, false)}%`}
  //               showDollar={false}
  //             />
  //             <StatsTooltipRow
  //               label={t`Target Weight`}
  //               value={`${formatAmount(targetWeightBps, 2, 2, false)}%`}
  //               showDollar={false}
  //             />
  //             <br />
  //             {currentWeightBps.lt(targetWeightBps) && (
  //               <div className="text-white">
  //                 <Trans>
  //                   {tokenInfo.symbol} is below its target weight.
  //                   <br />
  //                   <br />
  //                   Get lower fees to{" "}
  //                   <Link to="/buy_glp" target="_blank" rel="noopener noreferrer">
  //                     buy GLP
  //                   </Link>{" "}
  //                   with {tokenInfo.symbol},&nbsp; and to{" "}
  //                   <Link to="/trade" target="_blank" rel="noopener noreferrer">
  //                     swap
  //                   </Link>{" "}
  //                   {tokenInfo.symbol} for other tokens.
  //                 </Trans>
  //               </div>
  //             )}
  //             {currentWeightBps.gt(targetWeightBps) && (
  //               <div className="text-white">
  //                 <Trans>
  //                   {tokenInfo.symbol} is above its target weight.
  //                   <br />
  //                   <br />
  //                   Get lower fees to{" "}
  //                   <Link to="/trade" target="_blank" rel="noopener noreferrer">
  //                     swap
  //                   </Link>{" "}
  //                   tokens for {tokenInfo.symbol}.
  //                 </Trans>
  //               </div>
  //             )}
  //             <br />
  //             <div>
  //               <ExternalLink href="https://gmxio.gitbook.io/gmx/glp">
  //                 <Trans>More Info</Trans>
  //               </ExternalLink>
  //             </div>
  //           </>
  //         );
  //       }}
  //     />
  //   );
  // };

  let stakedPercent = 0;

  if (totalGmxSupply && !totalGmxSupply.isZero() && !totalStakedGmx.isZero()) {
    stakedPercent = totalStakedGmx.mul(100).div(totalGmxSupply).toNumber();
  }

  let liquidityPercent = 0;

  if (totalGmxSupply && !totalGmxSupply.isZero() && totalGmxInLiquidity) {
    liquidityPercent = totalGmxInLiquidity.mul(100).div(totalGmxSupply).toNumber();
  }

  let notStakedPercent = 100 - stakedPercent - liquidityPercent;

  let gmxDistributionData = [
    {
      name: t`staked`,
      value: stakedPercent,
      color: "#4353fa",
    },
    {
      name: t`in liquidity`,
      value: liquidityPercent,
      color: "#0598fa",
    },
    {
      name: t`not staked`,
      value: notStakedPercent,
      color: "#5c0af5",
    },
  ];

  // const totalStatsStartDate = chainId === AVALANCHE ? t`06 Jan 2022` : t`01 Sep 2021`;

  let stableGlp = 0;
  let totalGlp = 0;

  // let glpPool = tokenList.map((token) => {
  //   const tokenInfo = infoTokens[token.address];
  //   if (tokenInfo.usdgAmount && adjustedUsdgSupply && adjustedUsdgSupply.gt(0)) {
  //     const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
  //     if (tokenInfo.isStable) {
  //       stableGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
  //     }
  //     totalGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
  //     return {
  //       fullname: token.name,
  //       name: token.symbol,
  //       value: parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`),
  //     };
  //   }
  //   return null;
  // });

  let stablePercentage = totalGlp > 0 ? ((stableGlp * 100) / totalGlp).toFixed(2) : "0.0";

  // glpPool = glpPool.filter(function (element) {
  //   return element !== null;
  // });

  // glpPool = glpPool.sort(function (a, b) {
  //   if (a.value < b.value) return 1;
  //   else return -1;
  // });

  gmxDistributionData = gmxDistributionData.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  const [gmxActiveIndex, setGMXActiveIndex] = useState(null);

  // const onGMXDistributionChartEnter = (_, index) => {
  //   setGMXActiveIndex(index);
  // };

  // const onGMXDistributionChartLeave = (_, index) => {
  //   setGMXActiveIndex(null);
  // };

  // const [glpActiveIndex, setGLPActiveIndex] = useState(null);

  // const onGLPPoolChartEnter = (_, index) => {
  //   setGLPActiveIndex(index);
  // };

  // const onGLPPoolChartLeave = (_, index) => {
  //   setGLPActiveIndex(null);
  // };

  // const CustomTooltip = ({ active, payload }) => {
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="stats-label">
  //         <div className="stats-label-color" style={{ backgroundColor: payload[0].color }}></div>
  //         {payload[0].value}% {payload[0].name}
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  return (
    <SEO title={getPageTitle("Dexynth - Synthetics Trading Platform")}>
      {/* <div className="default-container DashboardV2 page-layout"> */}
      <div id="backgroundGreen">
        <span style={{ visibility: "hidden" }}>Background green</span>
      </div>
      <div className="DashboardV2 page-layout" style={{ zIndex: "10" }}>
        <div id="FirstBlock" style={{ marginTop: "200px" }}>
          <div className="col-10 defiSynthMain mx-auto mb-5">
            {/* <div className="col-10 defiSynthMain mx-auto mb-5"> */}
            <div className="section-title-content">
              <div className="Page-title text-center">
                {/* <Trans>DEXYNTH</Trans> {chainId === TESTNET && <img src={avalanche24Icon} alt="avalanche24Icon" />} */}
                <img src={dexynthLogo} alt="DEXYNTH Logo" style={{ zIndex: "100" }} />
                {/* {chainId === ARBITRUM && <img src={arbitrum24Icon} alt="arbitrum24Icon" />} */}
              </div>
              <div className="Page-title text-center page-title-header">
                {/* <div className="Page-title text-center defiSynthTradPlat"> */}
                <Trans>DEFI Synthetics trading platform</Trans>
              </div>
              <div className="Page-description text-center mb-5 mt-3 page-title-description">
                {/* <div className="Page-description text-center mb-5 page-title-description"> */}
                <Trans>Crypto, stocks, forex, Commodities, NFTs and more.</Trans>
                {/* <Trans>
                  {chainName} Total Stats start from {totalStatsStartDate}.<br /> For detailed stats:
                </Trans>{" "}
                {chainId === ARBITRUM && <ExternalLink href="https://stats.gmx.io">https://stats.gmx.io</ExternalLink>}
                {chainId === TESTNET && (
                  <ExternalLink href="https://stats.gmx.io/avalanche">https://stats.gmx.io/avalanche</ExternalLink>
                )}
                . */}
              </div>
            </div>
          </div>

          <div className="gold-reveal text-center">
            <div className="gold-reveal-top">
              <img src={stayTuned} alt="DEXYNTH Logo" />
            </div>
            <div className="gold-reveal-bottom mt-3">
              <Trans>SIGN UP TO OUR WEEKLY NEWSLETTER</Trans>
            </div>
            <div className="col-5 m-auto mt-4 gold-reveal-actions">
              <input
                type="text"
                placeholder="Enter your email address..."
                className="col-8 gold-reveal-input-subscription"
              />
              <span className="col-3 gold-reveal-span softGrey">JOIN US</span>
            </div>
          </div>
        </div>

        {/* <div className="launch-celebration mb-5">
          <div className="col-12 text-center launch-celebration-header">
            <Trans>LAUNCH CELEBRATION GIVEAWAYS !</Trans>
          </div>
          <div className="col-12 text-center">

            <div id="DiscButton">
              <span id="DiscButtonSpan">GO TO DISCORD</span>
            </div>
          </div>
        </div> */}

        <div className="smartTrading">
          <div className="text-center">
            <img src={smartTrading} alt="smartTrading" />
          </div>
          <div className="mt-5 text-center">
            <img src={delivering} alt="delivering" />
          </div>
          <div className="row text-center mt-15 col-8 mx-auto" id="contenedor-img">
            <div className="col-2">
              <img src={noKycImg} alt="noKycImg" className="w-95" />
            </div>
            <div className="col-2">
              <img src={synTradImg} alt="synTradImg" className="w-95" />
            </div>
            <div className="col-2">
              <img src={effLiqImg} alt="effLiqImg" className="w-95" />
            </div>
            <div className="col-2">
              <img src={realYieldImg} alt="realYieldImg" className="w-95" />
            </div>
            <div className="col-2">
              <img src={ownDonImg} alt="ownDonImg" className="w-95" />
            </div>
            <div className="col-2">
              <img src={leverageImg} alt="leverageImg" className="w-95" />
            </div>
          </div>
          <div id="smartTradingEndText" className="text-center mt-10 softGrey">
            <Trans>AMONG OTHER FEATURES. LEARN MORE ABOUT US</Trans>
          </div>
          <div className="goToPitchdeck">
            <a
              href="
https://docsend.com/view/m9gm9peratkya8bn"
              id="GoToDocsButton"
              target="_blank"
            >
              GO TO PITCHDECK
            </a>
          </div>
        </div>

        <div className="ourPartners mt-25">
          <div className="col-12 text-center" style={{ marginBottom: "-50px" }}>
            <img src={ourPartners} alt="Our Partners" />
          </div>
          <div className="partners-logos col-8 mx-auto">
            <div className="row mt-15 align-items-center">
              <div className="col-3 text-center">
                <a href="https://www.yellow.org/" target="_blank" rel="noreferrer">
                  <img src={yellowPartner} alt="Yellow Partner" />
                </a>
              </div>
              <div className="col-3 text-center">
                <a href="https://www.prasaga.com/" target="_blank" rel="noreferrer">
                  <img src={PrasagaPartner} alt="PrasagaPartner" />
                </a>
              </div>
              <div className="col-3 text-center">
                <a href="https://shardeum.org/" target="_blank" rel="noreferrer">
                  <img src={ShardeumPartner} alt="ShardeumPartner" />
                </a>
              </div>
              <div className="col-3 text-center">
                <a href="https://obscu.ro/" target="_blank" rel="noreferrer">
                  <img src={OBSCPartner} alt="OBSCPartner" />
                </a>
              </div>
            </div>

            <div className="row mt-10 align-items-center justify-content-center">
              <div className="col-3 text-center">
                <a href="https://coinzix.com/" target="_blank" rel="noreferrer">
                  <img src={COINZIXPartner} alt="COINZIXPartner" />
                </a>
              </div>

              <div className="col-3 text-center">
                <a href="https://damex.io/" target="_blank" rel="noreferrer">
                  <img src={DAMEXPartner} alt="DAMEXPartner" />
                </a>
              </div>

              <div className="col-3 text-center">
                <a href="https://outerringmmo.com/" target="_blank" rel="noreferrer">
                  <img src={OUTERPartner} alt="OUTERPartner" />
                </a>
              </div>
            </div>

            <div className="row mt-10 align-items-center justify-content-center">
              <div className="col-3 text-center">
                <a href="https://www.tradingview.com/" target="_blank" rel="noreferrer">
                  <img
                    src={numenPartner}
                    alt="Trading View Partner"
                    style={{ filter: "brightness(10000%)", width: "130px" }}
                  />
                </a>
              </div>

              <div className="col-3 text-center">
                <a href="https://www.verichains.io/" target="_blank" rel="noreferrer">
                  <img
                    src={verichains}
                    alt="Verichains Partner"
                    style={{ filter: "brightness(10000%)", width: "90px" }}
                  />
                </a>
              </div>
              <div className="col-3 text-center">
                <a href="https://www.tradingview.com/" target="_blank" rel="noreferrer">
                  <img src={TV} alt="Trading View Partner" style={{ filter: "brightness(10000%)", width: "130px" }} />
                </a>
              </div>
            </div>
          </div>
          <div
            className="text-center w-50"
            style={{ margin: "50px auto", fontFamily: "Lato", letterSpacing: "0.4px", fontSize: "14px" }}
          >
            <p>
              Charts and financial information provided by TradingView, a popular trading and charting platform. Check
              out the{" "}
              <a
                style={{ color: "#ffd700" }}
                href="https://www.tradingview.com/symbols/XAUUSD/"
                target="_blank"
                rel="noreferrer"
              >
                gold chart
              </a>{" "}
              or explore{" "}
              <a
                style={{ color: "#ffd700" }}
                href="https://www.tradingview.com/symbols/BTC.D/"
                target="_blank"
                rel="noreferrer"
              >
                Bitcoin's dominance
              </a>{" "}
              directly from their website.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </SEO>
  );
}

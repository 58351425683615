import { useEffect, useState, useRef } from "react";
import { Menu } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";
import cx from "classnames";
import { LONG, SHORT, SWAP } from "lib/legacy";
import { getTokens, getWhitelistedTokens } from "config/tokens";
import "./ChartTokenSelector.css";
import DesplegablePrices from "./DesplegablePrices";
import { Dropdown } from "react-bootstrap";
import starFav from "../../img/Star1.svg";
import starNormal from "../../img/Star2.svg";

export default function ChartTokenSelector(props) {
  // Extraemos todos los props
  const {
    setCleanPairs,
    chainId,
    onSelectToken,
    onSelect,
    tokenTitle,
    swapOption,
    ourSelectedToken,
    pares,
    pairs,
    setPairs,
    cleanPairs,
  } = props;

  // Declaración de variables y estados
  const [hideClosed, setHideClosed] = useState(false);
  const [actualMarket, setActualMarket] = useState();

  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  const storedPairs = localStorage.getItem("filtered-pairs");
  const initialSelectedPairs = storedPairs ? JSON.parse(storedPairs) : [];
  const [focus, setFocus] = useState(false);
  const searchTokenRef = useRef(null);

  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;
  const isSwap = swapOption === SWAP;

  let options = getTokens(chainId);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped);
  const shortableTokens = indexTokens.filter((token) => token.isShortable);

  if (isLong) {
    options = indexTokens;
  }
  if (isShort) {
    options = shortableTokens;
  }

  const handleInput = (event) => {
    const searchTermValue = event.target.value.toLowerCase();
    setSearchTerm(searchTermValue);

    if (searchTermValue === "") {
      setPairs(cleanPairs);
    } else {
      if (previousSearchTerm.length > searchTermValue.length) {
        setPairs(
          cleanPairs
            .filter((item) => item[0].toLowerCase().includes(searchTermValue))
            .sort((a, b) => {
              if (a[8] === true && b[8] === false) {
                return -1;
              } else if (a[8] === false && b[8] === true) {
                return 1;
              } else {
                return a[0].localeCompare(b[0]);
              }
            })
        );
      } else {
        setPairs(
          pairs
            .filter((item) => item[0].toLowerCase().includes(searchTermValue))
            .sort((a, b) => {
              if (a[8] === true && b[8] === false) {
                return -1;
              } else if (a[8] === false && b[8] === true) {
                return 1;
              } else {
                return a[0].localeCompare(b[0]);
              }
            })
        );
      }
      setPreviousSearchTerm(searchTermValue);
    }
  };

  // Ocultar los pares que estan closed
  const handleHideClosedChange = (e) => {
    setHideClosed(e.target.checked);
    if (e.target.checked) {
      setPairs(pairs.filter((pair) => pair[5] !== "CLOSED"));
    } else {
      setPairs(cleanPairs);
    }
  };

  const filterMarkets = (selectedMarket, cleanPairs) => {
    const selectedGroup = marketsToGroups[selectedMarket];

    if (selectedGroup === -1) {
      return cleanPairs;
    } else {
      const filteredData = cleanPairs.filter((market) => {
        if (Array.isArray(selectedGroup)) {
          return selectedGroup.includes(market[2]);
        } else {
          return market[2] === selectedGroup;
        }
      });

      return filteredData;
    }
  };

  useEffect(() => {
    if (focus) {
      searchTokenRef?.current?.focus();
    }
  }, [focus]);

  useEffect(() => {
    const indexPairs = JSON.parse(localStorage.getItem("indexFavoritesPairs"));
    const pairsLocalStorage = JSON.parse(localStorage.getItem("pairs")) || pairs;
    const filteredMarketsState = JSON.parse(localStorage.getItem("filteredMarket")) || [
      "All Markets",
      "Crypto",
      "Forex",
      "US Indices",
      "US Stocks",
      "Commodities",
      "NFT",
    ];

    const favoritesPairs = pairs?.filter((pair) => {
      return indexPairs?.includes(pair[1]);
    });

    const noFavoritesPairs = pairs?.filter((pair) => {
      return !indexPairs?.includes(pair[1]);
    });

    const paresOrdenados = pairs.map((pair) => {
      const isInFavorites = favoritesPairs.some((favoritePair) => favoritePair[1] === pair[1]);
      return [...pair.slice(0, 8), isInFavorites, ...pair.slice(9)];
    });

    setPairs(pairsLocalStorage);
    setCleanPairs(pairsLocalStorage);
    handleClick();

    setTimeout(() => {
      setFilteredMarketsState(filteredMarketsState);
    }, 300);
  }, []);

  useEffect(() => {
    let element = cleanPairs.find((item) => item[7] == tokenTitle);
    let btc = cleanPairs.find((p) => p[0] == "BTC/USD");

    if (element) {
      var property4 = element[4];
      var property0 = element[0];
    }

    if (typeof property4 !== "string" && typeof tokenTitle !== "string") {
      document.title = `${btc[0]} | ${btc[4]} | Dexynth - Synthetics Trading Platform`;
      return;
    }

    document.title = `${property0} | ${property4} | Dexynth - Synthetics Trading Platform`;
  }, [onSelectToken, pairs]);

  // Todos los mercados existentes
  const marketsToGroups = {
    "All Markets": [0, 1, 2, 3, 4, 5, 6, 7, 10],
    Crypto: 0,
    Forex: 1,
    "US Stocks": [2, 3, 4],
    "US Indices": 5,
    Commodities: [6, 7],
    NFT: 10,
  };

  const handleClick = (event) => {
    const selectedMarket = event?.target.textContent || localStorage.getItem("SelectedMarket") || "All Markets";
    setActualMarket(selectedMarket);

    let filteredData;
    if (selectedMarket !== "null") {
      localStorage.setItem("SelectedMarket", selectedMarket);
      filteredData = filterMarkets(selectedMarket, cleanPairs);
    } else {
      filteredData = filterMarkets("All Markets", cleanPairs);
      localStorage.removeItem("SelectedMarket");
    }

    setPairs(filteredData);
  };

  const market = cleanPairs.find((par) => ourSelectedToken.pairName === par[7]);

  let marketPrice;
  let marketToken;
  if (market) {
    marketPrice = market[4];
    marketToken = market[5];
  }

  let color;

  if (marketToken === "CLOSED" || marketToken < 0) {
    color = "#E23E3E";
  } else {
    color = "#148A63";
  }

  let parts;
  if (ourSelectedToken.pairSymbols) {
    parts = ourSelectedToken.pairSymbols.split("/");
  } else {
    parts = ["BTC", "USD"];
  }

  const image = require(`../../img/tokenlogo/${parts[0] + parts[1]}.svg`).default;

  // PRUEBAS PARA FILTRAR POR MERCADO/MERCADOS// Inicialmente, igual que pairs.
  const [filteredMarketsState, setFilteredMarketsState] = useState([
    "All Markets",
    "Crypto",
    "Forex",
    "US Stocks",
    "US Indices",
    "Commodities",
    "NFT",
  ]);
  const [filteredPairs, setFilteredPairs] = useState();
  const [marketText, setMarketText] = useState("All Markets");

  const handleFilterMarkets = (market) => {
    let newFilteredMarket;

    if (market === "All Markets") {
      const allMarketsExceptAll = Object.keys(marketsToGroups).filter((item) => item !== "All Markets");
      if (filteredMarketsState.length < allMarketsExceptAll.length) {
        newFilteredMarket = allMarketsExceptAll.concat("All Markets");
      } else {
        newFilteredMarket = [];
      }
    } else {
      if (filteredMarketsState.includes(market)) {
        newFilteredMarket = filteredMarketsState.filter((item) => item !== market);
        if (filteredMarketsState.includes("All Markets")) {
          newFilteredMarket = newFilteredMarket.filter((item) => item !== "All Markets");
        }
      } else {
        newFilteredMarket = [...filteredMarketsState, market];
        const allMarketsSelected = Object.keys(marketsToGroups)
          .filter((item) => item !== "All Markets")
          .every((item) => newFilteredMarket.includes(item));
        if (allMarketsSelected) {
          newFilteredMarket = [...newFilteredMarket, "All Markets"];
        }
      }
    }

    setFilteredMarketsState(newFilteredMarket);
    localStorage.setItem("filteredMarket", JSON.stringify(newFilteredMarket));
  };

  useEffect(() => {
    if (filteredMarketsState.length == 7) {
      setMarketText("All Markets");
    } else if (filteredMarketsState.length < 7 && filteredMarketsState.length > 1) {
      setMarketText("Multiple Markets");
    } else if (filteredMarketsState.length == 0) {
      setMarketText("No Markets");
    } else if (filteredMarketsState.length == 1) {
      setMarketText(filteredMarketsState[0]);
    }

    const indicesArray = filteredMarketsState.flatMap((market) => {
      const indices = marketsToGroups[market];
      return Array.isArray(indices) ? indices : [indices];
    });

    setFilteredPairs(cleanPairs.filter((par) => indicesArray?.includes(par[2])));
  }, [filteredMarketsState]);

  useEffect(() => {
    setPairs(filteredPairs || pairs);
  }, [filteredPairs]);

  return (
    <div className="desplegable">
      <Menu as="div" style={{ fontFamily: "Lato" }}>
        <div
          style={{
            height: "50px",
            width: "327px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: "-100px",
            marginLeft: "-3px",
            borderRight: "1px solid #19181d",
          }}
          className="deplegable-info"
        >
          <Menu.Button
            as="div"
            disabled={isSwap}
            className="desplegable-button"
            style={{ width: "40%", display: "flex", marginRight: "10px" }}
          >
            <button
              className={cx("App-cta small transparent chart-token-selector", { "default-cursor": isSwap })}
              onClick={() => {
                const pairsLocalStorage = JSON.parse(localStorage.getItem("pairs")) || pairs;
                setSearchTerm("");
                setPairs(filteredPairs);
                setFocus(!focus);
              }}
            >
              <div style={{ display: "flex", marginRight: "6px", alignItems: "center" }}>
                <img width="21px" src={image} style={{ marginRight: "0px", padding: "0px" }} />
                <span
                  style={{
                    marginLeft: "10px",
                    fontFamily: "Lato",
                    fontWeight: "500",
                    fontSize: "21px",
                    letterSpacing: "0.15px",
                    color: "white",
                  }}
                >
                  {ourSelectedToken.pairSymbols || "BTC/USD"}
                </span>
              </div>
            </button>
            <span
              style={{
                fontWeight: "lighter",
                fontSize: "18px",
                marginTop: "1px",
                marginLeft: "14px",
                textAlign: "right",
                cursor: "pointer",
              }}
            >
              {!isSwap && (
                <FaChevronDown
                  onClick={() => {
                    setFocus(!focus);
                  }}
                />
              )}
            </span>
          </Menu.Button>
          <div
            className="textos-desplegable"
            style={{
              height: "100%",
              padding: "10px",
              width: "35%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "15px",
            }}
          >
            <p
              style={{
                width: "100%",
                textAlign: "right",
                fontFamily: "Lato",
                fontSize: "21px",
                letterSpacing: "0.15px",
                marginLeft: "-25px",
                fontWeight: "lighter",
                marginBottom: "-3px",
              }}
              className="desplegable-info"
            >
              {marketPrice == "" || marketPrice == "-1" ? "--" : marketPrice.toFixed(2)}
            </p>
            <p
              style={{
                width: "100%",
                textAlign: "right",
                fontSize: "15px",
                marginLeft: "-25px",
                fontWeight: "lighter",
                color: color,
                marginBottom: "-1px",
              }}
              className="desplegable-info"
            >
              {marketToken === "CLOSED" ? marketToken : `${marketToken} %` || ""}{" "}
            </p>
          </div>
        </div>
        <div className="chart-token-menu">
          <Menu.Items as="div" className="chart-token-menu-items stylePairsList">
            <div className="desplegable-select">
              <div className="desplegable-extra-elements">
                <div className="elements">
                  <i class="fa-solid fa-magnifying-glass"></i>
                  <input
                    type="text"
                    id="SearchToken"
                    value={searchTerm}
                    placeholder="Search..."
                    onChange={handleInput}
                    ref={searchTokenRef}
                  />
                </div>
                {/* Desplegable de mercados */}
                <div className="elements" style={{ zIndex: "900" }}>
                  <Dropdown
                    style={{
                      marginLeft: "20px",
                    }}
                  >
                    <Dropdown.Toggle className="dropdown-toggle-menu">{marketText}</Dropdown.Toggle>
                    <Dropdown.Menu
                      variant="dark"
                      style={{
                        background: "#181818",
                        padding: "12px",
                        marginTop: "0px",
                        width: "125px",
                        marginLeft: "0px",
                      }}
                    >
                      {Object.keys(marketsToGroups).map((marketGroup) => (
                        <div
                          key={marketGroup}
                          className="checkbox-item"
                          style={{ display: "flex", justifyContent: "space-between" }}
                        >
                          <label>
                            <input
                              style={{ marginLeft: "5px" }}
                              type="checkbox"
                              className="input-checkbox"
                              onChange={() => {
                                handleFilterMarkets(marketGroup);
                              }}
                              checked={filteredMarketsState?.includes(marketGroup)}
                            />
                          </label>
                          <div style={{ marginRight: "5px" }}>{marketGroup}</div>
                        </div>
                      ))}
                    </Dropdown.Menu>
                    {/* Dropdown.Menu va aquí */}
                  </Dropdown>
                </div>
                {/* Hide closed */}
                <div className="elements" style={{ marginLeft: "10px" }}>
                  <span>Hide closed</span>
                  <input
                    type="checkbox"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    checked={hideClosed}
                    onChange={handleHideClosedChange}
                  />
                </div>
              </div>
              <div className="desplegable-selected">
                <div className="favs-chart">
                  <p style={{ color: "transparent" }}>Favs</p>
                  {pairs.map((option, index) => {
                    let img = option[8] == false ? starNormal : starFav;

                    return (
                      <Menu.Item key={index}>
                        <div className="menu-item">
                          <div className="favs">
                            <button
                              className="favs-button"
                              style={{
                                marginBottom: "16px",
                                backgroundImage: `url(${img})`,
                                width: "20px",
                                height: "20px",
                                backgroundColor: "transparent",
                                border: "none",
                                outline: "none",
                                backgroundPosition: "center",
                                backgroundRepeat: "none",
                                backgroundSize: "cover",
                              }}
                              onClick={(e) => {
                                e.preventDefault();

                                // Actualizar 'pairs'
                                const nuevosPares = [...pairs];
                                nuevosPares[index][8] = !nuevosPares[index][8];

                                // Actualizar 'cleanPairs'
                                const nuevosCleanPares = [...cleanPairs];
                                const cleanPairsIndex = nuevosCleanPares.findIndex(
                                  (pair) => pair[0] === nuevosPares[index][0]
                                );
                                if (cleanPairsIndex !== -1) {
                                  nuevosCleanPares[cleanPairsIndex] = nuevosPares[index];
                                }

                                // Ordenar ambos arreglos y actualizar el estado
                                const sortFunction = (a, b) => {
                                  if (a[8] === true && b[8] === false) {
                                    return -1;
                                  } else if (a[8] === false && b[8] === true) {
                                    return 1;
                                  } else {
                                    return a[0].localeCompare(b[0]);
                                  }
                                };

                                const indexPairs = nuevosCleanPares
                                  .filter((par) => par[8] === true)
                                  .map((par) => par[1]);

                                nuevosPares.sort(sortFunction);
                                nuevosCleanPares.sort(sortFunction);
                                setPairs(nuevosPares);
                                setCleanPairs(nuevosCleanPares);

                                // Guardar 'cleanPairs' en localStorage
                                localStorage.setItem("pairs", JSON.stringify(nuevosCleanPares));
                                localStorage.setItem("indexFavoritesPairs", JSON.stringify(indexPairs));
                              }}
                            ></button>
                          </div>
                        </div>
                      </Menu.Item>
                    );
                  })}
                </div>

                <div style={{ marginTop: "40px" }}>
                  {pairs.map((option, index) => {
                    let parts = option[0].split("/");
                    const image = require(`../../img/tokenlogo/${parts[0]}${parts[1]}.svg`).default;

                    return (
                      <Menu.Item key={index}>
                        <div
                          style={{ marginLeft: "-35px" }}
                          className="menu-item"
                          onClick={() => {
                            if (option[4] != 0) {
                              onSelect(option[0], option[7]);
                            }
                          }}
                        >
                          <p style={{ width: "30%", marginBottom: "16px", textAlign: "center" }}>
                            <img src={image} width={"16px"} />
                          </p>
                        </div>
                      </Menu.Item>
                    );
                  })}
                </div>

                <div style={{ width: "20%" }}>
                  <p style={{ marginLeft: "-60px", fontSize: "16px", color: "#616161" }}>Pair</p>
                  {pairs.map((option, index) => {
                    let parts = option[0].split("/");

                    return (
                      <Menu.Item key={index}>
                        <div
                          style={{ marginLeft: "-45px" }}
                          className="menu-item"
                          onClick={() => {
                            if (option[4] != 0) {
                              onSelect(option[0], option[7]);
                            }
                          }}
                        >
                          <span className={`${option[4] == 0 ? "notAllowed" : "allowed"} pairNameSpan`}>
                            <span style={{ color: "white" }}>{parts[0]}</span>
                            <span style={{ color: "#999999" }}>/{parts[1]}</span>
                          </span>
                        </div>
                      </Menu.Item>
                    );
                  })}
                </div>

                <DesplegablePrices pairs={pairs} setPairs={setPairs} onSelect={onSelect} />
              </div>
            </div>
          </Menu.Items>
        </div>
      </Menu>
    </div>
  );
}

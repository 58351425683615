import React, { useEffect, useState, useRef, useMemo } from "react";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import _ from "lodash";
import { helperToast } from "lib/helperToast";

import { IS_PROD } from "config/context";

import {
  POS_STATUS_TRYING_TO_CLOSE,
  POS_STATUS_NORMAL,
  POS_STATUS_TRYING_TO_CLOSE_BY_BOT,
} from "components/Exchange/PositionsLogic";
import { set } from "date-fns";
import { ca } from "date-fns/locale";

export function usePositions({
  pares,
  closingPositionData,
  blockNumber,
  getPairName,
  ourSelectedToken,
  filterBySelectedPair,
}) {
  const [positions, _setPositions] = useState({});
  const positionsRef = useRef(positions);
  const setPositions = (data) => {
    positionsRef.current = data;
    _setPositions(data);
  };

  const [orderStatus, _setOrderStatus] = useState({});
  const orderStatusRef = useRef(orderStatus);
  const setOrderStatus = (data) => {
    orderStatusRef.current = data;
    _setOrderStatus(data);
  };

  const orderIdIndicesRef = useRef({});
  const [overridenOrderId, setOverridenOrderId] = useState(null);

  const [positionsLoading, setPositionsLoading] = useState(false);
  const [historicEvent, setHistoricEvent] = useState({});

  const { chainId } = useChainId();
  const { active, account, library } = useWeb3React();

  const cancelReasons = {
    0: "<Unknown>",
    1: "Trading is paused",
    2: "Market is closed",
    3: "Slippage too low",
    4: "Price is outside of TP",
    5: "Price is outside of SL",
    6: "Trade is outside of exposure limits",
    7: "Price impact too high",
    8: "Leverage is too high",
    9: "Trade not found",
    10: "Wrong trade",
    11: "No hit",
  };

  const toastEventMessage = (event, isInitialLoad = false) => {
    if (isInitialLoad || !account) return;

    let cancelReasonText = "";
    if (event.event == "MarketOpenCanceled" || event.event == "MarketCloseCanceled") {
      const cancelReason = cancelReasons[event.cancelReason];
      cancelReasonText = cancelReason ? `Reason: ${cancelReason}` : "";
    }
    //cancelReasonText = "Trade is outside of exposure limits";
    const pairIndex = event.pairIndex;
    const pair = pares.find((p) => p[1] == pairIndex);

    const toastMessage = (
      <div>
        Positions: {event.event} for pair {pair[0]}
        <br />
        {cancelReasonText}
      </div>
    );
    //helperToast.success(`Positions: ${event.event} for pair: ${pair[0]}${cancelReasonText}`);
    helperToast.success(toastMessage);
  };

  const setPositionValues = (pairIndex, index, newValues) => {
    if (pairIndex in positionsRef.current) {
      if (index in positionsRef.current[pairIndex]) {
        let pos = positionsRef.current[pairIndex][index];
        positionsRef.current[pairIndex][index] = { ...pos, ...newValues };
      } else {
        positionsRef.current[pairIndex][index] = { ...newValues };
      }
    } else {
      positionsRef.current[pairIndex] = { [index]: { ...newValues } };
    }
  };

  const openNewPositionHandler = (position) => {
    position.initialPosToken = 0;
    position.positionSizeDai = 0;
    position.tp = 0;
    position.sl = 0;
    position.open = position.open;
    position.openPrice = 0;
    position.percentProfit = 0;
    position.price = 0;
    position.priceImpactP = 0;
    position.daiSentToTrader = 0;
    position.block = blockNumber;

    let existingIndex = [];
    //const newPositions = _.cloneDeep(positions);
    //console.log("newPositions", newPositions);
    try {
      if (Object.keys(positionsRef.current.length)) {
        existingIndex = Object.keys(positionsRef.current[position.pairIndex]).filter(
          (x) => positionsRef.current[position.pairIndex][x]?.orderId == parseInt(position.orderId)
        );
      }
    } catch (error) {}

    if (existingIndex.length > 0) position.index = existingIndex[0];
    else {
      if (position.open) {
        let index = 0;
        if (positionsRef.current[position.pairIndex]) {
          let usedIndices = new Set(Object.keys(positionsRef.current[position.pairIndex]));
          //console.log("usedIndices", usedIndices);
          let indices = new Set(["0", "1", "2"]);
          let unusedIndices = [...indices].filter((x) => !usedIndices.has(x));
          if (unusedIndices.length > 0) {
            index = unusedIndices[0];
          }
        }
        position.index = index;
      }
    }

    orderIdIndicesRef.current[position.orderId] = { pairIndex: position.pairIndex, index: position.index };

    getOurSpecificPositionByEvent(position);
  };
  const closePositionHandler = (position) => {
    const { pairIndex, index } = closingPositionData.current;
    if (pairIndex === undefined || index === undefined) return;
    setPositionValues(pairIndex, index, {
      orderId: position.orderId,
      open: false,
      status: POS_STATUS_TRYING_TO_CLOSE,
    });
  };
  const closePositionByBotHandler = (position) => {
    const { pairIndex, index, orderId } = position;
    const currentTime = Date.now();

    if (pairIndex === undefined || index === undefined) return;

    const isOpenPosition = position?.open;
    if (isOpenPosition == undefined) return;

    console.log("@@@@@ closePositionByBotHandler", isOpenPosition);

    if (!isOpenPosition) {
      console.log("@@@@@ closePositionByBotHandler.LimitOrderInitiated.POS_STATUS_TRYING_TO_CLOSE", position);
      setPositionValues(pairIndex, index, {
        orderId: orderId,
        open: false,
        timestamp: currentTime,
        status: POS_STATUS_TRYING_TO_CLOSE_BY_BOT,
      });
    } else {
      console.log("@@@@@ closePositionByBotHandler.LimitOrderInitiated.OPEN", position);
      //getOurSpecificPositionByEvent(position);
    }
  };

  const marketCloseCanceled = (position) => {
    const { pairIndex, index } = position;
    setPositionValues(pairIndex, index, {
      orderId: position.orderId,
      status: POS_STATUS_NORMAL,
    });
  };

  const marketExecutedHandler = (position) => {
    if (position.open) {
      const { pairIndex: stPairIndex, index: stIndex } = orderIdIndicesRef.current[position.orderId];
      const { pairIndex, index } = position;

      if (parseInt(index) !== parseInt(stIndex)) {
        setOverridenOrderId(positionsRef.current[pairIndex][index].orderId);
        if (positionsRef.current[pairIndex][stIndex].orderId == position.orderId) {
          delete positionsRef.current[pairIndex][stIndex];
        }
      }
      getOurSpecificPositionByEvent(position);
    } else {
      deleteOurSpecificPositionByEvent(position);
      setHistoricEvent(position);
    }
  };

  const marketOpenCanceled = (position) => {
    const { pairIndex, orderId } = position;
    if (positions[pairIndex] === undefined) return;
    const index = Object.keys(positions[pairIndex]).find((index) => positions[pairIndex][index]["orderId"] == orderId);
    deleteOurSpecificPositionByEvent({ pairIndex: pairIndex, index: index });
  };
  const deleteOurSpecificPositionByEvent = async (event) => {
    setPositionsLoading(1);
    delete positionsRef.current[event.pairIndex][event.index];
    setPositionsLoading(0);
  };

  const getOurSpecificPositionByEvent = async (event) => {
    //const newPositions = _.cloneDeep(positions);
    const pairIndex = event.pairIndex;
    const tradeIndex = event.index;
    positionsRef.current[pairIndex] && delete positionsRef.current[pairIndex][tradeIndex];
    positionsRef.current[pairIndex] = positionsRef.current[pairIndex] || {};

    const initialPosToken = event.initialPosToken
      ? ethers.utils.formatEther(event.initialPosToken.toLocaleString("fullwide", { useGrouping: false }))
      : 0;
    const positionSizeDai = event.positionSizeDai
      ? ethers.utils.formatEther(event.positionSizeDai.toLocaleString("fullwide", { useGrouping: false }))
      : 0;

    positionsRef.current[pairIndex][tradeIndex] = {
      trader: event.trader,
      pairIndex: pairIndex,
      pairName: pares.find((el) => el[1] == pairIndex)[0],
      index: event.index,
      initialPosToken: initialPosToken,
      positionSizeDai: positionSizeDai,
      openPrice: event.price ? event.price : event.openPrice,
      buy: event.buy,
      leverage: event.leverage,
      tp: event.tp,
      sl: event.sl,
      orderId: parseInt(event.orderId),
      block: parseInt(event.block),
      open: event.open,
      orderId: event.orderId,
    };
    //setPositions(newPositions);
    //setPositionsLoading(0);
  };

  const handleEvent = async (event) => {
    IS_PROD || console.log("@@@ usePositions.processEvents", event);

    let object = event;

    if (object.event == "getInitialTradesStarted") {
      setPositionsLoading(1);
      return;
    } else if (object.event == "getInitialTradesFinished") {
      setPositionsLoading(0);
      return;
    }

    let isInitialLoad = false;
    let isAnPosition = false;
    if (object.event == "MarketExecuted") {
      isAnPosition = true;
      marketExecutedHandler(object);
    } else if (object.event == "MarketOrderInitiated") {
      if (object.open) openNewPositionHandler(object);
      else closePositionHandler(object);
      isAnPosition = true;
    } else if (object.event == "MarketOpenCanceled") {
      marketOpenCanceled(object);
      isAnPosition = true;
    } else if (object.event == "MarketCloseCanceled") {
      marketCloseCanceled(object);
      isAnPosition = true;
    } else if (object.event == "LimitOrderInitiated") {
      console.log("@@@@@ usePositions.LimitOrderInitiated", object);
      closePositionByBotHandler(object);
      isAnPosition = true;
    } else if (object.event == "LimitExecutedEvent" || object.event == "LimitExecuted") {
      if (object.orderType == 3) {
        getOurSpecificPositionByEvent(object);
      } else {
        deleteOurSpecificPositionByEvent(object);
        setHistoricEvent(object);
      }
      isAnPosition = true;
    } else if (object.event == "OpenTradeInitialLoad") {
      let values = {
        trader: object.trader,
        pairIndex: object.pairIndex,
        pairName: getPairName(object.pairIndex),
        index: object.index,
        initialPosToken: ethers.utils.formatEther(
          object.initialPosToken.toLocaleString("fullwide", { useGrouping: false })
        ),
        positionSizeDai: ethers.utils.formatEther(
          object.positionSizeDai.toLocaleString("fullwide", { useGrouping: false })
        ),
        openPrice: object.price ? object.price : object.openPrice,
        buy: object.buy,
        leverage: object.leverage,
        tp: object.tp,
        sl: object.sl,
      };
      setPositionValues(object.pairIndex, object.index, values);
      isInitialLoad = true;
      isAnPosition = true;
    } else if (object.event == "TpUpdated") {
      setPositionValues(object.pairIndex, object.index, { tp: object.newTp });
      isInitialLoad = true;
      isAnPosition = true;
    } else if (object.event == "SlUpdated") {
      setPositionValues(object.pairIndex, object.index, { sl: object.newSl });
      isInitialLoad = true;
      isAnPosition = true;
    }

    if (!isAnPosition) return;
    orderStatusRef.current[object.orderId] = object.event;
    toastEventMessage(object, isInitialLoad);
  };

  useEffect(() => {
    setPositions({});
  }, [account, chainId]);

  const positionsStats = () => {
    let numOfRealPositions = 0;
    let numOfShownPositions = 0;
    Object.keys(positionsRef.current).forEach((pairIndex) => {
      Object.keys(positionsRef.current[pairIndex]).forEach((tradeIndex) => {
        const position = positionsRef.current[pairIndex][tradeIndex];
        numOfRealPositions++;
        if (filterBySelectedPair && ourSelectedToken.pairIndex == pairIndex) {
          numOfShownPositions++;
        } else if (!filterBySelectedPair) {
          numOfShownPositions++;
        }
      });
    });
    return { numOfRealPositions, numOfShownPositions };
  };

  return {
    positions: positionsRef.current,
    positionsLoading,
    historicEvent,
    handleEvent,
    setPositionValues,
    orderStatus: orderStatusRef.current,
    overridenOrderId,
    positionsStats,
  };
}

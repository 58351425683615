import { useState } from "react";
import { Trans, t } from "@lingui/macro";

import Modal from "components/Modal/Modal";
import { ethers } from "ethers";

import { formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { callContract } from "lib/contracts";
// import { useLocalStorageSerializeKey } from "lib/localStorage";
import { USDG_DECIMALS } from "lib/legacy";

export function CancelWithDrawModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    account,
    library,
    epoch,
    contractInfo,
    cancelWithdrawMethodName,
    setPendingTxns,
  } = props;
  const [isCancellingWithdraw, setIsCancellingWithdraw] = useState(false);

  //console.log("-*---" + epoch);
  let amount = parseValue(value, 18);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return t`Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    let amount = parseValue(value, USDG_DECIMALS);
    setIsCancellingWithdraw(true);
    //console.log(`fA: ${contractInfo.address} cancelWithdrawMethodName ${cancelWithdrawMethodName} amount ${amount}`);

    callContract(chainId, contractInfo.contract, cancelWithdrawMethodName, [amount, account, epoch], {
      sentMsg: t`CancelWithdraw submitted!`,
      failMsg: t`CancelWithdraw failed.`,
      successMsg: t`CancelWithdraw completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsCancellingWithdraw(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isCancellingWithdraw) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isCancellingWithdraw) {
      return t`CancellingWithdraw...`;
    }
    return t`Cancel Withdraw`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd"></div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <Trans>Max: {formatAmount(maxAmount, 18, 4, true)}</Trans>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{contractInfo.label}</div>
          </div>
        </div>

        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

import { useState, useEffect } from "react";
import useSWR from "swr";

import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import { useChainId } from "lib/chains";

import { BigNumber, ethers } from "ethers";
import TradingStorageABI from "abis/DexynthTradingStorageV5.json";
import {
  POS_STATUS_STUCK,
  POS_STATUS_ORPHAN_CLOSING_STUCK,
  POS_STATUS_NORMAL,
  shouldBeConsideredAsStuck,
  POS_STATUS_SURE_STUCK,
} from "components/Exchange/PositionsLogic";

import { IS_PROD } from "config/context";

export function usePendingOrders({
  account,
  library,
  active,
  setPositionValues,
  initialTradesLoading,
  getPairName,
  ourPositions,
  orderStatus,
  eventsRelayerEvent,
  overridenOrderId,
}) {
  const { chainId } = useChainId();
  const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum, "any") : null;
  const signer = provider ? provider.getSigner() : null;
  const TradingStorage_Contract = getContract(chainId, "TradingStorage");
  const TradingStorageAbi = TradingStorageABI.abi;
  const farmTradingStorageV5Contract = new ethers.Contract(TradingStorage_Contract, TradingStorageAbi, signer);

  const [pendingOrders, setPendingOrders] = useState([]);

  useEffect(() => {
    if (!overridenOrderId) return;
    IS_PROD || console.log("@@@@ usePendingOrders.overridenOrderId", overridenOrderId);
    const isPendingOrder = overridenOrderId in pendingOrders;
    IS_PROD || console.log("@@@@ usePendingOrders.isPendingOrder", isPendingOrder);
    if (isPendingOrder) {
      const newPendingOrders = { ...pendingOrders };
      newPendingOrders[overridenOrderId].status = POS_STATUS_SURE_STUCK;
      setPendingOrders(newPendingOrders);
    }
  }, [overridenOrderId]);

  const { data: userPendingOrderIds } = useSWR(
    account &&
      active && [
        `Exchange:userPendingOrderIds:${active}`,
        chainId,
        TradingStorage_Contract,
        "getPendingOrderIds",
        account,
      ],
    {
      fetcher: contractFetcher(library, TradingStorageABI),
      refreshInterval: 2000,
    }
  );

  const getNewStatus = (orderId) => {
    if (orderId in pendingOrders) {
      return pendingOrders[orderId].status === POS_STATUS_SURE_STUCK ? POS_STATUS_SURE_STUCK : POS_STATUS_STUCK;
    }
    return POS_STATUS_STUCK;
  };

  const getBlockTimestamp = async (blockNumber) => {
    let block = await provider.getBlockNumber(blockNumber);
    while (!block) {
      block = await provider.getBlockNumber(blockNumber);
    }
    IS_PROD || console.log("%%% getBlockTimestamp", blockNumber, block);
    return block.timestamp * 1000;
  };

  const processPendingOrders = async (userPendingOrderIds) => {
    //const [initialTradesLoading, setInitialTradesLoading] = useState(1);
    IS_PROD || console.log("@@@ processPendingOrders", initialTradesLoading, chainId, userPendingOrderIds?.length);
    if (initialTradesLoading == 1) return;
    if (!chainId) return;
    if (!userPendingOrderIds || userPendingOrderIds.length == 0) {
      setPendingOrders([]);
      return;
    }
    let auxPendingOrders = {};

    if (userPendingOrderIds.length > 0 && farmTradingStorageV5Contract) {
      for (let i = 0; i < userPendingOrderIds.length; i++) {
        const orderId = userPendingOrderIds[i];
        const { trade, block, wantedPrice, slippageP, spreadReductionP, tokenId } =
          await farmTradingStorageV5Contract.reqID_pendingMarketOrder(orderId);
        const { index, pairIndex, leverage, initialPosToken, openPrice, positionSizeDai, buy, sl, tp, trader } = trade;

        // The order is not ours or is 0x00000000 address
        if (trader != account) continue;

        const intPairIndex = parseInt(pairIndex);
        const intIndex = parseInt(index);

        //const timestamp = (await provider.getBlock(parseInt(block))).timestamp * 1000;
        const blockNumber = parseInt(block);
        const timestamp = await getBlockTimestamp(blockNumber);

        if (leverage > 0) {
          const newPendingOrder = {
            orderId: parseInt(orderId),
            block: blockNumber,
            pairIndex: intPairIndex,
            index: intIndex,
            pairName: getPairName(intPairIndex),
            open: leverage > 0,
            status: getNewStatus(orderId),
            timestamp: timestamp,
          };

          IS_PROD ||
            console.log(`@@@ STUCK_WHEN_OPENING [orderId:${orderId}]`, intPairIndex, parseInt(index), newPendingOrder);
          auxPendingOrders[orderId] = newPendingOrder;
        } else {
          IS_PROD ||
            console.log(
              "@@@ orderStatus[orderId]",
              intPairIndex,
              intIndex,
              parseInt(orderId),
              orderStatus[orderId],
              shouldBeConsideredAsStuck(orderStatus[orderId])
            );
          //if (!shouldBeConsideredAsStuck(orderStatus[orderId])) continue;
          let oldPos;
          try {
            oldPos = ourPositions[pairIndex][intIndex];
          } catch (error) {
            oldPos = undefined;
          }

          if (oldPos) {
            setPositionValues(intPairIndex, intIndex, {
              timestamp: timestamp,
              status: POS_STATUS_STUCK,
              orderId: parseInt(orderId),
              block: blockNumber,
              pairName: getPairName(intPairIndex),
            });

            IS_PROD || console.log(`@@@ STUCK_WHEN_CLOSING [orderId:${orderId}]`, intPairIndex, parseInt(index));
          } else {
            const newPendingOrder = {
              orderId: parseInt(orderId),
              block: blockNumber,
              pairIndex: intPairIndex,
              pairName: getPairName(intPairIndex),
              open: false,
              status: POS_STATUS_ORPHAN_CLOSING_STUCK,
              timestamp: timestamp,
            };
            IS_PROD ||
              console.log(
                `@@@ STUCK_WHEN_CLOSING Orphan-Closing-Stuck!!!! [orderId:${orderId}]`,
                intPairIndex,
                parseInt(index),
                newPendingOrder
              );
            auxPendingOrders[orderId] = newPendingOrder;
          }
        }
      }

      setPendingOrders(auxPendingOrders);
    }
  };

  useEffect(() => {
    processPendingOrders(userPendingOrderIds);
  }, [userPendingOrderIds]);

  useEffect(() => {
    if (initialTradesLoading == 0) processPendingOrders(userPendingOrderIds);
  }, [initialTradesLoading]);

  useEffect(() => {
    if (!eventsRelayerEvent) return;
    if (
      eventsRelayerEvent.event == "MarketExecuted" ||
      eventsRelayerEvent.event == "LimitExecutedEvent" ||
      eventsRelayerEvent.event == "LimitExecuted"
    ) {
      IS_PROD || console.log("@@@ usePendingOrders.handleEventRelayer", eventsRelayerEvent.event);
      // wait a little more for the order to be processed at Shardeum or slow chains
      setTimeout(() => {
        processPendingOrders(userPendingOrderIds);
      }, 3000);
    }
  }, [eventsRelayerEvent]);

  return { pendingOrders };
}

import "./Vault.css";
import { t, Trans } from "@lingui/macro";
import { useEffect, useState } from "react";
import useSWR from "swr";

import { useWeb3React } from "@web3-react/core";

import { ethers } from "ethers";

import { dUSDC, USDC, OpenPnlFeed } from "config/contracts";

import { callContract, contractFetcher } from "lib/contracts";
import { useContracts } from "hooks/useContracts";

import { USDG_DECIMALS } from "lib/legacy";
import { formatAmount } from "lib/numbers";
import { epochSecondsToDateString, SECONDS_PER_HOUR, formatSecondsToDaysString, formatDateTime } from "lib/dates";

import { CancelWithDrawModal } from "components/Vault/VaultModals";
import { helperToast } from "lib/helperToast";

import Tooltip from "../Tooltip/Tooltip";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { IS_PROD } from "config/context";

const SECONDS_48H = 48 * SECONDS_PER_HOUR;
const SECONDS_72H = 72 * SECONDS_PER_HOUR;

export default function VaultWithdraw(props) {
  const { chainId, setPendingTxns, account, vaultData } = props;

  const {
    currentEpoch,
    currentEpochStart,
    currentEpochStartFmt,
    currentEpochWithdrawRequestWindow,
    elapsedEpochTime,
    dBUSDPriceFmt,
    dBUSDPrice,
    dBUSDUserBalance,
    dBUSDUserBalanceFmt,
  } = vaultData;

  const { contractInfo } = useContracts({ chainId });

  const [fromValue, setFromValue] = useState("");
  const [pendingRequests, setPendingRequests] = useState([]);
  const [isRequestingWithdrawal, setIsRequestingWithdrawal] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const [isCancelWithdrawModalVisible, setIsCancelWithdrawModalVisible] = useState(false);
  const [cancelWithdrawModalTitle, setCancelWithdrawModalTitle] = useState("");
  const [cancelWithdrawModalMaxAmount, setCancelWithdrawModalMaxAmount] = useState(undefined);
  const [cancelWithdrawValue, setCancelWithdrawValue] = useState("");
  const [cancelWithdrawEpoch, setCancelWithdrawEpoch] = useState(undefined);
  // Web3
  const { active, library } = useWeb3React();

  //console.log("### VaultWithdraw", epochData);
  //const [totalSharesBeingWithdrawn, setTotalSharesBeingWithdrawn] = useState(0);

  const getCurrentWithdrawalRequests = async (...args) => {
    const pendingRequestsAux = [];
    const cE = parseInt(currentEpoch);
    console.log("getCurrentWithdrawalRequests  " + currentEpoch);
    const contract = contractInfo[dUSDC].contract;
    for (let epoch = cE + 1; epoch < cE + 4; epoch++) {
      await contract
        .withdrawRequests(account, epoch)
        .then((shares) => {
          const nextEpochStart = parseInt(currentEpochStart) + (epoch - cE) * SECONDS_72H;

          if (parseInt(shares) > 0) {
            pendingRequestsAux.push({ epoch: epoch, shares: shares, epochStart: nextEpochStart });
          }
        })
        .catch((response) => {
          console.log("errormsg pairsBackend: " + response);
        });
    }
    setPendingRequests(pendingRequestsAux);
  };

  useEffect(() => {
    if (account) {
      const contract = contractInfo[dUSDC].contract;
      getCurrentWithdrawalRequests();
      contract.on("WithdrawCanceled", getCurrentWithdrawalRequests);
      contract.on("WithdrawRequested", getCurrentWithdrawalRequests);
    }
    return function cleanup() {
      const contract = contractInfo[dUSDC].contract;
      contract.off("WithdrawRequested", getCurrentWithdrawalRequests);
      contract.off("WithdrawCanceled", getCurrentWithdrawalRequests);
    };
  }, [account, currentEpoch]);

  const { data: withdrawEpochsTimelock } = useSWR(
    active && [
      `Vault:withdrawEpochsTimelock:${active}`,
      chainId,
      contractInfo[dUSDC].address,
      "withdrawEpochsTimelock",
    ],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  const { data: totalSharesBeingWithdrawn } = useSWR(
    active && [
      `Vault:totalSharesBeingWithdrawn:${active}`,
      chainId,
      contractInfo[dUSDC].address,
      "totalSharesBeingWithdrawn",
      account,
    ],
    {
      fetcher: contractFetcher(library, contractInfo[dUSDC].abiJson),
    }
  );
  //console.log("totalSharesBeingWithdrawn   " + totalSharesBeingWithdrawn + "  " + active);

  const { data: nextEpochValuesRequestCount } = useSWR(
    active && [
      `Vault:nextEpochValuesRequestCount:${active}`,
      chainId,
      contractInfo[OpenPnlFeed].address,
      "nextEpochValuesRequestCount",
    ],
    {
      fetcher: contractFetcher(library, contractInfo[OpenPnlFeed].abiJson),
    }
  );
  //console.log("nextEpochValuesRequestCount   " + nextEpochValuesRequestCount + "  " + active);

  const onFromValueChange = (e) => {
    if (!e.target.value) {
      setFromValue(e.target.value);
      return;
    }
    const _fromValue = e.target.value ? e.target.value : 0.0;
    setFromValue(_fromValue);
    //console.log("FROMVALUE " + _fromValue);
  };

  const onClickPrimary = () => {
    const fromValueInWei = ethers.utils.parseUnits(fromValue.toString(), USDG_DECIMALS);

    const lessThanBalance =
      parseInt(totalSharesBeingWithdrawn) + parseInt(fromValueInWei) <= parseInt(dBUSDUserBalance);

    if (lessThanBalance) {
      setIsRequestingWithdrawal(true);
      const contract = contractInfo[dUSDC].contract;
      callContract(chainId, contract, "makeWithdrawRequest", [fromValueInWei, account], {
        sentMsg: t`Request Withdrawal submitted!`,
        failMsg: t`Request Withdrawal failed.`,
        setPendingTxns,
      })
        .then(async (res) => {
          IS_PROD || console.log("Request Withdrawal completing");
          await res.wait(1);
          IS_PROD || console.log("Request Withdrawal successful");
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsRequestingWithdrawal(false);
        });

      //console.log("Click");
    } else {
      helperToast.error(t`MORE_THAN_BALANCE`);
      console.error("MORE_THAN_BALANCE");
    }
  };
  const onClickMax = () => {
    setFromValue(
      formatAmount(dBUSDUserBalance, USDG_DECIMALS, 2, false) -
      formatAmount(totalSharesBeingWithdrawn, USDG_DECIMALS, 2, false)
    );
  };

  const getPrimaryText = () => {
    if (isRequestingWithdrawal) {
      return t`REQUESTING...`;
    }
    return t`REQUEST`;
  };

  const withdrawalCanBeRequested = () => {
    const isWithinFirst48h = elapsedEpochTime < SECONDS_48H;
    const nextEpochValuesRequestCountIzZero = nextEpochValuesRequestCount == 0;
    return isWithinFirst48h && nextEpochValuesRequestCountIzZero;
  };

  const isWithdrawalRequestEnabled = () => {
    if (fromValue <= 0.0) return false;
    if (withdrawalCanBeRequested()) return true;
    return false;
  };

  const isWithdrawEnabled = () => {
    const now = Date.now() / 1000.0;
    const withdrawalEpochStart = parseInt(currentEpochStart) + withdrawEpochsTimelock * SECONDS_72H;
    const withdrawalWindowCloseTime = withdrawalEpochStart + SECONDS_48H;
    const enabled = withdrawalEpochStart < now && now < withdrawalWindowCloseTime;
    /*IS_PROD ||
      console.log(
        "START",
        formatDateTime(withdrawalEpochStart),
        "NOW",
        formatDateTime(now),
        "END",
        formatDateTime(withdrawalWindowCloseTime)
      );*/

    return enabled;
  };

  const onClickWithdraw = (epochData) => {
    console.log("onClickWithdraw " + epochData.shares);

    const sheresInWei = ethers.utils.parseUnits(epochData.shares.toString(), USDG_DECIMALS);

    setIsWithdrawing(true);
    const contract = contractInfo[dUSDC].contract;
    callContract(chainId, contract, "redeem", [sheresInWei, account, account], {
      sentMsg: t` Withdrawal submitted!`,
      failMsg: t` Withdrawal failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        //console.log(" Withdrawal completing");
        await res.wait(1);
        IS_PROD || console.log(" Withdrawal successful");
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };

  const onClickCancelWithdraw = (epochData) => {
    setIsCancelWithdrawModalVisible(true);
    setCancelWithdrawModalTitle(t`Cancel Withdraw for epoch ` + `${epochData.epoch}`);
    setCancelWithdrawModalMaxAmount(epochData.shares);
    setCancelWithdrawEpoch(epochData.epoch);
    setCancelWithdrawValue("");
  };

  const getWithDrawText = (epochData) => {
    if (isWithdrawEnabled()) return t`WITHDRAW`;
    if (isWithdrawing) return t`WITHDRAWING...`;
    //return `${epochSecondsToDateString(epochData.epochStart)}`;
    return t`WAIT FOR WITHDRAW`;
  };

  const getWithdrawalRequests = () => {
    return (
      <div>
        {pendingRequests.map((epochData) => {
          return (
            <div key={epochData.epoch} className="App-card-row">
              <div className="muted">
                <strong>Amount (@{parseInt(epochData.epoch)}):</strong>{" "}
                {formatAmount(epochData.shares, USDG_DECIMALS, 2, false)}
                {"USDT"}
              </div>
              <div className="muted align-right">
                <Tooltip
                  handle={
                    <button
                      onClick={() => {
                        onClickWithdraw(epochData);
                      }}
                      disabled={!isWithdrawEnabled()}
                    >
                      {getWithDrawText(epochData)}
                    </button>
                  }
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div>
                        <StatsTooltipRow
                          label={t`Withdrawal Start Time`}
                          value={[
                            `${formatDateTime(parseInt(currentEpochStart) + withdrawEpochsTimelock * SECONDS_72H)}`,
                          ]}
                        />
                        <StatsTooltipRow
                          label={t`Withdrawal End Time`}
                          value={[
                            `${formatDateTime(
                              parseInt(currentEpochStart) + withdrawEpochsTimelock * SECONDS_72H + SECONDS_48H
                            )}`,
                          ]}
                        />
                      </div>
                    );
                  }}
                />
                <button
                  onClick={() => {
                    onClickCancelWithdraw(epochData);
                  }}
                  disabled={false}
                >
                  {t`CANCEL`}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="withdraw-container">
      <div className="withdraw-part1" style={{ width: "50%", fontSize: "14px", color: "#808080", padding: "40px" }}>
        <div>
          Withdraws follow an epoch system of 72 hours long. You can make a request to withdraw your assets during the first 48 hours of any epoch, but you must wait until a specific withdraw epoch to actually withdraw them. <br /> <br />

          Depending on the collateralization ratio of the vault, your withdraw epoch will be between 1 and 3 epochs later. <br /> <br />

          You must withdraw your assets in the first 48 hours of your withdraw epoch, otherwise a new request is required. <br /> <br />
        </div>
        <div className="withdraw-part1-info">
          <div className="">
            <strong>Remaining time to request withdraw:</strong>
          </div>
          <div className="">
            {withdrawalCanBeRequested() ? (
              formatSecondsToDaysString(currentEpochWithdrawRequestWindow)
            ) : (
              <div>ENDED</div>
            )}
          </div>
        </div>
      </div>

      <div className="withdraw-part2" style={{ width: "50%" }}>
        <div className="">
          <div className="">
            <div className="" style={{ color: "#808080", marginBottom: "-10px" }}>
              <p>Wait Period: <span style={{ color: "#DEDEDE" }}>{parseInt(withdrawEpochsTimelock)} epochs</span></p>
            </div>
            <div className="" style={{ color: "#808080" }}>
              <p>Withdraw Date: <span style={{ color: "#DEDEDE" }}>{formatDateTime(parseInt(currentEpochStart) + withdrawEpochsTimelock * SECONDS_72H)}</span></p>
            </div>
          </div>
          <div className="">
            <div>
              <input
                disabled={!withdrawalCanBeRequested()}
                type="number"
                min="10"
                max="10000000"
                placeholder="0.0 USDT"
                className=""
                id="withDrawInput"
                value={fromValue}
                onChange={onFromValueChange}
              />
            </div>
            {/* <div>{tokenInfo[dUSDC].label}</div> */}
          </div>
        </div>
        <div
          className="dialog-withdraw"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "10px",
          }}
        >
          <div style={{ width: "50%" }}>
            <div className="text-withdraw" style={{ color: "#808080", marginBottom: "20px", fontSize: "16px" }}>
              1 {"dUSDT"} ={" "}
              <span className="normal-text" style={{ color: "#808080", fontSize: "16px" }}>
                {dBUSDPriceFmt} {"USDT"}
              </span>
            </div>
          </div>

          <div className="" style={{ textAlign: "center", width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <button className="button-end-vault button-withdraw" onClick={onClickPrimary} disabled={!isWithdrawalRequestEnabled()}>
              {getPrimaryText()}
            </button>
            <div style={{ marginTop: "10px" }} className="App-card-title"><span style={{ color: "#808080", paddingRight: "5px", fontSize: "1.4rem" }}>REQUESTS IN QUEUE</span>{" "}({pendingRequests.length})</div>
          </div>
        </div>

        {getWithdrawalRequests()}
        <CancelWithDrawModal
          isVisible={isCancelWithdrawModalVisible}
          setIsVisible={setIsCancelWithdrawModalVisible}
          chainId={chainId}
          title={cancelWithdrawModalTitle}
          maxAmount={cancelWithdrawModalMaxAmount}
          value={cancelWithdrawValue}
          setValue={setCancelWithdrawValue}
          account={account}
          library={library}
          epoch={cancelWithdrawEpoch}
          contractInfo={contractInfo[dUSDC]}
          cancelWithdrawMethodName="cancelWithdrawRequest"
          setPendingTxns={setPendingTxns}
        />
      </div>
    </div>
  );
}

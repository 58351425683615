import cx from "classnames";
import { useContext, useEffect, useState } from "react";
import { POS_STATUS_NORMAL, isPositionStillInProcessingTimeframe } from "./PositionsLogic";
import { Multicall } from "@uniswap/v3-sdk";
import { POS_STATUS_ORPHAN_CLOSING_STUCK, POS_STATUS_STUCK, POS_STATUS_SURE_STUCK } from "./PositionsLogic";
import AppContext from "AppContext";

export function PendingPosition(props) {
  const { chainId, orderId, pendingPosition, batchData, closeStuckPosition, setOurSelectedToken, onSelectToken } =
    props;
  const [color, setColor] = useState("");
  const [statusText, setStatusText] = useState("");
  const [warningText, setWarningText] = useState("");

  const { pares, pairs, setPairs, cleanPairs, setCleanPairs, setupPricesWebsocket, latestPrices, market24 } =
    useContext(AppContext);

  const getStatusText = () => {
    if (pendingPosition.status === POS_STATUS_STUCK) {
      setStatusText("STUCK");
      setColor("negative");
      setWarningText("THIS OPENING ATTEMPT IS STUCK. PLEASE CANCEL IT MANUALLY TO RECOVER YOUR FUNDS.");
    } else if (pendingPosition.status === POS_STATUS_ORPHAN_CLOSING_STUCK) {
      setStatusText("STUCK");
      setColor("negative");
      setWarningText("THIS CLOSING ATTEMPT IS STUCK, PLEASE CANCEL IT MANUALLY...");
    } else {
      setStatusText("STUCK");
      setColor("negative");
      setWarningText("THIS TRADE ATTEMPT IS STUCK. ⚠️");
    }
  };

  useEffect(() => {
    getStatusText();
  }, [pendingPosition]);

  let pairName = pendingPosition.pairName;

  if (
    pendingPosition.status !== POS_STATUS_SURE_STUCK &&
    isPositionStillInProcessingTimeframe(pendingPosition, chainId, batchData)
  )
    return null;

  const [token1, token2] = pendingPosition.pairName.split("/");
  const image = require(`../../img/tokenlogo/${token1}${token2}.svg`).default;
  return (
    <div key={orderId}>
      <div
        id="PositionsList"
        className="PendingPositionList"
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "12px",
          padding: "5px 0px",
          height: "40px",
          minHeight: "40px",
          maxHeight: "40px",
        }}
      >
        <div className="element-position-stuck position-element-9">
          <span className={cx("Exchange-list-side negative")}>{statusText}</span>
        </div>

        <div className="hide position-element-9" style={{ marginLeft: "-30px" }}>
          <span className="negative">#{orderId}</span>
        </div>

        <div
          style={{
            textAlign: "center",
            width: "8%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-around",
          }}
          className="element-par-img"
          onClick={() => {
            let pairName = pendingPosition.pairName;
            let par = cleanPairs.find((p) => p[0] == pairName);
            let pairSymbols = par[0];

            const pairIndex = cleanPairs.find((i) => {
              return i[0] === pairName;
            });

            const precioPair = cleanPairs.find((i) => {
              return i[0] === pairName;
            });

            const symbol = pairSymbols.split("/")[0];

            const token = {
              pairSymbols: pairSymbols,
              symbol: symbol,
              pairName: par[7],
              pairIndex: pairIndex[1],
              price: precioPair[4],
              marketStatus: precioPair[5],
              pairFullName: precioPair[0],
            };

            setOurSelectedToken(token);
            onSelectToken(token);
          }}
        >
          <div style={{ width: "15%" }}>
            <img src={image} width="100%" />
          </div>
          <div style={{ width: "85%" }}>
            <span>{pendingPosition.pairName}</span>
          </div>
        </div>

        <div
          style={{
            width: "64%",
            textAlign: "center",
            fontStyle: "italic",
            marginTop: "2px",
          }}
          className="element-position text-stuck-regular"
        >
          <span>
            <span className="normal-text">⚠️</span> {warningText} <span className="normal-text">⚠️</span>
          </span>
        </div>

        <div id="actions" className="element-position position-element-9">
          <div className="App-card-buttons" id="actions-buttons">
            <button
              disabled={batchData <= pendingPosition.block}
              onClick={() => {
                closeStuckPosition(pendingPosition);
              }}
              className={`${batchData <= pendingPosition.block ? "gray" : "white"}`}
              style={{
                border: "none",
                background: "none",
                color: "white",
                fontSize: "18px",
                cursor: "pointer",
                marginTop: "-2px",
                padding: "0px",
              }}
            >
              <i
                style={{ marginLeft: "30px" }}
                className={`${
                  batchData <= pendingPosition.block ? "#333" : "#808080"
                } fa-solid fa-xmark closeTradeButton`}
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function PendingPositions(props) {
  const { onSelectToken, pendingOrders, chainId, batchData, closeStuckPosition, setOurSelectedToken } = props;

  return (
    Object.keys(pendingOrders).length > 0 &&
    Object.keys(pendingOrders).map((orderId) => {
      return (
        <PendingPosition
          onSelectToken={onSelectToken}
          chainId={chainId}
          orderId={orderId}
          pendingPosition={pendingOrders[orderId]}
          batchData={batchData}
          closeStuckPosition={closeStuckPosition}
          setOurSelectedToken={setOurSelectedToken}
        />
      );
    })
  );
}

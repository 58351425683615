import React, { useEffect, useState } from "react";
import "./TradingCompetition.css";
import { TradingCompetitorUserStatic } from "components/TradingCompetition/TradingCompetitorUserStatic";
import useSWR from "swr";

export const Round2 = () => {
  const dataLeaderboard = [
    {
      position: "🏆",
      address: "0x5A55....0E0814",
      rank: 28,
      percentage: "28.6%",
      balance: "108547.73",
    },
    {
      position: "🥈",
      address: "0x5477....9D195a",
      rank: 1,
      percentage: "100.0%",
      balance: "89620.68",
    },
    {
      position: "🥉",
      address: "0x25CC....F47B9f",
      rank: 3,
      percentage: "100.0%",
      balance: "49031.06",
    },
    {
      position: "4",
      address: "0x3960....dE5271",
      rank: 11,
      percentage: "63.6%",
      balance: "40610.36",
    },
    {
      position: "5",
      address: "0x26D5....5ca348",
      rank: 3,
      percentage: "100.0%",
      balance: "17338.36",
    },
    {
      position: "6",
      address: "0x945F....65Cc8e",
      rank: 1,
      percentage: "100.0%",
      balance: "4327.55",
    },
    {
      position: "7",
      address: "0x26DB....f010c1",
      rank: 13,
      percentage: "76.9%",
      balance: "2572.60",
    },
    {
      position: "8",
      address: "0x68d2....3c4b11",
      rank: 1,
      percentage: "100.0%",
      balance: "2488.10",
    },
    {
      position: "9",
      address: "0x2B78....6A27e1",
      rank: 8,
      percentage: "50.0%",
      balance: "2264.61",
    },
    {
      position: "10",
      address: "0xb901....844EEe",
      rank: 1,
      percentage: "100.0%",
      balance: "25.51",
    },
    {
      position: "11",
      address: "0x59a2....638106",
      rank: 1,
      percentage: "0.0%",
      balance: "-26.62",
    },
    {
      position: "12",
      address: "0x40E1....E960E3",
      rank: 5,
      percentage: "20.0%",
      balance: "-29.69",
    },
    {
      position: "13",
      address: "0x605f....2c2636",
      rank: 3,
      percentage: "33.3%",
      balance: "-79.94",
    },
    {
      position: "14",
      address: "0x9B56....D4B107",
      rank: 8,
      percentage: "75.0%",
      balance: "-652.27",
    },
    {
      position: "15",
      address: "0xC246....E12772",
      rank: 40,
      percentage: "45.0%",
      balance: "-714.45",
    },
    {
      position: "16",
      address: "0x34c7....067a3a",
      rank: 7,
      percentage: "57.1%",
      balance: "-1426.08",
    },
    {
      position: "17",
      address: "0xF043....05dF2e",
      rank: 5,
      percentage: "20.0%",
      balance: "-2503.49",
    },
    {
      position: "18",
      address: "0x2Bb5....B0e404",
      rank: 2,
      percentage: "50.0%",
      balance: "-9616.11",
    },
    {
      position: "19",
      address: "0x5710....b51400",
      rank: 10,
      percentage: "30.0%",
      balance: "-12735.33",
    },
    {
      position: "20",
      address: "0x32702....0cE960",
      rank: 24,
      percentage: "29.2%",
      balance: "-16157.34",
    },
    {
      position: "21",
      address: "0x8C97....4Bf82e",
      rank: 5,
      percentage: "0.0%",
      balance: "-18521.68",
    },
    {
      position: "22",
      address: "0x5fDc....8a0AAD",
      rank: 3,
      percentage: "0.0%",
      balance: "-23700.00",
    },
    {
      position: "23",
      address: "0x50C7....d72E51",
      rank: 27,
      percentage: "44.4%",
      balance: "-29675.06",
    },
    {
      position: "24",
      address: "0x070E....f616d6",
      rank: 12,
      percentage: "41.7%",
      balance: "-40251.33",
    },
    {
      position: "25",
      address: "0x9978....630428",
      rank: 9,
      percentage: "0.0%",
      balance: "-74739.71",
    },
    {
      position: "26",
      address: "0x346f....A137F3",
      rank: 5,
      percentage: "40.0%",
      balance: "-81039.00",
    },
    {
      position: "27",
      address: "0x2898....7dC7f6",
      rank: 22,
      percentage: "31.8%",
      balance: "-95293.46",
    },
  ];

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
      <div className="TradingTitle">
        <h1 className="orange-title">🏆 TRADING CONTEST LEADERBOARD - ROUND 2 🏆</h1>
      </div>

      <div className="TradingButtons">
        <p style={{ fontSize: "18px" }}>From December 14, 2023, at 10:00 UTC to December 28, 2023, at 10:00 UTC</p>
      </div>

      <div className="TradingCompetition">
        <div className="TradingCompetitionHeader">
          <div className="competition-info">Rank</div>
          <div className="competition-info">Address</div>
          <div className="competition-info">Trades</div>
          <div className="competition-info">Winrate</div>
          <div className="competition-info">PnL</div>
        </div>
      </div>

      {Array.isArray(dataLeaderboard)
        ? dataLeaderboard.map((tradingCompetitor, index) => {
            return <TradingCompetitorUserStatic index={index + 1} tradingCompetitor={tradingCompetitor} key={index} />;
          })
        : ""}
    </div>
  );
};

import { useEffect, useState } from "react";

export function usePairPrices(pairs, cleanPairs, latestPrices, market24) {
  useEffect(() => {
    cleanPairs.forEach((par) => {
      const pairIndex = par[1];
      if (latestPrices[pairIndex]) {
        if (par[4] != latestPrices[pairIndex]) {
          par[6] = par[4];
        }
        par[4] = latestPrices[pairIndex];
        par[5] = market24[pairIndex];
      }
    });

    pairs.forEach((par) => {
      const pairIndex = par[1];
      if (latestPrices[pairIndex]) {
        if (par[4] != latestPrices[pairIndex]) {
          par[6] = par[4];
        }

        par[4] = latestPrices[pairIndex];
        par[5] = market24[pairIndex];
      }
    });
  }, [latestPrices]);
}

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
import Tooltip from "components/Tooltip/Tooltip";
import { formatDecimals } from "lib/numbers";
import { DropdownButton, Dropdown } from "react-bootstrap";

import cx from "classnames";

import { USD_DECIMALS, USDG_DECIMALS, TRADES_PAGE_SIZE } from "lib/legacy";
import { ZETACHAIN_TESTNET } from "config/chains";

import "./TradeHistory.css";
import { formatDateTime } from "lib/dates";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { select, t, Trans } from "@lingui/macro";
import TradeHistoryHeader from "./TradeHistoryHeader";

export default function TradeHistory(props) {
  const {
    account,
    infoTokens,
    getTokenInfo,
    chainId,
    nativeTokenAddress,
    shouldShowPaginationButtons,
    pares,
    isFetchingTrades,
    numberOfDaysRef,
    setNumberOfDays,
    tradesWithMessages,
  } = props;

  const [pageIds, setPageIds] = useState({});
  const [pageIndex, setPageIndex] = useState(0);

  const handleValueSelect = (value) => {
    console.log("valor", value);
    let valor = parseInt(value);
    setNumberOfDays(valor);
  };

  const loadNextPage = () => {
    if (!tradesWithMessages || tradesWithMessages.length === 0) {
      return;
    }

    const lastTrade = tradesWithMessages[tradesWithMessages.length - 1];
    pageIds[pageIndex + 1] = lastTrade.id;
    setPageIds(pageIds);
    setPageIndex(pageIndex + 1);
  };

  const loadPrevPage = () => {
    setPageIndex(pageIndex - 1);
  };

  useEffect(() => {
    setPageIndex(0);
  }, [tradesWithMessages]);

  return (
    <div className="TradeHistory">
      {isFetchingTrades != 1 && (
        <>
          <div style={{ display: "flex", marginBottom: "10px", marginTop: "-35px" }}>
            <DropdownButton
              title={`Days: ${numberOfDaysRef.current}`}
              style={{ zIndex: 1, marginRight: "10px" }}
              onSelect={handleValueSelect}
              defaultValue={props.defaultValue || "Select"}
            >
              <Dropdown.Item style={{ color: "white" }} eventKey="1">
                1
              </Dropdown.Item>
              <Dropdown.Item style={{ color: "white" }} eventKey="3">
                3
              </Dropdown.Item>
              <Dropdown.Item style={{ color: "white" }} eventKey="7">
                7
              </Dropdown.Item>
              <Dropdown.Item style={{ color: "white" }} eventKey="30">
                30
              </Dropdown.Item>
              <Dropdown.Item style={{ color: "white" }} eventKey="60">
                60
              </Dropdown.Item>
              <Dropdown.Item style={{ color: "white" }} eventKey="90">
                90
              </Dropdown.Item>
            </DropdownButton>
          </div>
          {chainId === ZETACHAIN_TESTNET && (
            <span style={{ color: "yellow" }}>* Only last 3 hours are available due to Zeta chain RPC limitations</span>
          )}
        </>
      )}

      {tradesWithMessages.length === 0 && isFetchingTrades === 1 && (
        <div className="TradeHistory-row App-box">
          <Trans>...</Trans>
        </div>
      )}
      {tradesWithMessages.length === 0 && isFetchingTrades === 0 && (
        <div className="TradeHistory-row App-box">
          <Trans>No trades yet</Trans>
        </div>
      )}
      {tradesWithMessages.length > 0 && <TradeHistoryHeader />}
      {tradesWithMessages.length > 0 &&
        tradesWithMessages

          .filter((trade, index) => {
            return parseInt(index / TRADES_PAGE_SIZE) === pageIndex;
          })
          .map((trade, index) => {
            let pairName = pares.find((el) => el[1] == trade.pairIndex)[0];
            let type;
            let fechaFormateada;

            if (trade.timestamp) {
              let fecha = new Date(trade.timestamp * 1000);
              fechaFormateada = fecha
                .toLocaleString("es-ES", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
                .replace(",", "");
            }

            if (trade.event == "MarketExecuted") {
              type = "Market";
            } else if (trade.event == "LimitExecuted") {
              if (trade.orderType == "0") {
                type = "Take Profit";
              } else if (trade.orderType == "1") {
                type = "Stop Loss";
              } else if (trade.orderType == "2") {
                type = "Liquidation";
              } else if (trade.orderType == "3") {
                type = "Limit";
              }
            }
            const collateral = (trade.positionSizeDai / 1e18).toFixed(2);
            const profitP = trade.percentProfit;
            const pnl = ((trade.positionSizeDai / 100) * profitP) / 1e10 / 1e18;
            // Assuming pnl and collateral are defined and numeric
            const adjustedPnl = pnl > 0 ? pnl : Math.max(-collateral, pnl);

            let parts = pairName.split("/");
            const image = require(`../../img/tokenlogo/${parts[0]}${parts[1]}.svg`).default;

            return (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "13px",
                    height: "40px",
                    minHeight: "40px",
                    maxHeight: "40px",
                    padding: "5px 0px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ color: "#808080", textAlign: "center", width: "7.6%", cursor: "pointer" }}>
                    <span>{fechaFormateada}</span>
                  </div>
                  <div style={{ color: "#808080", textAlign: "center", width: "7.6%", cursor: "pointer" }}>
                    <span>{type}</span>
                  </div>
                  <div style={{ color: "#808080", textAlign: "center", width: "7.6%" }}>
                    <span
                      style={{ textTransform: "uppercase" }}
                      className={cx("Exchange-list-side", {
                        positive: trade.buy,
                        negative: !trade.buy,
                      })}
                    >
                      {trade.buy ? t`Long` : t`Short`}
                    </span>
                  </div>
                  <div
                    style={{
                      color: "#808080",
                      textAlign: "center",
                      width: "7.6%",
                      cursor: "pointer",
                    }}
                  >
                    <img src={image} width="15%" style={{ marginRight: "0px" }} />
                    <span style={{ color: "#808080", width: "80%", marginLeft: "10px" }}>{pairName}</span>
                  </div>

                  <div style={{ color: "#808080", textAlign: "center", width: "7.6%" }}>
                    <span>{(trade.leverage * (trade.positionSizeDai / 1e18)).toFixed(2)}$</span>
                  </div>

                  <div style={{ color: "#808080", textAlign: "center", width: "7.6%" }}>
                    <span>{collateral}$</span>
                  </div>

                  <div style={{ color: "#808080", textAlign: "center", width: "7.6%" }}>
                    <span>{trade.leverage}x</span>
                  </div>

                  <div style={{ color: "#808080", textAlign: "center", width: "7.6%" }}>
                    <span>{(trade.price / 1e10).toFixed(2)}</span>
                  </div>

                  <div style={{ color: "#808080", textAlign: "center", width: "7.6%" }}>
                    <span>{(trade.openPrice / 1e10).toFixed(2)}</span>
                  </div>

                  <div style={{ color: "#808080", textAlign: "center", width: "7.6%" }}>
                    <span>{formatDecimals(trade.tp)}</span>
                  </div>

                  <div style={{ color: "#808080", textAlign: "center", width: "7.6%" }}>
                    <span>{trade.sl == 0 ? "X" : formatDecimals(trade.sl)}</span>
                  </div>

                  <div style={{ color: "#808080", textAlign: "center", width: "7.6%" }}>
                    <span>{trade.orderType == 2 ? -100 : Math.max((profitP / 1e10).toFixed(2), -100)}%</span>
                  </div>

                  <div style={{ color: "#808080", textAlign: "center", width: "7.6%" }}>
                    <span>{adjustedPnl.toFixed(2)}$</span>
                  </div>
                </div>
              </div>
            );
          })}
      {shouldShowPaginationButtons && (
        <div style={{ display: "flex" }}>
          {pageIndex > 0 && (
            <button className="prev-button" onClick={loadPrevPage}>
              <Trans> &lt; </Trans>
            </button>
          )}
          <span className="text-button" style={{ width: "80px", marginLeft: "0px" }}>
            <Trans>Page </Trans> {pageIndex + 1} / {parseInt((tradesWithMessages.length - 1) / TRADES_PAGE_SIZE) + 1}
          </span>
          {tradesWithMessages && pageIndex < parseInt((tradesWithMessages.length - 1) / TRADES_PAGE_SIZE) && (
            <button className="next-button" onClick={loadNextPage}>
              <Trans> &gt; </Trans>
            </button>
          )}
        </div>
      )}
    </div>
  );
}

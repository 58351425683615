import React, { useEffect, useState } from "react";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import _ from "lodash";
import { helperToast } from "lib/helperToast";
import { IS_PROD } from "config/context";

export function useOrders({ pares }) {
  const [orders, setOrders] = useState({});
  const [ordersLoading, setOrdersLoading] = useState(false);

  const { chainId } = useChainId();
  const { active, account, library } = useWeb3React();

  const toastEventMessage = (event, isInitialLoad = false) => {
    if (isInitialLoad || !account) return;

    const pairIndex = event.pairIndex;
    const pair = pares.find((p) => p[1] == pairIndex);
    const toastMessage = (
      <div>
        Orders: {event.event} for pair {pair[0]}
      </div>
    );

    helperToast.success(toastMessage);
  };
  const cancelOurSpecificOrderByEvent = async (event) => {
    setOrdersLoading(1);
    const newOrders = _.cloneDeep(orders);
    const pairIndex = event.pairIndex;
    const tradeIndex = event.index;
    console.log("@@@ useOrders.cancelOurSpecificOrderByEvent", pairIndex, tradeIndex, event);
    newOrders[pairIndex] && delete newOrders[pairIndex][tradeIndex];
    newOrders[pairIndex] = newOrders[pairIndex] || {};
    setOrders(newOrders);
    setOrdersLoading(0);
  };

  const deleteOurSpecificOrderByEvent = async (event) => {
    setOrdersLoading(1);
    const newOrders = _.cloneDeep(orders);
    const { pairIndex, tradeIndex, tp, sl, buy } = event;
    if (newOrders[pairIndex]) {
      const newOrdersPairIndex = newOrders[pairIndex];
      for (const [key, value] of Object.entries(newOrdersPairIndex)) {
        const { tp: _tp, sl: _sl, buy: _buy } = value;
        console.log(key, _tp, tp, _sl, sl, _buy, buy);
        if (_tp === tp && _sl === sl && _buy === buy) {
          delete newOrdersPairIndex[key];
        }
      }
    }
    newOrders[pairIndex] = newOrders[pairIndex] || {};
    setOrders(newOrders);
    setOrdersLoading(0);
  };
  const getOurSpecificOrderByEvent = async (event) => {
    //setOrdersLoading(1);
    const newOrders = _.cloneDeep(orders);
    const pairIndex = event.pairIndex;
    const tradeIndex = event.index;
    newOrders[pairIndex] && delete newOrders[pairIndex][tradeIndex];
    newOrders[pairIndex] = newOrders[pairIndex] || {};

    const positionSize = event.positionSize ? event.positionSize : event.positionSizeDai;
    newOrders[pairIndex][tradeIndex] = {
      trader: event.trader,
      pairIndex: pairIndex,
      index: event.index,
      positionSize: ethers.utils.formatEther(positionSize.toLocaleString("fullwide", { useGrouping: false })),
      openPrice: event.openPrice ? event.openPrice : event.minPrice,
      minPrice: event.minPrice,
      maxPrice: event.maxPrice,
      buy: event.buy,
      pairName: pares.find((el) => el[1] == pairIndex)[0],
      leverage: event.leverage,
      tp: event.tp,
      sl: event.sl,
    };

    setOrders(newOrders);
    //setOrdersLoading(0);
  };
  /*
  const setOrderValues = (pairIndex, index, newValues) => {
    if (pairIndex in ordersRef.current) {
      if (index in ordersRef.current[pairIndex]) {
        let pos = ordersRef.current[pairIndex][index];
        ordersRef.current[pairIndex][index] = { ...pos, ...newValues };
      } else {
        ordersRef.current[pairIndex][index] = { ...newValues };
      }
    } else {
      ordersRef.current[pairIndex] = { [index]: { ...newValues } };
    }
  };*/
  const handleEvent = async (event) => {
    IS_PROD || console.log(">>> useOrders.processEvents", event);

    let object = event;

    if (object.event == "getInitialTradesStarted") {
      setOrdersLoading(1);
      return;
    } else if (object.event == "getInitialTradesFinished") {
      setOrdersLoading(0);
      return;
    }

    let isInitialLoad = false;
    let isAnOrder = false;
    if (object.event == "OpenLimitCanceled") {
      cancelOurSpecificOrderByEvent(object);
      isAnOrder = true;
    } else if (object.event == "OpenLimitPlaced") {
      getOurSpecificOrderByEvent(object);
      isAnOrder = true;
    } else if (object.event == "LimitOrderInitialLoad") {
      getOurSpecificOrderByEvent(object);
      isInitialLoad = true;
      isAnOrder = true;
    } else if (object.event == "LimitExecutedEvent" || object.event == "LimitExecuted") {
      deleteOurSpecificOrderByEvent(object);
      isAnOrder = true;
    } else if (object.event == "LimitOrderInitiated") {
      console.log("@@@@@ useOrders.LimitOrderInitiated", object, object.open);
      isAnOrder = true;
    }
    if (isAnOrder) toastEventMessage(object, isInitialLoad);
  };

  useEffect(() => {
    setOrders({});
  }, [account, chainId]);

  return { orders, ordersLoading, handleEvent };
}

import React, { useState } from "react";
import btnBitcoinOn from "../../img/fuckies-buttons/BTC-ON.png";
import btnBitcoinOff from "../../img/fuckies-buttons/BTC-OFF.png";
import btnEthereumOn from "../../img/fuckies-buttons/ETH-ON.png";
import btnEthereumOff from "../../img/fuckies-buttons/ETH-OFF.png";
import btnLongOn from "../../img/fuckies-buttons/LONG-ON.png";
import btnLongOff from "../../img/fuckies-buttons/LONG-OFF.png";
import btnShortOn from "../../img/fuckies-buttons/SHORT-ON.png";
import btnShortOff from "../../img/fuckies-buttons/SHORT-OFF.png";
import btnGogogoOn from "../../img/fuckies-buttons/GO-ON.png";
import btnGogogoOff from "../../img/fuckies-buttons/GO-OFF.png";
import { ethers } from "ethers";

const FuckiesButtons = ({ fuckiesTradeContract, pairs, latestPrices }) => {
  const [operationState, setOperationState] = useState("long"); // 'long' o 'short'
  const [currencyState, setCurrencyState] = useState("BTC"); // 'btc' o 'eth'

  const [tradeInfo, setTradeInfo] = useState({
    pairIndex: 0,
    marketPrice: latestPrices[0],
    long: true,
  });

  const handleOperationChange = (operation) => {
    setOperationState(operation);
    handleButtonsStateChange({ operation, currency: currencyState });
  };

  const handleButtonsStateChange = (newState) => {
    const actualPair = pairs.find((pair) => pair[0] == newState.currency + "/USD");
    console.log("ACTUALPAIR", actualPair[4]);

    if (newState.operation === "long") {
      setTradeInfo({
        pairIndex: actualPair[1],
        marketPrice: actualPair[4],
        long: true,
      });
    } else {
      setTradeInfo({
        pairIndex: actualPair[1],
        marketPrice: actualPair[4],
        long: false,
      });
    }
  };

  const handleCurrencyChange = (currency) => {
    setCurrencyState(currency);
    handleButtonsStateChange({ operation: operationState, currency });
  };

  const handleOpenTrade = () => {
    const { pairIndex, marketPrice, long } = tradeInfo;
    const precio = marketPrice * 1e10;
    console.log(pairIndex, precio, long);
    fuckiesTradeContract.startFreeTrade(pairIndex, precio, long);
  };

  return (
    <div className="fuckies-buttons-operation">
      <div className="btn-operation">
        <button className="btn-op" onClick={() => handleOperationChange("long")}>
          {operationState === "long" ? <img src={btnLongOn} /> : <img src={btnLongOff} />}
        </button>
        <button className="btn-op" onClick={() => handleOperationChange("short")}>
          {operationState === "short" ? <img src={btnShortOn} /> : <img src={btnShortOff} />}
        </button>
      </div>
      <div className="btn-operation">
        <button className="btn-op" onClick={() => handleCurrencyChange("BTC")}>
          {currencyState === "BTC" ? <img src={btnBitcoinOn} /> : <img src={btnBitcoinOff} />}
        </button>
        <button className="btn-op" onClick={() => handleCurrencyChange("ETH")}>
          {currencyState === "ETH" ? <img src={btnEthereumOn} /> : <img src={btnEthereumOff} />}
        </button>
      </div>
      <div className="btn-operation">
        <button className="btn-go" onClick={handleOpenTrade}>
          <img src={btnGogogoOn} />
        </button>
      </div>
    </div>
  );
};

export default FuckiesButtons;

import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";
import { IS_PROD, MODE } from "config/context";
import { Trans, t, Plural } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { mutate } from "swr";
import { BigNumber, ethers } from "ethers";
import cx from "classnames";
import { Helmet } from "react-helmet";

import _ from "lodash";
import GNSTRADINGV6 from "../../abis/DexynthTradingV6_4_1.json";
import { waitForOpenConnection } from "lib/sockets";

import {
  FUNDING_RATE_PRECISION,
  BASIS_POINTS_DIVISOR,
  MARGIN_FEE_BASIS_POINTS,
  SWAP,
  LONG,
  SHORT,
  USD_DECIMALS,
  USDG_DECIMALS,
  E10_DECIMALS,
  getPositionKey,
  getPositionContractKey,
  getLeverage,
  getDeltaStr,
  useAccountOrders,
  getPageTitle,
} from "lib/legacy";
import { getConstant, getExplorerUrl } from "config/chains";
import { approvePlugin, useMinExecutionFee, cancelMultipleOrders } from "domain/legacy";

import { getContract } from "config/contracts";

import Reader from "abis/ReaderV2.json";
import Token from "abis/Token.json";
import GNSTradingV6_2ABI from "abis/DexynthTradingV6_4_1.json";
import GNSBUSDABI from "abis/USDT.json";
import GNSdBUSDABI from "abis/DexynthTokenVault.json";

import GNSGFarmTradingStorageABI from "abis/DexynthTradingStorageV5.json";
import GNSPairsStorageV6ABI from "abis/DexynthPairsStorageV6.json";
import GNSPairInfosV6_1ABI from "abis/DexynthPairInfosV6_1.json";

import Checkbox from "components/Checkbox/Checkbox";
import SwapBox from "components/Exchange/SwapBox";
import ExchangeTVChart, { getChartToken } from "components/Exchange/ExchangeTVChart";
import PositionsList from "components/Exchange/PositionsList";
import OrdersList from "components/Exchange/OrdersList";
import TradeHistory from "components/Exchange/TradeHistory";
import ExchangeBanner from "components/Exchange/ExchangeBanner";
import Tab from "components/Tab/Tab";

import "./Exchange.css";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { getTokenInfo } from "domain/tokens/utils";
import { bigNumberify, formatAmount, formatNumber } from "lib/numbers";
import { getToken, getTokenBySymbol, getTokens, getWhitelistedTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";

import AppContext from "AppContext";
import { usePairPrices } from "hooks/usePairsPrices";
import { usePendingOrders } from "hooks/usePendingOrders.js";
import { useOrders } from "hooks/useOrders.js";
import { usePositions } from "hooks/usePositions.js";
import { useEventsRelayer } from "hooks/useEventsRelayer.js";
import { useBlockBatchNumber } from "hooks/useBlockBatchNumber.js";
import { useHistoric } from "hooks/useHistoric";

import { useContracts } from "hooks/useContracts";
import { BorrowingFees } from "config/contracts";

import { POS_STATUS_TRYING_TO_CLOSE, POS_STATUS_NORMAL } from "components/Exchange/PositionsLogic";

const { AddressZero } = ethers.constants;

const PENDING_POSITION_VALID_DURATION = 600 * 1000;
const UPDATED_POSITION_VALID_DURATION = 60 * 1000;

const notifications = {};

function pushSuccessNotification(chainId, message, e) {
  const { transactionHash } = e;
  const id = ethers.utils.id(message + transactionHash);
  if (notifications[id]) {
    return;
  }

  notifications[id] = true;

  const txUrl = getExplorerUrl(chainId) + "tx/" + transactionHash;
  helperToast.success(
    <div>
      {message}{" "}
      <ExternalLink href={txUrl}>
        <Trans>View</Trans>
      </ExternalLink>
    </div>
  );
}

function pushErrorNotification(chainId, message, e) {
  const { transactionHash } = e;
  const id = ethers.utils.id(message + transactionHash);
  if (notifications[id]) {
    return;
  }

  notifications[id] = true;

  const txUrl = getExplorerUrl(chainId) + "tx/" + transactionHash;
  helperToast.error(
    <div>
      {message}{" "}
      <ExternalLink href={txUrl}>
        <Trans>View</Trans>
      </ExternalLink>
    </div>
  );
}

function getFundingFee(data) {
  let { entryFundingRate, cumulativeFundingRate, size } = data;
  if (entryFundingRate && cumulativeFundingRate) {
    return size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
  }
  return;
}

const getTokenAddress = (token, nativeTokenAddress) => {
  if (token.address === AddressZero) {
    return nativeTokenAddress;
  }
  return token.address;
};

function applyPendingChanges(position, pendingPositions) {
  if (!pendingPositions) {
    return;
  }
  const { key } = position;

  if (
    pendingPositions[key] &&
    pendingPositions[key].updatedAt &&
    pendingPositions[key].pendingChanges &&
    pendingPositions[key].updatedAt + PENDING_POSITION_VALID_DURATION > Date.now()
  ) {
    const { pendingChanges } = pendingPositions[key];
    if (pendingChanges.size && position.size.eq(pendingChanges.size)) {
      return;
    }

    if (pendingChanges.expectingCollateralChange && !position.collateral.eq(pendingChanges.collateralSnapshot)) {
      return;
    }

    position.hasPendingChanges = true;
    position.pendingChanges = pendingChanges;
  }
}

export function getPositions(
  chainId,
  positionQuery,
  positionData,
  infoTokens,
  includeDelta,
  showPnlAfterFees,
  account,
  pendingPositions,
  updatedPositions
) {
  const propsLength = getConstant(chainId, "positionReaderPropsLength");
  const positions = [];
  const positionsMap = {};
  if (!positionData) {
    return { positions, positionsMap };
  }
  const { collateralTokens, indexTokens, isLong } = positionQuery;
  for (let i = 0; i < collateralTokens.length; i++) {
    const collateralToken = getTokenInfo(infoTokens, collateralTokens[i], true, getContract(chainId, "NATIVE_TOKEN"));
    const indexToken = getTokenInfo(infoTokens, indexTokens[i], true, getContract(chainId, "NATIVE_TOKEN"));
    const key = getPositionKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
    let contractKey;
    if (account) {
      contractKey = getPositionContractKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
    }

    const position = {
      key,
      contractKey,
      collateralToken,
      indexToken,
      isLong: isLong[i],
      size: positionData[i * propsLength],
      collateral: positionData[i * propsLength + 1],
      averagePrice: positionData[i * propsLength + 2],
      entryFundingRate: positionData[i * propsLength + 3],
      cumulativeFundingRate: collateralToken.cumulativeFundingRate,
      hasRealisedProfit: positionData[i * propsLength + 4].eq(1),
      realisedPnl: positionData[i * propsLength + 5],
      lastIncreasedTime: positionData[i * propsLength + 6].toNumber(),
      hasProfit: positionData[i * propsLength + 7].eq(1),
      delta: positionData[i * propsLength + 8],
      markPrice: isLong[i] ? indexToken.minPrice : indexToken.maxPrice,
    };

    if (
      updatedPositions &&
      updatedPositions[key] &&
      updatedPositions[key].updatedAt &&
      updatedPositions[key].updatedAt + UPDATED_POSITION_VALID_DURATION > Date.now()
    ) {
      const updatedPosition = updatedPositions[key];
      position.size = updatedPosition.size;
      position.collateral = updatedPosition.collateral;
      position.averagePrice = updatedPosition.averagePrice;
      position.entryFundingRate = updatedPosition.entryFundingRate;
    }

    let fundingFee = getFundingFee(position);
    position.fundingFee = fundingFee ? fundingFee : bigNumberify(0);
    position.collateralAfterFee = position.collateral.sub(position.fundingFee);

    position.closingFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    position.positionFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).mul(2).div(BASIS_POINTS_DIVISOR);
    position.totalFees = position.positionFee.add(position.fundingFee);

    position.pendingDelta = position.delta;

    if (position.collateral.gt(0)) {
      position.hasLowCollateral =
        position.collateralAfterFee.lt(0) || position.size.div(position.collateralAfterFee.abs()).gt(50);

      if (position.averagePrice && position.markPrice) {
        const priceDelta = position.averagePrice.gt(position.markPrice)
          ? position.averagePrice.sub(position.markPrice)
          : position.markPrice.sub(position.averagePrice);
        position.pendingDelta = position.size.mul(priceDelta).div(position.averagePrice);

        position.delta = position.pendingDelta;

        if (position.isLong) {
          position.hasProfit = position.markPrice.gte(position.averagePrice);
        } else {
          position.hasProfit = position.markPrice.lte(position.averagePrice);
        }
      }

      position.deltaPercentage = position.pendingDelta.mul(BASIS_POINTS_DIVISOR).div(position.collateral);

      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: position.pendingDelta,
        deltaPercentage: position.deltaPercentage,
        hasProfit: position.hasProfit,
      });

      position.deltaStr = deltaStr;
      position.deltaPercentageStr = deltaPercentageStr;
      position.deltaBeforeFeesStr = deltaStr;

      let hasProfitAfterFees;
      let pendingDeltaAfterFees;

      if (position.hasProfit) {
        if (position.pendingDelta.gt(position.totalFees)) {
          hasProfitAfterFees = true;
          pendingDeltaAfterFees = position.pendingDelta.sub(position.totalFees);
        } else {
          hasProfitAfterFees = false;
          pendingDeltaAfterFees = position.totalFees.sub(position.pendingDelta);
        }
      } else {
        hasProfitAfterFees = false;
        pendingDeltaAfterFees = position.pendingDelta.add(position.totalFees);
      }

      position.hasProfitAfterFees = hasProfitAfterFees;
      position.pendingDeltaAfterFees = pendingDeltaAfterFees;
      position.deltaPercentageAfterFees = position.pendingDeltaAfterFees
        .mul(BASIS_POINTS_DIVISOR)
        .div(position.collateral);

      const { deltaStr: deltaAfterFeesStr, deltaPercentageStr: deltaAfterFeesPercentageStr } = getDeltaStr({
        delta: position.pendingDeltaAfterFees,
        deltaPercentage: position.deltaPercentageAfterFees,
        hasProfit: hasProfitAfterFees,
      });

      position.deltaAfterFeesStr = deltaAfterFeesStr;
      position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr;

      if (showPnlAfterFees) {
        position.deltaStr = position.deltaAfterFeesStr;
        position.deltaPercentageStr = position.deltaAfterFeesPercentageStr;
      }

      let netValue = position.hasProfit
        ? position.collateral.add(position.pendingDelta)
        : position.collateral.sub(position.pendingDelta);

      netValue = netValue.sub(position.fundingFee);

      if (showPnlAfterFees) {
        netValue = netValue.sub(position.closingFee);
      }

      position.netValue = netValue;
    }

    position.leverage = getLeverage({
      size: position.size,
      collateral: position.collateral,
      entryFundingRate: position.entryFundingRate,
      cumulativeFundingRate: position.cumulativeFundingRate,
      hasProfit: position.hasProfit,
      delta: position.delta,
      includeDelta,
    });

    positionsMap[key] = position;

    applyPendingChanges(position, pendingPositions);

    if (position.size.gt(0) || position.hasPendingChanges) {
      positions.push(position);
    }
  }

  return { positions, positionsMap };
}

export function getPositionQuery(tokens, nativeTokenAddress) {
  const collateralTokens = [];
  const indexTokens = [];
  const isLong = [];

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    if (token.isStable) {
      continue;
    }
    if (token.isWrapped) {
      continue;
    }
    collateralTokens.push(getTokenAddress(token, nativeTokenAddress));
    indexTokens.push(getTokenAddress(token, nativeTokenAddress));
    isLong.push(true);
  }

  for (let i = 0; i < tokens.length; i++) {
    const stableToken = tokens[i];
    if (!stableToken.isStable) {
      continue;
    }

    for (let j = 0; j < tokens.length; j++) {
      const token = tokens[j];
      if (token.isStable) {
        continue;
      }
      if (token.isWrapped) {
        continue;
      }
      collateralTokens.push(stableToken.address);
      indexTokens.push(getTokenAddress(token, nativeTokenAddress));
      isLong.push(false);
    }
  }

  return { collateralTokens, indexTokens, isLong };
}

// Exchange component
export const Exchange = forwardRef((props, ref) => {
  const {
    savedIsPnlInLeverage,
    setSavedIsPnlInLeverage,
    savedShowPnlAfterFees,
    savedSlippageAmount,
    pendingTxns,
    setPendingTxns,
    savedShouldShowPositionLines,
    setSavedShouldShowPositionLines,
    connectWallet,
    savedShouldDisableValidationForTesting,
    setWalletModalVisible,
  } = props;

  const { active, account, library } = useWeb3React();
  const { chainId } = useChainId();
  const currentAccount = account;

  //const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  //const signer = provider.getSigner();
  const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum, "any") : null;
  const signer = provider ? provider.getSigner() : null;

  const { contractInfo } = useContracts({ chainId });
  /*
  const { data: pairOis } = useSWR(
    active &&
      contractInfo[BorrowingFees].address && [
        `Exchange:pairOis:${active}`,
        chainId,
        contractInfo[BorrowingFees].address,
        "pairOis",
        0
      ],
    {
      fetcher: contractFetcher(library, contractInfo[BorrowingFees].abiJson),
    }
  );
  console.log("pairOis  " + pairOis + "  " + active);*/
  //console.log("requestsStart  " + requestsStart + "  " + active);

  const GNSTradingV6_2 = getContract(chainId, "Trading");
  const TradingStorage = getContract(chainId, "TradingStorage");
  const BUSD_ADDRESS_Testnet = getContract(chainId, "USDC");
  const dBUSD_ADDRESS_Testnet = getContract(chainId, "dUSDC");
  const GNSPairInfosV6_1 = getContract(chainId, "PairInfos");

  const tradingV6Abi = GNSTradingV6_2ABI.abi;
  const GNSGFarmTradingStorageAbi = GNSGFarmTradingStorageABI.abi;
  const busdAbi = GNSBUSDABI.abi;
  const dBusdAbi = GNSdBUSDABI.abi;

  const GNSPairsStorageV6Abi = GNSPairsStorageV6ABI.abi;
  const GNSPairInfosV6_1Abi = GNSPairInfosV6_1ABI.abi;

  const tradingV6Contract = new ethers.Contract(GNSTradingV6_2, tradingV6Abi, signer);
  const busdContract = new ethers.Contract(BUSD_ADDRESS_Testnet, busdAbi, signer);
  const dBusdContract = new ethers.Contract(dBUSD_ADDRESS_Testnet, dBusdAbi, signer);
  const GNSPairInfosV6_1Contract = new ethers.Contract(GNSPairInfosV6_1, GNSPairInfosV6_1Abi, signer);

  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const vaultAddress = getContract(chainId, "Vault");
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const readerAddress = getContract(chainId, "Reader");
  const usdgAddress = getContract(chainId, "USDG");
  const [showBanner, setShowBanner] = useLocalStorageSerializeKey("showBanner", true);
  const [bannerHidden, setBannerHidden] = useLocalStorageSerializeKey("bannerHidden", null);

  const [pendingPositions, setPendingPositions] = useState({});
  const [updatedPositions, setUpdatedPositions] = useState({});

  const [initialTradesLoading, setInitialTradesLoading] = useState(1);

  const [overFlow, setOverFlow] = useState("visible");
  const { pares, pairs, setPairs, cleanPairs, setCleanPairs, setupPricesWebsocket, latestPrices, market24 } =
    useContext(AppContext);

  // Se selecciona el token
  const [ourSelectedToken, setOurSelectedToken] = useState({ symbol: "BTC", pairName: "BTCUSDT", pairIndex: 0 });

  const getPairName = (pairIndex) => {
    return pares.find((el) => el[1] == pairIndex)[0];
  };

  useEffect(() => {
    console.log("@@@Exchange:useEffect:setupPricesWebsocket");
    setupPricesWebsocket();
  }, []);

  const toastEventMessage = (event, isInitialLoad = false) => {
    if (isInitialLoad || !account) return;

    const pairIndex = event.pairIndex;
    const pair = pairs.find((p) => p[1] == pairIndex);
    helperToast.success(`${event.event} for pair: ${pair[0]}`);
  };

  useEffect(async () => {
    const interval = setInterval(async () => {
      getPairMaxOpenInterest();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  usePairPrices(pairs, cleanPairs, latestPrices, market24);

  const hideBanner = () => {
    const hiddenLimit = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);
    setBannerHidden(hiddenLimit);
    setShowBanner(false);
  };

  useEffect(() => {
    if (new Date() > new Date("2021-11-30")) {
      setShowBanner(false);
    } else {
      if (bannerHidden && new Date(bannerHidden) > new Date()) {
        setShowBanner(false);
      } else {
        setBannerHidden(null);
        setShowBanner(true);
      }
    }
  }, [showBanner, bannerHidden, setBannerHidden, setShowBanner]);

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);

  const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress);

  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const defaultTokenSelection = useMemo(
    () => ({
      [SWAP]: {
        from: AddressZero,
        to: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
      },
      [LONG]: {
        from: AddressZero,
        to: AddressZero,
      },
      [SHORT]: {
        from: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
        to: AddressZero,
      },
    }),
    [chainId, defaultCollateralSymbol]
  );

  const { data: busdBalance } = useSWR(
    account && active && [`Exchange:busdBalance:${active}`, chainId, BUSD_ADDRESS_Testnet, "balanceOf", account],
    {
      fetcher: contractFetcher(library, GNSBUSDABI),
    }
  );

  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainId,
    "Exchange-token-selection-v2",
    defaultTokenSelection
  );

  // FRANKY
  // const [swappOption, setSwapOption] = useLocalStorageByChainId(chainId, "Swap-option-v2", LONG);
  const [swapOption, setSwapOption] = useState(localStorage.getItem("swapOption") || "Long");

  const [filterBySelectedPair, setFilterBySelectedPair] = useState(false);

  const fromTokenAddress = tokenSelection[swapOption].from;
  const toTokenAddress = tokenSelection[swapOption].to;

  const setFromTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].from = address;
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );

  const setToTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].to = address;
      if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
        newTokenSelection[LONG].to = address;
        newTokenSelection[SHORT].to = address;
      }
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );

  const setMarket = (selectedSwapOption, toTokenAddress) => {
    setSwapOption(selectedSwapOption);
    const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
    newTokenSelection[selectedSwapOption].to = toTokenAddress;
    if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
      newTokenSelection[LONG].to = toTokenAddress;
      newTokenSelection[SHORT].to = toTokenAddress;
    }
    setTokenSelection(newTokenSelection);
  };

  const [isConfirming, setIsConfirming] = useState(false);
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);

  const tokens = getTokens(chainId);

  const tokenAddresses = tokens.map((token) => token.address);
  /*
  const { data: tokenBalances } = useSWR(active && [active, chainId, readerAddress, "getTokenBalances", account], {
    fetcher: contractFetcher(library, Reader, [tokenAddresses]),
  });*/
  const tokenBalances = undefined;

  /*
  const { data: positionData, error: positionDataError } = useSWR(
    account && active && [active, chainId, readerAddress, "getPositions", vaultAddress, account],
    {
      fetcher: contractFetcher(library, Reader, [
        positionQuery.collateralTokens,
        positionQuery.indexTokens,
        positionQuery.isLong,
      ]),
    }
  );*/
  const positionData = undefined;
  const positionDataError = undefined;
  const positionsDataIsLoading = active && !positionData && !positionDataError;

  /*
  const { data: fundingRateInfo } = useSWR([active, chainId, readerAddress, "getFundingRates"], {
    fetcher: contractFetcher(library, Reader, [vaultAddress, nativeTokenAddress, whitelistedTokenAddresses]),
  });*/
  const fundingRateInfo = undefined;

  const totalTokenWeights = undefined;

  /*
  const { data: usdgSupply } = useSWR([`Exchange:usdgSupply:${active}`, chainId, usdgAddress, "totalSupply"], {
    fetcher: contractFetcher(library, Token),
  });*/
  const usdgSupply = undefined;

  const positionRouterApproved = true;

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, fundingRateInfo);
  /*
  const { minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage } = useMinExecutionFee(
    library,
    active,
    chainId,
    infoTokens
  );
  */
  // alfaori: Remove dependencies of not-used (GMX) Contracts Calls: PositionRouter.minExecutionFee
  const [minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage] = [undefined, undefined, undefined];
  const TradingAbi = GNSTRADINGV6.abi;
  const TradingAddress = getContract(chainId, "Trading");
  const Trading = new ethers.Contract(TradingAddress, TradingAbi, signer);

  const { blockNumber } = useBlockBatchNumber();

  const handleWsEvents = async (event) => {
    if (event.event == "getInitialTradesStarted") {
      setInitialTradesLoading(1);
    } else if (event.event == "getInitialTradesFinished") {
      setInitialTradesLoading(0);
    }
    handleOrderEvent(event);
    handlePositionEvent(event);
  };

  const closingPositionData = useRef({});

  const { event: eventsRelayerEvent } = useEventsRelayer();

  const { orders: ourOrders, ordersLoading, handleEvent: handleOrderEvent } = useOrders({ pares });

  const {
    positions: ourPositions,
    positionsLoading,
    historicEvent,
    handleEvent: handlePositionEvent,
    setPositionValues,
    orderStatus,
    overridenOrderId,
    positionsStats,
  } = usePositions({ pares, closingPositionData, blockNumber, getPairName, ourSelectedToken, filterBySelectedPair });

  useEffect(() => {
    if (eventsRelayerEvent) {
      handleWsEvents(eventsRelayerEvent);
    }
  }, [eventsRelayerEvent]);

  const [numberOfDays, _setNumberOfDays] = useState(3);
  const numberOfDaysRef = React.useRef(numberOfDays);
  const setNumberOfDays = (data) => {
    numberOfDaysRef.current = data;
  };

  const { trades: historicTrades, isFetchingTrades: isFetchingHistoricTrades } = useHistoric({
    filterBySelectedPair,
    ourSelectedToken,
    days: numberOfDaysRef.current,
    historicEvent,
  });

  useEffect(() => {
    const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
    const toToken = getTokenInfo(infoTokens, toTokenAddress);
    let selectedToken = getChartToken(swapOption, fromToken, toToken, chainId);
    let currentTokenPriceStr = formatAmount(selectedToken.maxPrice, USD_DECIMALS, 2, true);
    let title = getPageTitle(currentTokenPriceStr + ` | ${selectedToken.symbol}${selectedToken.isStable ? "" : "USD"}`);
  }, [tokenSelection, swapOption, infoTokens, chainId, fromTokenAddress, toTokenAddress]);

  const { positions, positionsMap } = getPositions(
    chainId,
    positionQuery,
    positionData,
    infoTokens,
    savedIsPnlInLeverage,
    savedShowPnlAfterFees,
    account,
    pendingPositions,
    updatedPositions
  );

  const [heartbeatTime, _setHeartbeatTime] = React.useState(0);
  const heartbeatTimeRef = React.useRef(heartbeatTime);
  const setHeartbeatTime = (data) => {
    heartbeatTimeRef.current = data;
    _setHeartbeatTime(data);
  };

  const [eventsSocketOpenning, _setEventsSocketOpenning] = useState(false);
  const eventsSocketOpenningRef = React.useRef(eventsSocketOpenning);
  const setEventsSocketOpenning = (data) => {
    eventsSocketOpenningRef.current = data;
    _setEventsSocketOpenning(data);
  };

  const [takeProfit, setTakeProfit] = useState(25);
  const [stopLoss, setStopLoss] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  //const [userBusdBalance, setUserBusdBalance] = useState(1);

  //const [pairsList, setPairsList] = useState([{ cryptoName: "BTC", stableName: "USD" }]);
  const [spread, setSpread] = useState(0);
  const [onePercentDepthAbove, setOnePercentDepthAbove] = useState(0);
  const [onePercentDepthBelow, setOnePercentDepthBelow] = useState(0);

  const [ourTokenIndex, _setOurTokenIndex] = useState(0);

  const ourTokenIndexRef = React.useRef(ourTokenIndex);
  const setOurTokenIndex = (data) => {
    ourTokenIndexRef.current = data;
    _setOurTokenIndex(data);
  };
  const chainIdRef = React.useRef(chainId);

  const [minLeverage, setMinLeverage] = useState(0);
  const [maxLeverage, setMaxLeverage] = useState(200);
  const [maxCollateralP, setMaxCollateralP] = useState(0);
  const [tvl, setTvl] = useState(0);
  const [longOpenInterest, setLongOpenInterest] = useState(0);
  const [shortOpenInterest, setShortOpenInterest] = useState(0);
  const [maxOpenInterest, setMaxOpenInterest] = useState(0);
  const [formattedLongOpenInterest, setFormattedLongOpenInterest] = useState(0);
  const [formattedShortOpenInterest, setFormattedShortOpenInterest] = useState(0);
  const [formattedMaxOpenInterest, setFormattedMaxOpenInterest] = useState(0);
  const [minLevPosDai, setMinLevPosDai] = useState(1500);
  const [openFee, setOpenFee] = useState(0);
  const [groupCollateralLong, setGroupCollateralLong] = useState(0);
  const [groupCollateralShort, setGroupCollateralShort] = useState(0);
  const [groupMaxCollateral, setGroupMaxCollateral] = useState(0);

  const { pendingOrders } = usePendingOrders({
    account,
    library,
    active,
    setPositionValues,
    initialTradesLoading,
    getPairName,
    ourPositions,
    orderStatus,
    eventsRelayerEvent,
    overridenOrderId,
  });

  const getSpreadAndLeverage = async () => {
    if (!provider) return;
    if (!chainIdRef.current) return;
    try {
      const GNSPairsStorageV6 = getContract(chainIdRef.current, "PairsStorage");
      const GNSPairsStorageV6Contract = new ethers.Contract(GNSPairsStorageV6, GNSPairsStorageV6Abi, signer);
      let val = await GNSPairsStorageV6Contract.pairsBackend(ourTokenIndex);

      const pairParams = await GNSPairInfosV6_1Contract.pairParams(ourTokenIndex);

      setOnePercentDepthAbove(pairParams.onePercentDepthAbove);
      setOnePercentDepthBelow(pairParams.onePercentDepthBelow);

      setMinLeverage(val[1].minLeverage.toNumber());
      setMaxLeverage(val[1].maxLeverage.toNumber());
      setMaxCollateralP(val[1].maxCollateralP.toNumber());
      setSpread(parseFloat(val[0].spreadP / 1e10));

      setMinLevPosDai(ethers.utils.formatEther(val[2].minLevPosDai));
      var _openFee = (parseInt(val[2].openFeeP) / 1e10) * 2 + parseInt(val[2].nftLimitOrderFeeP) / 1e10;
      setOpenFee(_openFee);

      let _tvl = await dBusdContract.tvl();
      setTvl(ethers.utils.formatEther(_tvl));
    } catch (e) {
      console.log("ERROR:", e);
    }
  };

  const getPairMaxOpenInterest = async () => {
    //console.log("getPairMaxOpenInterest", ourTokenIndex);
    //console.log("getPairMaxOpenInterest.CURR", ourTokenIndexRef.current);

    if (!provider) return;
    if (!chainIdRef.current) return;
    try {
      const currentTokenIndex = ourTokenIndexRef.current;

      const { long, short, max } = contractInfo[BorrowingFees].contract
        ? await contractInfo[BorrowingFees].contract.pairOis(currentTokenIndex)
        : { long: 0, short: 0, max: 0 };

      //const max = 0;
      /*
    const uLong = ethers.utils.formatUnits(long,10)
    setLongOpenInterest(uLong);
    setFormattedLongOpenInterest(FormatOpenInterest(uLong));

    const uShort = ethers.utils.formatUnits(short,10)
    setShortOpenInterest(uShort);
    setFormattedShortOpenInterest(FormatOpenInterest(uShort));*/

      const uMax = ethers.utils.formatUnits(max, 10);
      setMaxOpenInterest(uMax);
      setFormattedMaxOpenInterest(FormatOpenInterest(uMax));

      //console.log("getPairMaxOpenInterest", currentTokenIndex, /*uLong, uShort,*/ uMax);

      const GFarmTradingStorageV5_Testnet = getContract(chainIdRef.current, "TradingStorage");
      const farmTradingStorageV5Contract = new ethers.Contract(
        GFarmTradingStorageV5_Testnet,
        GNSGFarmTradingStorageAbi,
        signer
      );

      for (let index = 0; index < 3; index++) {
        let val = await farmTradingStorageV5Contract.openInterestDai(currentTokenIndex, index);
        //console.log("getPairMaxOpenInterest", currentTokenIndex, index, val);
        if (val) {
          switch (index) {
            case 0:
              setLongOpenInterest(ethers.utils.formatEther(val));
              //IS_PROD || console.log(">>>>>>> getPairMaxOpenInterest.Long", ethers.utils.formatEther(val));
              setFormattedLongOpenInterest(FormatOpenInterest(ethers.utils.formatEther(val)));
              break;

            case 1:
              setShortOpenInterest(ethers.utils.formatEther(val));
              //IS_PROD || console.log(">>>>>>> getPairMaxOpenInterest.Short", ethers.utils.formatEther(val));
              setFormattedShortOpenInterest(FormatOpenInterest(ethers.utils.formatEther(val)));
              break;

            /*
          case 2:
            setMaxOpenInterest(ethers.utils.formatEther(val));
            setFormattedMaxOpenInterest(FormatOpenInterest(ethers.utils.formatEther(val)));
            break;*/

            default:
              setLongOpenInterest(0);
              setShortOpenInterest(0);
              //setMaxOpenInterest(0);
              break;
          }
        }
      }

      const pairInfo = await contractInfo[BorrowingFees].contract.getPairGroupIndex(currentTokenIndex);
      const grInfo = await contractInfo[BorrowingFees].contract.getGroup(pairInfo);

      const { maxOi } = grInfo[0];
      const oiMaxUnits = ethers.utils.formatUnits(maxOi, E10_DECIMALS);

      const GNSPairsStorageV6 = getContract(chainIdRef.current, "PairsStorage");
      const GNSPairsStorageV6Contract = new ethers.Contract(GNSPairsStorageV6, GNSPairsStorageV6Abi, signer);
      let groupCollateralLong_ = await GNSPairsStorageV6Contract.groupCollateral(currentTokenIndex, true);
      let groupCollateralShort_ = await GNSPairsStorageV6Contract.groupCollateral(currentTokenIndex, false);
      let groupMaxCollateral_ = await GNSPairsStorageV6Contract.groupMaxCollateral(currentTokenIndex);

      let _groupCollateralLong = ethers.utils.formatEther(groupCollateralLong_);
      let _groupCollateralShort = ethers.utils.formatEther(groupCollateralShort_);
      let _groupMaxCollateral = ethers.utils.formatEther(groupMaxCollateral_);

      if (_groupCollateralLong > 0) {
        _groupCollateralLong = parseInt(groupCollateralLong_) / 1000000000000000000;
      }
      if (_groupCollateralShort > 0) {
        _groupCollateralShort = parseInt(groupCollateralShort_) / 1000000000000000000;
      }

      setGroupCollateralLong(_groupCollateralLong);
      setGroupCollateralShort(_groupCollateralShort);
      setGroupMaxCollateral(oiMaxUnits);
      /*IS_PROD ||
        console.log(">>>>>>> getPairMaxOpenInterest.getGroup", _groupCollateralLong, _groupCollateralShort, oiMaxUnits);*/
    } catch (e) {
      console.log("ERROR:", e);
    }
  };

  const FormatOpenInterest = (interest) => {
    var _formattedOpenInterest = "";
    if (interest) {
      if (interest >= 1000000) {
        _formattedOpenInterest = (interest / 1000000).toFixed(1) + "M";
      } else if (interest < 1000000 && interest > 1000) {
        _formattedOpenInterest = (interest / 1000).toFixed(1) + "K";
      } else if (interest < 1000 && interest > 0) {
        _formattedOpenInterest = parseInt(interest).toFixed(1);
      } else if (interest == 0) {
        _formattedOpenInterest = interest.substring(0, 1);
      }
    }
    return _formattedOpenInterest;
  };

  React.useEffect(() => {
    mutate("Trade:busdBalance:true");
    chainIdRef.current = chainId;
    try {
      getPairMaxOpenInterest();
      getSpreadAndLeverage();
    } catch (e) {
      console.log("ERROR:", e);
    }
  }, [account, chainId, ourTokenIndex]);

  useImperativeHandle(ref, () => ({
    onUpdatePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size,
            collateral,
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          };
          setUpdatedPositions({ ...updatedPositions });
          break;
        }
      }
    },
    onClosePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl, e) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size: bigNumberify(0),
            collateral: bigNumberify(0),
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          };
          setUpdatedPositions({ ...updatedPositions });
          break;
        }
      }
    },

    onIncreasePosition(key, account, collateralToken, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
      if (account !== currentAccount) {
        return;
      }

      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? t`Long` : t`Short`;
      let message;
      if (sizeDelta.eq(0)) {
        message = t`Deposited ${formatAmount(
          collateralDelta,
          USD_DECIMALS,
          2,
          true
        )} USD into ${tokenSymbol} ${longOrShortText}`;
      } else {
        message = t`Increased ${tokenSymbol} ${longOrShortText}, +${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD.`;
      }

      pushSuccessNotification(chainId, message, e);
    },

    onDecreasePosition(key, account, collateralToken, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
      if (account !== currentAccount) {
        return;
      }

      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? t`Long` : t`Short`;

      let message;
      if (sizeDelta.eq(0)) {
        message = t`Withdrew ${formatAmount(
          collateralDelta,
          USD_DECIMALS,
          2,
          true
        )} USD from ${tokenSymbol} ${longOrShortText}.`;
      } else {
        message = t`Decreased ${tokenSymbol} ${longOrShortText}, -${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD.`;
      }

      pushSuccessNotification(chainId, message, e);
    },

    onCancelIncreasePosition(
      account,
      path,
      indexToken,
      amountIn,
      minOut,
      sizeDelta,
      isLong,
      acceptablePrice,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (account !== currentAccount) {
        return;
      }
      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? t`Long` : t`Short`;

      const message =
        t`Could not increase ` +
        `${tokenSymbol} ${longOrShortText}` +
        ` within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;
      pushErrorNotification(chainId, message, e);

      const key = getPositionKey(account, path[path.length - 1], indexToken, isLong);
      pendingPositions[key] = {};
      setPendingPositions({ ...pendingPositions });
    },

    onCancelDecreasePosition(
      account,
      path,
      indexToken,
      collateralDelta,
      sizeDelta,
      isLong,
      receiver,
      acceptablePrice,
      minOut,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (account !== currentAccount) {
        return;
      }
      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? t`Long` : t`Short`;

      const message =
        t`Could not decrease ` +
        `${tokenSymbol} ${longOrShortText}` +
        `within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

      pushErrorNotification(chainId, message, e);

      const key = getPositionKey(account, path[path.length - 1], indexToken, isLong);
      pendingPositions[key] = {};
      setPendingPositions({ ...pendingPositions });
    },
  }));

  const flagOrdersEnabled = true;
  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false);
  const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false);
  const [isPluginApproving, setIsPluginApproving] = useState(false);
  const [isPositionRouterApproving, setIsPositionRouterApproving] = useState(false);
  const [isCancelMultipleOrderProcessing, setIsCancelMultipleOrderProcessing] = useState(false);
  const [cancelOrderIdList, setCancelOrderIdList] = useState([]);

  const onSelectToken = (token) => {
    setOurTokenIndex(token.pairIndex);
    setOurSelectedToken(token);
  };

  const onMultipleCancelClick = useCallback(
    async function () {
      setIsCancelMultipleOrderProcessing(true);
      try {
        const tx = await cancelMultipleOrders(chainId, library, cancelOrderIdList, {
          successMsg: t`Orders cancelled.`,
          failMsg: t`Cancel failed.`,
          sentMsg: t`Cancel submitted.`,
          pendingTxns,
          setPendingTxns,
        });
        const receipt = await tx.wait();
        if (receipt.status === 1) {
          setCancelOrderIdList([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsCancelMultipleOrderProcessing(false);
      }
    },
    [
      chainId,
      library,
      pendingTxns,
      setPendingTxns,
      setCancelOrderIdList,
      cancelOrderIdList,
      setIsCancelMultipleOrderProcessing,
    ]
  );

  const approvePositionRouter = ({ sentMsg, failMsg }) => {
    return approvePlugin(chainId, positionRouterAddress, {
      library,
      pendingTxns,
      setPendingTxns,
      sentMsg,
      failMsg,
    })
      .then(() => {
        setIsWaitingForPositionRouterApproval(true);
      })
      .finally(() => {
        setIsPositionRouterApproving(false);
      });
  };

  const getFilterCheckboxText = () => {
    return filterBySelectedPair ? `Show all positions` : `Show only ${ourSelectedToken.symbol} positions`;
  };

  const toggleFilterBySelectedPair = (value) => {
    setFilterBySelectedPair(value);
  };

  const getNumOfRealPositions = (positions) => {
    let numOfRealPositions = 0;
    let numOfShownPositions = 0;
    Object.keys(positions).forEach((pairIndex) => {
      Object.keys(positions[pairIndex]).forEach((tradeIndex) => {
        const position = positions[pairIndex][tradeIndex];
        numOfRealPositions++;
        if (filterBySelectedPair && ourSelectedToken.pairIndex == pairIndex) {
          numOfShownPositions++;
        } else if (!filterBySelectedPair) {
          numOfShownPositions++;
        }
      });
    });
    return { numOfRealPositions, numOfShownPositions };
  };

  const POSITIONS = "Positions";
  const ORDERS = "Orders";
  const TRADES = "Trades";

  const LIST_SECTIONS = [POSITIONS, flagOrdersEnabled && ORDERS, TRADES].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-section-v2", LIST_SECTIONS[0]);
  //"List-section-v2
  //"{"97":"Positions"}"
  const { numOfRealPositions, numOfShownPositions } = positionsStats();

  const LIST_SECTIONS_LABELS = {
    [ORDERS]: ordersLoading
      ? t`Limit Orders (...)`
      : t`Limit Orders ` +
        ` (${getNumOfRealPositions(ourOrders).numOfShownPositions}/${
          getNumOfRealPositions(ourOrders).numOfRealPositions
        })`,
    [POSITIONS]: positionsLoading
      ? t`Open Trades (...)`
      : t`Open Trades ` + ` (${numOfShownPositions}/${numOfRealPositions})`,
    [TRADES]:
      historicTrades.length > 0
        ? t`History ` + ` (${historicTrades.length}/${historicTrades.length})`
        : t`History (0/0) `,
  };

  const LIST_SECTIONS_STATUS = {
    [ORDERS]: ordersLoading,
    [POSITIONS]: positionsLoading,
    [TRADES]: isFetchingHistoricTrades,
  };

  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }

  // if (!getToken(chainId, toTokenAddress)) {
  //   return null;
  // }

  const renderCancelOrderButton = () => {
    if (cancelOrderIdList.length === 0) return;
    return (
      <button
        className="muted font-base cancel-order-btn"
        disabled={isCancelMultipleOrderProcessing}
        type="button"
        onClick={onMultipleCancelClick}
      >
        <Plural value={cancelOrderIdList.length} one="Cancel order" other="Cancel # orders" />
      </button>
    );
  };

  const getListSection = () => {
    return (
      <div>
        <div className="Exchange-list-tab-container">
          <Tab
            options={LIST_SECTIONS}
            optionLabels={LIST_SECTIONS_LABELS}
            optionStatus={LIST_SECTIONS_STATUS}
            option={listSection}
            onChange={(section) => setListSection(section)}
            type="inline"
            className="Exchange-list-tabs"
          />
          <div className="align-right Exchange-should-show-position-lines" style={{ marginTop: "-15px" }}>
            {renderCancelOrderButton()}
            <Checkbox
              isChecked={filterBySelectedPair}
              setIsChecked={toggleFilterBySelectedPair}
              className={cx("muted chart-positions", { active: savedShouldShowPositionLines })}
            >
              <span>{getFilterCheckboxText()}</span>
            </Checkbox>
          </div>
        </div>

        {listSection === POSITIONS && (
          <PositionsList
            onSelectToken={onSelectToken}
            batchData={blockNumber}
            setOverFlow={setOverFlow}
            market24={market24}
            pairs={pairs}
            setOurSelectedToken={setOurSelectedToken}
            latestPrices={latestPrices}
            positionsDataIsLoading={positionsDataIsLoading}
            pendingPositions={pendingPositions}
            setPendingPositions={setPendingPositions}
            setListSection={setListSection}
            setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
            setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
            //approveOrderBook={approveOrderBook}
            //approvePositionRouter={approvePositionRouter}
            isPluginApproving={isPluginApproving}
            isPositionRouterApproving={isPositionRouterApproving}
            isWaitingForPluginApproval={isWaitingForPluginApproval}
            isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
            //orderBookApproved={orderBookApproved}
            positionRouterApproved={positionRouterApproved}
            positions={positions}
            ourPositions={ourPositions}
            positionsMap={positionsMap}
            infoTokens={infoTokens}
            active={active}
            account={account}
            library={library}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
            flagOrdersEnabled={flagOrdersEnabled}
            savedIsPnlInLeverage={savedIsPnlInLeverage}
            chainId={chainId}
            nativeTokenAddress={nativeTokenAddress}
            setMarket={setMarket}
            //orders={orders}
            showPnlAfterFees={savedShowPnlAfterFees}
            minExecutionFee={minExecutionFee}
            minExecutionFeeUSD={minExecutionFeeUSD}
            minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
            usdgSupply={usdgSupply}
            totalTokenWeights={totalTokenWeights}
            tradingV6Contract={tradingV6Contract}
            busdContract={busdContract}
            GFarmTradingStorageV5_Testnet={TradingStorage}
            actualPrice={actualPrice}
            ourSelectedToken={ourSelectedToken}
            filterBySelectedPair={filterBySelectedPair}
            pendingOrders={pendingOrders}
            closingPositionData={closingPositionData}
            setPositionValues={setPositionValues}
          />
        )}
        {listSection === ORDERS && (
          <OrdersList
            setOurSelectedToken={setOurSelectedToken}
            pairs={pairs}
            market24={market24}
            latestPrices={latestPrices}
            account={account}
            active={active}
            library={library}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
            infoTokens={infoTokens}
            positionsMap={positionsMap}
            chainId={chainId}
            totalTokenWeights={totalTokenWeights}
            usdgSupply={usdgSupply}
            savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
            cancelOrderIdList={cancelOrderIdList}
            setCancelOrderIdList={setCancelOrderIdList}
            ourOrders={ourOrders}
            actualPrice={actualPrice}
            tradingV6Contract={tradingV6Contract}
            ourSelectedToken={ourSelectedToken}
            filterBySelectedPair={filterBySelectedPair}
          />
        )}
        {listSection === TRADES && (
          <TradeHistory
            account={account}
            forSingleAccount={true}
            infoTokens={infoTokens}
            getTokenInfo={getTokenInfo}
            chainId={chainId}
            nativeTokenAddress={nativeTokenAddress}
            shouldShowPaginationButtons={true}
            pares={pares}
            tradesWithMessages={historicTrades}
            numberOfDaysRef={numberOfDaysRef}
            setNumberOfDays={setNumberOfDays}
          />
        )}
      </div>
    );
  };

  const onSelectWalletToken = (token) => {
    setFromTokenAddress(swapOption, token.address);
  };

  const renderChart = () => {
    return (
      <ExchangeTVChart
        onSelectToken={onSelectToken}
        pares={pares}
        latestPrices={latestPrices}
        ourSelectedToken={ourSelectedToken}
        cleanPairs={cleanPairs}
        setCleanPairs={setCleanPairs}
        pairs={pairs}
        setPairs={setPairs}
        setOurSelectedToken={setOurSelectedToken}
        fromTokenAddress={fromTokenAddress}
        toTokenAddress={toTokenAddress}
        infoTokens={infoTokens}
        swapOption={swapOption}
        chainId={chainId}
        positions={positions}
        savedShouldShowPositionLines={savedShouldShowPositionLines}
        //orders={orders}
        setActualPrice={setActualPrice}
        setToTokenAddress={setToTokenAddress}
        setOurTokenIndex={setOurTokenIndex}
        formattedLongOpenInterest={formattedLongOpenInterest}
        formattedShortOpenInterest={formattedShortOpenInterest}
        formattedMaxOpenInterest={formattedMaxOpenInterest}
      />
    );
  };

  const [leftWidth, setLeftWidth] = useState(localStorage.getItem("leftWidth") || 200);
  const [leftHeight, setLeftHeight] = useState(localStorage.getItem("leftHeight") || 800);
  const [isResizing, setIsResizing] = useState(false);

  const leftRef = useRef(null);
  const resizeRightRef = useRef(null);
  const resizeBottomRef = useRef(null);

  useEffect(() => {
    // Almacena el tamaño actual en el localStorage cada vez que cambia
    localStorage.setItem("leftWidth", leftWidth);
    localStorage.setItem("leftHeight", leftHeight);
  }, [leftWidth, leftHeight]);

  const handleMouseMove = (e) => {
    if (!isResizing) return;

    if (resizeRightRef.current) {
      const { clientX } = e;
      const { left } = leftRef.current.getBoundingClientRect();
      const offsetX = clientX - left;
      const newLeftWidth = offsetX;

      setLeftWidth(newLeftWidth);
    }
    if (resizeBottomRef.current) {
      const { clientY } = e;
      const { top } = leftRef.current.getBoundingClientRect();
      const offsetY = clientY - top;
      const newLeftHeight = offsetY;

      setLeftHeight(newLeftHeight);
    }
  };

  const handleMouseDown = (e, type) => {
    if (type === "right") {
      resizeRightRef.current = e.target;
    } else if (type === "bottom") {
      resizeBottomRef.current = e.target;
    }
    setIsResizing(true);
  };

  const handleMouseUp = () => {
    resizeRightRef.current = null;
    resizeBottomRef.current = null;
    setIsResizing(false);
  };

  return (
    <div className="Exchange page-layout">
      {isResizing && (
        <Helmet>
          <style>
            {`
              body {
                user-select: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
              }
            `}
          </style>
        </Helmet>
      )}

      {showBanner && <ExchangeBanner hideBanner={hideBanner} />}
      <div
        className="Exchange-content"
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div className="Exchange-left" ref={leftRef} style={{ width: `${leftWidth}px` }}>
          {renderChart()}
          <div className="Exchange-lists-large">{getListSection()}</div>
        </div>

        <div className="resize" onMouseDown={(e) => handleMouseDown(e, "right")}></div>
        <div
          className="Exchange-right"
          style={{ width: `calc(100% - ${leftWidth}px)`, marginRight: "-12px" }}
          onMouseDown={(e) => handleMouseDown(e, "bottom")}
        >
          <SwapBox
            setWalletModalVisible={setWalletModalVisible}
            leftWidth={leftWidth}
            overFlow={overFlow}
            market24={market24}
            pairs={pairs}
            latestPrices={latestPrices}
            ourSelectedToken={ourSelectedToken}
            pendingPositions={pendingPositions}
            setPendingPositions={setPendingPositions}
            setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
            setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
            //approveOrderBook={approveOrderBook}
            approvePositionRouter={approvePositionRouter}
            isPluginApproving={isPluginApproving}
            isPositionRouterApproving={isPositionRouterApproving}
            isWaitingForPluginApproval={isWaitingForPluginApproval}
            isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
            //orderBookApproved={orderBookApproved}
            positionRouterApproved={positionRouterApproved}
            //orders={orders}
            flagOrdersEnabled={flagOrdersEnabled}
            chainId={chainId}
            infoTokens={infoTokens}
            active={active}
            connectWallet={connectWallet}
            library={library}
            account={account}
            positionsMap={positionsMap}
            fromTokenAddress={fromTokenAddress}
            setFromTokenAddress={setFromTokenAddress}
            toTokenAddress={toTokenAddress}
            setToTokenAddress={setToTokenAddress}
            swapOption={swapOption}
            setSwapOption={setSwapOption}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
            tokenSelection={tokenSelection}
            setTokenSelection={setTokenSelection}
            isConfirming={isConfirming}
            setIsConfirming={setIsConfirming}
            isPendingConfirmation={isPendingConfirmation}
            setIsPendingConfirmation={setIsPendingConfirmation}
            savedIsPnlInLeverage={savedIsPnlInLeverage}
            setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
            nativeTokenAddress={nativeTokenAddress}
            savedSlippageAmount={savedSlippageAmount}
            totalTokenWeights={totalTokenWeights}
            usdgSupply={usdgSupply}
            savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
            minExecutionFee={minExecutionFee}
            minExecutionFeeUSD={minExecutionFeeUSD}
            minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
            tradingV6Contract={tradingV6Contract}
            busdContract={busdContract}
            GFarmTradingStorageV5_Testnet={TradingStorage}
            setTakeProfit={setTakeProfit}
            takeProfit={takeProfit}
            setStopLoss={setStopLoss}
            stopLoss={stopLoss}
            actualPrice={actualPrice}
            userBusdBalance={formatAmount(busdBalance, USDG_DECIMALS, 2, false)}
            spread={spread}
            onePercentDepthAbove={onePercentDepthAbove}
            onePercentDepthBelow={onePercentDepthBelow}
            minLeverage={minLeverage}
            maxLeverage={maxLeverage}
            //getOurPositions={getOurPositions}
            //getOurOrders={getOurOrders}
            longOpenInterest={longOpenInterest}
            shortOpenInterest={shortOpenInterest}
            maxOpenInterest={maxOpenInterest}
            groupCollateralLong={groupCollateralLong}
            groupCollateralShort={groupCollateralShort}
            groupMaxCollateral={groupMaxCollateral}
            minLevPosDai={minLevPosDai}
            openFee={openFee}
            maxCollateralP={maxCollateralP}
            tvl={tvl}
            ourOrders={ourOrders}
          />
          <div className="Exchange-wallet-tokens">
            <div className="Exchange-wallet-tokens-content">
              {/* <ExchangeWalletTokens tokens={tokens} infoTokens={infoTokens} onSelectToken={onSelectWalletToken} />*/}
            </div>
          </div>
        </div>
        <div className="Exchange-lists-small">{getListSection()}</div>
      </div>
    </div>
  );
});

import React, { useEffect, useState, useRef, useContext } from "react";

import { useChainId } from "lib/chains";
import { waitForOpenConnection } from "lib/sockets";
import { useWeb3React } from "@web3-react/core";
import { IS_PROD } from "config/context";
import AppContext from "AppContext";

export function useEventsRelayer() {
  const { active, account, library } = useWeb3React();
  const { chainId } = useChainId();
  const { MODE, filteredEndpointsInfo } = useContext(AppContext);

  const [eventsSocketOpenning, _setEventsSocketOpenning] = useState(false);
  const eventsSocketOpenningRef = React.useRef(eventsSocketOpenning);
  const setEventsSocketOpenning = (data) => {
    eventsSocketOpenningRef.current = data;
    _setEventsSocketOpenning(data);
  };
  const [heartbeatTime, _setHeartbeatTime] = React.useState(0);
  const heartbeatTimeRef = React.useRef(heartbeatTime);
  const setHeartbeatTime = (data) => {
    heartbeatTimeRef.current = data;
    _setHeartbeatTime(data);
  };
  const [currentEvent, setCurrentEvent] = useState(null);
  const eventSocket = useRef(null);
  const eventSocketTimeout = 20;

  const handleWsEvents = async (event) => {
    let object;
    try {
      object = JSON.parse(event.data);
    } catch (error) {
      console.error(error);
      return;
    }
    if (object.event == "Heartbeat") {
      setHeartbeatTime(Date.now());
      return;
    }

    if (object.trader != account) {
      return;
    }
    !IS_PROD && console.log("@@@@@ eventsRelayer", object);
    setCurrentEvent(object);
  };

  const setupEventRelayerWebsocket = async () => {
    //const eventsUrl = getUrl(PROD, "EVENTS_RELAYER");
    const eventsUrl = filteredEndpointsInfo.events_relayer;
    if (eventSocket.current) {
      await waitForOpenConnection(eventSocket.current);
      IS_PROD || console.log(`Loging out and logging in again ${account}:${chainId}`);
      eventSocket.current.send(`/logout`);
      eventSocket.current.send(`/login:${account}:${chainId}`);
      eventSocket.current.send(`/getInitialTrades`);
      //setInitialTradesLoading(1);
    } else {
      eventSocket.current = new WebSocket(eventsUrl);
    }

    eventSocket.current.onopen = () => {
      setEventsSocketOpenning(false);
      IS_PROD || console.log(`WebSocket connection to ${eventsUrl} opened, trying to login with ${account}:${chainId}`);
      eventSocket.current.send(`/login:${account}:${chainId}`);
      eventSocket.current.send(`/getInitialTrades`);
    };

    eventSocket.current.onmessage = handleWsEvents;

    eventSocket.current.onclose = () => {
      IS_PROD || console.log(`WebSocket connection to ${eventsUrl} closed. Retrying in 3 seconds...`);

      setEventsSocketOpenning(true);
      setHeartbeatTime(0);
      eventSocket.current = null;
      // Reconnect the WebSocket after a delay
      setTimeout(setupEventRelayerWebsocket, 3000);
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!heartbeatTimeRef.current) return;
      const offset = (Date.now() - heartbeatTimeRef.current) / 1000;

      if (offset > eventSocketTimeout && !eventsSocketOpenningRef.current) {
        IS_PROD || console.log("Heartbeat timeout, reconnecting");
        setEventsSocketOpenning(true);
        setHeartbeatTime(0);
        eventSocket.current.close();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!account) return;
    setupEventRelayerWebsocket();
  }, [account, chainId]);

  return { event: currentEvent };
}

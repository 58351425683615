import { useLocation, Link } from "react-router-dom";
import { Dropdown, NavDropdown } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./Header.css";
import { useState, useEffect } from "react";

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
  openSettings?: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

function NavLink({
  to,
  children,
  className,
  style,
}: {
  to: string;
  children: React.ReactNode;
  className?: string;
  style?: any;
}) {
  let location = useLocation();
  let query = location.search;

  return (
    <Link className={className} style={style} to={to + query}>
      {children}
    </Link>
  );
}

export function AppHeaderLinks({
  small,
  openSettings,
  clickCloseIcon,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [actualDisable, setActualDisable] = useState(false);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">Please, report bugs in "#bug-reporting" Discord channel.</Tooltip>
  );

  const location = useLocation();

  const handleDropdownToggle = (e) => {
    e.preventDefault();
    setDropdownOpen(!isDropdownOpen);
  };

  const actualUrl = window.location.href;

  useEffect(() => {
    if (actualUrl === "http://localhost:3000/#/dashboard") {
      setActualDisable(true);
    }
  }, [actualUrl]);

  return (
    <nav className="navbar navbar-dark">
      <div className="container">
        <Link className="btn" to="/trading">
          Trading
        </Link>

        <NavDropdown
          title="Earn"
          id="nav-dropdown"
          menuVariant="dark"
          style={{ backgroundColor: "#111117 !important", marginLeft: "7px" }}
        >
          <NavDropdown.Item className="dropdown-item" eventKey="4.1">
            <Link to="/vault">Vault</Link>
          </NavDropdown.Item>
          <NavDropdown.Item className="dropdown-item">
            <Link to="/otc">OTC</Link>
          </NavDropdown.Item>
          <NavDropdown.Item className="dropdown-item">
            <Link
              to="/#"
              className="disable"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ padding: 5, textAlign: "left", color: "#808080" }}
              aria-disabled
            >
              Staking
              <sup style={{ color: "#D1A35A" }}> Soon!</sup>
            </Link>
          </NavDropdown.Item>
        </NavDropdown>

        <NavDropdown
          title="Leaderboards"
          id="nav-dropdown"
          menuVariant="dark"
          style={{ backgroundColor: "#111117 !important", marginLeft: "7px" }}
        >
          <NavDropdown.Item className="dropdown-item" eventKey="4.1">
            <Link
              to="/general-trading/leaderboard"
              className={`pitchdeck`}
            >
              General Trading
            </Link>
          </NavDropdown.Item>
{/* 
          <NavDropdown.Item className="dropdown-item" eventKey="4.1">
            <Link
              to="/loyalty-program/leaderboard"
              className={`pitchdeck ${
                location.pathname == "/loyalty-program/leaderboard" ? "active-link" : "inactive-link"
              }`}
            >
              Loyalty Points
            </Link>
          </NavDropdown.Item> */}

          <NavDropdown
            title="Trading Contest"
            menuVariant="dark"
            style={{ backgroundColor: "#111117", marginLeft: "7px", padding: "10px 0px" }}
          >
            <NavDropdown.Item className="dropdown-item" eventKey="4.1">
              <Link
                to="/trading-contest/round-1/leaderboard"
                className={`pitchdeck`}
              >
                Round 1
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item className="dropdown-item">
              <Link
                to="/trading-contest/round-2/leaderboard"
                className={`pitchdeck`}
              >
                Round 2
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item className="dropdown-item">
              <Link
                to="/trading-contest/round-3/leaderboard"
                className={`pitchdeck`}
              >
                Round 3
              </Link>
            </NavDropdown.Item>
          </NavDropdown>
        </NavDropdown>

        <Link to="/stats" className="padding-link" style={{ padding: 5, textAlign: "left", color: "#DEDEDE", marginLeft: "12px" }}>
          Stats
        </Link>

        <Link to="/loyalty-program" className="padding-link" style={{ padding: 5, textAlign: "left", color: "#DEDEDE", marginLeft: "12px" }}>
          Loyalty Program
        </Link>

        <a
          href="https://discord.com/invite/UnqsFgdk9N"
          title="Please, report bugs in bug-reporting"
          target="_blank"
          rel="noreferrer"
        >
          Bug Reporting
        </a>

        {/* <Link className="btn" to="/contacto">
          Statics
        </Link>
        <Link className="btn" to="/contacto">
          Documentation
        </Link> */}
      </div>
    </nav>
  );
}

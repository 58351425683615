import { Trans, t, select } from "@lingui/macro";

const OrdersListHeader = () => {
  return (
    <div
      style={{
        display: "flex",
        marginTop: "-25px",
        justifyContent: "space-between",
        fontSize: "14px",
        backgroundColor: "#303030",
        padding: "5px 0px",
      }}
      id="OrdersListHeader"
    >
      <div style={{ textAlign: "center", width: "10%", color: "#828785" }}>
        <Trans>Type</Trans>
        {/* Ok */}
      </div>
      <div style={{ textAlign: "center", width: "10%", color: "#828785" }}>
        <Trans>Pair</Trans>
        {/* Ok */}
      </div>
      <div className="hide" style={{ textAlign: "center", width: "10%", color: "#828785" }}>
        <Trans>Position</Trans>
        {/* Ok */}
      </div>

      <div className="hide" style={{ textAlign: "center", width: "10%", color: "#828785" }}>
        <Trans>Collateral</Trans>
        {/* Ok */}
      </div>

      <div className="hide" style={{ textAlign: "center", width: "10%", color: "#828785" }}>
        <Trans>Leverage</Trans>
        {/* Ok */}
      </div>

      <div className="hide" style={{ textAlign: "center", width: "10%", color: "#828785" }}>
        <Trans>Current price</Trans>
        {/* Ok */}
      </div>

      <div className="hide" style={{ textAlign: "center", width: "10%", color: "#828785" }}>
        <Trans>Open price</Trans>
        {/* Ok */}
      </div>

      <div style={{ textAlign: "center", width: "10%", color: "#828785" }}>
        <Trans>Stop Loss</Trans>
        {/* Ok */}
      </div>

      <div style={{ textAlign: "center", width: "10%", color: "#828785" }}>
        <Trans>Take Profit</Trans>
        {/* Ok */}
      </div>
      <div style={{ textAlign: "center", width: "10%", color: "#828785" }}>
        <Trans>Actions</Trans>
        {/* Ok */}
      </div>
    </div>
  );
};

export default OrdersListHeader;

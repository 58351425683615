import React, { useEffect, useState } from "react";
import "./TradingCompetition.css";
import { TradingCompetitorUserStatic } from "components/TradingCompetition/TradingCompetitorUserStatic";

export const Round1 = () => {
  const dataLeaderboard = [
    {
      position: "🏆",
      address: "0xd257....CCC7c0",
      rank: 73,
      percentage: "74.0%",
      balance: "53670.39$",
    },
    {
      position: "🥈",
      address: "0xDF6E....8d352A",
      rank: 8,
      percentage: "50.0%",
      balance: "39151.43$",
    },
    {
      position: "🥉",
      address: "0x3960....dE5271",
      rank: 27,
      percentage: "37.0%",
      balance: "38597.95$",
    },
    {
      position: "4",
      address: "0xA7b2....619a5c",
      rank: 3,
      percentage: "66.7%",
      balance: "33698.33$",
    },
    {
      position: "5",
      address: "0xc619....e90883",
      rank: 3,
      percentage: "33.3%",
      balance: "33658.97$",
    },
    {
      position: "6",
      address: "0x86A2....78476e",
      rank: 7,
      percentage: "28.6%",
      balance: "25331.12$",
    },
    {
      position: "7",
      address: "0x720F....81A348",
      rank: 11,
      percentage: "72.7%",
      balance: "19586.08$",
    },
    {
      position: "8",
      address: "0x5A55....0E0814",
      rank: 29,
      percentage: "34.5%",
      balance: "15007.00$",
    },
    {
      position: "9",
      address: "0x26D5....5ca348",
      rank: 5,
      percentage: "80.0%",
      balance: "14203.90$",
    },
    {
      position: "10",
      address: "0x03B3....2bD37D",
      rank: 13,
      percentage: "46.2%",
      balance: "14127.58$",
    },
    {
      position: "11",
      address: "0x1D29....55Cbf8",
      rank: 3,
      percentage: "100.0%",
      balance: "8160.94$",
    },
    {
      position: "12",
      address: "0x6170....0D4Eef",
      rank: 7,
      percentage: "14.3%",
      balance: "2639.59$",
    },
    {
      position: "13",
      address: "0x176f....b143b9",
      rank: 4,
      percentage: "75.0%",
      balance: "1524.26$",
    },
    {
      position: "14",
      address: "0x50C7....d72E51",
      rank: 35,
      percentage: "60.0%",
      balance: "1462.27$",
    },
    {
      position: "15",
      address: "0x2898....7dC7f6",
      rank: 23,
      percentage: "60.9%",
      balance: "1356.51$",
    },
    {
      position: "16",
      address: "0x26DB....f010c1",
      rank: 2,
      percentage: "50.0%",
      balance: "1021.77$",
    },
    {
      position: "17",
      address: "0x2fB0....3480FF",
      rank: 20,
      percentage: "80.0%",
      balance: "856.66$",
    },
    {
      position: "18",
      address: "0x5710....b51400",
      rank: 2,
      percentage: "50.0%",
      balance: "594.25$",
    },
    {
      position: "19",
      address: "0x38B7....3fD6F1",
      rank: 8,
      percentage: "62.5%",
      balance: "337.81$",
    },
    {
      position: "20",
      address: "0x34c7....067a3a",
      rank: 2,
      percentage: "100.0%",
      balance: "204.34$",
    },
    {
      position: "21",
      address: "0xA5b2....347305",
      rank: 1,
      percentage: "100.0%",
      balance: "26.15$",
    },
    {
      position: "22",
      address: "0x44aa....5A5d23",
      rank: 1,
      percentage: "100.0%",
      balance: "0.38$",
    },
    {
      position: "23",
      address: "0x6FDC....936707",
      rank: 3,
      percentage: "33.3%",
      balance: "-97.77$",
    },
    {
      position: "24",
      address: "0x75cA....4aD3d6",
      rank: 3,
      percentage: "33.3%",
      balance: "-160.76$",
    },
    {
      position: "25",
      address: "0x1aa4....d2A422",
      rank: 10,
      percentage: "20.0%",
      balance: "-391.24$",
    },
    {
      position: "26",
      address: "0x173a....20dF08",
      rank: 16,
      percentage: "18.8%",
      balance: "-397.35$",
    },
    {
      position: "27",
      address: "0xc76E....d80399",
      rank: 15,
      percentage: "20.0%",
      balance: "-525.60$",
    },
    {
      position: "28",
      address: "0x65C3....d02b03",
      rank: 1,
      percentage: "0.0%",
      balance: "-595.81$",
    },
    {
      position: "29",
      address: "0xC698....E469dB",
      rank: 3,
      percentage: "0.0%",
      balance: "-705.99$",
    },
    {
      position: "30",
      address: "0x1d97....a6E202",
      rank: 2,
      percentage: "0.0%",
      balance: "-728.06$",
    },
    {
      position: "31",
      address: "0x59a2....638106",
      rank: 2,
      percentage: "0.0%",
      balance: "-942.04$",
    },
    {
      position: "32",
      address: "0x8C97....4Bf82e",
      rank: 5,
      percentage: "0.0%",
      balance: "-1779.46$",
    },
    {
      position: "33",
      address: "0x1D65....55f423",
      rank: 2,
      percentage: "50.0%",
      balance: "-2366.43$",
    },
    {
      position: "34",
      address: "0x16aE....8f8aCb",
      rank: 29,
      percentage: "51.7%",
      balance: "-3030.32$",
    },
    {
      position: "35",
      address: "0x75Ed....40559f",
      rank: 7,
      percentage: "14.3%",
      balance: "-4166.47$",
    },
    {
      position: "36",
      address: "0x1F2b....8AaDd3",
      rank: 18,
      percentage: "50.0%",
      balance: "-4252.45$",
    },
    {
      position: "37",
      address: "0xD912....43AFD2",
      rank: 1,
      percentage: "0.0%",
      balance: "-7899.21$",
    },
    {
      position: "38",
      address: "0x346f....A137F3",
      rank: 21,
      percentage: "61.9%",
      balance: "-11420.31$",
    },
    {
      position: "39",
      address: "0x9978....630428",
      rank: 78,
      percentage: "26.9%",
      balance: "-11910.24$",
    },
    {
      position: "40",
      address: "0xbF13....2cdB99",
      rank: 43,
      percentage: "46.5%",
      balance: "-12632.20$",
    },
    {
      position: "41",
      address: "0x070E....f616d6",
      rank: 25,
      percentage: "28.0%",
      balance: "-13338.79$",
    },
    {
      position: "42",
      address: "0x4293....E5968d",
      rank: 2,
      percentage: "0.0%",
      balance: "-13659.48$",
    },
    {
      position: "43",
      address: "0x5477....9D195a",
      rank: 2,
      percentage: "0.0%",
      balance: "-15800.00$",
    },
    {
      position: "44",
      address: "0x56e2....2DA061",
      rank: 3,
      percentage: "0.0%",
      balance: "-15800.00$",
    },
    {
      position: "45",
      address: "0x5869....48928a",
      rank: 2,
      percentage: "0.0%",
      balance: "-15800.00$",
    },
    {
      position: "46",
      address: "0x5fDc....8a0AAD",
      rank: 10,
      percentage: "0.0%",
      balance: "-18023.92$",
    },
    {
      position: "47",
      address: "0xD94E....5Bb71d",
      rank: 5,
      percentage: "60.0%",
      balance: "-18511.70$",
    },
    {
      position: "48",
      address: "0x7a74....632437",
      rank: 10,
      percentage: "40.0%",
      balance: "-20104.62$",
    },
    {
      position: "49",
      address: "0xD6A1....17F650",
      rank: 5,
      percentage: "0.0%",
      balance: "-23700.00$",
    },
    {
      position: "50",
      address: "0x8cF6....684BA3",
      rank: 5,
      percentage: "0.0%",
      balance: "-23700.00$",
    },
    {
      position: "51",
      address: "0x3e8A....c02116",
      rank: 5,
      percentage: "0.0%",
      balance: "-23700.00$",
    },
    {
      position: "52",
      address: "0x9FDa....D8cE7A",
      rank: 5,
      percentage: "0.0%",
      balance: "-23700.00$",
    },
    {
      position: "53",
      address: "0xBeE7....9C53B7",
      rank: 3,
      percentage: "0.0%",
      balance: "-23700.00$",
    },
    {
      position: "54",
      address: "0x7d8F....756B63",
      rank: 3,
      percentage: "0.0%",
      balance: "-23700.00$",
    },
    {
      position: "55",
      address: "0xa432....3429c5",
      rank: 6,
      percentage: "0.0%",
      balance: "-24332.00$",
    },
    {
      position: "56",
      address: "0xe3a6....5FDb6d",
      rank: 13,
      percentage: "0.0%",
      balance: "-31363.00$",
    },
    {
      position: "57",
      address: "0x5e31....Df1bFF",
      rank: 7,
      percentage: "0.0%",
      balance: "-33744.83$",
    },
    {
      position: "58",
      address: "0xF136....Ce55cE",
      rank: 3,
      percentage: "0.0%",
      balance: "-39500.00$",
    },
    {
      position: "59",
      address: "0xab8D....Ba918C",
      rank: 8,
      percentage: "0.0%",
      balance: "-40204.39$",
    },
    {
      position: "60",
      address: "0x67d1....9E4F99",
      rank: 2,
      percentage: "0.0%",
      balance: "-44761.48$",
    },
    {
      position: "61",
      address: "0x9f34....ACd61D",
      rank: 4,
      percentage: "0.0%",
      balance: "-51817.28$",
    },
    {
      position: "62",
      address: "0x0dEE....C1a99e",
      rank: 3,
      percentage: "0.0%",
      balance: "-53159.31$",
    },
    {
      position: "63",
      address: "0xbfC3....977250",
      rank: 4,
      percentage: "0.0%",
      balance: "-59442.13$",
    },
  ];

  const updatedDataLeaderboard = dataLeaderboard.map((item) => ({
    ...item,
    balance: item.balance.replace("$", ""),
  }));

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
      <div className="TradingTitle">
        <h1 className="orange-title">🏆 TRADING CONTEST LEADERBOARD - ROUND 1 🏆</h1>
      </div>

      <div className="TradingButtons">
        <p style={{ fontSize: "18px" }}>From November 19, 2023, at 10:00 UTC to December 3, 2023, at 10:00 UTC</p>
      </div>

      <div className="TradingCompetition">
        <div className="TradingCompetitionHeader">
          <div className="competition-info">Rank</div>
          <div className="competition-info">Address</div>
          <div className="competition-info">Trades</div>
          <div className="competition-info">Winrate</div>
          <div className="competition-info">PnL</div>
        </div>
      </div>

      {Array.isArray(updatedDataLeaderboard)
        ? updatedDataLeaderboard.map((tradingCompetitor, index) => {
            return <TradingCompetitorUserStatic index={index + 1} tradingCompetitor={tradingCompetitor} key={index} />;
          })
        : ""}
    </div>
  );
};

import React, { useContext, useState } from "react";
import { Trans, t, select } from "@lingui/macro";
// import Tooltip from "../Tooltip/Tooltip";
import PositionSeller from "./PositionSeller";
import PositionEditor from "./PositionEditor";
import OrdersToa from "./OrdersToa";
import Modal from "../Modal/Modal";
import PositionsListHeader from "./PositionsListHeader";

import { formatAmount } from "lib/numbers";
import { getLeverage, E10_DECIMALS } from "lib/legacy";
import { helperToast } from "lib/helperToast";
import { getUsd } from "domain/tokens/utils";

import RegularPositions from "./RegularPositions";

import { PendingPosition, PendingPositions } from "./PendingPositions";
import { POS_STATUS_STUCK, POS_STATUS_NORMAL } from "./PositionsLogic";
import AppContext from "AppContext";

export default function PositionsList(props) {
  const {
    batchData,
    setOurSelectedToken,
    pendingPositions,
    setPendingPositions,
    ourPositions,
    positionsMap,
    infoTokens,
    active,
    account,
    library,
    pendingTxns,
    setPendingTxns,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    chainId,
    nativeTokenAddress,
    orders,
    setIsWaitingForPluginApproval,
    approveOrderBook,
    isPluginApproving,
    isWaitingForPluginApproval,
    orderBookApproved,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    setMarket,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
    usdgSupply,
    totalTokenWeights,
    tradingV6Contract,
    positionsLoading,
    ourSelectedToken,
    filterBySelectedPair,
    setOverFlow,
    pendingOrders,
    closingPositionData,
    setPositionValues,
    onSelectToken,
  } = props;

  const [positionToEditKey, setPositionToEditKey] = useState(undefined);
  const [positionToSellKey, setPositionToSellKey] = useState(undefined);
  // const [positionToShare, setPositionToShare] = useState(null);
  const [isPositionEditorVisible, setIsPositionEditorVisible] = useState(undefined);
  const [isPositionSellerVisible, setIsPositionSellerVisible] = useState(undefined);
  const [collateralTokenAddress, setCollateralTokenAddress] = useState(undefined);
  const [isEditTakeProfitStopLossModalOpen, setIsEditTakeProfitStopLossModalOpen] = useState(false);
  const [ordersToaOpen, setOrdersToaOpen] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState();
  const [_TakeProfit, set_TakeProfit] = useState();
  const [_StopLoss, set_StopLoss] = useState();
  const [_LiquidationPrice, set_LiquidationPrice] = useState();
  const [par, setPar] = useState();

  // estado para guardar si se está en periodo de espera para cerrar el trade
  const [waitingToClose, setWaitingToClose] = useState(false);

  const closePosition = (position) => {
    tradingV6Contract
      .closeTradeMarket(position.pairIndex, position.index)
      .then((v) => {
        //TODO: Usar un toast o algo para informar de que se ha ejecutado la orden correctamente
        helperToast.success("The trade closing has been requested successfully");
        closingPositionData.current = { pairIndex: position.pairIndex, index: position.index };
      })
      .catch((response) => {
        console.log("errormsg closeTradeMarket: " + response);
      })
      .finally(() => {
        // restablecer el estado de waitingToClose a falso cuando la promesa se resuelve
        setWaitingToClose(false);
      });
  };

  const closeStuckPosition = (position) => {
    if (position.open)
      tradingV6Contract
        .openTradeMarketTimeout(position.orderId)
        .then((v) => {
          helperToast.success("The openTradeMarketTimeout has been requested successfully");
        })
        .catch((response) => {
          console.log("errormsg openTradeMarketTimeout: " + response);
        })
        .finally(() => {
          setWaitingToClose(false);
        });
    else {
      console.log("closeTradeMarketTimeout", position);
      tradingV6Contract
        .closeTradeMarketTimeout(position.orderId)
        .then((v) => {
          helperToast.success("The closeTradeMarketTimeout has been requested successfully");
          if (position.status === POS_STATUS_STUCK)
            setPositionValues(position.pairIndex, position.index, { status: POS_STATUS_NORMAL });
        })
        .catch((response) => {
          console.log("errormsg closeTradeMarketTimeout: " + response);
        })
        .finally(() => {
          setWaitingToClose(false);
        });
    }
  };

  const editTakeProfit = () => {
    const TPValue = parseInt(_TakeProfit * 1e10);
    tradingV6Contract
      .updateTp(selectedPosition.pairIndex, selectedPosition.index, TPValue)
      .then((v) => {
        helperToast.success("The order " + selectedPosition.index + " has been edited successfully");
      })
      .catch((response) => {
        console.log("errormsg updateTp: " + response);
      });
  };

  const editStopLoss = () => {
    const SLValue = parseInt(_StopLoss * 1e10);
    tradingV6Contract
      .updateSl(selectedPosition.pairIndex, selectedPosition.index, SLValue)
      .then((v) => {
        helperToast.success("The order " + selectedPosition.index + " has been edited successfully");
      })
      .catch((err) => {
        console.log("errormsg updateSl: " + err);
        if (err.code == 4001) {
          helperToast.error("User has denied the transaction");
        } else if (err.code == -32603) {
          helperToast.error("The transaction has been reverted");
        } else if (err.reason.includes("SL_TOO_BIG")) {
          const maxSlDist = (selectedPosition.openPrice * 75) / 100 / selectedPosition.leverage;
          const minPrice = selectedPosition.buy
            ? selectedPosition.openPrice - maxSlDist
            : selectedPosition.openPrice + maxSlDist;
          const lowerOrHigher = selectedPosition.buy ? "lower" : "higher";
          helperToast.error(
            `SL cannot be ${lowerOrHigher} than ` + formatAmount(parseInt(minPrice), E10_DECIMALS, 2, false)
          );
        }
      });
  };

  return (
    <div className="PositionsList">
      <PositionEditor
        pendingPositions={pendingPositions}
        setPendingPositions={setPendingPositions}
        positionsMap={positionsMap}
        positionKey={positionToEditKey}
        isVisible={isPositionEditorVisible}
        setIsVisible={setIsPositionEditorVisible}
        infoTokens={infoTokens}
        active={active}
        account={account}
        library={library}
        collateralTokenAddress={collateralTokenAddress}
        pendingTxns={pendingTxns}
        setPendingTxns={setPendingTxns}
        getUsd={getUsd}
        getLeverage={getLeverage}
        savedIsPnlInLeverage={savedIsPnlInLeverage}
        positionRouterApproved={positionRouterApproved}
        isPositionRouterApproving={isPositionRouterApproving}
        isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
        approvePositionRouter={approvePositionRouter}
        chainId={chainId}
        minExecutionFee={minExecutionFee}
        minExecutionFeeUSD={minExecutionFeeUSD}
        minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
      />
      {ordersToaOpen && (
        <OrdersToa
          setIsVisible={setOrdersToaOpen}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
      {isEditTakeProfitStopLossModalOpen && (
        <Modal
          className="position-share-modal"
          isVisible={isEditTakeProfitStopLossModalOpen}
          setIsVisible={setIsEditTakeProfitStopLossModalOpen}
          label={t`Update Take profit/Stop loss`}
          style={{ marginBottom: "none" }}
        >
          <label
            htmlFor="takeProfitBoxModal"
            className="label-modal"
            style={{ textTransform: "uppercase", marginBottom: "3px", fontSize: "12px" }}
          >
            Take Profit
          </label>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <input
              id="takeProfitBoxModal"
              type="number"
              className=""
              placeholder="Take profit"
              autoComplete="off"
              onChange={(e) => set_TakeProfit(e.target.value)}
              value={_TakeProfit}
              style={{ height: "35px", borderRadius: "2px" }}
            />
            <div>
              <button
                style={{
                  textTransform: "uppercase",
                  backgroundColor: "#1A4027",
                  color: "white",
                  height: "35px",
                  borderRadius: "2px",
                }}
                // disabled={position.size.eq(0)}
                onClick={() => editTakeProfit()}
                id="buttonTP"
              >
                <Trans>Update</Trans>
              </button>
            </div>
          </div>

          <label
            htmlFor="stopLossBoxModal"
            className="label-modal"
            style={{ textTransform: "uppercase", marginTop: "20px", marginBottom: "3px", fontSize: "12px" }}
          >
            Stop Loss
          </label>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <input
              id="stopLossBoxModal"
              type="number"
              className=""
              placeholder="Stop Loss"
              autoComplete="off"
              onChange={(e) => set_StopLoss(e.target.value)}
              value={_StopLoss}
              style={{ height: "35px", borderRadius: "2px" }}
            />
            <button
              style={{
                textTransform: "uppercase",
                backgroundColor: "#1A4027",
                color: "white",
                height: "35px",
                borderRadius: "2px",
              }}
              // disabled={position.size.eq(0)}
              onClick={() => editStopLoss()}
              id="buttonSL"
            >
              <Trans>Update</Trans>
            </button>
          </div>

          <p className="label-modal">Liquidation price: {_LiquidationPrice}</p>
        </Modal>
      )}
      {ordersToaOpen && (
        <OrdersToa
          setIsVisible={setOrdersToaOpen}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
      {isPositionSellerVisible && (
        <PositionSeller
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
          isWaitingForPluginApproval={isWaitingForPluginApproval}
          orderBookApproved={orderBookApproved}
          positionsMap={positionsMap}
          positionKey={positionToSellKey}
          isVisible={isPositionSellerVisible}
          setIsVisible={setIsPositionSellerVisible}
          infoTokens={infoTokens}
          active={active}
          account={account}
          orders={orders}
          library={library}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          flagOrdersEnabled={flagOrdersEnabled}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          setOrdersToaOpen={setOrdersToaOpen}
          positionRouterApproved={positionRouterApproved}
          isPositionRouterApproving={isPositionRouterApproving}
          isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
          approvePositionRouter={approvePositionRouter}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          minExecutionFee={minExecutionFee}
          minExecutionFeeUSD={minExecutionFeeUSD}
          minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
          usdgSupply={usdgSupply}
          totalTokenWeights={totalTokenWeights}
        />
      )}
      <table className="Exchange-list large App-box">
        <tbody>{/*renderTableHeader()*/}</tbody>
      </table>
      {ourPositions && (
        <div className="Exchange-list" id="exchange-lists">
          <div>
            {Object.keys(ourPositions).length === 0 && positionsLoading === 1 && (
              <div
                className="Exchange-empty-positions-list-note App-card"
                style={{ background: "none", padding: "8px" }}
              >
                <p style={{ marginTop: "30px" }}>...</p>
              </div>
            )}
            {Object.keys(ourPositions).length === 0 && positionsLoading === 0 && (
              <div
                className="Exchange-empty-positions-list-note App-card"
                style={{ background: "none", padding: "8px" }}
              >
                <p>No open positions</p>
              </div>
            )}
            {(Object.keys(ourPositions).length > 0 || Object.keys(pendingOrders).length > 0) && <PositionsListHeader />}

            <PendingPositions
              onSelectToken={onSelectToken}
              pendingOrders={pendingOrders}
              chainId={chainId}
              batchData={batchData}
              closeStuckPosition={closeStuckPosition}
              setOurSelectedToken={setOurSelectedToken}
            />

            <RegularPositions
              onSelectToken={onSelectToken}
              account={account}
              chainId={chainId}
              batchData={batchData}
              ourPositions={ourPositions}
              setOverFlow={setOverFlow}
              closePosition={closePosition}
              closeStuckPosition={closeStuckPosition}
              ourSelectedToken={ourSelectedToken}
              setOurSelectedToken={setOurSelectedToken}
              set_TakeProfit={set_TakeProfit}
              set_StopLoss={set_StopLoss}
              set_LiquidationPrice={set_LiquidationPrice}
              setIsEditTakeProfitStopLossModalOpen={setIsEditTakeProfitStopLossModalOpen}
              setSelectedPosition={setSelectedPosition}
              filterBySelectedPair={filterBySelectedPair}
            />
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import cx from "classnames";
import { Resizable } from "re-resizable";

// import { createChart } from "krasulya-lightweight-charts";

import { USD_DECIMALS, SWAP, INCREASE, CHART_PERIODS, getLiquidationPrice } from "lib/legacy";
import { useChartPrices } from "domain/legacy";
// import Tab from "../Tab/Tab";

import ChartTokenSelector from "./ChartTokenSelector";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { getTokenInfo } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import { formatAmount } from "lib/numbers";
import { getToken, getTokens } from "config/tokens";
import { formatDateTime } from "lib/dates";
import { t, Trans } from "@lingui/macro";
import Tooltip from "../Tooltip/Tooltip";
import TradingViewWidget from "./TradingView";
import { FavoritesHeader } from "./FavoritesHeader";
import { TVChartContainer } from "./TVChartContainer";
import { Banner } from "./Banners";

const PRICE_LINE_TEXT_WIDTH = 15;

const timezoneOffset = -new Date().getTimezoneOffset() * 60;

export function getChartToken(swapOption, fromToken, toToken, chainId) {
  if (!fromToken || !toToken) {
    return;
  }

  if (swapOption !== SWAP) {
    return toToken;
  }

  if (fromToken.isUsdg && toToken.isUsdg) {
    return getTokens(chainId).find((t) => t.isStable);
  }
  if (fromToken.isUsdg) {
    return toToken;
  }
  if (toToken.isUsdg) {
    return fromToken;
  }

  if (fromToken.isStable && toToken.isStable) {
    return toToken;
  }
  if (fromToken.isStable) {
    return toToken;
  }
  if (toToken.isStable) {
    return fromToken;
  }

  return toToken;
}

const DEFAULT_PERIOD = "4h";

// const getSeriesOptions = () => ({
//   // https://github.com/tradingview/lightweight-charts/blob/master/docs/area-series.md
//   lineColor: "#5472cc",
//   topColor: "rgba(49, 69, 131, 0.4)",
//   bottomColor: "rgba(42, 64, 103, 0.0)",
//   lineWidth: 2,
//   priceLineColor: "#3a3e5e",
//   downColor: "#E23E3E",
//   wickDownColor: "#E23E3E",
//   upColor: "#0ecc83",
//   wickUpColor: "#0ecc83",
//   borderVisible: false,
// });

// const getChartOptions = (width, height) => ({
//   width,
//   height,
//   layout: {
//     backgroundColor: "rgba(255, 255, 255, 0)",
//     textColor: "#ccc",
//     fontFamily: "Relative",
//   },
//   localization: {
//     // https://github.com/tradingview/lightweight-charts/blob/master/docs/customization.md#time-format
//     timeFormatter: (businessDayOrTimestamp) => {
//       return formatDateTime(businessDayOrTimestamp - timezoneOffset);
//     },
//   },
//   grid: {
//     vertLines: {
//       visible: true,
//       color: "rgba(35, 38, 59, 1)",
//       style: 2,
//     },
//     horzLines: {
//       visible: true,
//       color: "rgba(35, 38, 59, 1)",
//       style: 2,
//     },
//   },
//   // https://github.com/tradingview/lightweight-charts/blob/master/docs/time-scale.md#time-scale
//   timeScale: {
//     rightOffset: 5,
//     borderVisible: false,
//     barSpacing: 5,
//     timeVisible: true,
//     fixLeftEdge: true,
//   },
//   // https://github.com/tradingview/lightweight-charts/blob/master/docs/customization.md#price-axis
//   priceScale: {
//     borderVisible: false,
//   },
//   crosshair: {
//     horzLine: {
//       color: "#aaa",
//     },
//     vertLine: {
//       color: "#aaa",
//     },
//     mode: 0,
//   },
// });

export default function ExchangeTVChart(props) {
  const {
    latestPrices,
    pares,
    pairs,
    setPairs,
    cleanPairs,
    ourSelectedToken,
    setOurSelectedToken,
    swapOption,
    fromTokenAddress,
    toTokenAddress,
    infoTokens,
    chainId,
    positions,
    savedShouldShowPositionLines,
    orders,
    setActualPrice,
    setToTokenAddress,
    setOurTokenIndex,
    formattedLongOpenInterest,
    formattedShortOpenInterest,
    formattedMaxOpenInterest,
    setCleanPairs,
    onSelectToken,
  } = props;

  const [currentChart, setCurrentChart] = useState();
  const [currentSeries, setCurrentSeries] = useState();

  let [period, setPeriod] = useLocalStorageSerializeKey([chainId, "Chart-period"], DEFAULT_PERIOD);
  if (!(period in CHART_PERIODS)) {
    period = DEFAULT_PERIOD;
  }

  const [hoveredCandlestick, setHoveredCandlestick] = useState();
  const [tokenTitle, setTokenTitle] = useState();

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
  const toToken = getTokenInfo(infoTokens, toTokenAddress);

  const [chartToken, setChartToken] = useState({
    maxPrice: null,
    minPrice: null,
  });

  useEffect(() => {
    const tmp = getChartToken(swapOption, fromToken, toToken, chainId);
    setChartToken(tmp);
  }, [swapOption, fromToken, toToken, chainId]);

  const symbol = chartToken ? (chartToken.isWrapped ? chartToken.baseSymbol : chartToken.symbol) : undefined;
  const marketName = chartToken ? symbol + "_USD" : undefined;
  const previousMarketName = usePrevious(marketName);

  const currentOrders = useMemo(() => {
    if (swapOption === SWAP || !chartToken || !orders) {
      return [];
    }

    return orders.filter((order) => {
      if (order.type === SWAP) {
        return false;
      }

      const indexToken = getToken(chainId, order.indexToken);
      return order.indexToken === chartToken.address || (chartToken.isNative && indexToken.isWrapped);
    });
  }, [orders, chartToken, swapOption, chainId]);

  const ref = useRef(null);
  const chartRef = useRef(null);

  const currentAveragePrice =
    chartToken.maxPrice && chartToken.minPrice ? chartToken.maxPrice.add(chartToken.minPrice).div(2) : null;
  const priceData = [];
  /*
  const [priceData, updatePriceData] = useChartPrices(
    chainId,
    chartToken.symbol,
    chartToken.isStable,
    period,
    currentAveragePrice
  );
*/
  const [chartInited, setChartInited] = useState(false);
  useEffect(() => {
    if (marketName !== previousMarketName) {
      setChartInited(false);
    }
  }, [marketName, previousMarketName]);

  const scaleChart = useCallback(() => {
    const from = Date.now() / 1000 - (7 * 24 * CHART_PERIODS[period]) / 2 + timezoneOffset;
    const to = Date.now() / 1000 + timezoneOffset;
    currentChart.timeScale().setVisibleRange({ from, to });
  }, [currentChart, period]);

  // const onCrosshairMove = useCallback(
  //   (evt) => {
  //     if (!evt.time) {
  //       setHoveredCandlestick(null);
  //       return;
  //     }

  //     for (const point of evt.seriesPrices.values()) {
  //       setHoveredCandlestick((hoveredCandlestick) => {
  //         if (hoveredCandlestick && hoveredCandlestick.time === evt.time) {
  //           // rerender optimisations
  //           return hoveredCandlestick;
  //         }
  //         return {
  //           time: evt.time,
  //           ...point,
  //         };
  //       });
  //       break;
  //     }
  //   },
  //   [setHoveredCandlestick]
  // );
  /*
  useEffect(() => {
    const interval = setInterval(() => {
      updatePriceData(undefined, true);
    }, 30 * 1000);
    return () => clearInterval(interval);
  }, [updatePriceData]);*/

  useEffect(() => {
    if (!currentChart) {
      return;
    }
    const resizeChart = () => {
      currentChart.resize(chartRef.current.offsetWidth, chartRef.current.offsetHeight);
    };
    window.addEventListener("resize", resizeChart);
    return () => window.removeEventListener("resize", resizeChart);
  }, [currentChart]);

  useEffect(() => {
    if (currentSeries && priceData && priceData.length) {
      currentSeries.setData(priceData);

      if (!chartInited) {
        scaleChart();
        setChartInited(true);
      }
    }
  }, [priceData, currentSeries, chartInited, scaleChart]);

  useEffect(() => {
    const lines = [];

    if (currentSeries && savedShouldShowPositionLines) {
      if (currentOrders && currentOrders.length > 0) {
        currentOrders.forEach((order) => {
          const indexToken = getToken(chainId, order.indexToken);
          let tokenSymbol;
          if (indexToken && indexToken.symbol) {
            tokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;
          }
          const title = `${order.type === INCREASE ? t`Inc.` : t`Dec.`} ${tokenSymbol} ${
            order.isLong ? t`Long` : t`Short`
          }`;
          const color = "#3a3e5e";
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(order.triggerPrice, USD_DECIMALS, 2)),
              color,
              title: title.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            })
          );
        });
      }
      if (positions && positions.length > 0) {
        const color = "#3a3e5e";
        positions.forEach((position) => {
          const longOrShortText = position.isLong ? t`Long` : t`Short`;
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(position.averagePrice, USD_DECIMALS, 2)),
              color,
              title: t`Open ${position.indexToken.symbol} ${longOrShortText}`.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            })
          );

          const liquidationPrice = getLiquidationPrice(position);
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(liquidationPrice, USD_DECIMALS, 2)),
              color,
              title: t`Liq. ${position.indexToken.symbol} ${longOrShortText}`.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            })
          );
        });
      }
    }
    return () => {
      lines.forEach((line) => currentSeries.removePriceLine(line));
    };
  }, [currentOrders, positions, currentSeries, chainId, savedShouldShowPositionLines]);

  // const candleStatsHtml = useMemo(() => {
  //   if (!priceData) {
  //     return null;
  //   }
  //   const candlestick = hoveredCandlestick || priceData[priceData.length - 1];
  //   if (!candlestick) {
  //     return null;
  //   }

  //   const className = cx({
  //     "ExchangeChart-bottom-stats": true,
  //     positive: candlestick.open <= candlestick.close,
  //     negative: candlestick.open > candlestick.close,
  //     [`length-${String(parseInt(candlestick.close)).length}`]: true,
  //   });

  //   const toFixedNumbers = 2;

  //   return (
  //     <div className={className}>
  //       <span className="ExchangeChart-bottom-stats-label">O</span>
  //       <span className="ExchangeChart-bottom-stats-value">{candlestick.open.toFixed(toFixedNumbers)}</span>
  //       <span className="ExchangeChart-bottom-stats-label">H</span>
  //       <span className="ExchangeChart-bottom-stats-value">{candlestick.high.toFixed(toFixedNumbers)}</span>
  //       <span className="ExchangeChart-bottom-stats-label">L</span>
  //       <span className="ExchangeChart-bottom-stats-value">{candlestick.low.toFixed(toFixedNumbers)}</span>
  //       <span className="ExchangeChart-bottom-stats-label">C</span>
  //       <span className="ExchangeChart-bottom-stats-value">{candlestick.close.toFixed(toFixedNumbers)}</span>
  //     </div>
  //   );
  // }, [hoveredCandlestick, priceData]);

  let high;
  let low;
  let deltaPrice;
  let delta;
  let deltaPercentage;
  let deltaPercentageStr;
  const [initialPos, setInitialPos] = useState();
  const [initialSize, setInitialSize] = useState(500);
  const [dailyChange, setDailyChange] = useState(0);
  const [chartHeight, setChartHeight] = useState("");

  useEffect(() => {
    if (priceData) {
      //Price data can be in Days, hours, or minutes:
      if (priceData.length === 157) {
        if (priceData[priceData.length - 1] && priceData[priceData.length - 2]) {
          var aux = priceData[priceData.length - 1].close - priceData[priceData.length - 2].close;
          var percentage = (aux * 100) / priceData[priceData.length - 2].close;
          setDailyChange(percentage.toFixed(2));
        }
      } else if (priceData.length === 941) {
        if (priceData[priceData.length - 1] && priceData[priceData.length - 7]) {
          aux = priceData[priceData.length - 1].close - priceData[priceData.length - 7].close;
          percentage = (aux * 100) / priceData[priceData.length - 7].close;
          setDailyChange(percentage.toFixed(2));
        }
      } else if (priceData.length === 3766) {
        if (priceData[priceData.length - 1] && priceData[priceData.length - 25]) {
          aux = priceData[priceData.length - 1].close - priceData[priceData.length - 25].close;
          percentage = (aux * 100) / priceData[priceData.length - 25].close;
          setDailyChange(percentage.toFixed(2));
        }
      } else if (priceData.length === 15063) {
        if (priceData[priceData.length - 1] && priceData[priceData.length - 100]) {
          aux = priceData[priceData.length - 1].close - priceData[priceData.length - 100].close;
          percentage = (aux * 100) / priceData[priceData.length - 100].close;
          setDailyChange(percentage.toFixed(2));
        }
      } else if (priceData.length === 45186) {
        if (priceData[priceData.length - 1] && priceData[priceData.length - 1500]) {
          aux = priceData[priceData.length - 1].close - priceData[priceData.length - 1500].close;
          percentage = (aux * 100) / priceData[priceData.length - 1500].close;
          setDailyChange(percentage.toFixed(2));
        }
      }
    }
  }, [priceData]);

  const now = parseInt(Date.now() / 1000);
  const timeThreshold = now - 24 * 60 * 60;

  if (priceData) {
    if (priceData[priceData.length - 1]) {
      setActualPrice(parseFloat(priceData[priceData.length - 1].close.toFixed(2)));
    }

    for (let i = priceData.length - 1; i > 0; i--) {
      const price = priceData[i];
      if (price.time < timeThreshold) {
        break;
      }
      if (!low) {
        low = price.low;
      }
      if (!high) {
        high = price.high;
      }

      if (price.high > high) {
        high = price.high;
      }
      if (price.low < low) {
        low = price.low;
      }

      deltaPrice = price.open;
    }
  }

  if (deltaPrice && currentAveragePrice) {
    const average = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 2));
    delta = average - deltaPrice;
    deltaPercentage = (delta * 100) / average;
    if (deltaPercentage > 0) {
      deltaPercentageStr = `+${deltaPercentage.toFixed(2)}%`;
    } else {
      deltaPercentageStr = `${deltaPercentage.toFixed(2)}%`;
    }
    if (deltaPercentage === 0) {
      deltaPercentageStr = "0.00";
    }
  }

  if (!chartToken) {
    return null;
  }

  const onSelect = async (pairSymbols, pairName) => {
    console.log(pairSymbols, pairName);
    const pairIndex = cleanPairs.find((i) => {
      return i[7] === pairName;
    });

    // Función para recibir precio || precio se cambia en pairs, no en cleanPairs
    const precioPair = cleanPairs.find((i) => {
      return i[7] === pairName;
    });

    const symbol = pairSymbols.split("/")[0];
    // Añado precio para pasarselo a gush en el objeto token, si da error avisar

    const token = {
      pairSymbols: pairSymbols,
      symbol: symbol,
      pairName: pairName,
      pairIndex: pairIndex[1],
      price: precioPair[4],
      marketStatus: precioPair[5],
      pairFullName: precioPair[0],
    };

    setTokenTitle(pairName);
    onSelectToken(token);
  };

  return (
    <div className="ExchangeChart tv" ref={ref}>
      <FavoritesHeader
        ourSelectedToken={ourSelectedToken}
        setOurSelectedToken={setOurSelectedToken}
        onSelect={onSelect}
        latestPrices={latestPrices}
        cleanPairs={cleanPairs}
      />
      <div
        className="ExchangeChart-top App-box App-box-border"
        style={{ marginBottom: "0", height: "50px", boxSizing: "border-box" }}
      >
        <div className="ExchangeChart-top-inner">
          <div>
            <div className="ExchangeChart-title">
              <ChartTokenSelector
                pares={pares}
                setCleanPairs={setCleanPairs}
                onSelect={onSelect}
                tokenTitle={tokenTitle}
                cleanPairs={cleanPairs}
                pairs={pairs}
                setPairs={setPairs}
                chainId={chainId}
                selectedToken={chartToken}
                swapOption={swapOption}
                infoTokens={infoTokens}
                onSelectToken={onSelectToken}
                ourSelectedToken={ourSelectedToken}
                className="chart-token-selector"
              />
            </div>
          </div>
          <div>
            <div className="ExchangeChart-info-label"></div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "40px",
            }}
          >
            <div className="ExchangeChart-additional-info">
              <div className="ExchangeChart-info-label" style={{ textAlign: "center" }}>
                <Tooltip
                  handle={`Open interest long`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div style={{ color: "#999" }}>
                        <Trans>Sum of long open positions of this pair</Trans>
                      </div>
                    );
                  }}
                />
              </div>
              <div>
                {formattedLongOpenInterest &&
                  formattedMaxOpenInterest &&
                  formattedLongOpenInterest + "/" + formattedMaxOpenInterest}
              </div>
            </div>
            <div className="ExchangeChart-additional-info">
              <div className="ExchangeChart-info-label">
                <Tooltip
                  handle={`Open interest short`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div style={{ color: "#999" }}>
                        <Trans>Sum of short open positions of this pair</Trans>
                      </div>
                    );
                  }}
                />
              </div>
              <div>
                {formattedShortOpenInterest &&
                  formattedMaxOpenInterest &&
                  formattedShortOpenInterest + "/" + formattedMaxOpenInterest}
              </div>
            </div>
            {/*
            <div className="ExchangeChart-additional-info">
              <div className="ExchangeChart-info-label">
                <Tooltip
                  handle={`Rollover`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div style={{ color: "#999" }}>
                        <Trans>Rollover Fee Hourly rate, charged per block on the collateral only</Trans>
                      </div>
                    );
                  }}
                />
              </div>
              <div>{rolloverFees && rolloverFees}%</div>
            </div>
            */}
          </div>
        </div>
      </div>

      <TradingViewWidget ourSelectedToken={ourSelectedToken} />

      {/* <TVChartContainer ourSelectedToken={ourSelectedToken} /> */}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Menu } from "@headlessui/react";

const DesplegablePrices = ({ pairs, onSelect }) => {
  const [cursor, setCursor] = useState("pointer");
  const defaultColor = "none";

  useEffect(() => {
    console.log(pairs)
  }, [pairs])

  return (
    <>
      <div style={{ width: "20%" }}>
        <p style={{ marginBottom: "10px", fontSize: "16px", color: "#616161" }}>Price</p>
        {pairs.map((option, index) => {
          let color = "#fff";

          if (option[4] > option[6]) {
            color = "#148A63";
          } else {
            color = "#E23E3E";
          }

          return (
            <Menu.Item
              key={index}
              onClick={() => {
                if (option[4] != 0) {
                  onSelect(option[0], option[7]);
                }
              }}
            >
              <div className="menu-item" style={{ marginLeft: "-20px" }}>
                <span
                  className={`${option[4] == 0 ? "notAllowed" : "allowed"} pairPriceSpan`}
                  style={{
                    color: color,
                  }}
                >
                  {`${option[4] == "-1" ? "--" : option[4]}`}
                </span>
              </div>
            </Menu.Item>
          );
        })}
      </div>
      <div style={{ width: "20%" }}>
        <p
          style={{
            marginBottom: "9px",
            marginLeft: "30px",
            color: "#616161",
            fontSize: "16px",
            fontFamily: "Lato",
            fontWeight: "400",
          }}
        >
          24hs
        </p>
        {pairs.map((option, index) => {
          let color;

          if (option[5] === "CLOSED" || option[5] < 0) {
            color = "#E23E3E";
          } else {
            color = "#148A63";
          }
          return (
            <Menu.Item
              key={index}
              onClick={() => {
                if (option[4] != 0) {
                  onSelect(option[0], option[7]);
                }
              }}
            >
              <div className="menu-item">
                <span
                  style={{
                    color: color,
                  }}
                  className={`${option[4] == 0 ? "notAllowed" : "allowed"} pairPriceSpan`}
                >
                  {option[5] === "CLOSED" ? option[5] : `${option[5]} %`}
                </span>
              </div>
            </Menu.Item>
          );
        })}
      </div>
    </>
  );
};

export default DesplegablePrices;

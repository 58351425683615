import React, { useEffect, useState, useContext} from "react";
import "./TradingCompetition.css";
import { TradingCompetitorUser } from "components/TradingCompetition/TradingCompetitorUser";
import useSWR from "swr";

import AppContext from "AppContext";

export const Leaderboard = () => {
  const { MODE, filteredEndpointsInfo } = useContext(AppContext);
  const [dataLeaderboard, setDataLeaderboard] = useState([]);
  const [dataRange, setDataRange] = useState("all");
  const DATA_API_URL = filteredEndpointsInfo.data_api;
  const LEADERBOARD_URL = `${DATA_API_URL}/leaderboard`;

  const dataRangeButtons = [
    { value: "all", label: "all", active: dataRange === "all" },
    { value: "30", label: "30d", active: dataRange === "30" },
    { value: "7", label: "7d", active: dataRange === "7" },
    { value: "1", label: "24h", active: dataRange === "1" },
  ];

  const fetcher = () =>
    fetch(LEADERBOARD_URL, {
      method: "POST",
      body: {
        "Content-Type": "application/json",
      },
    }).then((resp) => resp.json());

  const { data, error } = useSWR(LEADERBOARD_URL, { fetcher, refreshInterval: 30000 });

  useEffect(() => {
    if (!data) return;
    setDataLeaderboard(data.leaderboard[dataRange]);
  }, [data]);

  useEffect(() => {
    if (data && data.leaderboard) {
      setDataLeaderboard(data.leaderboard[dataRange]);
    }
  }, [data, dataRange]);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
      <div className="TradingTitle">
        <h1 className="leaderboard-title">🏆 GENERAL TRADING 🏆</h1>
      </div>

      <div className="TradingButtons">
        {dataRangeButtons.map((button) => (
          <button
            key={button.value}
            className={`RangeButton ${button.active ? "active" : "inactive"}`}
            onClick={() => setDataRange(button.value)}
          >
            {button.label}
          </button>
        ))}
      </div>

      <div className="TradingCompetition">
        <div className="TradingCompetitionHeader">
          <div className="competition-info">Rank</div>
          <div className="competition-info">Address</div>
          <div className="competition-info">Trades</div>
          <div className="competition-info">Winrate</div>
          <div className="competition-info">PnL</div>
        </div>
      </div>

      {Array.isArray(dataLeaderboard)
        ? dataLeaderboard.map((tradingCompetitor, index) => {
            return <TradingCompetitorUser index={index + 1} tradingCompetitor={tradingCompetitor} key={index} />;
          })
        : ""}
    </div>
  );
};

import React, { useEffect, useRef, useState } from "react";
import "./TradingView.css";
import { Resizable } from "re-resizable";
let tvScriptLoadingPromise;

export default function TradingViewWidget({ ourSelectedToken }) {
  const onLoadScriptRef = useRef();
  const containerRef = useRef(null);
  let token = ourSelectedToken.pairName;
  const [height, setHeight] = useState(() => {
    // Obtener la altura guardada en el localStorage al inicio
    const savedHeight = localStorage.getItem("resizableHeight");
    return savedHeight ? parseInt(savedHeight, 10) : 100;
  });

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;
        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (document.getElementById("tradingview_a785c") && "TradingView" in window) {
        new window.TradingView.widget({
          autosize: true,
          symbol: token,
          timezone: "Etc/UTC",
          theme: "dark",
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          hide_legend: true,
          withdateranges: true,
          hide_side_toolbar: false,
          allow_symbol_change: false,
          save_image: false,
          show_popup_button: true,
          container_id: "tradingview_a785c",
        });
      }
    }
  }, [token]);

  useEffect(() => {
    localStorage.setItem("resizableHeight", height);
  }, [height]);

  return (
    <Resizable
      id="Resizable"
      size={{ height, width: "100%" }}
      onResizeStop={(e, direction, ref, d) => {
        // Actualizar la altura al cambiar el tamaño
        setHeight(ref.style.height);
      }}
    >
      <div id="tradingview_a785c" ref={containerRef} />
    </Resizable>
  );
}

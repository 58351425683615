import React from "react";
import { Trans, t, select } from "@lingui/macro";

const PositionsListHeader = () => {
  return (
    <div
      id="PositionsListHeader"
      style={{
        display: "flex",
        justifyContent: "space-between",
        fontSize: "14px",
        backgroundColor: "#303030",
        marginTop: "-25px",
        padding: "5px 0px",
      }}
    >
      {/* Type Pair Leverage Collateral Openprice Price Liq/SL TP NetPnL Close */}
      <div style={{ textAlign: "center", width: "8%", color: "#828785" }}>
        <Trans>Status</Trans>
        {/* Ok */}
      </div>
      <div style={{ textAlign: "center", width: "8%", color: "#828785" }}>
        <Trans>Type</Trans>
        {/* Ok */}
      </div>
      <div style={{ textAlign: "center", width: "8%", color: "#828785" }}>
        <Trans>Pair</Trans>
        {/* Ok */}
      </div>
      <div style={{ textAlign: "center", width: "8%", color: "#828785" }}>
        <Trans>Position</Trans>
        {/* Ok */}
      </div>

      <div className="hide" style={{ textAlign: "center", width: "8%", color: "#828785" }}>
        <Trans>Collateral</Trans>
        {/* Ok */}
      </div>

      <div className="hide" style={{ textAlign: "center", width: "8%", color: "#828785" }}>
        <Trans>Leverage</Trans>
        {/* Ok */}
      </div>

      <div className="hide" style={{ textAlign: "center", width: "8%", color: "#828785" }}>
        <Trans>Current price</Trans>
        {/* Ok */}
      </div>

      <div className="hide" style={{ textAlign: "center", width: "8%", color: "#828785" }}>
        <Trans>Open price</Trans>
        {/* Ok */}
      </div>

      <div className="hide" style={{ textAlign: "center", width: "8%", color: "#828785" }}>
        <Trans>Liq/SL</Trans>
        {/* Ok */}
      </div>
      <div className="hide" style={{ textAlign: "center", width: "8%", color: "#828785" }}>
        <Trans>Take profit</Trans>
        {/* Ok */}
      </div>
      <div style={{ textAlign: "center", width: "8%", color: "#828785" }}>
        <Trans>Net PnL</Trans>
        {/* Ok */}
      </div>
      <div style={{ textAlign: "center", width: "8%", color: "#828785" }}>
        <Trans>Actions</Trans>
        {/* Ok */}
      </div>
    </div>
  );
};

export default PositionsListHeader;
